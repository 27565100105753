require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/0ad2b60f2b5d7267fc2a9b1ae396cd0a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a'] = async function fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a(pageName) {
var isTeacherTest, teacherPages;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTeacherTest = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher'));
  teacherPages = ['Schedule', 'Students', 'StudentDetail', 'Settings'];
  // If user not logged in, they get redirected to Landing unless on a Public page
  // This means user is not logged in
  // If not a teacher, user has no business on a page that's just for teachers. Send them to Home.
  // If teacher lands on Home for some reason, redirect to Schedule.
  if (!((await Backendless.UserService.getCurrentUser(false)) !== null && !Array.isArray((await Backendless.UserService.getCurrentUser(false))) && (typeof (await Backendless.UserService.getCurrentUser(false)) === 'object')) && 'Public' != pageName) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Landing', undefined);
  } else if (isTeacherTest == false && (teacherPages.includes(pageName))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);
  } else if (isTeacherTest == true && pageName == 'Home') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Schedule', undefined);
  }

return isTeacherTest
}

})
define('./functions/0da4120383addec95876bbd33fbcb589/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0da4120383addec95876bbd33fbcb589'] = async function fn_0da4120383addec95876bbd33fbcb589(type, params, data, is_production) {
var params, stripe_customer_id, stripe_element_appearance, stripe_element_options;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  stripe_element_options = ({ 'terms': ({ 'card': 'never' }) });
  // we use Verdana because it's the closest to Nunito, and otherwise we have to load the font as we load the form
  stripe_element_appearance = ({ 'theme': 'stripe','variables': ({ 'fontFamily': 'Verdana, sans-serif','colorPrimary': '#DAC1B5','colorText': '#000006 ','colorTextPlaceholder': '#DAC1B5','colorDanger': '#E44868' }),'rules': ({ '.Label': ({ 'color': '#DAC1B5' }) }),'labels': 'floating' });
  if (!(typeof type === 'string')) {
    throw (new Error('Type required. "single" or "subscription"'))
  }
  if (!(params !== null && !Array.isArray(params) && (typeof params === 'object'))) {
    throw (new Error('Params required'))
  }
  if (!(data !== null && !Array.isArray(data) && (typeof data === 'object'))) {
    throw (new Error('Data required'))
  }
  stripe_customer_id = (getObjectProperty(params, 'stripe_customer_id'));
  params = (Object.assign(params, ({ 'options': stripe_element_options,'appearance': stripe_element_appearance })));
  if (type == 'single') {
    await (async function(params, data, is_production) {
    	UbindiStripe.ENVIRONMENT = is_production ? 'prod' : 'dev';
    	UbindiStripe.createStudentSinglePaymentElement(params, data);
    })(params, data, (is_production == true));
  } else if (type == 'subscription') {
    if (!((typeof stripe_customer_id === 'string') && (stripe_customer_id.startsWith('cus_')))) {
      throw (new Error('stripe_customer_id required for subscriptions'))
    }
    await (async function(params, data, is_production) {
    	UbindiStripe.ENVIRONMENT = is_production ? 'prod' : 'dev';
    	UbindiStripe.createStudentSubscriptionPaymentElement(params, data);
    })(params, data, (is_production == true));
  } else {
    throw (new Error('Invalid stripeElement type'))
  }

return 
}

})
define('./functions/16b8adb496affb2332e4f778c51323cf/code.js', () => { 

BackendlessUI.Functions.Custom['fn_16b8adb496affb2332e4f778c51323cf'] = function fn_16b8adb496affb2332e4f778c51323cf() {
var optionsText, i, incrementMins, optionsObject;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function genMinSelectOptions() {
  optionsObject = [];
  incrementMins = 5;
  var i_inc2 = Math.abs(incrementMins);
  if (0 > 59) {
    i_inc2 = -i_inc2;
  }
  for (i = 0; i_inc2 >= 0 ? i <= 59 : i >= 59; i += i_inc2) {
    addItemToList(optionsObject, ({ [`value`]: i,[`label`]: i < 10 ? String('0') + String(JSON.stringify(i)) : (JSON.stringify(i)) }));
  }
  return optionsObject
}


  optionsText = '';
  incrementMins = 5;
  var i_inc = Math.abs(incrementMins);
  if (0 > 59) {
    i_inc = -i_inc;
  }
  for (i = 0; i_inc >= 0 ? i <= 59 : i >= 59; i += i_inc) {
    optionsText = String(optionsText) + String(['{"value":',(JSON.stringify(i)),',"label":"',i < 10 ? String('0') + String(JSON.stringify(i)) : (JSON.stringify(i)),'"},'].join(''));
  }
  optionsText = ['[',optionsText.slice(0, optionsText.length - 1),']'].join('');

return (JSON.parse(optionsText))
}

})
define('./functions/16d4175dec79f99b2284796afa1aed12/code.js', () => { 

BackendlessUI.Functions.Custom['fn_16d4175dec79f99b2284796afa1aed12'] = async function fn_16d4175dec79f99b2284796afa1aed12(classInstanceObj, student_object_id, studentPackageObj, attended, paid_online, teacher_object_id) {
var attendanceObj, txResult, student_package_used, addStudentToClassTx, cost, upsertStudentPackageRef, studentCredits, createAttendanceObjRef, upsertClassInstanceRef;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Cost for the instance object will have been adjusted
  // if it's donation based pricing. Get it out here as
  // a variable and remove from classInstanceObject so
  // upsert doesn't change the cost of the class for others!
  cost = (getObjectProperty(classInstanceObj, 'cost'));
  // Delete cost property so we don't change class
  // cost when upserting Class Instance Object.
  delete classInstanceObj['cost'];
  // Increment spots taken for the class instance object
  classInstanceObj['taken_spots'] = ((getObjectProperty(classInstanceObj, 'taken_spots')) + 1);
  // if studentPackageObject is supplied to the function...
  if (!(studentPackageObj === null)) {
    studentCredits = (getObjectProperty(studentPackageObj, 'credits'));
    // ...check to see if credits are involved. If so, we adjust credits the student has.
    if (typeof studentCredits === 'number' && !isNaN(studentCredits)) {
      // Take a credit away from the student's package
      studentPackageObj['credits'] = (studentCredits - 1);
    }
  }
  // Massive transaction now creates user, various table objects + relations...
  addStudentToClassTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = addStudentToClassTx;

    // Upsert object in class_instances table with new spots_taken.
    upsertClassInstanceRef = (_uow.upsert('class_instances', classInstanceObj).setOpResultId('upsertClassInstance'));
    // Create a new record in the class_attendance_entries
    // table. Need at least one property, do attended = false.
    createAttendanceObjRef = (_uow.create('class_attendance_entries', ({ [`attended`]: attended,[`paid_online`]: paid_online })).setOpResultId('createAttendanceObj'));
    // Relate class instance to class attendance record.
    _uow.setRelation('class_attendance_entries', (createAttendanceObjRef.resolveTo('objectId')), 'class_instance_id', (upsertClassInstanceRef.resolveTo('objectId'))).setOpResultId('relateAttendanceToClassInstance');
    // Relate student to class attendance record.
    _uow.setRelation('class_attendance_entries', (createAttendanceObjRef.resolveTo('objectId')), 'student_id', ({ [`objectId`]: student_object_id })).setOpResultId('relateAttendanceToStudent');
    if (!(studentPackageObj === null)) {
      // Relate class_attendance record to student_package used for the registration.
      _uow.setRelation('class_attendance_entries', (createAttendanceObjRef.resolveTo('objectId')), 'student_package_used', studentPackageObj).setOpResultId('relateAttendanceToStudentPackage');
      // ...check to see if credits are involved. If so, we adjust credits the student has.
      if (typeof studentCredits === 'number' && !isNaN(studentCredits)) {
        // Upsert object in student_packages table with a credit taken away.
        upsertStudentPackageRef = (_uow.upsert('student_packages', studentPackageObj).setOpResultId('upsertStudentPackage'));
      }
    } else {
      // Don't write to metrics table if costs is $0
      if (cost > 0) {
        // If no package was used to register student, we save an item to revenue_metrics
        _uow.create('revenue_metrics', ({ [`source`]: 'class',[`student_profile_Id`]: student_object_id,[`teacher_profile_id`]: teacher_object_id,[`value`]: cost })).setOpResultId('saveToMetrics');
      }
    }

  })();

  txResult = (await addStudentToClassTx.execute());
  if (txResult.isSuccess()) {
    attendanceObj = (getObjectProperty((txResult.getResults()), 'createAttendanceObj.result'));
    // We add the class_instance_id to the attendanceObj because we need it sometimes.
    attendanceObj['class_instance_id'] = (getObjectProperty((getObjectProperty((txResult.getResults()), 'upsertClassInstance.result')), 'objectId'));
    student_package_used = (getObjectProperty((txResult.getResults()), 'upsertStudentPackage.result'));
    // We add package_used to the attendanceObj because we need it sometimes.
    // if no package used, we want "null" (not undefined)
    attendanceObj['student_package_used'] = ((typeof student_package_used === 'undefined') ? null : student_package_used);
  } else {
    console.log('Failed to register student for class. Error is:');
    console.log(txResult.getError());
    throw (txResult.getError())
  }

return attendanceObj
}

})
define('./functions/1784cfcd953fdb5af22c06ed5426be72/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1784cfcd953fdb5af22c06ed5426be72'] = async function fn_1784cfcd953fdb5af22c06ed5426be72(value) {
return (await (async function(value) {
	return toFixed(2)
})(null))
}

})
define('./functions/2b649a7c977d9e7a695f1b21106afc93/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'] = async function fn_2b649a7c977d9e7a695f1b21106afc93(PageData, tableName, whereClause, properties, relations, relDepth, sortBy) {
var allData, loading, currentBatch, offset;


  PageData['loading'] = true;
  loading = true;
  allData = [];
  offset = 0;
  while (loading) {
    // Get all students and their tags for the logged in teacher. The properties input gets data from the profiles table (child table of teacher_student_rels).
    currentBatch = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(properties).setRelated(relations).setRelationsDepth(relDepth).setSortBy(relDepth).setPageSize(100)));
    allData = (allData.concat(currentBatch));
    offset = offset + 100;
    if (currentBatch.length < 100) {
      loading = false;
      PageData['loading'] = false;
    }
  }

return allData
}

})
define('./functions/353fc83c55a5eb4a3842590682a2bd34/code.js', () => { 

BackendlessUI.Functions.Custom['fn_353fc83c55a5eb4a3842590682a2bd34'] = async function fn_353fc83c55a5eb4a3842590682a2bd34(classInstanceObj, student_object_id, studentPackageObj, attended, paid_online, teacher_object_id) {
var attendanceObj, txResult, student_package_used, addStudentToClassTx, cost, upsertStudentPackageRef, studentCredits, createAttendanceObjRef, upsertClassInstanceRef;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Cost for the instance object will have been adjusted
  // if it's donation based pricing. Get it out here as
  // a variable and remove from classInstanceObject so
  // upsert doesn't change the cost of the class for others!
  cost = (getObjectProperty(classInstanceObj, 'cost'));
  // Delete cost property so we don't change class
  // cost when upserting Class Instance Object.
  delete classInstanceObj['cost'];
  // Increment spots taken for the class instance object
  classInstanceObj['taken_spots'] = ((getObjectProperty(classInstanceObj, 'taken_spots')) + 1);
  // if studentPackageObject is supplied to the function...
  if (!(studentPackageObj === null)) {
    studentCredits = (getObjectProperty(studentPackageObj, 'credits'));
    // ...check to see if credits are involved. If so, we adjust credits the student has.
    if (typeof studentCredits === 'number' && !isNaN(studentCredits)) {
      // Take a credit away from the student's package
      studentPackageObj['credits'] = (studentCredits - 1);
    }
  }
  // Massive transaction now creates user, various table objects + relations...
  addStudentToClassTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = addStudentToClassTx;

    // Upsert object in class_instances table with new spots_taken.
    upsertClassInstanceRef = (_uow.upsert('class_instances', classInstanceObj).setOpResultId('upsertClassInstance'));
    // Create a new record in the class_attendance_entries
    // table. Need at least one property, do attended = false.
    createAttendanceObjRef = (_uow.create('class_attendance_entries', ({ [`attended`]: attended,[`paid_online`]: paid_online })).setOpResultId('createAttendanceObj'));
    // Relate class instance to class attendance record.
    _uow.setRelation('class_attendance_entries', (createAttendanceObjRef.resolveTo('objectId')), 'class_instance_id', (upsertClassInstanceRef.resolveTo('objectId'))).setOpResultId('relateAttendanceToClassInstance');
    // Relate student to class attendance record.
    _uow.setRelation('class_attendance_entries', (createAttendanceObjRef.resolveTo('objectId')), 'student_id', ({ [`objectId`]: student_object_id })).setOpResultId('relateAttendanceToStudent');
    if (!(studentPackageObj === null)) {
      // Relate class_attendance record to student_package used for the registration.
      _uow.setRelation('class_attendance_entries', (createAttendanceObjRef.resolveTo('objectId')), 'student_package_used', studentPackageObj).setOpResultId('relateAttendanceToStudentPackage');
      // ...check to see if credits are involved. If so, we adjust credits the student has.
      if (typeof studentCredits === 'number' && !isNaN(studentCredits)) {
        // Upsert object in student_packages table with a credit taken away.
        upsertStudentPackageRef = (_uow.upsert('student_packages', studentPackageObj).setOpResultId('upsertStudentPackage'));
      }
    } else {
      // Don't write to metrics table if costs is $0
      if (cost > 0) {
        // If no package was used to register student, we save an item to revenue_metrics
        _uow.create('revenue_metrics', ({ [`source`]: 'class',[`student_profile_Id`]: student_object_id,[`teacher_profile_id`]: teacher_object_id,[`value`]: cost })).setOpResultId('saveToMetrics');
      }
    }

  })();

  txResult = (await addStudentToClassTx.execute());
  if (txResult.isSuccess()) {
    attendanceObj = (getObjectProperty((txResult.getResults()), 'createAttendanceObj.result'));
    // We add the class_instance_id to the attendanceObj because we need it sometimes.
    attendanceObj['class_instance_id'] = (getObjectProperty((getObjectProperty((txResult.getResults()), 'upsertClassInstance.result')), 'objectId'));
    student_package_used = (getObjectProperty((txResult.getResults()), 'upsertStudentPackage.result'));
    // We add package_used to the attendanceObj because we need it sometimes.
    // if no package used, we want "null" (not undefined)
    attendanceObj['student_package_used'] = ((typeof student_package_used === 'undefined') ? null : student_package_used);
  } else {
    console.log('Failed to register student for class. Error is:');
    console.log(txResult.getError());
    throw (txResult.getError())
  }

return attendanceObj
}

})
define('./functions/3d883efd8c416a7df85afca371c23a98/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3d883efd8c416a7df85afca371c23a98'] = async function fn_3d883efd8c416a7df85afca371c23a98(email, first_name, last_name) {
var studentProfileObject, urlVariable, existingURLs;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

/**
 * New teacher registration requires URL for their public
 * profile, run this function to piece together first &
 * last name. If such a URL already exists, append number
 */
async function generateUniqueURL() {
  // default url is first_name.last_name —  unless no last name supplied, then just first_name.
  urlVariable = String(first_name) + String(last_name == null ? '' : String('.') + String(last_name));
  // Get rid of any characters other than letters, numbers, underscores and hyphens.
  urlVariable = (urlVariable.match((new RegExp('[a-zA-Z0-9_\\.\\-]', 'g')))).join('');
  // Check if URL is already taken. If not, just return the initial url.
  if (0 == (await Backendless.Data.of('profiles').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['url = \'',urlVariable,'\''].join('')))))) {
    return urlVariable;
  } else {
    existingURLs = ((await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['url LIKE \'',urlVariable,'%\''].join(''))).setPageSize(10))).map(item => item['url']));
    while (existingURLs.includes(urlVariable)) {
      // Add random number to url variable until it's not in list of already taken URLs
      urlVariable = String(urlVariable) + String(mathRandomInt(1, 100));
    }
  }
  return urlVariable
}


  // no email — there cannot be an existing profile
  // and we create one with the data from the form.
  if ((email === null) || email == '' || (typeof email === 'undefined')) {
    studentProfileObject = (await Backendless.Data.of('profiles').save( ({ [`first_name`]: first_name,[`last_name`]: last_name,[`url`]: await generateUniqueURL() }) ));
    return studentProfileObject;
  } else {
    // Look for existing profiles with the email address supplied, get the 1st one
    studentProfileObject = (await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['skeleton_email=\'',email,'\''].join(''))).setRelated('user_email').setPageSize(10)))[0];
    // if the query fails, there is no existing profile
    // and we create one with the data from the form.
    if (typeof studentProfileObject === 'undefined') {
      studentProfileObject = (await Backendless.Data.of('profiles').save( ({ [`first_name`]: first_name,[`last_name`]: last_name,[`skeleton_email`]: email,[`url`]: await generateUniqueURL() }) ));
      return studentProfileObject;
    } else {
      // if the profile object has a user email attached, it's a live profile and we just return the profile object, no editing.
      if ((getObjectProperty(studentProfileObject, 'user_email')) != null) {
        return studentProfileObject;
      } else {
        // We have a skeleton profile. If the name matches the form data, just
        // return the object. Otherwise, update the name based on the form data.
        if ((getObjectProperty(studentProfileObject, 'first_name')) == first_name && (getObjectProperty(studentProfileObject, 'last_name')) == last_name) {
          return studentProfileObject;
        } else {
          // Update first name of the skeleton profile
          studentProfileObject['first_name'] = first_name;
          // Only update last name if a new last name is supplied in the form.
          if (last_name != null) {
            studentProfileObject['last_name'] = last_name;
          }
          await Backendless.Data.of('profiles').save(studentProfileObject, true);
          return studentProfileObject;
        }
      }
    }
  }

return 
}

})
define('./functions/54acde4f4ff93dd22b3943e777da5ac8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8'] = async function fn_54acde4f4ff93dd22b3943e777da5ac8(teacherProfileObject, studentProfileObject, tags) {
var teacherStudentRelObject, error, myTx;


  try {
    myTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async () => {
      const _uow = myTx;

      // Just need some dummy data saved... even though this value is false by default
      _uow.create('teacher_student_rels', ({ [`email_subscribed`]: true })).setOpResultId('saveGenericRelationshipObject');
      _uow.setRelation('teacher_student_rels', (myTx.getOpResultById('saveGenericRelationshipObject')), 'teacher_profile_id', [teacherProfileObject]).setOpResultId('setTeacherRelation');
      _uow.setRelation('teacher_student_rels', (myTx.getOpResultById('saveGenericRelationshipObject')), 'student_profile_id', [studentProfileObject]).setOpResultId('setStudentRelation');
      // Only bother if tags are supplied. Tags have to be objects in the database.
      if (!(tags === null)) {
        // Only bother if tags are supplied. Tags have to be objects in the database.
        if (tags.length > 0) {
          _uow.setRelation('teacher_student_rels', (myTx.getOpResultById('saveGenericRelationshipObject')), 'tags', tags).setOpResultId('setStudentTags');
        }
      }

    })();

    await myTx.execute().then(function(result) {
        const error = result && result.error

        if (error) {
          throw error
        }

      return result
      });
    teacherStudentRelObject = ((myTx.getOpResultById('saveGenericRelationshipObject')).getResult());

  } catch (error) {
    console.log(error);

  }

return teacherStudentRelObject
}

})
define('./functions/5b0f4231d30bdc56dbdfa912013ea529/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529'] = function fn_5b0f4231d30bdc56dbdfa912013ea529(type) {
  if (type == 'small') {
    return 'https://ubindi-static.s3.amazonaws.com/static_assets/small_default.png';
  }
  if (type == 'large') {
    return 'https://ubindi-static.s3.amazonaws.com/static_assets/large_default.png';
  }
  if (type == 'green') {
    return 'https://ubindi-static.s3.amazonaws.com/static_assets/large_green.png';
  }

return 
}

})
define('./functions/666c379f51dd02fbcd06599faa9b21b9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_666c379f51dd02fbcd06599faa9b21b9'] = async function fn_666c379f51dd02fbcd06599faa9b21b9(appData, pageData) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.logout();
  // Wipe everything in App Data
  await BackendlessUI.Functions.Custom['fn_764b1cb20d01ea0112cf5815a4efac0e'](appData);
  // Wipe profile info in local storage
  localStorage.removeItem('profileObj');
  // If user is on someone's public page, stay
  // there. Otherwise, go to our Landing page.
  if ('Public' != (getObjectProperty(pageData, 'page'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Landing', undefined);
  }

return 
}

})
define('./functions/6bbab8956421f262b3881eeaa1a2ab6b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'] = async function fn_6bbab8956421f262b3881eeaa1a2ab6b(AD, emailToSet) {
var email;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user isn't logged in, go to Landing. Always.
  if (!((await Backendless.UserService.getCurrentUser(false)) !== null && !Array.isArray((await Backendless.UserService.getCurrentUser(false))) && (typeof (await Backendless.UserService.getCurrentUser(false)) === 'object'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Landing', undefined);
  }
  // If optional "emailToSet" is supplied, encrypt
  // it and save to profileObj in local storage.
  if (typeof emailToSet === 'string') {
    localStorage.setItem('profileObj', JSON.stringify(({ [`email`]: (await (async function(text, enOrDe) {
    	//xor encryption/decryption, very simple, from here: https://stackoverflow.com/questions/73593867/encrypt-decrypt-string-n-javascript
    	var key = "contramundum"; // Key, can be any string of any length


    	function encrypt(text, key){
    	    return [...text].map((x, i) =>
    	    (x.codePointAt() ^ key.charCodeAt(i % key.length) % 255)
    	     .toString(16)
    	     .padStart(2,"0")
    	   ).join('')
    	}
    	function decrypt(text, key){
    	    return String.fromCharCode(...text.match(/.{1,2}/g)
    	     .map((e,i) =>
    	       parseInt(e, 16) ^ key.charCodeAt(i % key.length) % 255)
    	     )
    	}

    	if (enOrDe) {
    	  //  if true we encrypt text input
    	  return(encrypt(text,key))
    	}
    	else {
    	  //  otherwise we decrypt text input
    	  return(decrypt(text,key))
    	}


    })(emailToSet, true)) })));
    // Wipe out accountProfile in App Data to trigger a re-fetch of things.
    delete AD['accountProfile'];
    console.log('Masquerading. Email in profileObj is:');
    console.log(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'email'));
  }
  // if App Data was wiped somehow, repopulate everything.
  if (!((getObjectProperty(AD, 'accountProfile')) !== null && !Array.isArray((getObjectProperty(AD, 'accountProfile'))) && (typeof (getObjectProperty(AD, 'accountProfile')) === 'object'))) {
    email = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'email'));
    if (!(typeof email === 'string')) {
      email = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email'));
    }
    console.log('refetching data...');
    await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](AD);
    // if user logging in is a teacher, we need more data for them.
    if (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) {
      await BackendlessUI.Functions.Custom['fn_a41fb2bd959296239882b925362b3c38'](AD);
    }
  }

return 
}

})
define('./functions/6cf4ed1f3196bfda722ecad5828f16b9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9'] = async function fn_6cf4ed1f3196bfda722ecad5828f16b9(method, scrollName, scrollComponent, dataModel, queryParameters, onQueryHandler, debugEnabled) {
  await (async function(method, scrollName, scrollComponent, dataModel, queryParameters, onQueryHandler, debugEnabled) {

  	UbindiInfiniteScroll.call(method, scrollName, scrollComponent, dataModel, queryParameters, onQueryHandler, debugEnabled);

  })(method, scrollName, scrollComponent, dataModel, queryParameters, onQueryHandler, debugEnabled);

return 
}

})
define('./functions/764b1cb20d01ea0112cf5815a4efac0e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_764b1cb20d01ea0112cf5815a4efac0e'] = function fn_764b1cb20d01ea0112cf5815a4efac0e(obj) {
var key, obj;


  var key_list = (Object.keys(obj));
  for (var key_index in key_list) {
    key = key_list[key_index];
    delete obj[key];
  }

return 
}

})
define('./functions/77a9623e8f0b159fc506b17d46c28a65/code.js', () => { 

BackendlessUI.Functions.Custom['fn_77a9623e8f0b159fc506b17d46c28a65'] = async function fn_77a9623e8f0b159fc506b17d46c28a65(key) {
var list;


  list = (await Backendless.Data.of('config').find(Backendless.DataQueryBuilder.create().setWhereClause((['key = \'',key,'\''].join(''))).setPageSize(1)));
  if (list.length != 1) {
    console.log(['could not get ',key,' config from database'].join(''));
    throw (new Error((['could not get ',key,' config from database'].join(''))))
  }

return (list[0])
}

})
define('./functions/7e5839b30aa99041f10d110f888e624f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'] = async function fn_7e5839b30aa99041f10d110f888e624f(PD, toastrText, color, timeMs) {
  PD['toastrText'] = toastrText;
  PD['toastrClasses'] = ['toastr'];
  await new Promise(r => setTimeout(r, 30 || 0));
  // if color class is supplied, use it. Otherwise use the default green.
  PD['toastrClasses'] = ['toastr', (typeof color === 'string') ? color : '', 'show'];
  // if timer value is supplied, use it. Otherwise default to 3 secs.
  await new Promise(r => setTimeout(r, (timeMs > 10 ? timeMs : 3000) || 0));
  PD['toastrClasses'] = ['toastr'];
  await new Promise(r => setTimeout(r, 500 || 0));
  delete PD['toastrClasses'];
  delete PD['toastrText'];

}

})
define('./functions/8b4c18135205cf53fcb51e05566d3bf1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8b4c18135205cf53fcb51e05566d3bf1'] = function fn_8b4c18135205cf53fcb51e05566d3bf1(dataModel) {
var i, rawList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  rawList = (getObjectProperty(dataModel, 'items'));
  var i_end = rawList.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    (rawList[(i - 1)])['idx'] = i;
  }

return 
}

})
define('./functions/8c13aae25af53def398c5c3595a47349/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'] = async function fn_8c13aae25af53def398c5c3595a47349(AppData) {
var allStudents, currentBatch, defaultThumbnail, item, loading, offset, studentLimit;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  AppData['loading'] = true;
  loading = true;
  allStudents = [];
  offset = 0;
  // studentLimit will be undefined if user is upgraded,
  // but if they are on the free plan we get max_students
  // from the 'upgraded' object in profileObj.
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free') {
    studentLimit = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.max_students'));
  }
  // studentLimit will be undefined if user is upgraded,
  // but if they are on the free plan we get max_students
  // from the 'upgraded' object in profileObj.
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) == null) {
    studentLimit = 50;
  }
  defaultThumbnail = (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small'));
  while (loading) {
    // Get all students and their tags for the logged in teacher. The properties input gets data from the profiles table (child table of teacher_student_rels).
    // The * gets all regular properties of the object, the student_profile_id properties have to be explicitly specified.
    // If user has student limit, get the oldest records first, otherwise sort by name.
    // If teacher is on a free plan or no plan, we show them only studentLimit number of students. Max can ever only be 100.
    currentBatch = (await Backendless.Data.of('teacher_student_rels').find(Backendless.DataQueryBuilder.create().setWhereClause((['teacher_profile_id.objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join(''))).setProperties(['student_profile_id.full_name', 'student_profile_id.thumbnail_small', 'student_profile_id.skeleton_email', 'student_profile_id.objectId as student_profile_id', '*']).setRelated('tags').setSortBy(((typeof studentLimit === 'number' && !isNaN(studentLimit)) ? 'created asc' : 'student_profile_id.full_name')).setPageSize(((typeof studentLimit === 'number' && !isNaN(studentLimit)) ? studentLimit : 100)).setOffset(offset)));
    // Extract the tag names as an array for each student and turn it into one single text string, "tagsString". Add this property to each student in the studentList... we also still have the individual tags in related data which we can use to filter our view. Also make default thumbnail if profile doesn't have one.
    // If profile of student has no thumbnail, give them the default thumbnail.
    currentBatch = (await Promise.all(currentBatch.map(async item => {  item['tagsString'] = (((getObjectProperty(item, 'tags')).map(item => item['title'])).join(', '));
      // If profile of student has no thumbnail, give them the default thumbnail.
      if ((getObjectProperty(item, 'thumbnail_small')) === null) {
        item['thumbnail_small'] = defaultThumbnail;
      }
    ; return item;})));
    allStudents = (allStudents.concat(currentBatch));
    // We can start assigning students to studentList in Page
    // Data so we don't wait for all students to be fetched.
    AppData['studentList'] = allStudents;
    offset = offset + 100;
    if (typeof studentLimit === 'number' && !isNaN(studentLimit)) {
      loading = false;
    } else if (currentBatch.length < 100) {
      loading = false;
    }
  }
  AppData['loading'] = false;

return 
}

})
define('./functions/9c076a99dc4833ccd2d2ee99ef7654ef/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9c076a99dc4833ccd2d2ee99ef7654ef'] = async function fn_9c076a99dc4833ccd2d2ee99ef7654ef(classInstanceObj, student_object_id, teacher_object_id, studentPackageObj, paid_online, attendance_object_id) {
var classInstanceObj, txResult, removeStudentFromClassTx, createGenPassStudentPackageRef, findGenPassPackageRef, upsertStudentPackageRef, studentPackageObj, upsertClassInstanceRef;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Decrement spots taken for the class instance object
  classInstanceObj['taken_spots'] = ((getObjectProperty(classInstanceObj, 'taken_spots')) - 1);
  // If paid_online and no packageUsed is supplied, we look to
  // see if the student has a General Pass with the teacher.
  // If we find one, we assign it to be studentPackageObj.
  if (paid_online && !(studentPackageObj !== null && !Array.isArray(studentPackageObj) && (typeof studentPackageObj === 'object'))) {
    studentPackageObj = (await Backendless.Data.of('student_packages').find(Backendless.DataQueryBuilder.create().setWhereClause((['package_id.editable = False AND package_id.profiles[packages].objectId= \'',teacher_object_id,'\' AND student_id.objectId = \'',student_object_id,'\''].join(''))).setPageSize(20)))[0];
    // if student doesn't have General Pass with teacher, set studentPackageObj to null.
    if (typeof studentPackageObj === 'undefined') {
      studentPackageObj = null;
    }
  }
  // if studentPackageObject is supplied to the function and "credits"
  // is a number, we add a credit to the student package object.
  if (!(studentPackageObj === null) && !(typeof studentPackageObj === 'undefined')) {
    if (typeof (getObjectProperty(studentPackageObj, 'credits')) === 'number' && !isNaN((getObjectProperty(studentPackageObj, 'credits')))) {
      studentPackageObj['credits'] = ((getObjectProperty(studentPackageObj, 'credits')) + 1);
      studentPackageObj = ({ [`objectId`]: (getObjectProperty(studentPackageObj, 'objectId')),[`credits`]: (getObjectProperty(studentPackageObj, 'credits')) });
    }
  } else {
    studentPackageObj = null;
  }
  // Massive transaction now creates user, various table objects + relations...
  removeStudentFromClassTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = removeStudentFromClassTx;

    // Upsert object in class_instances table with new spots_taken.
    upsertClassInstanceRef = (_uow.upsert('class_instances', classInstanceObj).setOpResultId('upsertClassInstance'));
    // Essentially, unregistering student means
    // deleting the class_attendance_entries record.
    _uow.delete('class_attendance_entries', attendance_object_id).setOpResultId('deleteAttendanceObj');
    // If paid_online is true and student has no general pass, we have to:
    // 1) find package id for general pass for the teacher
    // 2) create Gen Pass for student in student_packages, with 1 credit
    // 3) relate their Gen Pass to student and to Gen Pass of the teacher.
    if (!(studentPackageObj === null)) {
      // If student is supposed to get a credit back, upsert object
      // in student_packages table with a credit added back.
      upsertStudentPackageRef = (_uow.upsert('student_packages', studentPackageObj).setOpResultId('upsertStudentPackage'));
    } else if (paid_online) {
      createGenPassStudentPackageRef = (_uow.create('student_packages', ({ [`credits`]: 1 })).setOpResultId('createGenPassStudentPackage'));
      // Getting General Pass object from teacher — every teacher MUST have one.
      findGenPassPackageRef = (_uow.find('packages', Backendless.DataQueryBuilder.create().setWhereClause((['editable = False AND profiles[packages].objectId = \'',teacher_object_id,'\''].join(''))).setProperties('objectId').setPageSize(1)).setOpResultId('findGenPassPackage'));
      _uow.setRelation('student_packages', (createGenPassStudentPackageRef.resolveTo('objectId')), 'package_id', (findGenPassPackageRef.resolveTo(0))).setOpResultId('relateGenPassToPackage');
      _uow.setRelation('student_packages', (createGenPassStudentPackageRef.resolveTo('objectId')), 'student_id', ({ [`objectId`]: student_object_id })).setOpResultId('relateGenPassToStudent');
    }

  })();

  txResult = (await removeStudentFromClassTx.execute());
  if (txResult.isSuccess()) {
    classInstanceObj = (getObjectProperty((txResult.getResults()), 'upsertClassInstance.result'));
  } else {
    console.log('Failed to unregister student for class. Error is:');
    console.log(txResult.getError());
    throw (txResult.getError())
  }

return classInstanceObj
}

})
define('./functions/9dd2d48dddefbbe8930ac234476c7fc3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9dd2d48dddefbbe8930ac234476c7fc3'] = async function fn_9dd2d48dddefbbe8930ac234476c7fc3(fileName, content, type) {
  await (async function(fileName, content, type) {
  	const bb = new Blob([content], { type });
  	const a = document.createElement('a');
  	a.download = fileName;
  	a.href = window.URL.createObjectURL(bb);
  	a.click();
  	a.remove();
  })(fileName, content, type);

return 
}

})
define('./functions/9fd1d14c3001203b50de810ab2dbec08/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08'] = async function fn_9fd1d14c3001203b50de810ab2dbec08(doWhat, tag_objectId, tag_title) {
var allTeacherTags, createSettingsRef, createTagRef, error, getAllTeacherTags, getTeacherDataTx, item, setTeacherTagsTx, teacher_profile_id, teacherObjects, teacherProfileObject, txResult;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  teacher_profile_id = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'));
  setTeacherTagsTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async function() {
    const _uow = setTeacherTagsTx;

    if (doWhat == 'Delete') {
      _uow.delete('tags', tag_objectId).setOpResultId('deleteTag');
    }
    if (doWhat == 'Edit') {
      _uow.update('tags', ({ 'objectId': tag_objectId,'title': tag_title,'tag_type': 'student' })).setOpResultId('editTag');
    }
    // If this is an edit operation, we have to create
    // object with the same objectId as the tag to be edited.
    // Then replace in tags list in profileTeacherObject.
    //
    // If this is a "new tag" operation, we create an object without
    // objectId and add it to the tags list in profileTeacherObject.
    if (doWhat == 'Create') {
      createTagRef = (_uow.create('tags', ({ 'title': tag_title,'tag_type': 'student' })).setOpResultId('createTag'));
      _uow.addToRelation('profiles', teacher_profile_id, 'tags', (createTagRef.resolveTo('objectId'))).setOpResultId('relateNewTag');
    }
    // Find profiles related to the user
    // Currently, only student tags fetched. Future perhaps other types...
    getAllTeacherTags = (_uow.find('tagsView', Backendless.DataQueryBuilder.create().setWhereClause((['teacher_objectId = \'',teacher_profile_id,'\' AND tag_type = \'Student\''].join(''))).setProperties(['title', 'numberUsed', 'tag_objectId']).setSortBy(['created']).setPageSize(100)).setOpResultId('getAllteacherTags'));

  })();

  txResult = (await setTeacherTagsTx.execute());
  if (txResult.isSuccess()) {
    allTeacherTags = (getObjectProperty((txResult.getResults()), 'getAllteacherTags.result'));
  } else {
    console.log('setting tags transaction failed?!');
    throw (new Error('setting tags transaction failed'))
  }

return allTeacherTags
}

})
define('./functions/a41fb2bd959296239882b925362b3c38/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a41fb2bd959296239882b925362b3c38'] = async function fn_a41fb2bd959296239882b925362b3c38(AD) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user is not a teacher we don't need to fetch things from the db.
  if (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) {
    Object.assign(AD, (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/setTeacherData`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']())))));
  }

return 
}

})
define('./functions/a5a3eec897d959b6c72285df27d8907f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'] = async function fn_a5a3eec897d959b6c72285df27d8907f(minuteIncrement) {
var hrOptionsObject, i, minOptionsObject, incrementMins;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function genHrSelectOptions() {
  hrOptionsObject = [];
  if ((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 == null) {
    for (i = 0; i <= 23; i++) {
      addItemToList(hrOptionsObject, ({ [`value`]: i,[`label`]: (JSON.stringify(i)) }));
    }
  } else {
    for (i = 0; i <= 23; i++) {
      if (i < 12) {
        addItemToList(hrOptionsObject, ({ [`value`]: i,[`label`]: String(JSON.stringify(i)) + String(' am') }));
      } else {
        addItemToList(hrOptionsObject, ({ [`value`]: i,[`label`]: String(JSON.stringify((i == 12 ? i : i - 12))) + String(' pm') }));
      }
    }
  }
  return hrOptionsObject
}

/**
 * Describe this function...
 */
async function genMinSelectOptions() {
  minOptionsObject = [];
  incrementMins = minuteIncrement;
  var i_inc = Math.abs(incrementMins);
  if (0 > 59) {
    i_inc = -i_inc;
  }
  for (i = 0; i_inc >= 0 ? i <= 59 : i >= 59; i += i_inc) {
    addItemToList(minOptionsObject, ({ [`value`]: i,[`label`]: i < 10 ? String('0') + String(JSON.stringify(i)) : (JSON.stringify(i)) }));
  }
  return minOptionsObject
}



return ({ [`hrOptions`]: await genHrSelectOptions(),[`minOptions`]: await genMinSelectOptions() })
}

})
define('./functions/a9caf714cff1d13faba8103aaa293e04/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a9caf714cff1d13faba8103aaa293e04'] = async function fn_a9caf714cff1d13faba8103aaa293e04(rruleString, class_length) {
var dataBlockModel, RRULE, repeat;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  RRULE = (await (async function(rruleString) {
  	//rrule.js must be installed here: ./libs/rrule/rrule.js and the path "./libs/rrule/rrule.js" must be set in UI Builder settings.


  	//argument must be  RRule string like so:
  	//let rruleString = 'DTSTART:20120201T023000Z\nRRULE:FREQ=MONTHLY;COUNT=5';

  	// the following creates a rule (object) in the required format from parsing the string
  	const rule = rrule.rrulestr (rruleString);

  	//print object to console in a reasonable format
  	//console.log(JSON.stringify(rule,null,2))



  	//Spit out the "options" part of RRULE
  	return (rule.options)
  })(rruleString));
  if ((getObjectProperty(RRULE, 'count')) == 1) {
    repeat = 1;
  } else if ((getObjectProperty(RRULE, 'byweekday')).length == 1) {
    repeat = 2;
  } else if ((getObjectProperty(RRULE, 'byweekday')).length == 5) {
    repeat = 3;
  } else if ((getObjectProperty(RRULE, 'byweekday')).length == 7) {
    repeat = 4;
  } else {
    repeat = 5;
  }
  dataBlockModel = ({ [`startDatetime`]: (getObjectProperty(RRULE, 'dtstart')),[`repeat`]: repeat,[`startHr`]: (JSON.stringify((new Date((getObjectProperty(RRULE, 'dtstart'))).getHours()))),[`startMin`]: (JSON.stringify((new Date((getObjectProperty(RRULE, 'dtstart'))).getMinutes()))),[`repeatUntilDatetime`]: (getObjectProperty(RRULE, 'until')),[`endDatetime`]: (new Date((getObjectProperty(RRULE, 'dtstart')).getTime() + ((class_length * 60) * 1000))) });
  dataBlockModel['endHr'] = (JSON.stringify((new Date((getObjectProperty(dataBlockModel, 'endDatetime'))).getHours())));
  dataBlockModel['endMin'] = (JSON.stringify((new Date((getObjectProperty(dataBlockModel, 'endDatetime'))).getMinutes())));

return dataBlockModel
}

})
define('./functions/caae0044ca52123d14818f26157c82b8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8'] = async function fn_caae0044ca52123d14818f26157c82b8() {
var profileObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('profileObj')) === null) || (typeof ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('profileObj')) === 'undefined')) && ((await Backendless.UserService.getCurrentUser(true)) !== null && !Array.isArray((await Backendless.UserService.getCurrentUser(true))) && (typeof (await Backendless.UserService.getCurrentUser(true)) === 'object'))) {
    console.log('profileObj got lost, see about handling this in getProfileObj');
  }
  profileObj = (((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('profileObj')) === null) || (typeof ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('profileObj')) === 'undefined') ? ({ [`is_teacher`]: null,[`profile_id`]: null,[`email`]: null,[`upgraded`]: null,[`avatar`]: null,[`full_name`]: null,[`url`]: null }) : ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('profileObj'));
  if (!((getObjectProperty(profileObj, 'email')) === null)) {
    profileObj['email'] = (await (async function(text, enOrDe) {
    	//xor encryption/decryption, very simple, from here: https://stackoverflow.com/questions/73593867/encrypt-decrypt-string-n-javascript
    	var key = "contramundum"; // Key, can be any string of any length


    	function encrypt(text, key){
    	    return [...text].map((x, i) =>
    	    (x.codePointAt() ^ key.charCodeAt(i % key.length) % 255)
    	     .toString(16)
    	     .padStart(2,"0")
    	   ).join('')
    	}
    	function decrypt(text, key){
    	    return String.fromCharCode(...text.match(/.{1,2}/g)
    	     .map((e,i) =>
    	       parseInt(e, 16) ^ key.charCodeAt(i % key.length) % 255)
    	     )
    	}

    	if (enOrDe) {
    	  //  if true we encrypt text input
    	  return(encrypt(text,key))
    	}
    	else {
    	  //  otherwise we decrypt text input
    	  return(decrypt(text,key))
    	}


    })((getObjectProperty(profileObj, 'email')), false));
  }

return profileObj
}

})
define('./functions/d2b80076e5346ce5995879da90f1ef38/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d2b80076e5346ce5995879da90f1ef38'] = function fn_d2b80076e5346ce5995879da90f1ef38(csv) {
var output, newItem, j, row, key, header, i, rows, rowKeyMap;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  output = [];
  rows = (csv.replace((new RegExp('\\r', 'g')), '')).split('\n');
  rowKeyMap = ({  });
  header = rows[0].split(',');
  var i_end = rows.length;
  var i_inc = 1;
  if (2 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 2; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    row = rows[(i - 1)].split(',');
    newItem = ({  });
    var j_end = header.length;
    var j_inc = 1;
    if (1 > j_end) {
      j_inc = -j_inc;
    }
    for (j = 1; j_inc >= 0 ? j <= j_end : j >= j_end; j += j_inc) {
      key = header[(j - 1)];
      newItem[key] = (row[(j - 1)]);
    }
    addItemToList(output, newItem);
  }

return output
}

})
define('./functions/d47cae3b23b596aa0043c941a238585f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d47cae3b23b596aa0043c941a238585f'] = async function fn_d47cae3b23b596aa0043c941a238585f(email) {
return (await (async function(email) {
	if (!(typeof email === 'string' || email instanceof String)) {
	  console.error(`string expected, ${typeof email} provided`)

	  return false
	}

	const expression = /\S+@\S+\.\S+/

	return expression.test(email)
})(email))
}

})
define('./functions/d8222a8e2a043a9756c8771718f943fd/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d8222a8e2a043a9756c8771718f943fd'] = async function fn_d8222a8e2a043a9756c8771718f943fd(tableName, dataModel, whereClause, properties, excludeProperties, sortBy, relations, pageSize) {
var loadedData, offset, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function init_vars() {
  offset = (getObjectProperty(dataModel, 'offset'));
  if (offset == null) {
    offset = 0;
  }
  if ((getObjectProperty(dataModel, 'loadedCount')) == null) {
    dataModel['loadedCount'] = 0;
  }
}

/**
 * Describe this function...
 */
async function load_data() {
  loadedData = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(properties).excludeProperties(excludeProperties).setRelated(relations).setSortBy(sortBy).setPageSize(pageSize).setOffset(offset)));
  if (loadedData.length > 0) {
    // adding an index to each item, this can be
    // used to have items reference other items.
    var i_end = loadedData.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      (loadedData[(i - 1)])['idx'] = (offset + i);
    }
  }
}

/**
 * Describe this function...
 */
async function adjust_page_data_state() {
  if (loadedData.length < pageSize) {
    dataModel['loadingData'] = false;
  } else {
    dataModel['offset'] = (offset + loadedData.length);
  }
  dataModel['loadedCount'] = ((getObjectProperty(dataModel, 'loadedCount')) + loadedData.length);
}


  await init_vars();
  await load_data();
  await adjust_page_data_state();

return loadedData
}

})
define('./functions/d89644da099a68b1ba14d478acbdd215/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'] = async function fn_d89644da099a68b1ba14d478acbdd215(AD) {
var error, freshTemplateObjectId, templateObj, templatesArray, j, day;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  error = false;
  templatesArray = (getObjectProperty(AD, 'teacherObjects.availability_templates'));
  var day_list = (getObjectProperty(templatesArray, 'items'));
  for (var day_index in day_list) {
    day = day_list[day_index];
    var j_list = (getObjectProperty(day, 'intervals'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      // If error is already true, we keep it. Otherwise, set it to our test result.
      // Show error if end time is not larger than start time.
      error = error ? true : j[0] * 100 + j[1] >= j[2] * 100 + j[3];
    }
  }
  if (!error) {
    // Massage object from App Data into object
    // suitable for availability_templates table.
    templateObj = ({ [`objectId`]: (getObjectProperty(templatesArray, 'availability_template_objectId')),[`Mondays`]: (getObjectProperty(((getObjectProperty(templatesArray, 'items'))[0]), 'intervals')),[`Tuesdays`]: (getObjectProperty(((getObjectProperty(templatesArray, 'items'))[1]), 'intervals')),[`Wednesdays`]: (getObjectProperty(((getObjectProperty(templatesArray, 'items'))[2]), 'intervals')),[`Thursdays`]: (getObjectProperty(((getObjectProperty(templatesArray, 'items'))[3]), 'intervals')),[`Fridays`]: (getObjectProperty(((getObjectProperty(templatesArray, 'items'))[4]), 'intervals')),[`Saturdays`]: (getObjectProperty(((getObjectProperty(templatesArray, 'items'))[5]), 'intervals')),[`Sundays`]: (getObjectProperty(((getObjectProperty(templatesArray, 'items'))[6]), 'intervals')) });
    try {
      // Deep save lets us create and relate an availability_template object if there was none before. If there is one already, this just upserts the existing template.
      freshTemplateObjectId = (await Backendless.Data.of('profiles').deepSave( ({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`availability_templates`]: templateObj }) ));
      // Set the availability_template_objectId in App Data.
      (getObjectProperty((getObjectProperty(AD, 'teacherObjects')), 'availability_templates'))['availability_template_objectId'] = (getObjectProperty(freshTemplateObjectId, 'availability_templates.objectId'));

    } catch (error) {
      error = true;

    }
  }

return error
}

})
define('./functions/e13bc3056d54967e1cba0de250054c9c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e13bc3056d54967e1cba0de250054c9c'] = function fn_e13bc3056d54967e1cba0de250054c9c(videoURL) {
var videoURL;


  // If not allowed video hosts, exit the function with empty string result.
  if (!((videoURL.indexOf('vimeo') !== -1) || (videoURL.indexOf('youtube') !== -1) || (videoURL.indexOf('youtu.be') !== -1))) {
    return '';
  }
  // Massage the url user saved to allow for iframe
  // embedding on external site. Return the fixed url.
  if (videoURL.indexOf('youtube') !== -1) {
    return (videoURL.replace('watch?v=', 'embed/'));
  } else if (videoURL.indexOf('youtu.be') !== -1) {
    return (videoURL.replace('youtu.be', 'youtube.com/embed'));
  } else if (videoURL.indexOf('vimeo') !== -1) {
    return videoURL;
  } else {
    return '';
  }

return ''
}

})
define('./functions/f82bb73eefabfdd6cf85d43337b1ba28/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'] = async function fn_f82bb73eefabfdd6cf85d43337b1ba28(AD) {
var bigData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // See Clickup documentation for setProfileObj API and "masquerading" scheme
  bigData = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/setProfileObj`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'userObj': (await Backendless.UserService.getCurrentUser(false)),'profileObj': (await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()) }));
  Object.assign(AD, (getObjectProperty(bigData, 'appData')));
  // Stick profileObj into local storage. It can be
  // used to give user "act as profile" experience.
  localStorage.setItem('profileObj', JSON.stringify((getObjectProperty(bigData, 'locStoreData'))));

return ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('profileObj'))
}

})
define('./pages/404/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['page'] = '404';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/Account/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Account';
  await BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a']((getObjectProperty(___arguments.context.pageData, 'page')));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Account/components/b39d77b8cb8f47aeecf5f9dedf8f0009/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('teacher_student_rels').save(({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c526335aedd08ac9ea986f45b93e0d8'), 'tsr_objectId')),'email_subscribed': ___arguments.value }), true);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (String(___arguments.value ? 'You\'ll receive emails from ' : 'You\'ll no longer receive emails from ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c526335aedd08ac9ea986f45b93e0d8'), 'teacher_full_name'))), 'green', null);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Account/components/2e48accb5816eba83ffddca49ffb71e4/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c526335aedd08ac9ea986f45b93e0d8'), 'teacher_thumbnail_small')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c526335aedd08ac9ea986f45b93e0d8'), 'teacher_thumbnail_small')))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ [`profile_url`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c526335aedd08ac9ea986f45b93e0d8'), 'teacher_url')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/8d9cc7b7767459bb8e21e491b4f70cbc/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f75cc3caad8a40947fa2b22b7c3f25d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74e91b317e0b7b3988d2aadb7868e72d', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Account/components/908b13cc0b548a322e0a9c2a6919e17a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f75cc3caad8a40947fa2b22b7c3f25d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74e91b317e0b7b3988d2aadb7868e72d', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Account/components/e2b995432fef06528458e7d17a6878be/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8cf7f4afc841de8540cec97898383ca'), 'currentPassword')) == null || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8cf7f4afc841de8540cec97898383ca'), 'newPassword')) == null || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8cf7f4afc841de8540cec97898383ca'), 'confirm_password')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Account/components/ae3574b3739a07e298a877c67c2db579/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('485a70f179c32ce15ef169d42b5b3298');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/e8cf7f4afc841de8540cec97898383ca/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, user;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e2b995432fef06528458e7d17a6878be')), 'button-loading');
  ___arguments.context.dataModel['newPasswordError'] = '';
  if ((getObjectProperty(___arguments.context.dataModel, 'newPassword')) == (getObjectProperty(___arguments.context.dataModel, 'confirm_password'))) {
    try {
      user = (await Backendless.UserService.getCurrentUser(false));
      await Backendless.Request.post(`${Backendless.appPath}/services/UserService/changePassword`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'currentPassword': (getObjectProperty(___arguments.context.dataModel, 'currentPassword')),'newPassword': (getObjectProperty(___arguments.context.dataModel, 'newPassword')) });
      await Backendless.UserService.login( (getObjectProperty(user, 'email')), (getObjectProperty(___arguments.context.dataModel, 'newPassword')), true  );
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('485a70f179c32ce15ef169d42b5b3298');
      await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Password successfully changed!', 'green', null);

    } catch (error) {
      removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e2b995432fef06528458e7d17a6878be')), 'button-loading', '');
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74e91b317e0b7b3988d2aadb7868e72d', false);
      ___arguments.context.dataModel['newPasswordError'] = ((typeof (getObjectProperty(error, 'message')) === 'string') ? (getObjectProperty(error, 'message')) : error);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f75cc3caad8a40947fa2b22b7c3f25d', true);

    }
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74e91b317e0b7b3988d2aadb7868e72d', false);
    ___arguments.context.dataModel['newPasswordError'] = 'New password and confirm password don\'t match!';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f75cc3caad8a40947fa2b22b7c3f25d', true);
  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Account/components/f3e2948a5aac053a2be474e9336c6c46/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d8dce1e589c1b83fc38c9ada91c5bd24');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/1ae8fc8c8325b1c5a80f921d7c8c01f5/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, userObject, accountProfile, newUserObject;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9f39c8360a8c06b2cd223b06a122a351')), 'button-loading');
  try {
    userObject = (await Backendless.UserService.getCurrentUser(false));
    userObject['email'] = (getObjectProperty(___arguments.context.dataModel, 'newEmail'));
    // We use this "change password" API to simply validate user knows
    // their password. Sets new password to be the old password.
    await Backendless.Request.post(`${Backendless.appPath}/services/UserService/changePassword`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'currentPassword': (getObjectProperty(___arguments.context.dataModel, 'currentPassword')),'newPassword': (getObjectProperty(___arguments.context.dataModel, 'currentPassword')) });
    userObject['profiles'] = [({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`skeleton_email`]: (getObjectProperty(___arguments.context.dataModel, 'newEmail')) })];
    // Updating email in Users table and also skeleton_email in profiles table.
    newUserObject = (await Backendless.Data.of('Users').deepSave( userObject ));
    // extract the profile (1st in profiles relation)
    accountProfile = (getObjectProperty(newUserObject, 'profiles'))[0];
    // add upgraded property from local storage
    accountProfile['upgraded'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded'));
    // To update page data so user sees the effect of changing email
    ___arguments.context.pageData['accountProfile'] = accountProfile;
    console.log('accountProfile saved in page data now:');
    console.log(accountProfile);
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d8dce1e589c1b83fc38c9ada91c5bd24');
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Email successfully changed!', 'green', null);

  } catch (error) {
    console.log(getObjectProperty(error, 'code'));
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9f39c8360a8c06b2cd223b06a122a351')), 'button-loading', '');
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f498900e3a778c0a0412ba8a48ebc4fb', false);
    // Error code 3033 is if email/password are incorrect. Show
    // error text right on the form if this is the case. Otherwise,
    // show general error description at the top of the form.
    if (3018 == (getObjectProperty(error, 'code'))) {
      ___arguments.context.dataModel['errorText'] = 'This email is already in use!';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecb97a87595a09bc6a1b147c4c71df93', true);
    } else if (0 == (getObjectProperty(error, 'code'))) {
      ___arguments.context.dataModel['errorText'] = 'That\'s not your current password!';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecb97a87595a09bc6a1b147c4c71df93', true);
    } else {
      ___arguments.context.dataModel['errorText'] = 'Sorry, something weird happened, please try again...';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecb97a87595a09bc6a1b147c4c71df93', true);
    }

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Account/components/9f39c8360a8c06b2cd223b06a122a351/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1ae8fc8c8325b1c5a80f921d7c8c01f5'), 'newEmail')) == null || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1ae8fc8c8325b1c5a80f921d7c8c01f5'), 'currentPassword')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Account/components/8c89625e12d71c1f87a9a1eaab353111/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.appData, 'packagesOwned')))) ? !!(getObjectProperty(___arguments.context.appData, 'packagesOwned')).length : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Account/components/b80b84254d7990bce677f40b63b34fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.is_teacher')) ? 'Switch to student account' : 'Switch to teacher account')

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('99fa4f47bce456ed1061225a4e3a78e3');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/d98545a1eb9ac3c3206ce728ef527ef6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.is_teacher')) ? 'You\'re a teacher' : 'You\'re a student')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Account/components/ade8ee8600a801de6bdd582abefb7aff/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.is_teacher')) ? String('You are using the Teacher version of Ubindi. If you don’t plan to use Ubindi to manage your classes, you’re welcome to switch to a student account.') : 'You are using the Student version of Ubindi. If you teach group classes of any kind, you’re very welcome to switch to a teacher account!')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Account/components/da56befd420e1bb3a917dc55163cafdb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.is_teacher')) ? 'You’re about to switch to a student account. Are you really sure?' : 'You’re about to switch to a teacher account. You’ll be able to manage your classes, students, bookings, videos and more on your own Ubindi teacher page.')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Account/components/e91874ea638f3683d46d41871c4d7a07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, profileObject, anyExistingPackages;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4e123121259efc8b2666a0ce54e4a528', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cf294473ca69e3d250cd9802da09756b', false);
    (getObjectProperty(___arguments.context.appData, 'accountProfile'))['is_teacher'] = (!(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')));
    profileObject = (await Backendless.Data.of('profiles').save((getObjectProperty(___arguments.context.appData, 'accountProfile')), true));
    profileObject = (getObjectProperty(___arguments.context.appData, 'accountProfile'));
    // If user is switching to teacher account, they need a General Pass.
    if (getObjectProperty(___arguments.context.appData, 'accountProfile.is_teacher')) {
      anyExistingPackages = (getObjectProperty((await Backendless.Data.of('profiles').findById((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')), Backendless.DataQueryBuilder.create().setProperties([]).setRelated('packages'))), 'packages'));
      // For the edge case where user already had a previous teacher
      // account and already has a General Pass, we don't create a new one.
      if (!((anyExistingPackages.map(item => item['title'])).includes('General Pass'))) {
        profileObject['packages'] = [({ [`title`]: 'General Pass',[`description`]: 'This pass is active when your referral program is active.',[`active`]: false,[`editable`]: false,[`type`]: 'Pass',[`credits`]: 0,[`cost`]: 0 })];
      }
    }
    profileObject = (await Backendless.Data.of('profiles').deepSave( profileObject ));
    // Update the profileObj in browser local storage.
    await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    // this delay is added so that the user sees that
    // sth is happening, otherwise it's too abrupt.
    await new Promise(r => setTimeout(r, 1000 || 0));
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('99fa4f47bce456ed1061225a4e3a78e3');
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) ? 'You now have a teacher account!' : 'You now have a student account!'), 'green', null);

  } catch (error) {
    console.log(error);
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('99fa4f47bce456ed1061225a4e3a78e3');
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry, something went wrong... please contact support!', 'red', null);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/f4410e4473dc5f1f8b1dff011e477875/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('99fa4f47bce456ed1061225a4e3a78e3');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/a1d9127fb9cf655558a5352b831eabbf/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var backendlessProfileFile;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // replace whatever was in Form Data with new thumbnail image link
  ___arguments.context.getComponentDataStoreByUid('ca85d28bda7d759de53adc13b898ef2b')['thumbnail'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': ___arguments.uploadedFiles,'path': 'profiles/thumbnails/large' }));
  // get link to small avatar/thumbnail of the user
  // Makes a small thumbnail if not used before... otherwise returns cached image or link to small image. See here: https://backendless.com/features/marketplace/image-processor-api
  backendlessProfileFile = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/ImageProcessor/resize`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'image_path': JSON.stringify(___arguments.uploadedFiles),'width': 64,'height': 64 }).send()), 'url'));
  ___arguments.context.getComponentDataStoreByUid('ca85d28bda7d759de53adc13b898ef2b')['thumbnail_small'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': backendlessProfileFile,'path': 'profiles/thumbnails/small' }));
  // make the uploader button reappear to show
  // image and in case user wants to change image
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('a1d9127fb9cf655558a5352b831eabbf');

  },
  /* handler:onUploadSuccess */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If object in page data has a thumbnail link, get it...
  // otherwise get link to default thumbnail. Then make this the
  // url for the background image of the file upload button.
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a1d9127fb9cf655558a5352b831eabbf'))['background-image'] = (['url(',((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ca85d28bda7d759de53adc13b898ef2b'), 'thumbnail')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('green')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ca85d28bda7d759de53adc13b898ef2b'), 'thumbnail')),')'].join(''));

  },
  /* handler:onStyleAssignment */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}



  return (generateUUID().short())

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/Account/components/ca85d28bda7d759de53adc13b898ef2b/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	// code snippet from crop.guide, detects file upload process on page and inserts crop/resize overlay
  	await BackendlessUI.requireModule("https://cdn.crop.guide/loader/l.js?c=MDWNWW");

  })();
  // Get the relevant data from user's profile
  // object and store them in the Form Data Model.
  Object.assign(___arguments.context.dataModel, (getObjectProperty(___arguments.context.appData, 'accountProfile')));
  // If profile table has no link to a thumbnail,
  // we set imageURL to the "defaultThumbnail" image.
  if ((getObjectProperty(___arguments.context.dataModel, 'thumbnail')) == null) {
    ___arguments.context.dataModel['thumbnail'] = (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('large'));
  }

  },
  /* handler:onBeforeMount */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('84783ba5b2e8ddaf757344e57fae5868')), 'button-loading');
  try {
    ___arguments.context.appData['accountProfile'] = (await Backendless.Data.of('profiles').save(___arguments.context.dataModel, true));
    // With new thumbnail, we should update profileObj for the avatar of the user
    await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (String(error)), 'red', null);

  }
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('84783ba5b2e8ddaf757344e57fae5868')), 'button-loading', '');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('268f9d926b53653b87f6c11d73b290d8');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Account/components/40a1be9310c581dece328f91b6da581d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('268f9d926b53653b87f6c11d73b290d8');

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.appData, 'accountProfile')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Account/components/2d2747e2b0bbb7cfae0bb76cdef68933/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'has_subscription')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'hasSubscribers'))) {
    return false;
  }

  return (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'upgradedPlan')) === 'number' && !isNaN((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'upgradedPlan'))))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Your subscription to the Ubindi ',(getObjectProperty(___arguments.context.pageData, 'accountProfile.upgraded.plan_name')),' plan will be cancelled.'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Account/components/2659676a6e26092b7867eae4f1a83bdc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'hasSubscribers')) == false && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'has_subscription')) == false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Account/components/66b275f75aef9ebc88b5787c2c4e635f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'has_subscription'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Account/components/4b7b1e4f2542f4abb31cf7ef3cd9008f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'hasSubscribers'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Account/components/e27c68ac2aea4995fa2c503155467d62/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var item, subscribers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  subscribers = (await Backendless.Data.of('student_packages_view').find(Backendless.DataQueryBuilder.create().setWhereClause((['teacher_objectId=\'',(getObjectProperty(___arguments.context.appData, 'accountProfile.objectId')),'\' AND sub_status=\'active\''].join(''))).setPageSize(10)));
  ___arguments.context.dataModel['hasSubscribers'] = ((Array.isArray(subscribers)) ? !!subscribers.length : false);
  ___arguments.context.dataModel['is_teacher'] = (getObjectProperty(___arguments.context.appData, 'accountProfile.is_teacher'));
  ___arguments.context.dataModel['has_subscription'] = (!!(await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


   return ((getObjectProperty(item, 'type')) == 'Subscription' && (getObjectProperty(item, 'sub_status')) == 'active');
  })).length);
  ___arguments.context.dataModel['upgradedPlan'] = (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/Account/components/4067682422f37683fb8741915fd37a71/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'has_subscription')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'hasSubscribers'))) {
    return 'All right';
  }

  return 'Nevermind'

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('7d4690678094360a77910f5a4771ffa3');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/18372c31fdd8adedffa561da52dc1715/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'has_subscription')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'hasSubscribers'))))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, deleteAccTx, profileObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    deleteAccTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async function() {
      const _uow = deleteAccTx;

      // If user has active Zoho subscription, we cancel them.
      if (typeof (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) === 'number' && !isNaN((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')))) {
        await Backendless.Request.delete(`${Backendless.appPath}/services/zoho/subscription/delete`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.appData, 'accountProfile.objectId'))));
      }
      // Remove user from student lists of any teachers they follow
      _uow.bulkDelete('teacher_student_rels', (['student_profile_id = \'',(getObjectProperty(___arguments.context.appData, 'accountProfile.objectId')),'\''].join(''))).setOpResultId('deleteTeacherRels');
      profileObject = (getObjectProperty(___arguments.context.appData, 'accountProfile'));
      profileObject['url'] = null;
      // wipe URL of profile so there is no public page, people can't book anything.
      _uow.update('profiles', profileObject).setOpResultId('wipeURL');
      // Delete current user from Users table!
      _uow.delete('Users', (await Backendless.UserService.getCurrentUser(true))).setOpResultId('deleteUserObj');

    })();

    await deleteAccTx.execute().then(function(result) {
        const error = result && result.error

        if (error) {
          throw error
        }

      return result
      });
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('AccountDeleted', undefined);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/bb12e8a1832daafc2fba904e4b785364/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'hasSubscribers')) == false && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'has_subscription')) == false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Account/components/f5ca8a4d56b5659634e6743cf7256275/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('7d4690678094360a77910f5a4771ffa3');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/2abcce8b7bef4729ec74618530f7a1aa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecb97a87595a09bc6a1b147c4c71df93', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f498900e3a778c0a0412ba8a48ebc4fb', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Account/components/cdd848cdc0987c4d83dd8006c6af8817/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecb97a87595a09bc6a1b147c4c71df93', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f498900e3a778c0a0412ba8a48ebc4fb', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Account/components/a5429801d53f934d256d2d11847c8af2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f75cc3caad8a40947fa2b22b7c3f25d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74e91b317e0b7b3988d2aadb7868e72d', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Account/components/fece91251a37114f2ece40cd41412555/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teachersFollowed'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Account/components/601b568921efe90577b71a68c482f6ad/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.appData, 'accountProfile')) === 'undefined') ? '        ' : (getObjectProperty(___arguments.context.appData, 'accountProfile.skeleton_email')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Account/components/6c569d5d6bab7f62ec3f236f8e3133d1/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'packagesOwned'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Account/components/7ccf0b9f046d56c83b77850781a1c788/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.appData, 'accountProfile')) === 'undefined') ? '        ' : (getObjectProperty(___arguments.context.appData, 'accountProfile.full_name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Account/components/d857e379a6f54c0d484ef84763b5966e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ [`profile_url`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c526335aedd08ac9ea986f45b93e0d8'), 'teacher_url')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/db77acd18f92b75efb9c3ebed15b4a01/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.appData, 'packagesOwned')))) ? (getObjectProperty(___arguments.context.appData, 'packagesOwned')).length == 0 : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Account/components/7e61b6decfe8ec6f6ed6ebdf6376b52f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d8dce1e589c1b83fc38c9ada91c5bd24');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/88ba6ae72a58afbe1cf2cdc92d36e0a7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('485a70f179c32ce15ef169d42b5b3298');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/121fa7927a9b6a09752ea409d378a817/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'has_subscription')) === 'undefined') && (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e27c68ac2aea4995fa2c503155467d62'), 'hasSubscribers')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Account/components/455c006ca02c8c94b4672bb7e69bc6df/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('268f9d926b53653b87f6c11d73b290d8');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Account/components/fa31c9aa93dc7c4def851caea0fac1f8/bundle.js', [], () => ({
  /* content */
  /* handler:onLoad */
  ['onLoad'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fa31c9aa93dc7c4def851caea0fac1f8')), 'loading', '');

  },
  /* handler:onLoad */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.appData, 'accountProfile.thumbnail')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('large')) : (getObjectProperty(___arguments.context.appData, 'accountProfile.thumbnail')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Account/components/3662c7c865ebc0ac7991d7579436f055/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.appData, 'teachersFollowed')))) ? !!(getObjectProperty(___arguments.context.appData, 'teachersFollowed')).length : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/AccountDeleted/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['page'] = 'AccountDeleted';

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Admin/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    
  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Admin';
  if (!(getObjectProperty((await Backendless.UserService.getCurrentUser(true)), 'admin'))) {
    console.log('User not allowed on Admin page!');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Landing', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Admin/components/ed05ffbd2b49731439c8b4a2c772dab3/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['candidates'] = (await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['(skeleton_email LIKE \'%',(getObjectProperty(___arguments.context.dataModel, 'input')),'%\' OR full_name LIKE \'%',(getObjectProperty(___arguments.context.dataModel, 'input')),'%\') AND (user_email is not NULL)'].join(''))).setRelated(['upgraded']).setPageSize(100)));

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Admin/components/2415f4d7356567786b58bd154d8d4ae6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      console.log('Page Data is:');
  console.log(___arguments.context.pageData);
  console.log('profile Obj is:');
  console.log((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Admin/components/7ec83cbfc0760a37401d3e53cc68d8f7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('befb008f894ebdcaf7440308d6ec7887'), 'is_teacher')) ? 'TEACHER' : 'Student')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Admin/components/8a9fee2afeded4cf9c9e5867c053fff2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('befb008f894ebdcaf7440308d6ec7887'), 'is_teacher')) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ 'profile_id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('befb008f894ebdcaf7440308d6ec7887'), 'objectId')) }));
  } else {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Not a teacher, no public profile!', 'red', null);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Admin/components/7f0e0611f2392b1673a5661c9524c404/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  async ['onBeforeUpload'](___arguments) {
    var profile_id, error, tx, email, createdTeacherStudentRel, createdStudentProfileRel, note, last, first, foundProfile, teacherStudentRels, j, dataList, data, file, files, found, skeletonEmail, k;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function uploadStudentsCSV(profile_id, files) {
  console.log('in function');
  try {
    teacherStudentRels = (await BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'](___arguments.context.pageData, 'teacher_student_rels', (['teacher_profile_id = \'',profile_id,'\''].join('')), null, ['student_profile_id'], null, null));
    console.log('TSR objects:');
    console.log(JSON.stringify(teacherStudentRels));
    for (var file_index in files) {
      file = files[file_index];
      data = (JSON.stringify((await BackendlessUI.Functions.Custom['fn_d2b80076e5346ce5995879da90f1ef38']((await (async function(file) {
      	console.log(file);
      	const text = await file.text();
      	return text;
      })(file))))));
      dataList = (JSON.parse(data));
      for (var j_index in dataList) {
        j = dataList[j_index];
        email = (getObjectProperty(j, 'Email'));
        first = (getObjectProperty(j, 'First'));
        last = (getObjectProperty(j, 'Last'));
        note = (getObjectProperty(j, 'Notes'));
        if (await find_existing_student_email(teacherStudentRels, email)) {
        } else {
          foundProfile = (await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['skeleton_email = \'',email,'\''].join(''))).setPageSize(1)))[0];
          tx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
          await ( async function() {
            const _uow = tx;

            if (foundProfile != null) {
              createdTeacherStudentRel = (_uow.create('teacher_student_rels', ({ 'notes': note })).setOpResultId('teacherStudentRelId'));
              _uow.setRelation('teacher_student_rels', createdTeacherStudentRel, 'student_profile_id', [foundProfile]);
              _uow.setRelation('teacher_student_rels', createdTeacherStudentRel, 'teacher_profile_id', [profile_id]);
            } else {
              createdStudentProfileRel = (_uow.create('profiles', ({ 'skeleton_email': email,'first_name': first,'last_name': last })).setOpResultId('studentProfileRelId'));
              createdTeacherStudentRel = (_uow.create('teacher_student_rels', ({ 'notes': note })).setOpResultId('teacherStudentRelId'));
              _uow.setRelation('teacher_student_rels', createdTeacherStudentRel, 'student_profile_id', [createdStudentProfileRel]);
              _uow.setRelation('teacher_student_rels', createdTeacherStudentRel, 'teacher_profile_id', [profile_id]);
            }

          })();

          await tx.execute().then(function(result) {
              const error = result && result.error

              if (error) {
                throw error
              }

            return result
            });
          await new Promise(r => setTimeout(r, 250 || 0));
        }
      }
    }

  } catch (error) {
    console.log(error);
    Backendless.Logging.getLogger('frontend').error((JSON.stringify(error)));
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'level': 'error','tags': ({ 'function': 'uploadStudentCsv' }),'extra': ({ 'profileId': undefined }),'type': 'UIError','value': (String('could not upload student CSV: ') + String(JSON.stringify(error))) });

  } finally {
    console.log('the function ran, supposedly');

  }
}

/**
 * Describe this function...
 */
async function find_existing_student_email(teacherStudentRels, email) {
  found = false;
  for (var k_index in teacherStudentRels) {
    k = teacherStudentRels[k_index];
    skeletonEmail = (getObjectProperty((getObjectProperty(k, 'student_profile_id')), 'skeleton_email'));
    if (skeletonEmail == email) {
      return true;
    }
  }
  return found
}


  profile_id = (getObjectProperty(___arguments.context.pageData, 'teacher_id'));
  ___arguments.context.pageData['loading'] = true;
  await uploadStudentsCSV(profile_id, ___arguments.files);
  ___arguments.context.pageData['loading'] = false;

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
      await new Promise(r => setTimeout(r, 1000 || 0));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('f842737ae1886b23c9ff9db21fce129b'))['open'] = false;
  delete ___arguments.context.pageData['teacher_id'];
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('7f0e0611f2392b1673a5661c9524c404');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/Admin/components/0b52ff3fdf6615298dc311c767010259/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log('Not wired yet. Masquerade as user and delete account from Account page.');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Admin/components/27ea07193fdd42b6dbee95c6691ba6c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['teacher_id'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('befb008f894ebdcaf7440308d6ec7887'), 'objectId'));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('9e1ec3942065268a6738c07b29d58577');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Admin/components/9dec6553cd4cc331493b971150f9b4fc/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('9dec6553cd4cc331493b971150f9b4fc', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('9dec6553cd4cc331493b971150f9b4fc', false);

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/Admin/components/56c5cbdb1f816e65ddc34c5174b5cf68/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var encodedEmail, userObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('befb008f894ebdcaf7440308d6ec7887'), 'skeleton_email')));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (String('You are now acting as ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('befb008f894ebdcaf7440308d6ec7887'), 'full_name'))), 'green', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/AvailabilityPlay/components/c2747213f7358c3f9e57f5ed94e2de53/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_templates.items'))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_templates')), 'Mondays'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/af9dc8787d8cfd2ebe1badf2ecac674a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    
  },
  /* handler:onDisplayAssignment */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_templates.items'))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['hrOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'hrOptions'));
  ___arguments.context.dataModel['minOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'minOptions'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/AvailabilityPlay/components/c1b891b6cebffd247f18800d73543c33/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5a6537986cd78376f20d3db279afaf8c'), 'intervals')))) ? !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5a6537986cd78376f20d3db279afaf8c'), 'intervals')).length : false) || ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5a6537986cd78376f20d3db279afaf8c'), 'intervals')) === null))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/a4b34d9f7997107c7a65592d48264d8a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var lastHour, firstHour;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('aa9a2e09825a245b955a169c3aca5172'), 'intervals')).length) {
    firstHour = 9;
    lastHour = 17;
  } else {
    firstHour = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('aa9a2e09825a245b955a169c3aca5172'), 'intervals')).slice(-1)[0][2] + 1;
    lastHour = firstHour + 1;
  }
  if (firstHour >= 22) {
    firstHour = 0;
    lastHour = 0;
  }
  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('aa9a2e09825a245b955a169c3aca5172'), 'intervals')), [[firstHour, 0, lastHour, 0]]);
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/dc8dcfd404414628cb0929a994f629f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // We remove the interval from the list of intervals.
  removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5a6537986cd78376f20d3db279afaf8c'), 'intervals')), ___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75'), '');
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/a08ab562b5349f7f47818d2124bb14e5/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af9dc8787d8cfd2ebe1badf2ecac674a'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[0])

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[0] = ___arguments.value;
  ___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[2] = ___arguments.value + 1;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/AvailabilityPlay/components/0608e24c20f1eeb47f23cc1bc9990e8d/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af9dc8787d8cfd2ebe1badf2ecac674a'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[2])

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[2] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/AvailabilityPlay/components/1ff7efd32575daffd22b2e959d0488ea/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af9dc8787d8cfd2ebe1badf2ecac674a'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[1])

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[1] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/AvailabilityPlay/components/9765690115768661a43732ab79b93f7e/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af9dc8787d8cfd2ebe1badf2ecac674a'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[3])

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('1ae24bff460b2727ee8f553b03c5ba75')[3] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/AvailabilityPlay/components/bbea2bd41d61c616ae129f66c4833b3b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    // Show error if end time is not larger than start time.

  return (___arguments.context.dataModel[0] * 100 + ___arguments.context.dataModel[1] >= ___arguments.context.dataModel[2] * 100 + ___arguments.context.dataModel[3])

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/37ed02d8368cd70ceadba30925dfa0b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('availability_templates:');
  console.log(getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_templates'));
  console.log('availability_overrides:');
  console.log(getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides'));
  console.log('page data:');
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/6f49ab85650c7162071aa312db5fd9fc/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['hrOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'hrOptions'));
  ___arguments.context.dataModel['minOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'minOptions'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/AvailabilityPlay/components/7105cfb40ea5ba57b0912e589595c728/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/73591b27334c8ec941e464e56b3cc075/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('136a3a43c905f6a665a2019fff1d26b2'), 'datetime'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/abf562718532d948539588fd85cb3c9d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d81358c3ac85089593eed9296f434485'), 'intervals')))) ? !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d81358c3ac85089593eed9296f434485'), 'intervals')).length : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/c167529efdd595757f174269cbb6276d/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6f49ab85650c7162071aa312db5fd9fc'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[0] = ___arguments.value;
  ___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[2] = ___arguments.value + 1;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[0])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/f38afb8c8338a504e526b0fa8c02ee39/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6f49ab85650c7162071aa312db5fd9fc'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[1] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[1])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/651cff687eb6664691cc12a504d373ae/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6f49ab85650c7162071aa312db5fd9fc'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[2] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[2])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/51ccfc256a6c5a0e04173ecfec277f8b/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6f49ab85650c7162071aa312db5fd9fc'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[3] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a')[3])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/0b6cce99e1415f428aad0864e6c24f93/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    // Show error if end time is not larger than start time.

  return (___arguments.context.dataModel[0] * 100 + ___arguments.context.dataModel[1] >= ___arguments.context.dataModel[2] * 100 + ___arguments.context.dataModel[3])

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/f7d5097ddb16a7b5b183de1acddd2ee7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['dayOverride'] = ___arguments.context.getComponentDataStoreByUid('d2df8447a52295e76663d0c69bf5757a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/1ca795c1b70befa1638fb41d5d3dbd46/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      // Decide if user needs am/pm time or not
  if ((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 == null) {
    return ([___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[0],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[1]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[1])))].join(''));
  } else {
    return ([___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[0] > 12 ? ___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[0] - 12 : ___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[0],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[1]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[1]))),___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[0] < 12 ? ' am' : ' pm'].join(''));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/54573d454d69e98dcbe187da9eda3583/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      // Decide if user needs am/pm time or not
  if ((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 == null) {
    return ([___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[2],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[3]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[3])))].join(''));
  } else {
    return ([___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[2] > 12 ? ___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[2] - 12 : ___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[2],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[3]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[3]))),___arguments.context.getComponentDataStoreByUid('f7d5097ddb16a7b5b183de1acddd2ee7')[2] < 12 ? ' am' : ' pm'].join(''));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/AvailabilityPlay/components/d81358c3ac85089593eed9296f434485/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['hrOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'hrOptions'));
  ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['minOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'minOptions'));

  },
  /* handler:onBeforeMount */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'dayOverride'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, item, cleanOverrideObj, overrideObj, newOverrideObj;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('035276872d818d2bc21c466e53c2f2d4')), 'button-loading');
    // basically just rename override_objectId to objectId for subsequent DeepSave.
    overrideObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'override_objectId')),[`datetime`]: (getObjectProperty(___arguments.context.dataModel, 'datetime')),[`intervals`]: (getObjectProperty(___arguments.context.dataModel, 'intervals')) });
    // Deep save lets us create and relate an availability_overridesobject if there was none before. If there is one already, this just upserts the existing availability_overrides object with new interval.
    newOverrideObj = (getObjectProperty((await Backendless.Data.of('profiles').deepSave( ({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`availability_overrides`]: [overrideObj] }) )), 'availability_overrides'))[0];
    newOverrideObj['override_objectId'] = (getObjectProperty(newOverrideObj, 'objectId'));
    cleanOverrideObj = ({ [`override_objectId`]: (getObjectProperty(newOverrideObj, 'override_objectId')),[`datetime`]: (getObjectProperty(newOverrideObj, 'datetime')),[`intervals`]: (getObjectProperty(newOverrideObj, 'intervals')) });
    // If user is just editing a day, we replace the item
    // in App Data with the newly updated override day.
    if (typeof (getObjectProperty(overrideObj, 'objectId')) === 'string') {
      replaceItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), cleanOverrideObj, 'override_objectId');
    } else {
      // else the override day is new. Have to add to
      // our array in App Data and re-sort by date.
      (getObjectProperty(___arguments.context.appData, 'teacherObjects'))['availability_overrides'] = (await asyncListSort((addItemToList((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), cleanOverrideObj)), 1, async (item) => {


       return (getObjectProperty(item, 'datetime'));
      }));
    }

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Something went wrong: couldn\'t save the override data!', 'red', null);
    console.log(error);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('035276872d818d2bc21c466e53c2f2d4')), 'button-loading', '');
    delete ___arguments.context.pageData['dayOverride'];

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/AvailabilityPlay/components/2c22942f29aef00a1c2905eef0bbd594/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'dayOverride')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'dayOverride'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'dayOverride')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['dayOverride'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/AvailabilityPlay/components/67fcd8434bc39a51470f410481edd939/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('2c22942f29aef00a1c2905eef0bbd594');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/67af90995a57e8719a34c96d55d8f74d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d2df8447a52295e76663d0c69bf5757a'), 'intervals')))) ? !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d2df8447a52295e76663d0c69bf5757a'), 'intervals')).length : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['dayOverride'] = ___arguments.context.getComponentDataStoreByUid('d2df8447a52295e76663d0c69bf5757a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/f89eb83de23cebb8cd5d840ebe339d6b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('2c22942f29aef00a1c2905eef0bbd594');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/ec4245f9a88af0728d02f7189706469e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ec4245f9a88af0728d02f7189706469e')), 'button-loading-dark');
    await Backendless.Data.of('availability_overrides').remove( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('136a3a43c905f6a665a2019fff1d26b2'), 'override_objectId')) );
    removeItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), ___arguments.context.getComponentDataStoreByUid('136a3a43c905f6a665a2019fff1d26b2'), 'override_objectId');

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Something went wrong: couldn\'t delete this day from overrides', 'red', null);
    console.log(error);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ec4245f9a88af0728d02f7189706469e')), 'button-loading-dark', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/db285c0f5975976121b58d4ebfcda053/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['dayOverride'] = ({ [`override_objectId`]: null,[`datetime`]: (new Date()),[`intervals`]: [[9, 0, 17, 0]] });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/a262c3fbac81545e42bffc6caa0540f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var lastHour, firstHour;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d81358c3ac85089593eed9296f434485'), 'intervals')).length) {
    firstHour = 9;
    lastHour = 17;
  } else {
    firstHour = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d81358c3ac85089593eed9296f434485'), 'intervals')).slice(-1)[0][2] + 1;
    lastHour = firstHour + 1;
  }
  if (firstHour >= 22) {
    firstHour = 0;
    lastHour = 0;
  }
  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d81358c3ac85089593eed9296f434485'), 'intervals')), [[firstHour, 0, lastHour, 0]]);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/b43391b302bc42368386fffbfe1a71d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // We remove the interval from the list of intervals.
  removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d81358c3ac85089593eed9296f434485'), 'intervals')), ___arguments.context.getComponentDataStoreByUid('bccba818442ceab6eb0adbac105d230a'), '');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/AvailabilityPlay/components/0a26138b6041817c64f8baab66e64e06/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  console.log(await Promise.all((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')).map(async item => {; return ((new Date((getObjectProperty(item, 'datetime')))).toDateString());})));
  console.log((new Date(___arguments.value)).toDateString());
  console.log('Does this day exist already?');
  console.log((await Promise.all((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')).map(async item => {; return ((new Date((getObjectProperty(item, 'datetime')))).toDateString());}))).includes(((new Date(___arguments.value)).toDateString())));
  // If the override already exists (same date as the
  // one chosen by date picker), we load that item from
  // Page Data, our create will turn into an edit.
  if ((await Promise.all((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')).map(async item => {; return ((new Date((getObjectProperty(item, 'datetime')))).toDateString());}))).includes(((new Date(___arguments.value)).toDateString()))) {
    Object.assign(___arguments.context.getComponentDataStoreByUid('d81358c3ac85089593eed9296f434485'), ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), async (item) => {


     return (((new Date((getObjectProperty(item, 'datetime')))).toDateString()) == ((new Date(___arguments.value)).toDateString()));
    }))[0]));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/AvailabilityPlay/components/0693b44943621e49e79c997ca6f5f8ad/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['futureAppointmentsData'] = ({ [`items`]: [] });

  },
  /* handler:onBeforeMount */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var query, teacherIds, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * If component is on student Home page, we get all
 * instances of all teachers followed by the student. If
 * we're on a teachers public page, we get the classes for
 * that teacher. Otherwise, we're on Schedule and we get
 * the classes of the user (who is the controlling teacher)
 */
async function buildAppointmentsQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    counter = 0;
    // Can't build query until we know teachers being followed in
    // Page Data. After 4 seconds, give up and break out of loop.
    while (!(getObjectProperty(___arguments.context.appData, 'teachersFollowed'))) {
      counter = (typeof counter == 'number' ? counter : 0) + 1;
      await new Promise(r => setTimeout(r, 100 || 0));
      if (counter > 40) {
        console.log('failed to get teachersFollowed');
        break;
      }
    }
    teacherIds = ((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id']));
    // Query to get all class instances from all the teachers the user follows...
    query = ['teacher_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(teacherIds)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),') AND end_datetime >',(new Date((new Date())).getTime())].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime >',(new Date((new Date())).getTime())].join('');
  } else {
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\' AND end_datetime >',(new Date((new Date())).getTime())].join('');
  }
  return query
}


  ___arguments.context.pageData['page'] = 'Schedule';
  await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('down', 'appointmentsScrolly', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('88381e17223571c813268d993af17a11')), (getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData')), ({ [`tableName`]: 'appointments_view',[`whereClause`]: await buildAppointmentsQuery(),[`properties`]: undefined,[`sortBy`]: 'start_datetime asc',[`pageSize`]: 25 }), null, null);

  },
  /* handler:onMounted */
  /* handler:onBeforeUnmount */
  async ['onBeforeUnmount'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('destroy', 'appointmentsScrolly', null, null, null, null, null);

  },
  /* handler:onBeforeUnmount */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ [`min-height`]: 'auto !important;' })

  },
  /* handler:onStyleAssignment */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData')) == null ? [] : (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData')), 'items')))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Home/components/09bf55122a97cadbf1cbe863802833a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/bc4efc6f5031a43caa567cc9ee35a94d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var item, teachersFollowed;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Home';
  await BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a']((getObjectProperty(___arguments.context.pageData, 'page')));

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Home/components/48e153bdb2c9dd8a65af66e7e259f5ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['teacherData'] = ___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d');
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('543d3f9bca2effe726df38e2fdb5452f'))['open'] = true;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('543d3f9bca2effe726df38e2fdb5452f'))['titleText'] = 'Your referral link';
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('543d3f9bca2effe726df38e2fdb5452f'))['referral'] = true;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('543d3f9bca2effe726df38e2fdb5452f'))['teacher_url'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d'), 'teacher_url'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/a0fc4e863fff4227dbc6385cce970c79/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teachersFollowed'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Home/components/9fb3905975721822bec456993934736a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // it's called emailStudent because it's what we use on the
  // student detail page, but it works for the teacher too
  ___arguments.context.pageData['emailModal'] = ({ 'full_name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d'), 'teacher_full_name')),'student_profile_id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d'), 'teacher_profile_id')),'type': 'singleStudent' });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/ba6cb82597290e33510d5efbebf38d63/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Show default until data arrives

  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d'), 'teacher_thumbnail_small')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d'), 'teacher_thumbnail_small')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Home/components/fbc7219d472688da19166c8caf029581/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ [`profile_url`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d'), 'teacher_url')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/3bd56b973acbb11e6b19f4d576ffad13/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ [`profile_url`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a580f2cc72347f1de1e345f207b94d'), 'teacher_url')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Landing';
  // If user is logged in (local storage has
  // data), redirect them to their default place.
  if ((await Backendless.UserService.getCurrentUser(false)) !== null && !Array.isArray((await Backendless.UserService.getCurrentUser(false))) && (typeof (await Backendless.UserService.getCurrentUser(false)) === 'object')) {
    // Teachers redirected to Schedule, students to
    // their Home page. Turn this off for testing things
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) ? 'Schedule' : 'Home'), undefined);
  }
  if (!(typeof (getObjectProperty(___arguments.context.pageData, 'make_teacher')) === 'boolean')) {
    // If person signs up on this page, we make them a teacher.
    // Login Signup form looks for this property make_teacher
    ___arguments.context.pageData['make_teacher'] = true;
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Landing/components/9c41ae464daea32c1c2e0a610adada56/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'first';
  ___arguments.context.pageData['openLoginModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Landing/components/d5fd70abf50265964c887f651f1b4bb1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'second';
  ___arguments.context.pageData['openLoginModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/ff07b51ad60dcda7f50a3d3e00061a64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['copyLinkModal'] = ({ [`titleText`]: 'Share your schedule',[`referral`]: false });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/09bf55122a97cadbf1cbe863802833a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/bc4efc6f5031a43caa567cc9ee35a94d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Public';
  // If profile_id parameter is not there, send user somewhere sensible.
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'profile_url')) === 'undefined') && (typeof (getObjectProperty(___arguments.context.pageData, 'profile_id')) === 'undefined')) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Landing', ({ 'make_teacher': false }));
  }
  ___arguments.context.pageData['openLoginModal'] = false;
  // When user books something, want log in to be the default (not register)
  ___arguments.context.pageData['currentTab'] = 'second';

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * The public page of a teacher needs the data for that teacher.
 */
async function loadTeacherData() {
  // get profile object and some related data for the teacher who's public page it is.
  ___arguments.context.pageData['teacherData'] = ((await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\''].join(''))).setRelated(['locations', 'settings']).setPageSize(1)))[0]);
  (getObjectProperty(___arguments.context.pageData, 'teacherData'))['locations'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'teacherData.locations')), async (item) => {


   return (getObjectProperty(item, 'active'));
  }));
  // if user is following the teacher on this page or
  // not, we set "following" property in Page Data
  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'teachersFollowed')))) {
    (getObjectProperty(___arguments.context.pageData, 'teacherData'))['following'] = (((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).includes((getObjectProperty(___arguments.context.pageData, 'teacherData.objectId'))));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'teacherData'))['following'] = false;
  }
}


  await loadTeacherData();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Public/components/663cfb8f6562a214ceaa803256fd491b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['saveAboutModal'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only show edit button to teacher whose page this is.

  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) == (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/52a8d06e34a8efc21a173797a365b6e1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('52a8d06e34a8efc21a173797a365b6e1')), 'button-loading');
  (getObjectProperty(___arguments.context.pageData, 'teacherData'))['about_text'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getHTML() })('c7b2b306dded3e6ead0733083d6ae39a'));
  await Backendless.Data.of('profiles').save( (getObjectProperty(___arguments.context.pageData, 'teacherData')) );
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('52a8d06e34a8efc21a173797a365b6e1')), 'button-loading', '');
  ___arguments.context.pageData['saveAboutModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/bdfbed86b7c1fcc4465a2f7ddeadaf5f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['saveAboutModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/e68e482bf7b858dbbc8d7e24d9471eaf/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'teachersFollowed')))) {
    return ((getObjectProperty(___arguments.context.pageData, 'teacherData.following')) ? 'Following' : 'Follow');
  }

  return 'Follow'

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // If user is not logged in, we ask them to log in (or sign up).
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null) {
    ___arguments.context.pageData['currentTab'] = 'second';
    ___arguments.context.pageData['openLoginModal'] = true;
  } else {
    ___arguments.context.pageData['disableButton'] = true;
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e68e482bf7b858dbbc8d7e24d9471eaf')), 'button-loading-dark');
    if (getObjectProperty(___arguments.context.pageData, 'teacherData.following')) {
      await Backendless.Data.of('teacher_student_rels').remove( (getObjectProperty(((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).splice(((((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).indexOf(getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')) + 1) - 1), 1)[0]), 'tsr_objectId')) );
      (getObjectProperty(___arguments.context.pageData, 'teacherData'))['following'] = false;
    } else {
      await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8']((getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')), (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')), null);
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
      (getObjectProperty(___arguments.context.pageData, 'teacherData'))['following'] = true;
    }
    // if studentList is in AppData and you are looking at
    // your own public profile and unfollowing yourself, we
    // reload students to make sure you're no longer there
    if ((Array.isArray((getObjectProperty(___arguments.context.appData, 'studentList')))) && (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
    }
    delete ___arguments.context.pageData['disableButton'];
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e68e482bf7b858dbbc8d7e24d9471eaf')), 'button-loading-dark', '');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/5a4e0def4902b1267bdd069f4152024f/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	// code snippet from crop.guide, detects file upload process on page and inserts crop/resize overlay
  	await BackendlessUI.requireModule("https://cdn.crop.guide/loader/l.js?c=MDWNWW");

  })();
  // Get the relevant data from user's profile
  // object and store them in the Form Data Model.
  Object.assign(___arguments.context.dataModel, (getObjectProperty(___arguments.context.appData, 'accountProfile')));
  // If profile table has no link to a thumbnail,
  // we set imageURL to the "defaultThumbnail" image.
  if ((getObjectProperty(___arguments.context.dataModel, 'thumbnail')) == null) {
    ___arguments.context.dataModel['thumbnail'] = 'https://worthypickle.backendless.app/api/files/media/Thumbnails_large/defaultThumbnail.png';
  }

  },
  /* handler:onBeforeMount */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('38fd769a9207c56bd1bfbb322a7368cf')), 'button-loading');
  try {
    ___arguments.context.appData['accountProfile'] = (await Backendless.Data.of('profiles').save(___arguments.context.dataModel, true));
    ___arguments.context.pageData['teacherData.full_name'] = (getObjectProperty(___arguments.context.appData, 'accountProfile.full_name'));
    ___arguments.context.pageData['teacherData.thumbnail'] = (getObjectProperty(___arguments.context.appData, 'accountProfile.thumbnail'));
    await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (String(error)), 'red', null);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('38fd769a9207c56bd1bfbb322a7368cf')), 'button-loading', '');
    delete ___arguments.context.pageData['editProfileModal'];

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Public/components/394c62c20411b7ddee7297dcad05d6ae/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // replace whatever was in Form Data with new thumbnail image link
  ___arguments.context.getComponentDataStoreByUid('5a4e0def4902b1267bdd069f4152024f')['thumbnail'] = ___arguments.uploadedFiles;
  // get link to small avatar/thumbnail of the user
  // Makes a small thumbnail if not used before... otherwise returns cached image or link to small image. See here: https://backendless.com/features/marketplace/image-processor-api
  ___arguments.context.getComponentDataStoreByUid('5a4e0def4902b1267bdd069f4152024f')['thumbnail_small'] = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/ImageProcessor/resize`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'image_path': JSON.stringify(___arguments.uploadedFiles),'width': 64,'height': 64 }).send()), 'url'));
  // make the uploader button reappear to show
  // image and in case user wants to change image
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('394c62c20411b7ddee7297dcad05d6ae');

  },
  /* handler:onUploadSuccess */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If object in page data has a thumbnail link, get it...
  // otherwise get link to default thumbnail. Then make this the
  // url for the background image of the file upload button.
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('394c62c20411b7ddee7297dcad05d6ae'))['background-image'] = (['url(',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5a4e0def4902b1267bdd069f4152024f'), 'thumbnail')) != null ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5a4e0def4902b1267bdd069f4152024f'), 'thumbnail')) : 'https://worthypickle.backendless.app/api/files/media/Thumbnails_large/defaultThumbnail.png',')'].join(''));

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Public/components/124c97689ffbbac2538f1182125f147b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editProfileModal'] = ({ [`open`]: true });

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) == (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/b359eae609155dd7e58d9b8a6abe252a/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('b359eae609155dd7e58d9b8a6abe252a', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('b359eae609155dd7e58d9b8a6abe252a', false);

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/Public/components/fc520d3fa32926ef50ccff8b8fc2bbd7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('78cf41e2d4beebb1af66b1d577e8bad6'), 'is_online')) ? 'ONLINE' : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('78cf41e2d4beebb1af66b1d577e8bad6'), 'address')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Public/components/d347d9ae8a42f2ede0a266fec37e48e5/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('78cf41e2d4beebb1af66b1d577e8bad6'), 'is_online')) ? 'TV' : 'pin drop')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/Public/components/4f9f74c29e9d2c2b2bdff0fa41e8b4ce/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e71fa6cc086e49f313fabd1afe0820cf'), 'is_online')) ? 'Virtual venue name' : 'Venue name')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Public/components/6ab62792103434b89f053e12cf21697b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editLocationModal'] = ({ [`titleText`]: 'Edit location',[`operation`]: 'edit',[`locationObj`]: ___arguments.context.getComponentDataStoreByUid('78cf41e2d4beebb1af66b1d577e8bad6') });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/e64f7c9e76e9de3946fe519a95c93c48/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e71fa6cc086e49f313fabd1afe0820cf'), 'is_online')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/e71fa6cc086e49f313fabd1afe0820cf/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('642d44e848cbf25e3eb83c7c44d60aad')), 'button-loading');
  try {
    Object.assign((getObjectProperty(___arguments.context.pageData, 'editLocationModal')), (await Backendless.Data.of('locations').save(___arguments.context.dataModel, true)));
    (getObjectProperty(___arguments.context.appData, 'teacherObjects'))['locations'] = (await Promise.all((getObjectProperty(___arguments.context.appData, 'teacherObjects.locations')).map(async item => {; return ((getObjectProperty(item, 'objectId')) == (getObjectProperty(___arguments.context.dataModel, 'objectId')) ? ___arguments.context.dataModel : item);})));
    (getObjectProperty(___arguments.context.pageData, 'teacherData'))['locations'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'teacherData.locations')).map(async item => {; return ((getObjectProperty(item, 'objectId')) == (getObjectProperty(___arguments.context.dataModel, 'objectId')) ? ___arguments.context.dataModel : item);})));

  } catch (error) {
    console.log(error);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('642d44e848cbf25e3eb83c7c44d60aad')), 'button-loading', '');
    delete ___arguments.context.pageData['editLocationModal'];

  }

  },
  /* handler:onSubmit */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editLocationModal.operation')) == 'edit')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editLocationModal.locationObj'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/Public/components/7e19940f36ce0d2fc5efd3e7cdbd90ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var locationObj;


  delete ___arguments.context.pageData['editLocationModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/ec38677c7c4c02ee8c23de147cd5e303/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var locationObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  locationObj = (getObjectProperty(___arguments.context.pageData, 'editLocationModal.locationObj'));
  locationObj['active'] = false;
  await Backendless.Data.of('locations').save(locationObj, true);
  (getObjectProperty(___arguments.context.pageData, 'teacherData'))['locations'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'teacherData.locations')), locationObj, 'objectId'));
  (getObjectProperty(___arguments.context.appData, 'teacherObjects'))['locations'] = (removeItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.locations')), locationObj, 'objectId'));
  delete ___arguments.context.pageData['editLocationModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/f17aa2c1b4b9b80fe16fdfa8fb9fbd0f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editLocationModal'] = ({ [`titleText`]: 'Delete location',[`operation`]: 'delete',[`locationObj`]: ___arguments.context.getComponentDataStoreByUid('78cf41e2d4beebb1af66b1d577e8bad6') });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/64835348ae224d324645ba3971585edc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'teacherData.following')) && (getObjectProperty(___arguments.context.pageData, 'teacherData.settings.student_referral_active')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/8ba92a93732844d4ef12179455a661ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['copyLinkModal'] = ({ [`titleText`]: 'Your referral link',[`referral`]: true });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/abfc1a05dd39c3f8bc5572c5e4638582/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editLocationModal.operation')) == 'delete')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/2f120dae63ab08946ab9c5e35ea1c77e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// this is to hide the whole locations card if there are no active locations

  return ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'teacherData.locations')))) ? !!(getObjectProperty(___arguments.context.pageData, 'teacherData.locations')).length : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/f503a6531861ff7a3515865565b36f6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user is not logged in, we ask them to log in (or sign up).
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null) {
    ___arguments.context.pageData['currentTab'] = 'second';
    ___arguments.context.pageData['openLoginModal'] = true;
  } else {
    // it's called emailStudent because it's what we use on the
    // student detail page, but it works for the teacher too
    ___arguments.context.pageData['emailModal'] = ({ 'full_name': (getObjectProperty(___arguments.context.pageData, 'teacherData.full_name')),'student_profile_id': (getObjectProperty(___arguments.context.pageData, 'profile_id')),'type': 'singleStudent' });
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/5007d1559262175aac2629d4a7a38aa7/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'teacherData.thumbnail')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('large')) : (getObjectProperty(___arguments.context.pageData, 'teacherData.thumbnail')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Public/components/a9adc4ac2f6ec1d7d94e65b58961e693/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) == (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/60b736167f3796b443d6e1ea9f89f4fd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only show schedule share element to the teacher whose page this is.

  return ((getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/090765cf6245f0aea32325566ea48ae1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// About text isn't there and you are not the teacher whose page this is: hide "About" block.

  return (!(!(typeof (getObjectProperty(___arguments.context.pageData, 'teacherData.about_text')) === 'string') && (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) != (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Public/components/629b39bb8cadc41df6614c879aea3d14/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'saveAboutModal'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['saveAboutModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Public/components/c9b82a1ebdb1f2b69a31c2c3a20e5859/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editProfileModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editProfileModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editProfileModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['editProfileModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Public/components/58061234aae16338d9c63e035f6ec28c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['editProfileModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/9d5344a551b7af23798acd2cac890f59/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editLocationModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editLocationModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editLocationModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['editLocationModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Public/components/bb49beaf965374ca780d559304fc0149/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editLocationModal.titletext'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Public/components/40ff55eed407afaa5551cfcd11d2f4f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['editLocationModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Public/components/a69c416d650f41be89d40e4b40f14f74/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'teacherData')) === 'undefined') ? ['loading', 'header'] : ['header'])

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'teacherData')) === 'undefined') ? '                      ' : (getObjectProperty(___arguments.context.pageData, 'teacherData.full_name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Public/components/18bb05827895a81be25d7163130d903d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'teacherData.thumbnail')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Public/components/c7b2b306dded3e6ead0733083d6ae39a/bundle.js', [], () => ({
  /* content */
  /* handler:contentLogic */
  ['contentLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'teacherData.about_text'))

  },
  /* handler:contentLogic */
  /* content */
}))

define('./pages/Schedule/components/6a17a133a8fb7ec352d72a21aff2975c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'repeat')) > 1);

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    
  },
  /* handler:onChange */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'repeatUntilDatetime')) === 'undefined')) {
    return 'End repeat:';
  }

  return 'End repeat: never ▾'

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Schedule/components/b67e2b4d14c9d8611a67f03813e96e66/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  ['onSubmit'](___arguments) {
    var error, startDatetime, endDatetime, repeatUntilDatetime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    // Initialize the startDatetime variable with the date
    // user set for start date. It has a messed up time
    // so we set hours and mins to what user wanted.
    startDatetime = (getObjectProperty(___arguments.context.dataModel, 'startDatetime'));
    startDatetime.setHours((getObjectProperty(___arguments.context.dataModel, 'startHr')));
    startDatetime.setMinutes((getObjectProperty(___arguments.context.dataModel, 'startMin')));
    // Add this property to the data model.
    ___arguments.context.dataModel['startDatetime'] = startDatetime;
    endDatetime = (new Date((getObjectProperty(___arguments.context.dataModel, 'startDatetime'))));
    endDatetime.setHours((getObjectProperty(___arguments.context.dataModel, 'endHr')));
    endDatetime.setMinutes((getObjectProperty(___arguments.context.dataModel, 'endMin')));
    // Add this property to the data model.
    ___arguments.context.dataModel['endDatetime'] = endDatetime;
    // If user doesn't set an end repeat Datetime, or they have the class set to never repeat, we just set repeatUntilDatetime to null. Otherwise, do some calculation.
    if ((typeof (getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')) === 'undefined') || ((getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')) === null) || (getObjectProperty(___arguments.context.dataModel, 'repeat')) == 1) {
      repeatUntilDatetime = null;
    } else {
      // Whatever day user chose as the untilDate, we add a day and set time
      // to 0:00. This way we are sure to include the whole day for the RRule.
      repeatUntilDatetime = (new Date((new Date((getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')))).getTime() + (86399 * 1000)));
      repeatUntilDatetime.setHours(0);
      repeatUntilDatetime.setMinutes(0);
    }
    ___arguments.context.dataModel['repeatUntilDatetime'] = repeatUntilDatetime;
    if ((endDatetime.valueOf()) <= (startDatetime.valueOf())) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4678d8d21b7745cb5bd8a6425069bab', true);
      throw (new Error('End time has to be bigger than start time!'))
    }
    // go to form 2
    ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['currentTab'] = 'second';

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onSubmit */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'createTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'createTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'createTemplate')) === 'object')) {
    // Don't let user create a class in the past
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('440c16b8d92919b57e18fad310472963'))['minDate'] = (new Date());
    // Initialize minimum end date to be today when component mounts.
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6a17a133a8fb7ec352d72a21aff2975c'))['minDate'] = (new Date());
  } else if ((getObjectProperty(___arguments.context.pageData, 'editTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editTemplate')) === 'object')) {
    ___arguments.context.dataModel['startDatetime'] = (getObjectProperty(___arguments.context.pageData, 'editTemplate.newStartDate'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'editInstance')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editInstance'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editInstance')) === 'object')) {
    ___arguments.context.dataModel['startDatetime'] = (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'instanceData.start_datetime'))));
  }

  },
  /* handler:onMounted */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')

  },
  /* handler:onContainerDataAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'currentTab')) == 'first') {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'editInstance')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editInstance'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editInstance')) === 'object')) || ((getObjectProperty(___arguments.context.pageData, 'editTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editTemplate')) === 'object'))) {
    return ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'title')) === 'undefined') ? ['form-loading'] : []);
  }

  return []

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/54a92677e7271cc4942e2253a22b51cb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4678d8d21b7745cb5bd8a6425069bab', false);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/6b8671e30457c4ae5821ce6d843cc8a3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4678d8d21b7745cb5bd8a6425069bab', false);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/bab7f424b314f31eb32c55ec6b31f393/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['currentTab'] = 'second';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/09ea871badf8988dbf155bfd0c279044/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var newInstance, RRuleString, string2, selectValue, inputString, days, j, ALLDays, idx, threshhold, item, TZOffset, classTime;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function constructRRule() {
  selectValue = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'repeat'));
  if (selectValue == 1) {
    string2 = ';COUNT=1';
  } else if (selectValue == 2) {
    string2 = '';
  } else if (selectValue == 3) {
    string2 = ';BYDAY=MO,TU,WE,TH,FR';
  } else if (selectValue == 4) {
    string2 = ';BYDAY=MO,TU,WE,TH,FR,SA,SU';
  }
  if (typeof (string2.indexOf('BYDAY') + 1) === 'number' && !isNaN((string2.indexOf('BYDAY') + 1))) {
    // This function shifts days of the week forward or backwards if user time zone and class time is such that instances fall on different days of the week in UTC.
    string2 = await shiftWeekDays(string2, (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'startDatetime'))).getHours()) + (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'startDatetime'))).getMinutes()) / 60);
  }
  RRuleString = ['DTSTART:',(await (async function(timestamp) {
  	// little function to take timestamp and create string matching requirement for RRULE format
  	// input is timestamp, convert to ISO format
  	let date = new Date(timestamp);
  	let str = date.toISOString();
  	// make the string only contain alphanumeric characters
  	var cleanString = str.match(/[a-zA-Z0-9]/g).join("");
  	//then remove the last 4 characters and add back the "Z" + more characters
  	cleanString = cleanString.substring(0, cleanString.length - 4) + "Z";

  	return cleanString
  })(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'startDatetime')).valueOf()))),String(String(' \n') + String('RRULE:FREQ=WEEKLY')) + String(string2),((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'repeatUntilDatetime')) === null) || (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'repeatUntilDatetime')) === 'undefined') ? '' : String(';UNTIL=') + String((await (async function(timestamp) {
  	// little function to take timestamp and create string matching requirement for RRULE format
  	// input is timestamp, convert to ISO format
  	let date = new Date(timestamp);
  	let str = date.toISOString();
  	// make the string only contain alphanumeric characters
  	var cleanString = str.match(/[a-zA-Z0-9]/g).join("");
  	//then remove the last 4 characters and add back the "Z" + more characters
  	cleanString = cleanString.substring(0, cleanString.length - 4) + "Z";

  	return cleanString
  })(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'repeatUntilDatetime')).valueOf()))))].join('');
  return RRuleString
}

/**
 * Describe this function...
 */
async function shiftWeekDays(inputString, classTime) {
  // Gets the timezone offset from UTC for the user wherever they are. For
  // example, if user browser is in Argentina, this returns the number: -3
  TZOffset = ((new Date((new Date())).getTimezoneOffset()) / 60) * -1;
  threshhold = classTime - TZOffset;
  // this function only really runs if it needs to.
  if (threshhold < 0 || threshhold >= 24) {
    days = (inputString.split(';BYDAY=', ))[1].split(',');
    // Al the days of the week starting on Monday
    ALLDays = 'MO,TU,WE,TH,FR,SA,SU'.split(',');
    // Get list of indeces where our chosen days are found in ALLDays.
    idx = (await Promise.all(days.map(async item => {; return (ALLDays.indexOf(item) + 1);})));
    // If the UTC day of the week is different from the local day of the
    // week, we shift days in the string so RRule stores things properly.
    // We take ALLDays and rotate left or right, depending on threshhold.
    if (threshhold < 0) {
      ALLDays.unshift(ALLDays.pop());
    } else if (threshhold >= 24) {
      ALLDays.push(ALLDays.shift());
    }
    days = [];
    // We now pick the days of the rotated ALLDays list at the same indexes
    // where our days were found in the un-rotated list of ALLDays.
    for (var j_index in idx) {
      j = idx[j_index];
      addItemToList(days, (ALLDays[(j - 1)]));
    }
    return (String(';BYDAY=') + String(days.join(',')));
  } else {
    return inputString;
  }
}

/**
 * Calculates class length in minutes.
 */
async function calculateClassLength() {
  return Math.round(((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'endDatetime'))).getTime()) - (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'startDatetime'))).getTime())) / 60000)
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('1239451310d87b120624933fd23f964a')), 'button-loading');
  // To write template to database we need rrule string and
  // class_length. Just add these properties to dataBlock model.
  ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['rrule'] = (await constructRRule());
  // class_length is a required field in database,
  // we have to calculate it and add to data model.
  ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['class_length'] = (await calculateClassLength());
  // This trigger unmounting of classes content in megaScrolly.
  // Remounting later will trigger a fetch (refresh) from the database.
  ___arguments.context.pageData['unmountClassScrolly'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'editInstance')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editInstance'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editInstance')) === 'object')) {
    // Decide if we can just upsert changed instance or we have to
    // create a new template, when user changes location or packages.
    // In JS, objects that look the same are not the same. Transforming to JSON text makes them the same (if they are the same).
    if ((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'editInstance.oldLocation')))) == (JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'location')))) && (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'editInstance.oldPackages')))) == (JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages'))))) {
      newInstance = ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'editInstance.instance_objectId')),[`title`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'title')),[`description`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'description')),[`start_datetime`]: ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'startDatetime')).valueOf()),[`end_datetime`]: ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'endDatetime')).valueOf()),[`pricing`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pricing')),[`cost`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'cost')),[`spots`]: (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'spots')))),[`pack_required`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pack_required')) });
      await Backendless.Data.of('class_instances').save(newInstance, true);
    } else {
      await Backendless.Data.of('class_instances').remove( (getObjectProperty(___arguments.context.pageData, 'editInstance.instance_objectId')) );
      // We create a new template with a single class instance. See
      // documentation https://app.clickup.com/3126042/v/dc/2zcru-605/2zcru-2144
      // Because we're editing single class instance, the rrule string will have "COUNT: 1;" (never repeat), this was set when we first mounted the dataModelBlock).
      // Value for selector components is always a string, db expects a number.
      await Backendless.Request.post(`${Backendless.appPath}/services/class_templates/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'class_length': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'class_length')),'cost': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'cost')),'description': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'description')),'location': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'location')),'pricing': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pricing')),'rrule': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'rrule')),'title': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'title')),'spots': (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'spots')))),'packages': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')),'profileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'pack_required': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pack_required')) });
    }
    // Clean up Page Data and this closes the modal.
    delete ___arguments.context.pageData['editInstance'];
  }
  if ((getObjectProperty(___arguments.context.pageData, 'editTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editTemplate')) === 'object')) {
    // Value for selector components is always a string, db expects a number.
    // must be timestamp format.
    await Backendless.Request.put(`${Backendless.appPath}/services/class_templates/${(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'objectId'))}`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'class_length': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'class_length')),'cost': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'cost')),'description': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'description')),'location': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'location')),'pricing': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pricing')),'rrule': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'rrule')),'title': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'title')),'spots': (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'spots')))),'packages': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')),'from_date': ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'startDatetime')).valueOf()),'pack_required': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pack_required')) });
    // Clean up Page Data and close the modal
    delete ___arguments.context.pageData['editTemplate'];
  }
  if ((getObjectProperty(___arguments.context.pageData, 'createTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'createTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'createTemplate')) === 'object')) {
    // Value for selector components is always a string, db expects a number.
    await Backendless.Request.post(`${Backendless.appPath}/services/class_templates/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'class_length': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'class_length')),'cost': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'cost')),'description': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'description')),'location': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'location')),'pricing': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pricing')),'rrule': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'rrule')),'title': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'title')),'spots': (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'spots')))),'packages': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')),'profileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'pack_required': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'pack_required')) });
    // Clean up Page Data and close the modal
    delete ___arguments.context.pageData['createTemplate'];
  }
  // This mounts the class containers in megaScrolly which
  // gets classes again from the data base (refresh).
  delete ___arguments.context.pageData['unmountClassScrolly'];

  },
  /* handler:onSubmit */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'currentTab')) == 'third') {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/Schedule/components/40d68593475d52968b5a70151ac2d5ac/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('40d68593475d52968b5a70151ac2d5ac')), 'value')) == 1) {
    ___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66')['endRepeat'] = null;
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editTemplate'))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If we're editing instance, don't show repeat options

  return (!((getObjectProperty(___arguments.context.pageData, 'editInstance')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editInstance'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editInstance')) === 'object')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/e48f4a56f4938ba4d651d4b49511137e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Next button is disabled until all required fields have data. Description is not required.
  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'locationIdx')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'spots')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'pricing')) || !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'cost')) === 'number' && !isNaN((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'cost')))));

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/3cffcdee256ecec9824f0e0208cc4857/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['currentTab'] = 'first';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/6098256da22d1c480a9ec585b07619c3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user selects "create new location" we hide the second form in favor of the create Location form.
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'currentTab')) == 'second' && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'locationIdx')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations')).length + 1) {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onSubmit */
  ['onSubmit'](___arguments) {
    var error;


  try {
    ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['currentTab'] = 'third';

  } catch (error) {
    console.log('error on submit form 2 is:');
    console.log(error);

  }

  },
  /* handler:onSubmit */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')

  },
  /* handler:onContainerDataAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    
  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Schedule/components/7db367f5aca3130289057aa85c9cc1d1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (null != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'locationIdx')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/a1a95311a97815ab80595d932f7bb604/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (null != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'spots')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/021b6adf3c31065f50aac9422886b80c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (null != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'pricing')) && null != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3'), 'cost')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/aa778c4c86329dd7f466efd895d73128/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3856af6966a2bbf58e6391a635c24e21', false);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/ad23bc35b78b36f47fa42a11eafed6bd/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var blockData, templateId, instanceData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Set defaults for pricing to be fixed. Default for packages to be empty
 * list. Default for times is "0". All required or things go wrong...
 */
async function initializeTemplateData() {
  blockData = ({ [`pricing`]: 'fixed',[`packages`]: [],[`startHr`]: '0',[`startMin`]: '0',[`endHr`]: '0',[`endMin`]: '0' });
  return blockData
}

/**
 * Describe this function...
 */
async function loadTemplateData(templateId) {
  // Get class_template record from database,
  // including location and packages relations.
  blockData = (await Backendless.Data.of('class_templates').findById(templateId, Backendless.DataQueryBuilder.create().setRelated(['location', 'packages'])));
  // Create properties for the forms using the rrule
  // string and class length info from database.
  Object.assign(blockData, (await BackendlessUI.Functions.Custom['fn_a9caf714cff1d13faba8103aaa293e04']((getObjectProperty(blockData, 'rrule')), (getObjectProperty(blockData, 'class_length')))));
  // Set location selector to the value that shows the location for
  // the class. The selector has value databound to "locationIdx"
  blockData['locationIdx'] = (((getObjectProperty(___arguments.context.dataModel, 'teacherLocations')).map(item => item['objectId'])).indexOf(getObjectProperty(blockData, 'location.objectId')) + 1);
  return blockData
}

/**
 * Describe this function...
 */
async function loadInstanceData(templateId) {
  // Get class_template record from database,
  // including location and packages relations.
  blockData = (await Backendless.Data.of('class_templates').findById(templateId, Backendless.DataQueryBuilder.create().setRelated(['location', 'packages'])));
  instanceData = (await Backendless.Data.of('class_instances').findById((getObjectProperty(___arguments.context.pageData, 'editInstance.instance_objectId')), ));
  // Any properties found in instance which have the
  // same name as properties in template will over-write
  // those... so we see instance info when form loads.
  Object.assign(blockData, instanceData);
  // Create properties for the forms using the instance data.
  // Since we're editing single instance, repeat is always "never", which is 1 in the selector.
  Object.assign(blockData, ({ [`startDatetime`]: (new Date((getObjectProperty(instanceData, 'start_datetime')))),[`startHr`]: (JSON.stringify((new Date((new Date((getObjectProperty(instanceData, 'start_datetime'))))).getHours()))),[`startMin`]: (JSON.stringify((new Date((new Date((getObjectProperty(instanceData, 'start_datetime'))))).getMinutes()))),[`endDatetime`]: (new Date((getObjectProperty(instanceData, 'end_datetime')))),[`endHr`]: (JSON.stringify((new Date((new Date((getObjectProperty(instanceData, 'end_datetime'))))).getHours()))),[`endMin`]: (JSON.stringify((new Date((new Date((getObjectProperty(instanceData, 'end_datetime'))))).getMinutes()))),[`repeat`]: 1 }));
  // Set location selector to the value that shows the location for
  // the class. The selector has value databound to "locationIdx"
  blockData['locationIdx'] = (((getObjectProperty(___arguments.context.dataModel, 'teacherLocations')).map(item => item['objectId'])).indexOf(getObjectProperty(blockData, 'location.objectId')) + 1);
  return blockData
}


  ___arguments.context.dataModel['currentTab'] = 'first';
  ___arguments.context.dataModel['teacherLocations'] = (getObjectProperty(___arguments.context.appData, 'teacherObjects.locations'));
  ___arguments.context.dataModel['teacherPackages'] = (getObjectProperty(___arguments.context.appData, 'teacherObjects.packages'));
  // If editing, we get existing data for the template
  // and/or instance. If creating, we set some defaults.
  if ((getObjectProperty(___arguments.context.pageData, 'editTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editTemplate')) === 'object')) {
    templateId = (getObjectProperty(___arguments.context.pageData, 'editTemplate.template_objectId'));
    blockData = await loadTemplateData(templateId);
    // The new Dtstart for the template should be the
    // date of the instance selected for editing.
    blockData['startDatetime'] = (new Date((getObjectProperty(___arguments.context.pageData, 'editTemplate.newStartDate'))));
  } else if ((getObjectProperty(___arguments.context.pageData, 'createTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'createTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'createTemplate')) === 'object')) {
    blockData = await initializeTemplateData();
  } else if ((getObjectProperty(___arguments.context.pageData, 'editInstance')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editInstance'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editInstance')) === 'object')) {
    templateId = (getObjectProperty(___arguments.context.pageData, 'editInstance.template_objectId'));
    blockData = await loadInstanceData(templateId);
    // Store packages set for this class as "oldPackages"
    // in the editInstance object in Page Data. Have to
    // use text conversion to create independent object. If
    // user changes packages while editing instance, we have
    // to create new template towards the end, when saving.
    (getObjectProperty(___arguments.context.pageData, 'editInstance'))['oldPackages'] = (JSON.parse((JSON.stringify((getObjectProperty(blockData, 'packages'))))));
    // Store location set for this class as "oldLocation"
    // in the editInstance object in Page Data. If user
    // changes location while editing instance, we have to
    // create new template towards the end, when saving.
    (getObjectProperty(___arguments.context.pageData, 'editInstance'))['oldLocation'] = (getObjectProperty(blockData, 'location'));
  }
  // Populate dataModelBlock properties, all the form elements are data-bound to it.
  Object.assign(___arguments.context.dataModel, blockData);

  },
  /* handler:onMounted */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var optionsText, i, optionsObject, incrementMins;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function genSpotsOpts() {
  optionsText = [];
  for (i = 1; i <= 100; i++) {
    addItemToList(optionsText, ({ [`value`]: i,[`label`]: (JSON.stringify(i)) }));
  }
  return optionsText
}


  ___arguments.context.dataModel['hrSelectOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'hrOptions'));
  ___arguments.context.dataModel['minSelectOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'minOptions'));
  ___arguments.context.dataModel['spotsOptions'] = (await genSpotsOpts());

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/Schedule/components/ff07b51ad60dcda7f50a3d3e00061a64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['copyLinkModal'] = ({ [`titleText`]: 'Share your schedule' });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/1bfc98dd11ec76ced937478f6bec9bd7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('37665234cfdc2125dce10443f4762c2a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/8b77a2b5c92b2b1f1718cbf96ec5be12/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var optionsObject, optionsText, teacherLocs, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Populate the locations selector component with the teachers stored
 * locations. Append options to create new location, online or in person.
 */
async function formatLocationsSelector() {
  teacherLocs = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations'));
  optionsObject = [];
  if (!(typeof teacherLocs === 'undefined') && teacherLocs != null) {
    for (var j_index in teacherLocs) {
      j = teacherLocs[j_index];
      addItemToList(optionsObject, ({ 'value': teacherLocs.indexOf(j) + 1,'label': String((getObjectProperty(j, 'is_online')) ? '🖥️  ' : '🏠  ') + String(getObjectProperty(j, 'title')) }));
    }
  }
  addItemToList(optionsObject, ({ 'value': optionsObject.length + 1,'label': 'ADD NEW LOCATION...' }));
  return optionsObject
}


  optionsText = await formatLocationsSelector();

  return optionsObject

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['location'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations'))[(___arguments.value - 1)]);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/27c96cb18a446c171f8a9c4eef330c68/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'title')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/8827376b010eed6f0f63c9c771166763/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'title')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      delete ___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5')['error'];

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/Schedule/components/891b2e4c00c4312efd97c24a188856df/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) ? 'Virtual venue name' : 'Venue name')

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/39b151f3f3163613e09f09d6f26d34c6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5')['is_online'] = (___arguments.checkedValue == 'online');
  ___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5')['is_online'] = (!(___arguments.checkedValue == 'notOnline'));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/b11dd3c1f14ad999b54f5c1ba7d603c9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) === 'undefined') && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'title')) === 'undefined') && ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) ? !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'link')) === 'undefined') : !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'address')) === 'undefined')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) {
      if (!(await (async function(string) {
      	// simple function to check if a string has a valid url format. Based on this article:
      	//https://www.freecodecamp.org/news/how-to-validate-urls-in-javascript/
      	      try {
      	      	return Boolean(new URL(string));
      	      }
      	      catch(e){
      	      	return false;
      	      }

      })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'link'))))) {
        error = (new Error('This needs a valid link'));
        throw error
      }
    }
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('8345c7bbe8f42e1bdecd828edf878dc5');

  } catch (error) {
    ___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5')['error'] = 'This needs to be a valid link';

  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// if link is empty but venue is online, we can't create venue object.
// if address is empty but venue is physical, we can't create venue object.

  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'link')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'address')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'is_online')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/8345c7bbe8f42e1bdecd828edf878dc5/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, newLocations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    // Save the new location to the database, get back all locations of the teacher.
    // Save the new venue and it's relation to the teacher profile using a "Deep Save". For 1:many relations, this is "cummulative" so old locations are presevered.
    newLocations = (getObjectProperty((await Backendless.Data.of('profiles').deepSave( ({ 'objectId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'locations': [___arguments.context.dataModel] }) )), 'locations'));
    // Update list of teacher locations in the data model (actives only)
    ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['teacherLocations'] = (newLocations.concat((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations'))));
    // Update teacher objects in App Data.
    (getObjectProperty(___arguments.context.appData, 'teacherObjects'))['locations'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations'));
    // The new location is always first in the array. Is this guaranteed?
    ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['location'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations'))[0]);
    // The new location is always first in the array. Though not guaranteed...
    ___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd')['locationIdx'] = 1;

  } catch (error) {
    console.log(error);

  }
  // Clear the form
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('8345c7bbe8f42e1bdecd828edf878dc5');
  // Hide the "new venue creation" form.
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8345c7bbe8f42e1bdecd828edf878dc5', false);

  },
  /* handler:onSubmit */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations')) === 'undefined') {
    return false;
  } else {
    // Show the "create location" form if user selects "create new location".
    return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'locationIdx')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'teacherLocations')).length + 1);
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.dataModel['active'] = true;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Schedule/components/b617d6eac874c83fb462abb9765f7bc7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8345c7bbe8f42e1bdecd828edf878dc5'), 'error')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/7c4d34ee3fabca1c5a84a1e67f41b35f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'active')) ? ['active'] : ['inactive'])

  },
  /* handler:onClassListAssignment */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If item is in list of packages (of the class, not the teacher), we show the checked status.

  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')).map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId'))))

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If we check a package, it gets added to
  // packages array. If we uncheck, it gets removed.
  if (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')).map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId')))) {
    removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')), ___arguments.context.dataModel, 'objectId');
  } else {
    addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')), ___arguments.context.dataModel);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/51f26d565c861366b8c63a29602d81e6/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
      if ('fixed' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('b49d59acd3988f0a0cce7578d09fe016'))) {
    return 'Cost';
  } else if ('donation' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('b49d59acd3988f0a0cce7578d09fe016'))) {
    return 'Suggested';
  } else if ('noPayment' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('b49d59acd3988f0a0cce7578d09fe016'))) {
    return 'Display';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value == null) {
    ___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3')['cost'] = null;
  } else {
    // Simplified Block
    ___arguments.context.getComponentDataStoreByUid('6098256da22d1c480a9ec585b07619c3')['cost'] = (await (async function(num) {

    	return Math.abs(num.toFixed(2));
    })(___arguments.value));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/09bf55122a97cadbf1cbe863802833a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/bc4efc6f5031a43caa567cc9ee35a94d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0cff957e986c0a675cb3793ade41214d')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Schedule';
  await BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a']((getObjectProperty(___arguments.context.pageData, 'page')));

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);
  ___arguments.context.pageData['scheduleStats'] = (await Backendless.Request.get(`${Backendless.appPath}/services/stats/${(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  await ( async function (componentUid, value) {  ___arguments.context.getComponentByUid(componentUid).setValue(value) })('af99c1962603e0d131d61dc49e492ebf', ((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dAttendanceMaxCapacity')) == 0 ? 0 : Math.round(((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dCameToClass')) / (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dAttendanceMaxCapacity'))) * 1000) / 10));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Schedule/components/0f4616b9784b5dd5f3026af5dde5ecc6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dAttendanceVSPrevious30')) >= 0 ? '▲ ' : '▼ ',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dAttendanceVSPrevious30')))),'%'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ['small', (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dAttendanceVSPrevious30')) >= 0 ? 'green' : 'red']

  },
  /* handler:onClassListAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/f8d743e6105622ef83216b1b236ad77b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dRevenueVSPrevious30')) >= 0 ? '▲ ' : '▼ ',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dRevenueVSPrevious30')))),'%'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ['small', (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dRevenueVSPrevious30')) >= 0 ? 'green' : 'red']

  },
  /* handler:onClassListAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/7519c738430e3c129ce47de59a092fd1/bundle.js', [], () => ({
  /* content */
  /* handler:dataLogic */
  ['dataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// we add 0.0001 so we don't divide by 0 otherwise the chart says null % instead of 0%

  return [({ [`name`]: String(JSON.stringify((Math.round(((getObjectProperty(___arguments.context.pageData, 'scheduleStats.next7dReservationsTotal')) / ((getObjectProperty(___arguments.context.pageData, 'scheduleStats.next7dReservationsTotal')) + (getObjectProperty(___arguments.context.pageData, 'scheduleStats.next7dFreeSpots')) + 0.0001)) * 100)))) + String('%'),[`goal`]: (getObjectProperty(___arguments.context.pageData, 'scheduleStats.next7dReservationsTotal')) + (getObjectProperty(___arguments.context.pageData, 'scheduleStats.next7dFreeSpots')),[`progress`]: (getObjectProperty(___arguments.context.pageData, 'scheduleStats.next7dReservationsTotal')) })]

  },
  /* handler:dataLogic */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/9515b176e23b1d3b5dc6df9182678f7f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'undefined') ? ' ' : (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1)) + String(getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dTotalRevenue')))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'scheduleStats')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'scheduleStats'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'object'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/3a9bd2d85e575f1bca4de1754a58cc5b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Classes ',(typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'undefined') ? ' ' : (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1),(getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dClassesRevenue'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/4763269c1f83b4b3c3d70a041b5bd429/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Packages ',(typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'undefined') ? ' ' : (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1),(getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPackagesRevenue'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/46613f1c9bcfacdbf124eb8f6f211346/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Videos ',(typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'undefined') ? ' ' : (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1),(getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dVideosRevenue'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/f45741c6061905c32399cfe22b59f8b9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Privates ',(typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'undefined') ? ' ' : (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1),(getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/904b6a2678dec0c32812f579981f3a86/bundle.js', [], () => ({
  /* content */
  /* handler:dataLogic */
  ['dataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return [({ 'value': (typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')) === 'number' && !isNaN((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')))) ? (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')) : 0,'color': '#597016' }), ({ 'value': (typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')) === 'number' && !isNaN((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')))) ? (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPackagesRevenue')) : 0,'color': '#5D89A9' }), ({ 'value': (typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')) === 'number' && !isNaN((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')))) ? (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dClassesRevenue')) : 0,'color': '#97B933' }), ({ 'value': (typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')) === 'number' && !isNaN((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dPrivatesRevenue')))) ? (getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dVideosRevenue')) : 0,'color': '#FA8E35' })]

  },
  /* handler:dataLogic */
  /* content */
}))

define('./pages/Schedule/components/a9a83e7c1b5fdb9847e1b2b3456de9bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Function determines if the number of registrations or
 * attendances has changed since modal opened. If so,
 * we have to reload classes on the Schedule page.
 */
async function maybeReloadClasses() {
  // If "reload" boolean is true, we have to reload classes
  // to make the little number on class card be accurate.
  if (getObjectProperty(___arguments.context.pageData, 'attendanceView.reload')) {
    // This sets visibility of class scrolly container to false...
    // causes unmount of all children. Then we mount them again...
    ___arguments.context.pageData['unmountClassScrolly'] = true;
    await new Promise(r => setTimeout(r, 100 || 0));
    // This mounts the class containers in megaScrolly which
    // gets classes again from the data base (refresh).
    delete ___arguments.context.pageData['unmountClassScrolly'];
  }
}


  await maybeReloadClasses();
  // Done button simply close the modal, clear allStudents
  delete ___arguments.context.pageData['allStudents'];
  delete ___arguments.context.pageData['attendanceView'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/639bd4fcea9c21411ececb0fd87352b9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var onboarding_link, profileObj, stripeAccountId;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('639bd4fcea9c21411ececb0fd87352b9')), 'button-loading');
  profileObj = (await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']());
  if ((getObjectProperty(profileObj, 'upgraded.stripe_account_id')) === null) {
    stripeAccountId = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_account`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'teacher_profile_id': (getObjectProperty(profileObj, 'profile_id')),'email': (getObjectProperty(profileObj, 'email')),'first_name': (((getObjectProperty(profileObj, 'full_name')).split(' ', ))[0]),'last_name': (((getObjectProperty(profileObj, 'full_name')).split(' ', )).slice(-1)[0]) })), 'upgraded.stripe_account_id'));
  } else {
    stripeAccountId = (getObjectProperty(profileObj, 'upgraded.stripe_account_id'));
  }
  console.log(stripeAccountId);
  onboarding_link = (await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_teacher_onboarding_link`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'return_url': (await (async function() {
  	return window.location.href;
  })()),'stripe_account_id': stripeAccountId }));
  console.log(onboarding_link);
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(onboarding_link, 'stripe_account_link')), false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/a238117944d27e062f7481636f1ce84f/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, finalList, attendanceData, allStudents, n, j, txResult, getClassInstanceDataTx, relevantPackages, classInstanceObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Get the classInstanceObject and the relevantPackages
 * as list of objects in a transaction.
 */
async function setInstanceData() {
  getClassInstanceDataTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = getClassInstanceDataTx;

    // Regardless of attendance mode, we update the instance.
    // If in attendance mode, we set attendance_done to True for
    // the class instance, and we get the instance object back.
    // Only mark attendance as done if the class is in attendance mode (class is done or about to be done).
    classInstanceObj = (_uow.update('class_instances', ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'attendanceView.instance_id')),[`attendance_done`]: (getObjectProperty(___arguments.context.pageData, 'attendanceView.type')) == 'attendances' })).setOpResultId('getClassInstanceObj'));
    // Get the tsr and all the teachers the user follows
    // but use the query to only get live teachers (profiles with user_email) who are teachers (is_teacher is true)
    relevantPackages = (_uow.find('class_templates', Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'attendanceView.template_objectId')),'\''].join(''))).setRelated('packages').setPageSize(1)).setOpResultId('getRelevantPackages'));

  })();

  txResult = (await getClassInstanceDataTx.execute());
  if (txResult.isSuccess()) {
    // Load entire class instance object and add to attendanceView property in Page Data.
    (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['classInstanceObj'] = (getObjectProperty((txResult.getResults()), 'getClassInstanceObj.result'));
    // Load packages that re atteched to the class and
    // add to attendanceView property in Page Data.
    (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['relevantPackages'] = (getObjectProperty(((getObjectProperty((txResult.getResults()), 'getRelevantPackages.result'))[0]), 'packages'));
  } else {
    console.log('Transaction to get instance data failed for some reson');
    throw (new Error('Transaction to get instance data failed for some reson'))
  }
}


  // if studentList is not in AppData, load students from the db.
  if (typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') {
    await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
  }
  await setInstanceData();
  // This parameter will cause reload of classes when modal closes
  ___arguments.context.pageData['attendanceView.reload'] = true;
  // We want a separate object from studentList in App Data, have to convert to text and back (see here: https://support.backendless.com/t/data-binding-question/16632/2)
  allStudents = (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.appData, 'studentList'))))));
  // just in case there are more than 100 registrations for a class, we use "load all" function.
  attendanceData = (await BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'](___arguments.context.pageData, 'class_attendance_view', (['class_instance_objectId = \'',(getObjectProperty(___arguments.context.pageData, 'attendanceView.instance_id')),'\''].join('')), null, null, null, 'full_name ASC'));
  n = 1;
  // appends list B to list A, here we just give an
  // index to each item in the mega list. attendanceData
  // items have lower idx numbers because they come first.
  var j_list = (attendanceData.concat(allStudents));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['idx'] = n;
    n = (typeof n == 'number' ? n : 0) + 1;
  }
  finalList = (await (async function(property, weakList, strongList) {
  	//Merges two arrays based on a common property (object key). The string "property" must be a property present in both arrays.
  	//Where both records have the same property name, we use the value from the second array (strongList).
  	//Based on this: https://stackoverflow.com/questions/74477195/how-to-merge-arrays-of-objects-by-id-key


  	//*note: eval('item.'+ property) causes "item.property" to be evaluated, similarly for "b.property"

  	const mergeArrays = (weakList, strongList) => {
  	  return weakList.reduce((acc, b) => {
  	    const idx = acc.findIndex(item => eval('item.'+property) === eval('b.'+property)); //look for the acc has the same property while iterating array1
  	    if (idx > -1) { // if found need to merge with value of array2
  	      acc[idx] = Object.assign(b, acc[idx]);
  	      return acc;
  	    }
  	    return [...acc, b]; //if we don't find anything so "b" is an unique entry
  	  }, strongList); //inital values of reduce is from array2

  	}

  	return mergeArrays(weakList, strongList)
  })('student_profile_id', allStudents, attendanceData));
  // NEW version, set list in Page Data
  // Sorting by index ensures the ones with attendance record go first.
  ___arguments.context.pageData['allStudents'] = (await asyncListSort(finalList, 1, async (item) => {


   return (Number((getObjectProperty(item, 'idx'))));
  }));

  },
  /* handler:onMounted */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // studentSubList is created and destroyed on change for search field.
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'studentSubList')))) {
    return (getObjectProperty(___arguments.context.pageData, 'studentSubList'));
  }

  return (getObjectProperty(___arguments.context.pageData, 'allStudents'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Schedule/components/1d8f93f9f75fc059d748c5778d94b63f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Visibility is set to on or off depending on if attendance modal is open. this way we mount & unmount children components.

  return ((getObjectProperty(___arguments.context.pageData, 'attendanceView')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'attendanceView'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'attendanceView')) === 'object'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/46fc44411eaddd52eb290483d3123436/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var error, studentPackageObj, attendanceObj, textToAdd, studentPackageCandidates, item, studentPackagesOwned;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // Trying to prevent double click
  ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['disable'] = true;
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('46fc44411eaddd52eb290483d3123436')), 'button-loading-dark');
  try {
    // If changed value is true, the student is being registered.
    // If it's false, the student is being un-registered.
    if (___arguments.value) {
      // If class doesn't have any packages attached, don't even bother...
      if (!!(getObjectProperty(___arguments.context.pageData, 'attendanceView.relevantPackages')).length) {
        // Get ALL packages owned by the student
        studentPackagesOwned = (await Backendless.Data.of('student_packages').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_id.objectId = \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')),'\''].join(''))).setRelated(['package_id']).setPageSize(20)));
        // List of packages student owns which could be used to register for class.
        studentPackageCandidates = (await asyncListFilter(studentPackagesOwned, async (item) => {


         return (((getObjectProperty(___arguments.context.pageData, 'attendanceView.relevantPackages')).map(item => item['objectId'])).includes((getObjectProperty(item, 'package_id.objectId'))));
        }));
        // List of packages student owns which could be used to register for class. Filtered down more.
        studentPackageCandidates = (await asyncListFilter(studentPackageCandidates, async (item) => {


         return ((getObjectProperty(item, 'sub_status')) == 'active' || (getObjectProperty(item, 'credits')) > 0 && (((getObjectProperty(item, 'expiry_date')) === null) || (getObjectProperty(item, 'expiry_date')) > (new Date())));
        }));
        // If student doesn't have any packages that
        // works for the class, don't even bother...
        if (!!studentPackageCandidates.length) {
          studentPackageCandidates = (await Promise.all(studentPackageCandidates.map(async item => {  item['label'] = (getObjectProperty(item, 'package_id.title'));
            delete item['package_id'];
            item['value'] = (JSON.parse((JSON.stringify(item))));
          ; return ({ [`label`]: (getObjectProperty(item, 'label')),[`value`]: (getObjectProperty(item, 'value')) });})));
          // null will be the option to not use any student package.
          addItemToList(studentPackageCandidates, ({ [`label`]: 'Don\'t use any package',[`value`]: null }));
          ___arguments.context.pageData['studentPackageCandidates'] = studentPackageCandidates;
          // opens modal where teacher chooses which student package to use, if any
          await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('0516bfc728f1e8463cec08f7bcb12a0b');
          while (typeof (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === 'undefined') {
            await new Promise(r => setTimeout(r, 200 || 0));
          }
          if (!((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === null)) {
            // Will be used to update view for the user, further below.
            // If they use the package successfully, a credit will have been deducted.
            textToAdd = ({ [`student_package_used`]: (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.label')),[`credits`]: ((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.credits')) === null) ? null : (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.credits')) - 1,[`package_used_objectId`]: (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.objectId')),[`paid_online`]: null });
            // We have t remove "label" because it's not a column
            // in the DB, can't have it in registerStudent function
            delete (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse'))['label'];
          }
        } else {
          // If student doesn't have any applicable packages:
          // no student package is used to register student.
          (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['packageToUse'] = null;
        }
      } else {
        // If no packages attached to class: no student package is used to register student.
        (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['packageToUse'] = null;
      }
      attendanceObj = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/registerStudentForClass`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'student_object_id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')),'teacher_object_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'classInstanceObj': (getObjectProperty(___arguments.context.pageData, 'attendanceView.classInstanceObj')),'studentPackageObj': (((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === null) ? null : (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse'))) }));
      // Updating the view for the user to see which package was used
      if (!((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === null)) {
        delete ___arguments.context.pageData['studentPackageCandidates'];
        Object.assign(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), textToAdd);
      }
      // Checks the box...
      ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['class_attendance_objectId'] = (getObjectProperty(attendanceObj, 'objectId'));
      // Clear out package to use for the next operation.
      delete (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['packageToUse'];
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('46fc44411eaddd52eb290483d3123436'))['opacity'] = 0.5;
      // studentPackageObj is required as an object
      // in the unregisterStudent function below.
      studentPackageObj = (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'package_used_objectId')) === 'string') ? ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'package_used_objectId')),[`credits`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'credits')) }) : null;
      await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/unregisterStudentFromClass`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'student_object_id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')),'teacher_object_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'attendance_object_id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'class_attendance_objectId')),'classInstanceObj': (getObjectProperty(___arguments.context.pageData, 'attendanceView.classInstanceObj')),'studentPackageObj': studentPackageObj,'paid_online': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'paid_online')) });
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('46fc44411eaddd52eb290483d3123436'))['opacity'] = 1;
      // Uncheck the box.
      delete ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['class_attendance_objectId'];
      // Get rid of package used info (and thus, text).
      delete ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['student_package_used'];
      // Get rid of paid_online info (and thus, text if it was there).
      delete ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['paid_online'];
    }
    // Special case: if teacher registers or unregisters
    // SELF, have to fetch updated user data for themselves.
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // This parameter will cause reload of classes when modal closes
    ___arguments.context.pageData['attendanceView.reload'] = true;

  } catch (error) {
    console.log(error);
    // Send an error to Sentry
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','tags': ({ [`operation`]: 'Register/unregister student for class' }),'type': 'teacher managing registrations','value': (JSON.stringify(error)) });

  } finally {
    delete ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['disable'];
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('46fc44411eaddd52eb290483d3123436')), 'button-loading-dark', '');
    // If teacher registers or unregisters self, update their App
    // Data so they see the right things on Public page and elsewhere
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }

  }

  },
  /* handler:onChange */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// the blueCheck checkbox only shows when we are doing registrations (not attendance)

  return ((getObjectProperty(___arguments.context.pageData, 'attendanceView.type')) == 'registrations')

  },
  /* handler:onDisplayAssignment */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'class_attendance_objectId')) === 'undefined'))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/1ea1ea258ccb1c1175a61c4ae2ff7c78/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // This is the id for the TSR object, not the students profile object.
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('StudentDetail', ({ 'TSR_objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/157cde39636e8a7c2045e4fb7b319f36/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If student paid online, show that. Else if they used a package to
  // sign up, show that... plus any credits left if there are credits.
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'paid_online')) {
    return 'Paid online';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_package_used')) != null) {
    return (['Used \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_package_used')),((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'credits')) === null) ? '\'' : ['\' (',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'credits')),')'].join('')].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If student paid online, show that. Else if they used a package to
  // sign up, show that... plus any credits left if there are credits.
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'paid_online')) != true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_package_used.credits')) == 0) {
    return ['red', 'small'];
  }

  return ['small']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/cbb8753d6fda22322d3e649b2ca381ab/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.pageData, 'allStudents')) === 'undefined')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/43f420c7d911ba1ae94c565db2772d4a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This checkbox shows when we are doing attendance  AND: student is not registered or student is registered and attended.

  return ((getObjectProperty(___arguments.context.pageData, 'attendanceView.type')) == 'attendances' && ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'class_attendance_objectId')) === 'undefined') || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'attended'))))

  },
  /* handler:onDisplayAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var error, attendanceObj, textToAdd, studentPackageCandidates, item, studentPackagesOwned;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // Trying to prevent double click
  ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['disable'] = true;
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('43f420c7d911ba1ae94c565db2772d4a')), 'button-loading-dark');
  try {
    // If changed value is true, the student is being registered.
    // If it's false, the student is being un-registered.
    if (___arguments.value) {
      // If class doesn't have any packages attached, don't even bother...
      if (!!(getObjectProperty(___arguments.context.pageData, 'attendanceView.relevantPackages')).length) {
        // Get ALL packages owned by the student
        studentPackagesOwned = (await Backendless.Data.of('student_packages').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_id.objectId = \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')),'\''].join(''))).setRelated(['package_id']).setPageSize(20)));
        // List of packages student owns which could be used to register for class.
        studentPackageCandidates = (await asyncListFilter(studentPackagesOwned, async (item) => {


         return (((getObjectProperty(___arguments.context.pageData, 'attendanceView.relevantPackages')).map(item => item['objectId'])).includes((getObjectProperty(item, 'package_id.objectId'))));
        }));
        // List of packages student owns which could be used to register for class. Filtered down more.
        studentPackageCandidates = (await asyncListFilter(studentPackageCandidates, async (item) => {


         return ((getObjectProperty(item, 'sub_status')) == 'active' || (getObjectProperty(item, 'credits')) > 0 && (((getObjectProperty(item, 'expiry_date')) === null) || (getObjectProperty(item, 'expiry_date')) > (new Date())));
        }));
        // If student doesn't have any packages that
        // works for the class, don't even bother...
        if (!!studentPackageCandidates.length) {
          studentPackageCandidates = (await Promise.all(studentPackageCandidates.map(async item => {  item['label'] = (getObjectProperty(item, 'package_id.title'));
            delete item['package_id'];
            item['value'] = (JSON.parse((JSON.stringify(item))));
          ; return ({ [`label`]: (getObjectProperty(item, 'label')),[`value`]: (getObjectProperty(item, 'value')) });})));
          // null will be the option to not use any student package.
          addItemToList(studentPackageCandidates, ({ [`label`]: 'Don\'t use any package',[`value`]: null }));
          ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('a0da5c927288d52b86d129d4985240e3'))['studentPackageCandidates'] = studentPackageCandidates;
          // opens modal where teacher chooses which student package to use, if any
          addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a0da5c927288d52b86d129d4985240e3')), 'open');
          while (typeof (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === 'undefined') {
            await new Promise(r => setTimeout(r, 200 || 0));
          }
          if (!((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === null)) {
            // Will be used to update view for the user, further below.
            // If they use the package successfully, a credit will have been deducted.
            textToAdd = ({ [`student_package_used`]: (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.label')),[`credits`]: ((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.credits')) === null) ? null : (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.credits')) - 1,[`package_used_objectId`]: (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse.objectId')),[`paid_online`]: null });
            // We have t remove "label" because it's not a column
            // in the DB, can't have it in registerStudent function
            delete (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse'))['label'];
          }
        } else {
          // If student doesn't have any applicable packages:
          // no student package is used to register student.
          (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['packageToUse'] = null;
        }
      } else {
        // If no packages attached to class: no student package is used to register student.
        (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['packageToUse'] = null;
      }
      attendanceObj = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/registerStudentForClass`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'student_object_id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')),'teacher_object_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'classInstanceObj': (getObjectProperty(___arguments.context.pageData, 'attendanceView.classInstanceObj')),'studentPackageObj': (((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === null) ? null : (getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse'))),'attended': true }));
      // Updating the view for the user to see which package was used
      if (!((getObjectProperty(___arguments.context.pageData, 'attendanceView.packageToUse')) === null)) {
        Object.assign(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), textToAdd);
      }
      // Checks the box...
      ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['class_attendance_objectId'] = (getObjectProperty(attendanceObj, 'objectId'));
      // Checks the box...
      ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['attended'] = (getObjectProperty(attendanceObj, 'attended'));
      // Clear out package to use for the next operation.
      delete (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['packageToUse'];
    } else {
      // We mark the student as un-attended by changing "attended" value in the database.
      await Backendless.Data.of('class_attendance_entries').save(({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'class_attendance_objectId')),[`attended`]: false }), true);
      // We also update attended state in the repeater item.
      ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['attended'] = false;
    }
    // Special case: if teacher registers or unregisters
    // SELF, have to fetch updated user data for themselves.
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // This parameter will cause reload of classes when modal closes
    ___arguments.context.pageData['attendanceView.reload'] = true;

  } catch (error) {
    console.log(error);
    // Send an error to Sentry
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','tags': ({ [`operation`]: 'Register/unregister student for PAST class' }),'type': 'teacher managing registrations','value': (JSON.stringify(error)) });

  } finally {
    delete ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['disable'];
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('43f420c7d911ba1ae94c565db2772d4a')), 'button-loading-dark', '');
    // If teacher registers or unregisters self, update their App
    // Data so they see the right things on Public page and elsewhere
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }

  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// It shows checked when there is an attendance record and student has "attended: true"
  // greenCheck is unchecked if there is no attendance record.
  if (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'class_attendance_objectId')) === 'undefined') {
    return false;
  }

  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'attended'))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/d03970b73d6f8bd9e99b846315172e4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This checkbox shows when we are doing attendance  AND: student is registered but not attended.

  return ((getObjectProperty(___arguments.context.pageData, 'attendanceView.type')) == 'attendances' && (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'class_attendance_objectId')) === 'string') && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'attended')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var attendanceObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // We mark the student as attended by changing "attended" value in the database.
  await Backendless.Data.of('class_attendance_entries').save(({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2'), 'class_attendance_objectId')),'attended': true }), true);
  // We also update attended state in the repeater item.
  ___arguments.context.getComponentDataStoreByUid('fbf8c2e765884eb888a194c3b98beda2')['attended'] = true;
  // This parameter will cause reload of classes when modal closes
  ___arguments.context.pageData['attendanceView.reload'] = true;

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
      return true

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/40a8e42974bb0a6cf5ee16982e718d9f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Function determines if the number of registrations or
 * attendances has changed since modal opened. If so,
 * we have to reload classes on the Schedule page.
 */
async function maybeReloadClasses() {
  // If "reload" boolean is true, we have to reload classes
  // to make the little number on class card be accurate.
  if (getObjectProperty(___arguments.context.pageData, 'attendanceView.reload')) {
    // This sets visibility of class scrolly container to false...
    // causes unmount of all children. Then we mount them again...
    ___arguments.context.pageData['unmountClassScrolly'] = true;
    await new Promise(r => setTimeout(r, 100 || 0));
    // This mounts the class containers in megaScrolly which
    // gets classes again from the data base (refresh).
    delete ___arguments.context.pageData['unmountClassScrolly'];
  }
}


  await maybeReloadClasses();
  // Done button simply close the modal, clear allStudents
  delete ___arguments.context.pageData['allStudents'];
  // Done button simply close the modal, clear allStudents
  delete ___arguments.context.pageData['searchMessage'];
  // Clear studentSubList so repeater shows all students.
  delete ___arguments.context.pageData['studentSubList'];
  // Done button simply close the modal.
  delete ___arguments.context.pageData['attendanceView'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/815acf13b53e4d85eb7d3e7a83ae35c3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')))) {
    // If no package selected, don't show "package required?"
    return (!!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad23bc35b78b36f47fa42a11eafed6bd'), 'packages')).length);
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/e5b799dd8a7d2d6e659c43b3936da16a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'scheduleStats.last30dNoShows')) == 1 ? 'No-show' : 'No-shows')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/7387a983ec6d15fde404c45b46e58ccc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If user doesn't have stripe_account_id in upgraded object in local storage, show this element.

  return (!(typeof (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.stripe_account_id')) === 'string'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/7a9f33178aae682ca1c4f061ec37222f/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var newPass, updatedPackageId, txResult, addNewPackageTx, createPackageRef, editedPassProperties;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * Triggers connectPackageMModal for a particular package id.
 */
async function triggerPackageConnect() {
  ___arguments.context.pageData['connectedContent'] = [];
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['packageObjectId'] = updatedPackageId;
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['open'] = true;
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e5a3f368c2f952a2ef5baffc4e62c2d3')), 'button-loading');
  // This toggles visibility for Packages Scrolly
  // Block, causing an un-mount of child containers.
  ___arguments.context.pageData['unmountPackageScrolly'] = true;
  if (typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'string') {
    // If we're editing an existing pass, we can just save the form data. Columns that don't exist are ignored.
    editedPassProperties = (await Backendless.Data.of('packages').save(___arguments.context.dataModel, true));
    // Get the objectId of the newly edited package
    updatedPackageId = (getObjectProperty(editedPassProperties, 'objectId'));
    // update teacher packages data in App Data
    replaceItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), editedPassProperties, 'objectId');
  } else {
    // transactions cannot handle properties that are not columns in table.
    delete ___arguments.context.dataModel['open'];
    // default setting for column doesn't work
    ___arguments.context.dataModel['active'] = true;
    // default setting for column doesn't work
    ___arguments.context.dataModel['editable'] = true;
    addNewPackageTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async () => {
      const _uow = addNewPackageTx;

      createPackageRef = (_uow.create('packages', ___arguments.context.dataModel).setOpResultId('createPackage'));
      _uow.addToRelation('profiles', ({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) }), 'packages', (createPackageRef.resolveTo('objectId'))).setOpResultId('relatedPackage');

    })();

    txResult = (await addNewPackageTx.execute());
    if (txResult.isSuccess()) {
      newPass = (getObjectProperty((txResult.getResults()), 'createPackage.result'));
      // Get the objectId of the newly created package
      updatedPackageId = (getObjectProperty(newPass, 'objectId'));
      // Add newly created package to the list of packages for the teacher in App Data
      addItemToList((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), newPass);
    } else {
      console.log('Transaction to create new package failed for some reason.');
      throw (new Error('Transaction to create new package failed for some reason.'))
    }
  }
  // This mounts the package container in megaScrolly which
  // gets packages again from the data base (refresh).
  delete ___arguments.context.pageData['unmountPackageScrolly'];
  // Just to make things smoother, user doesn't have blank packages container.
  await new Promise(r => setTimeout(r, 1000 || 0));
  await triggerPackageConnect();
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e5a3f368c2f952a2ef5baffc4e62c2d3')), 'button-loading', '');
  // Closes the modal
  delete ___arguments.context.pageData['packageModal'];

  },
  /* handler:onSubmit */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'packageModal.type')) == 'Pass')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign(___arguments.context.dataModel, (getObjectProperty(___arguments.context.pageData, 'packageModal')));

  },
  /* handler:onMounted */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var optionsText, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function genCreditsOpts() {
  optionsText = [];
  for (i = 1; i <= 100; i++) {
    addItemToList(optionsText, ({ 'value': i,'label': (JSON.stringify(i)) }));
  }
  return optionsText
}


  ___arguments.context.dataModel['creditsOptions'] = (await genCreditsOpts());

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/Schedule/components/cee34c1bd3e8767dd9a9027921167ffe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['packageModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/e5ec1475550c73a5116972678b0699c6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'packageModal'))['type'] = 'Pass';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/9958da7e50d7b8963c6d56c882ec8f16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'packageModal'))['type'] = 'Subscription';

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.stripe_account_id')) === null) || (typeof (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.stripe_account_id')) === 'undefined'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/17d6398ac2127a15fe4fcbea20097d1e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.pageData, 'packageModal.type')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/93046afb252d0a21d50d2585ec8dfe96/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'packageModal.type')) == 'Subscription')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign(___arguments.context.dataModel, (getObjectProperty(___arguments.context.pageData, 'packageModal')));

  },
  /* handler:onMounted */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var newPackage, updatedPackageId, deletedPackage, editedPassProperties;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * Describe this function...
 */
async function triggerPackageConnect() {
  ___arguments.context.pageData['connectedContent'] = [];
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['packageObjectId'] = updatedPackageId;
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['open'] = true;
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3ef84d5d01c5c0d811b10a627b863053')), 'button-loading');
  // This toggles visibility for Packages Scrolly
  // Block, causing an un-mount of child containers.
  ___arguments.context.pageData['unmountPackageScrolly'] = true;
  // If teacher only change title and/or description,
  // we can just update the package in the DB.
  if ((getObjectProperty(___arguments.context.dataModel, 'cost')) == (getObjectProperty(___arguments.context.pageData, 'packageModal.cost')) && (getObjectProperty(___arguments.context.dataModel, 'billing_cycle')) == (getObjectProperty(___arguments.context.pageData, 'packageModal.billing_cycle'))) {
    // If we're editing an existing pass, we can just save the form data. Columns that don't exist are ignored.
    editedPassProperties = (await Backendless.Data.of('packages').save(___arguments.context.dataModel, true));
    // Get the objectId of the newly edited package
    updatedPackageId = (getObjectProperty(editedPassProperties, 'objectId'));
    // Replace the info for this package in App Data.
    replaceItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), editedPassProperties, 'objectId');
  } else {
    // If teacher is changing/editing subscription, we mark the old one as "deleted".
    if (typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'string') {
      deletedPackage = (await Backendless.Data.of('packages').save(({ [`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'objectId')),[`deleted`]: true,[`active`]: false }), true));
      // remove the package now marked "deleted" from teacherObjects in App Data.
      removeItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), deletedPackage, 'objectId');
    }
    // This API creates a new product in Stripe and also saves the new package to packages table, along with relation between profile and package. Returns the new package.
    newPackage = (await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_student_subscription_package`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'teacher_profile_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'billing_cycle': (getObjectProperty(___arguments.context.dataModel, 'billing_cycle')),'cost': (getObjectProperty(___arguments.context.dataModel, 'cost')),'description': (getObjectProperty(___arguments.context.dataModel, 'description')),'title': (getObjectProperty(___arguments.context.dataModel, 'title')),'metadata': ({ [`teacher_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) }),'stripe_account_id': (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.stripe_account_id')),'stripe_currency': (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.stripe_currency')) }));
    // Add the new package to packages in teacherObjects in AppData
    addItemToList((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), newPackage);
    // Get the objectId of the newly edited package
    updatedPackageId = (getObjectProperty(newPackage, 'objectId'));
  }
  // This mounts the package container in megaScrolly which
  // gets packages again from the data base (refresh).
  delete ___arguments.context.pageData['unmountPackageScrolly'];
  // Just to make things smoother, user doesn't have blank packages container.
  await new Promise(r => setTimeout(r, 1000 || 0));
  await triggerPackageConnect();
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3ef84d5d01c5c0d811b10a627b863053')), 'button-loading', '');
  // Closes the modal
  delete ___arguments.context.pageData['packageModal'];

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Schedule/components/447e02784732243b7232593f05172cb5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((typeof (getObjectProperty(___arguments.context.pageData, 'packageModal.package_objectId')) === 'string') ? 'Edit ' : 'New ') + String(getObjectProperty(___arguments.context.pageData, 'packageModal.type')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/ba8fa19cc4e419fdd3c22217d7e4cc83/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a9f33178aae682ca1c4f061ec37222f'), 'pass_valid_period')) === null) ? 'Forever' : 'Days')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Schedule/components/3c9fa7f402979cab821c709e80b24458/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['setHolidayModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/ec06a33226a5d759ba7bf4ac6dbea61d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['setHolidayModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/f3a3361971c8baa6e0b3173a3959457c/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var realUntilDate, realFromDate;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('bf331da6e1ad2a33b6bead14fad266a1')), 'button-loading');
  realFromDate = (new Date((getObjectProperty(___arguments.context.dataModel, 'fromDate'))));
  realFromDate.setHours(0);
  realFromDate.setMinutes(0);
  realUntilDate = (new Date((new Date((getObjectProperty(___arguments.context.dataModel, 'untilDate')))).getTime() + (86400 * 1000)));
  realUntilDate.setHours(0);
  realUntilDate.setMinutes(0);
  await Backendless.Request.post(`${Backendless.appPath}/services/classes/${(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))}/classes`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'startDate': (realFromDate.valueOf()),'endDate': (realUntilDate.valueOf()) });
  ___arguments.context.pageData['unmountClassScrolly'] = true;
  await new Promise(r => setTimeout(r, 100 || 0));
  delete ___arguments.context.pageData['unmountClassScrolly'];
  ___arguments.context.pageData['setHolidayModal'] = false;
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('bf331da6e1ad2a33b6bead14fad266a1')), 'button-loading', '');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f3a3361971c8baa6e0b3173a3959457c');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Schedule/components/9b97e69baa3df831cee5db5af3533984/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'studentPackageCandidates'))

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'attendanceView'))['packageToUse'] = ___arguments['value'];
  // make the closing of the modal somewhat smoother
  await new Promise(r => setTimeout(r, 500 || 0));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('0516bfc728f1e8463cec08f7bcb12a0b');

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/4c33684b5bfdead4ba66bba986fdc01e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'string') {
    return ((getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1));
  }

  return '$'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/445f644a2f3a5fd221ccae99ae73589d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'string') {
    return ((getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/c602cb6af2a11d3b4877dd28a2b43731/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value == null) {
    ___arguments.context.getComponentDataStoreByUid('7a9f33178aae682ca1c4f061ec37222f')['cost'] = null;
  } else {
    // Simplified Block
    ___arguments.context.getComponentDataStoreByUid('7a9f33178aae682ca1c4f061ec37222f')['cost'] = (await (async function(num) {

    	return Math.abs(num.toFixed(2));
    })(___arguments.value));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/a53c8a3bcc0e94e260e29748e3cde0ea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'string') {
    return ((getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/aaef90e3c953cdfa9d36139aae29ff04/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value == null) {
    ___arguments.context.getComponentDataStoreByUid('93046afb252d0a21d50d2585ec8dfe96')['cost'] = null;
  } else {
    // Simplified Block
    ___arguments.context.getComponentDataStoreByUid('93046afb252d0a21d50d2585ec8dfe96')['cost'] = (await (async function(num) {

    	return Math.abs(num.toFixed(2));
    })(___arguments.value));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/e5a3f368c2f952a2ef5baffc4e62c2d3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a9f33178aae682ca1c4f061ec37222f'), 'title')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a9f33178aae682ca1c4f061ec37222f'), 'cost')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a9f33178aae682ca1c4f061ec37222f'), 'credits')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a9f33178aae682ca1c4f061ec37222f'), 'description')));

  return true

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/3ef84d5d01c5c0d811b10a627b863053/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('93046afb252d0a21d50d2585ec8dfe96'), 'title')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('93046afb252d0a21d50d2585ec8dfe96'), 'cost')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('93046afb252d0a21d50d2585ec8dfe96'), 'billing_cycle')));

  return true

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/bf331da6e1ad2a33b6bead14fad266a1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3a3361971c8baa6e0b3173a3959457c'), 'fromDate')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3a3361971c8baa6e0b3173a3959457c'), 'untilDate'))))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/c44ce3c2bab54378d16ebb6c3b7844c4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.stripe_account_id')) === null) || (typeof (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.stripe_account_id')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/e4d79905927517cbdcd62f8c6122a358/bundle.js', [], () => ({
  /* content */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
      return (new Date())

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/Schedule/components/34af38396e8d9c72bcf212347b3dfd14/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['videoModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/4606189f7cb16dc46b5bbf9789de9ade/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'appointmentModal.tab')) == 'first')

  },
  /* handler:onDisplayAssignment */
  /* handler:onSubmit */
  ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['tab'] = 'second';

  },
  /* handler:onSubmit */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.pageData, 'appointmentModal.appointment_objectId')) === 'string') {
    return ((typeof (getObjectProperty(___arguments.context.dataModel, 'title')) === 'undefined') ? ['form-loading'] : []);
  }

  return []

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/c38657e3a23d34fe165aa6860ed76ea9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Next button is disabled until all required fields have data.
  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4713d226b415424de5dcc188aa9c55a'), 'title')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4713d226b415424de5dcc188aa9c55a'), 'video_date')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4713d226b415424de5dcc188aa9c55a'), 'length')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4713d226b415424de5dcc188aa9c55a'), 'description')));

  return false

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/958187cbc29bf0ad1c38f6eaad7f6eda/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Next button is disabled until all required fields have data.
  return (!(getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime')) || !(getObjectProperty(___arguments.context.pageData, 'appointmentModal.date')));

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/8e66b74fd661970a73bc12ce17a85821/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'appointmentModal.tab')) == 'second')

  },
  /* handler:onDisplayAssignment */
  /* handler:onSubmit */
  ['onSubmit'](___arguments) {
    var start_datetime, end_datetime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // go to next tab in the process
  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['tab'] = 'third';

  },
  /* handler:onSubmit */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/Schedule/components/d1e6da4ed03660c39b5d9647fc6b632a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'appointmentModal.tab')) == 'third')

  },
  /* handler:onDisplayAssignment */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, appointmentObj;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('d5a5e77888c30108ef3eb2fe934cb358')), 'button-loading');
  // need to refresh appointments view in megaScrolly
  ___arguments.context.pageData['unmountAppointmentsScrolly'] = true;
  try {
    // Creates appointment, deducts credit if package is used, returns appointment object.
    appointmentObj = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/createAppointment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'serviceObj': (getObjectProperty(___arguments.context.pageData, 'appointmentModal.serviceObj')),'student_object_id': (getObjectProperty(___arguments.context.pageData, 'appointmentModal.student_object_id')),'start_datetime': (getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_datetime')),'end_datetime': (getObjectProperty(___arguments.context.pageData, 'appointmentModal.end_datetime')),'paid_online': false,'student_package_used': (getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse')) }));
    if (!(appointmentObj !== null && !Array.isArray(appointmentObj) && (typeof appointmentObj === 'object'))) {
      throw (new Error('createAppointment API failed for some reason'))
    }
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('d5a5e77888c30108ef3eb2fe934cb358')), 'button-loading', '');
    // This mounts the class containers in megaScrolly which
    // gets videos again from the data base (refresh).
    delete ___arguments.context.pageData['unmountAppointmentsScrolly'];
    // Cleanup Page Data
    delete ___arguments.context.pageData['appointmentModal'];
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('430a930cd97d308aeebcc8870eb1920f');
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'New appointment has been scheduled!', 'green', null);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onSubmit */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // if studentList is not in AppData, load students from the db.
  if (typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') {
    await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Schedule/components/579c3ec7ead921b58e16b18ba4eaf247/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['tab'] = 'first';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/1276564fca6f17d665080aa84981db71/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['tab'] = 'second';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/f05a440b796b7405a325763cbb8a1811/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f05a440b796b7405a325763cbb8a1811'))['background-image'] = (['url(',((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821'), 'thumbnail_url')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('green')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821'), 'thumbnail_url')),')'].join(''));

  },
  /* handler:onStyleAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var backendlessProfileFile;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // replace whatever was in Form Data with new thumbnail image link
  ___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821')['thumbnail_url'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': ___arguments.uploadedFiles,'path': 'videos/thumbnails' }));
  // get link to small avatar/thumbnail of the user
  // Makes a small thumbnail if not used before... otherwise returns cached image or link to small image. See here: https://backendless.com/features/marketplace/image-processor-api
  backendlessProfileFile = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/ImageProcessor/resize`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'image_path': JSON.stringify(___arguments.uploadedFiles),'width': 320,'height': 180 }).send()), 'url'));
  ___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821')['thumbnail_url'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': backendlessProfileFile,'path': 'videos/thumbnails' }));
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f05a440b796b7405a325763cbb8a1811'))['background-image'] = (['url(',((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821'), 'thumbnail_url')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('green')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821'), 'thumbnail_url')),')'].join(''));
  // make the uploader button reappear to show
  // image and in case user wants to change image
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f05a440b796b7405a325763cbb8a1811');

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}



  return (generateUUID().short())

  },
  /* handler:onFileNameAssignment */
  /* handler:onButtonLabelAssignment */
  ['onButtonLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821'), 'thumbnail_url')) === 'string') ? '⎋' : 'Click to add thumbnail image')

  },
  /* handler:onButtonLabelAssignment */
  /* content */
}))

define('./pages/Schedule/components/cb655ae84d33a881e188f0fa13ee4fcb/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var videoData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If we're editing the video, we load it and its relations.
  if (getObjectProperty(___arguments.context.pageData, 'videoModal.video_objectId')) {
    // Get class_template record from database,
    // including location and packages relations.
    videoData = (await Backendless.Data.of('videos').findById((getObjectProperty(___arguments.context.pageData, 'videoModal.video_objectId')), Backendless.DataQueryBuilder.create().setRelated(['packages'])));
  } else {
    // Initialize packages to be empty list, and pricing to be "fixed".
    videoData = ({ [`packages`]: [],[`pricing`]: 'fixed' });
  }
  Object.assign((getObjectProperty(___arguments.context.pageData, 'videoModal')), videoData);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Schedule/components/63f12bf8f0ab34f2644ad491e61d8bd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'active')) ? ['active'] : ['inactive'])

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If we check a package, it gets added to
  // packages array. If we uncheck, it gets removed.
  if (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d1e6da4ed03660c39b5d9647fc6b632a'), 'packages')).map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId')))) {
    removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d1e6da4ed03660c39b5d9647fc6b632a'), 'packages')), ___arguments.context.dataModel, 'objectId');
  } else {
    addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d1e6da4ed03660c39b5d9647fc6b632a'), 'packages')), ___arguments.context.dataModel);
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If item is in list of packages (of the video, not the teacher), we show the checked status.

  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d1e6da4ed03660c39b5d9647fc6b632a'), 'packages')).map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/558c7d370b8db4d3bd1c1836174c273f/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teacherObjects.packages'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Schedule/components/71bc250ef25ebb5fcf825c9318eb331c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d1e6da4ed03660c39b5d9647fc6b632a'), 'packages')))) {
    // If no package selected, don't show "package required?"
    return (!!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d1e6da4ed03660c39b5d9647fc6b632a'), 'packages')).length);
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/b5820c49920c54b4757905a385e553c4/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
      if ('fixed' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('5235ab151a06b48bb502156abdaa5e83'))) {
    return 'Cost';
  } else if ('donation' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('5235ab151a06b48bb502156abdaa5e83'))) {
    return 'Suggested';
  } else if ('noPayment' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('5235ab151a06b48bb502156abdaa5e83'))) {
    return 'Display';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value == null) {
    ___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821')['cost'] = null;
  } else {
    // Simplified Block
    ___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821')['cost'] = (await (async function(num) {

    	return Math.abs(num.toFixed(2));
    })(___arguments.value));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/cba9ea37c8486d02e6222152b2ca265a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821'), 'error')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/c061b1ed8b8e7a64e0e14e3b826356a7/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      delete ___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821')['error'];

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/Schedule/components/c7167675a864a51f46739e3d08a8362b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Visibility is set to on or off depending on if videoAccessView modal is open. This way we mount & unmount children components.

  return ((getObjectProperty(___arguments.context.pageData, 'videoAccessView')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'videoAccessView'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'videoAccessView')) === 'object'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/75843e3dd93e1f04db31a1fa9d1aa7c8/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, finalList, accessData, allStudents, n, j, videoObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  // if studentList is not in AppData, load students from the db.
  if (typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') {
    await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
  }
  videoObject = (await Backendless.Data.of('videos').findById((getObjectProperty(___arguments.context.pageData, 'videoAccessView.video_objectId')), Backendless.DataQueryBuilder.create().setRelated('packages')));
  // Load packages that are attached to the video and
  // add to videoAccessView property in Page Data.
  (getObjectProperty(___arguments.context.pageData, 'videoAccessView'))['relevantPackages'] = (getObjectProperty(videoObject, 'packages'));
  // This parameter will cause reload of classes when modal closes
  ___arguments.context.pageData['videoAccessView.reload'] = true;
  // We want a separate object from studentList in App Data, have to convert to text and back (see here: https://support.backendless.com/t/data-binding-question/16632/2)
  allStudents = (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.appData, 'studentList'))))));
  // just in case there are more than 100 access records for a video, we use "load all" function.
  accessData = (await BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'](___arguments.context.pageData, 'video_access_view', (['video_objectId = \'',(getObjectProperty(___arguments.context.pageData, 'videoAccessView.video_objectId')),'\''].join('')), null, null, null, 'full_name ASC'));
  n = 1;
  // appends list B to list A, here we just give an
  // index to each item in the mega list. attendanceData
  // items have lower idx numbers because they come first.
  var j_list = (accessData.concat(allStudents));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['idx'] = n;
    n = (typeof n == 'number' ? n : 0) + 1;
  }
  finalList = (await (async function(property, weakList, strongList) {
  	//Merges two arrays based on a common property (object key). The string "property" must be a property present in both arrays.
  	//Where both records have the same property name/value, we use the value from the second array (strongList).
  	//Based on this: https://stackoverflow.com/questions/74477195/how-to-merge-arrays-of-objects-by-id-key


  	//*note: eval('item.'+ property) causes "item.property" to be evaluated, similarly for "b.property"

  	const mergeArrays = (weakList, strongList) => {
  	  return weakList.reduce((acc, b) => {
  	    const idx = acc.findIndex(item => eval('item.'+property) === eval('b.'+property)); //look for the acc has the same property while iterating array1
  	    if (idx > -1) { // if found need to merge with value of array2
  	      acc[idx] = Object.assign(b, acc[idx]);
  	      return acc;
  	    }
  	    return [...acc, b]; //if we don't find anything so "b" is an unique entry
  	  }, strongList); //inital values of reduce is from array2

  	}

  	return mergeArrays(weakList, strongList)
  })('student_profile_id', allStudents, accessData));
  // NEW version, set list in Page Data
  // Sorting by index ensures the ones with attendance record go first.
  ___arguments.context.pageData['allStudents'] = (await asyncListSort(finalList, 1, async (item) => {


   return (Number((getObjectProperty(item, 'idx'))));
  }));

  },
  /* handler:onMounted */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // studentSubList is created and destroyed on change for search field.
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'studentSubList')))) {
    return (getObjectProperty(___arguments.context.pageData, 'studentSubList'));
  }

  return (getObjectProperty(___arguments.context.pageData, 'allStudents'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Schedule/components/e7fde875faea27a918ae470e719f9858/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.pageData, 'allStudents')) === 'undefined')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/3bbc2cd5938add48f30901e68c64b38b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Function determines if the number of registrations or
 * attendances has changed since modal opened. If so,
 * we have to reload classes on the Schedule page.
 */
async function maybeReloadVideos() {
  // If "reload" boolean is true, we have to reload classes
  // to make the little number on class card be accurate.
  if (getObjectProperty(___arguments.context.pageData, 'videoAccessView.reload')) {
    // This sets visibility of class scrolly container to false...
    // causes unmount of all children. Then we mount them again...
    ___arguments.context.pageData['unmountVideoScrolly'] = true;
    await new Promise(r => setTimeout(r, 100 || 0));
    // This mounts the class containers in megaScrolly which
    // gets classes again from the data base (refresh).
    delete ___arguments.context.pageData['unmountVideoScrolly'];
  }
}


  await maybeReloadVideos();
  // Done button simply close the modal, clear allStudents
  delete ___arguments.context.pageData['allStudents'];
  delete ___arguments.context.pageData['videoAccessView'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/0932c7cc0755ce724c242b51aae30ef8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Function determines if the number of registrations or
 * attendances has changed since modal opened. If so,
 * we have to reload classes on the Schedule page.
 */
async function maybeReloadVideos() {
  // If "reload" boolean is true, we have to reload classes
  // to make the little number on class card be accurate.
  if (getObjectProperty(___arguments.context.pageData, 'videoAccessView.reload')) {
    // This sets visibility of class scrolly container to false...
    // causes unmount of all children. Then we mount them again...
    ___arguments.context.pageData['unmountVideoScrolly'] = true;
    await new Promise(r => setTimeout(r, 100 || 0));
    // This mounts the class containers in megaScrolly which
    // gets classes again from the data base (refresh).
    delete ___arguments.context.pageData['unmountVideoScrolly'];
  }
}


  await maybeReloadVideos();
  // Done button simply close the modal, clear allStudents
  delete ___arguments.context.pageData['allStudents'];
  // Done button simply close the modal, clear allStudents
  delete ___arguments.context.pageData['searchMessage'];
  // Clear studentSubList so repeater shows all students.
  delete ___arguments.context.pageData['studentSubList'];
  delete ___arguments.context.pageData['videoAccessView'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/7488e05ba78ca7f5e8caf5a312cacbb4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var error, studentPackageObj, videoAccessObj, textToAdd, studentPackageCandidates, item, studentPackagesOwned;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // Trying to prevent double click
  ___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84')['disable'] = true;
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7488e05ba78ca7f5e8caf5a312cacbb4')), 'button-loading-dark');
  try {
    // If changed value is true, the student is being registered.
    // If it's false, the student is being un-registered.
    if (___arguments.value) {
      // If class doesn't have any packages attached, don't even bother...
      if (!!(getObjectProperty(___arguments.context.pageData, 'videoAccessView.relevantPackages')).length) {
        // Get ALL packages owned by the student
        studentPackagesOwned = (await Backendless.Data.of('student_packages').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_id.objectId = \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'student_profile_id')),'\''].join(''))).setRelated(['package_id']).setPageSize(20)));
        // List of packages student owns which could be used to register for class.
        studentPackageCandidates = (await asyncListFilter(studentPackagesOwned, async (item) => {


         return (((getObjectProperty(___arguments.context.pageData, 'videoAccessView.relevantPackages')).map(item => item['objectId'])).includes((getObjectProperty(item, 'package_id.objectId'))));
        }));
        // List of packages student owns which could be used to register for class. Filtered down more.
        studentPackageCandidates = (await asyncListFilter(studentPackageCandidates, async (item) => {


         return ((getObjectProperty(item, 'sub_status')) == 'active' || (getObjectProperty(item, 'credits')) > 0 && (((getObjectProperty(item, 'expiry_date')) === null) || (getObjectProperty(item, 'expiry_date')) > (new Date())));
        }));
        // If student doesn't have any packages that
        // works for the class, don't even bother...
        if (!!studentPackageCandidates.length) {
          studentPackageCandidates = (await Promise.all(studentPackageCandidates.map(async item => {  item['label'] = (getObjectProperty(item, 'package_id.title'));
            delete item['package_id'];
            item['value'] = (JSON.parse((JSON.stringify(item))));
          ; return ({ [`label`]: (getObjectProperty(item, 'label')),[`value`]: (getObjectProperty(item, 'value')) });})));
          // null will be the option to not use any student package.
          addItemToList(studentPackageCandidates, ({ [`label`]: 'Don\'t use any package',[`value`]: null }));
          ___arguments.context.pageData['studentPackageCandidates'] = studentPackageCandidates;
          // opens modal where teacher chooses which student package to use, if any
          await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('df6580544e7e4385a2e4acfbc2290b73');
          while (typeof (getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse')) === 'undefined') {
            await new Promise(r => setTimeout(r, 200 || 0));
          }
          if (!((getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse')) === null)) {
            // Will be used to update view for the user, further below.
            // If they use the package successfully, a credit will have been deducted.
            textToAdd = ({ [`student_package_used`]: (getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse.label')),[`credits`]: ((getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse.credits')) === null) ? null : (getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse.credits')) - 1,[`package_used_objectId`]: (getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse.objectId')),[`paid_online`]: null });
            // We have t remove "label" because it's not a column
            // in the DB, can't have it in registerStudent function
            delete (getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse'))['label'];
            delete ___arguments.context.pageData['studentPackageCandidates'];
          }
        } else {
          // If student doesn't have any applicable packages:
          // no student package is used to register student.
          (getObjectProperty(___arguments.context.pageData, 'videoAccessView'))['packageToUse'] = null;
        }
      } else {
        // If no packages attached to class: no student package is used to register student.
        (getObjectProperty(___arguments.context.pageData, 'videoAccessView'))['packageToUse'] = null;
      }
      videoAccessObj = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/giveStudentVideoAccess`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'videoObj': ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'videoAccessView.video_objectId')) }),'student_object_id': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'student_profile_id')),'studentPackageObj': (getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse')),'teacher_object_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) }));
      // Updating the view for the user to see which package was used
      if (!((getObjectProperty(___arguments.context.pageData, 'videoAccessView.packageToUse')) === null)) {
        Object.assign({}, textToAdd);
      }
      // Checks the box...
      ___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84')['video_access_objectId'] = (getObjectProperty(videoAccessObj, 'objectId'));
      // Clear out package to use for the next operation.
      delete (getObjectProperty(___arguments.context.pageData, 'videoAccessView'))['packageToUse'];
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7488e05ba78ca7f5e8caf5a312cacbb4'))['opacity'] = 0.5;
      // for videos, we simply delete the video_access_entries record.
      await Backendless.Data.of('video_access_entries').remove( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'video_access_objectId')) );
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7488e05ba78ca7f5e8caf5a312cacbb4'))['opacity'] = 1;
      // Uncheck the box.
      delete ___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84')['video_access_objectId'];
      // Get rid of package used info (and thus, text).
      delete ___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84')['student_package_used'];
      // Get rid of paid_online info (and thus, text if it was there).
      delete ___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84')['paid_online'];
    }
    // Special case: if teacher registers or unregisters
    // SELF, have to fetch updated user data for themselves.
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // This parameter will cause reload of classes when modal closes
    ___arguments.context.pageData['videoAccessView.reload'] = true;

  } catch (error) {
    console.log(error);
    // Send an error to Sentry
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','tags': ({ [`operation`]: 'Register/unregister student for video' }),'type': 'teacher managing registrations','value': (JSON.stringify(error)) });

  } finally {
    delete ___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84')['disable'];
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7488e05ba78ca7f5e8caf5a312cacbb4')), 'button-loading-dark', '');
    // If teacher registers or unregisters self, update their App
    // Data so they see the right things on Public page and elsewhere
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }

  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'video_access_objectId')) === 'undefined'))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/43de342d5421b9ea8adff6a402df86d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If student paid online, show that. Else if they used a package to
  // sign up, show that... plus any credits left if there are credits.
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'paid_online')) != true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'student_package_used.credits')) == 0) {
    return ['red', 'small'];
  }

  return ['small']

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If student paid online, show that. Else if they used a package to
  // sign up, show that... plus any credits left if there are credits.
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'paid_online')) {
    return 'Paid online';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'student_package_used')) != null) {
    return (['Used \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'student_package_used')),((getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'credits')) === null) ? '\'' : ['\' (',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('205c04328f2242832a54292baecb5f84'), 'credits')),')'].join('')].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/34fea0bd36ce310230eb30f9f1999776/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['serviceModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/440c16b8d92919b57e18fad310472963/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Convert timestamp from date-picker into date and put into data model.
  ___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66')['startDatetime'] = (new Date(___arguments.value));
  // In date picker for end date, make sure it can't be before start date.
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6a17a133a8fb7ec352d72a21aff2975c'))['minDate'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'startDatetime'));

  },
  /* handler:onChange */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'editInstance')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editInstance'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editInstance')) === 'object')) {
    return 'Date';
  }

  return 'Start date'

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editTemplate'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/1e163d22aa4aea1344683ba5f034257b/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['serviceModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/e67711f41b26c20d01b8356691f773f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'serviceModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'serviceModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'serviceModal')) === 'object')) ? (getObjectProperty(___arguments.context.pageData, 'serviceModal')) : ({ [`propName`]: null }))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user selects "create new location" we hide the second form in favor of the create Location form.
  if ((getObjectProperty(___arguments.context.pageData, 'serviceModal.tab')) == 'first' && (getObjectProperty(___arguments.context.pageData, 'serviceModal.locationIdx')) != (getObjectProperty(___arguments.context.pageData, 'serviceModal.teacherLocations')).length + 1) {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.pageData, 'serviceModal.service_objectId')) === 'string') {
    return ((typeof (getObjectProperty(___arguments.context.dataModel, 'location')) === 'undefined') ? ['form-loading'] : []);
  }

  return []

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/7164f6d2d3460f0f5bf900afaabfad5f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'serviceModal'))['tab'] = 'second';

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Next button is disabled until all required fields have data.
  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'title')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'description')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'cost')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'locationIdx')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'length')));

  return false

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/dee500f6c27f2165ea2cd4fe9e5f6902/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var serviceModalData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'serviceModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'serviceModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'serviceModal')) === 'object')) {
    if (typeof (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'teacherLocations')) === 'undefined') {
      return false;
    } else {
      // Show the "create location" form if user selects "create new location".
      return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'locationIdx')) == (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'teacherLocations')).length + 1);
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.dataModel['active'] = true;

  },
  /* handler:onMounted */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, serviceModalData, newLocations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  serviceModalData = (getObjectProperty(___arguments.context.pageData, 'serviceModal'));
  try {
    // Save the new location to the database, get back all locations of the teacher.
    // Save the new venue and it's relation to the teacher profile using a "Deep Save". For 1:many relations, this is "cummulative" so old locations are presevered.
    newLocations = (getObjectProperty((await Backendless.Data.of('profiles').deepSave( ({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`locations`]: [___arguments.context.dataModel] }) )), 'locations'));
    // Update list of teacher locations in the data model (actives only)
    serviceModalData['teacherLocations'] = (newLocations.concat((getObjectProperty(serviceModalData, 'teacherLocations'))));
    // Update teacher objects in App Data.
    (getObjectProperty(___arguments.context.appData, 'teacherObjects'))['locations'] = (getObjectProperty(serviceModalData, 'teacherLocations'));
    // The new location is always first in the array. Is this guaranteed?
    serviceModalData['location'] = ((getObjectProperty(serviceModalData, 'teacherLocations'))[0]);
    // The new location is always first in the array. Though not guaranteed...
    serviceModalData['locationIdx'] = 1;

  } catch (error) {
    console.log(error);

  } finally {
    // Clear the form
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('dee500f6c27f2165ea2cd4fe9e5f6902');
    // Hide the "new venue creation" form.
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dee500f6c27f2165ea2cd4fe9e5f6902', false);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Schedule/components/d95478ccc0eb341f424290b6256a06ff/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'serviceModal'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'serviceModal.tab')) == 'second')

  },
  /* handler:onDisplayAssignment */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, serviceObj, serviceLocation, servicePackages, j, goodProperties, txResult, saveServiceTx, saveServiceObjRef, relateTeacherToServiceRef, createServiceToLocationRef, relateServiceToPackagesRef;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function saveServiceTransaction() {
  saveServiceTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = saveServiceTx;

    saveServiceObjRef = (_uow.upsert('services', serviceObj).setOpResultId('saveServiceObj'));
    relateServiceToPackagesRef = (_uow.setRelation('services', (saveServiceObjRef.resolveTo('objectId')), 'packages', servicePackages).setOpResultId('relateServiceToPackages'));
    createServiceToLocationRef = (_uow.setRelation('services', (saveServiceObjRef.resolveTo('objectId')), 'location', serviceLocation).setOpResultId('relateServiceToLocation'));
    relateTeacherToServiceRef = (_uow.addToRelation('profiles', (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')), 'services', (saveServiceObjRef.resolveTo('objectId'))).setOpResultId('relateTeacherToService'));

  })();

  txResult = (await saveServiceTx.execute());
  if (txResult.isSuccess()) {
  } else {
    console.log('Transaction to save service failed for some reason');
    throw (new Error('Transaction to save service failed for some reason'))
  }
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('44363f7d34e18236fe45c834fd618c92')), 'button-loading');
  ___arguments.context.pageData['unmountPrivateScrolly'] = true;
  goodProperties = ['objectId', 'package_reqd', 'cost', 'length', 'description', 'active', 'title', 'deleted', 'pricing'];
  serviceObj = ({  });
  var j_list = (Object.keys((getObjectProperty(___arguments.context.pageData, 'serviceModal'))));
  for (var j_index in j_list) {
    j = j_list[j_index];
    if (goodProperties.includes(j)) {
      serviceObj[j] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), j));
    }
  }
  servicePackages = (getObjectProperty(___arguments.context.pageData, 'serviceModal.packages'));
  serviceLocation = (getObjectProperty(___arguments.context.pageData, 'serviceModal.location'));
  try {
    await saveServiceTransaction();
    // Closes modal and cleans up
    delete ___arguments.context.pageData['serviceModal'];

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Coudn\'t save the service for some reason...', 'red', null);

  } finally {
    // This mounts the class containers in megaScrolly which
    // gets services again from the data base (refresh).
    delete ___arguments.context.pageData['unmountPrivateScrolly'];

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Schedule/components/4cd96511d8bf390b000ab42b7aea8c04/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var optionsObject, optionsText, j, teacherLocs;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Populate the locations selector component with the teachers stored
 * locations. Append options to create new location, online or in person.
 */
async function formatLocationsSelector() {
  teacherLocs = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'teacherLocations'));
  optionsObject = [];
  if (!(typeof teacherLocs === 'undefined') && teacherLocs != null) {
    for (var j_index in teacherLocs) {
      j = teacherLocs[j_index];
      addItemToList(optionsObject, ({ [`value`]: teacherLocs.indexOf(j) + 1,[`label`]: String((getObjectProperty(j, 'is_online')) ? '🖥️  ' : '🏠  ') + String(getObjectProperty(j, 'title')) }));
    }
  }
  addItemToList(optionsObject, ({ [`value`]: optionsObject.length + 1,[`label`]: 'ADD NEW LOCATION...' }));
  return optionsObject
}


  optionsText = await formatLocationsSelector();

  return optionsObject

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2')['location'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'teacherLocations'))[(___arguments.value - 1)]);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/8a3469176bedbd8d5596befd6fdb96e2/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var blockData, service_objectId, serviceData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Default settings for creating a new service
 */
async function initializeServiceData() {
  return { [`pricing`]: 'fixed',[`packages`]: [] }
}

/**
 * Describe this function...
 */
async function loadServiceData(service_objectId) {
  // Get service object from database, including location and packages relations.
  serviceData = (await Backendless.Data.of('services').findById(service_objectId, Backendless.DataQueryBuilder.create().setRelated(['location', 'packages'])));
  // Set location selector to the value that shows the location for
  // the service. The selector has value databound to "locationIdx"
  serviceData['locationIdx'] = (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'teacherLocations')).map(item => item['objectId'])).indexOf(getObjectProperty(serviceData, 'location.objectId')) + 1);
  return serviceData
}


  // Get all locations for the teacher and add to serviceModal object
  (getObjectProperty(___arguments.context.pageData, 'serviceModal'))['teacherLocations'] = (getObjectProperty(___arguments.context.appData, 'teacherObjects.locations'));
  // Get all packages for the teacher and add to serviceModal object
  (getObjectProperty(___arguments.context.pageData, 'serviceModal'))['teacherPackages'] = (getObjectProperty(___arguments.context.appData, 'teacherObjects.packages'));
  service_objectId = (getObjectProperty(___arguments.context.pageData, 'serviceModal.service_objectId'));
  // If there is a service_objectId, we are editing an existing service.
  if (typeof service_objectId === 'string') {
    blockData = await loadServiceData(service_objectId);
    // Store packages set for this service as "oldPackages"
    // in the serviceModal object in Page Data. Have to
    // use text conversion to create independent object.
    (getObjectProperty(___arguments.context.pageData, 'serviceModal'))['oldPackages'] = (JSON.parse((JSON.stringify((getObjectProperty(blockData, 'packages'))))));
    // Store location set for this class as "oldLocation"
    // in the editInstance object in Page Data. If user
    // changes location while editing instance, we have to
    // create new template towards the end, when saving.
    (getObjectProperty(___arguments.context.pageData, 'serviceModal'))['oldLocation'] = (getObjectProperty(blockData, 'location'));
  } else {
    blockData = await initializeServiceData();
  }
  Object.assign((getObjectProperty(___arguments.context.pageData, 'serviceModal')), blockData);

  },
  /* handler:onMounted */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var optionsObject, i, incrementSize;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function lengthOptions() {
  optionsObject = [];
  incrementSize = 5;
  var i_inc = Math.abs(incrementSize);
  if (0 > 300) {
    i_inc = -i_inc;
  }
  for (i = 0; i_inc >= 0 ? i <= 300 : i >= 300; i += i_inc) {
    addItemToList(optionsObject, ({ [`value`]: i,[`label`]: i < 10 ? String('0') + String(JSON.stringify(i)) : (JSON.stringify(i)) }));
  }
  return optionsObject
}


  ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['lengthOptions'] = (await lengthOptions());

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/Schedule/components/586f694781fb6156a3963f4d71064fc3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'videoAccessView'))['packageToUse'] = ___arguments['value'];
  // make the closing of the modal somewhat smoother
  await new Promise(r => setTimeout(r, 500 || 0));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('df6580544e7e4385a2e4acfbc2290b73');

  },
  /* handler:onChange */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'studentPackageCandidates'))

  },
  /* handler:optionsLogic */
  /* content */
}))

define('./pages/Schedule/components/459edaabe076047e3b97e7bb46b3b94a/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'videoAccessView')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'videoAccessView'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'videoAccessView')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['videoAccessView'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/d30b937da3d8c7b73b046e6a6a33aa1d/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'attendanceView')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'attendanceView'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'attendanceView')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['attendanceView'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/37665234cfdc2125dce10443f4762c2a/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'createTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'createTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'createTemplate')) === 'object')) || ((getObjectProperty(___arguments.context.pageData, 'editTemplate')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editTemplate'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editTemplate')) === 'object')) || ((getObjectProperty(___arguments.context.pageData, 'editInstance')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editInstance'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editInstance')) === 'object')))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['createTemplate'];
  delete ___arguments.context.pageData['editTemplate'];
  delete ___arguments.context.pageData['editInstance'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/f195b2b590a74fdf2a667a13d9b3a41b/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'videoModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'videoModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'videoModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['videoModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/0e05e46401bf87df1654355c7c1b8466/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'packageModal.open'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['packageModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/97995a4966382b4c9627ed18f8df3e76/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'packageModal.type')) === 'string'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/d723db22c487f824eccf3d95d54ea69d/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'setHolidayModal'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['setHolidayModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/3836c7e65c8c282d369b0c39ed72567d/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f'), 'lengthOptions'))

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/Schedule/components/0d434c2d13d1b2d1a87de157da71bc2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'active')) ? ['active'] : ['inactive'])

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If we check a package, it gets added to
  // packages array. If we uncheck, it gets removed.
  if (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'packages')).map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId')))) {
    removeItemInList((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'packages')), ___arguments.context.dataModel, 'objectId');
  } else {
    addItemToList((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'packages')), ___arguments.context.dataModel);
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If item is in list of packages (of the service, not the teacher), we show the checked status.

  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceModal')), 'packages')).map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'objectId'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/e4196b36ccacda647952f8ffae7898dc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'serviceModal'))['tab'] = 'first';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/633e8a4eff069d4412ee307e74e47355/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902')['is_online'] = (___arguments.checkedValue == 'online');
  ___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902')['is_online'] = (!(___arguments.checkedValue == 'notOnline'));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/9269a27db0cef1587b71a91cc014accd/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) ? 'Virtual venue name' : 'Venue name')

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/1e6b21d94fb55647ea64c70783d40682/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'title')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/73e171e29e311f6b243125cfa785d81c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'title')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      delete ___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902')['error'];

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/Schedule/components/2294237e4d59d9cd949fc80bdaaa38a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e67711f41b26c20d01b8356691f773f2'), 'error')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/4871ffb46b8601ad5e164e8a8e91daaf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) === 'undefined') && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'title')) === 'undefined') && ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) ? !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'link')) === 'undefined') : !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'address')) === 'undefined')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// if link is empty but venue is online, we can't create venue object.
// if address is empty but venue is physical, we can't create venue object.

  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'link')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'address')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'is_online')) {
      if (!(await (async function(string) {
      	// simple function to check if a string has a valid url format. Based on this article:
      	//https://www.freecodecamp.org/news/how-to-validate-urls-in-javascript/
      	      try {
      	      	return Boolean(new URL(string));
      	      }
      	      catch(e){
      	      	return false;
      	      }

      })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902'), 'link'))))) {
        error = (new Error('This needs a valid link'));
        throw error
      }
    }
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('dee500f6c27f2165ea2cd4fe9e5f6902');

  } catch (error) {
    ___arguments.context.getComponentDataStoreByUid('dee500f6c27f2165ea2cd4fe9e5f6902')['error'] = 'This needs to be a valid link';

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/b8868abfcf34ff79010c1a58e2d16467/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['availabilityModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/e99a4839107cd7eb2f56fa8b3f2c24a7/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var optionsObject, i, incrementMins;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function genMinSelectOptions() {
  optionsObject = [];
  incrementMins = 5;
  var i_inc = Math.abs(incrementMins);
  if (0 > 59) {
    i_inc = -i_inc;
  }
  for (i = 0; i_inc >= 0 ? i <= 59 : i >= 59; i += i_inc) {
    addItemToList(optionsObject, ({ [`value`]: i,[`label`]: i < 10 ? String('0') + String(JSON.stringify(i)) : (JSON.stringify(i)) }));
  }
  return optionsObject
}

/**
 * Describe this function...
 */
async function genHrSelectOptions() {
  optionsObject = [];
  if ((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 == null) {
    for (i = 0; i <= 23; i++) {
      addItemToList(optionsObject, ({ [`value`]: i,[`label`]: (JSON.stringify(i)) }));
    }
  } else {
    for (i = 0; i <= 23; i++) {
      if (i < 12) {
        addItemToList(optionsObject, ({ [`value`]: i,[`label`]: String(JSON.stringify(i)) + String(' am') }));
      } else {
        addItemToList(optionsObject, ({ [`value`]: i,[`label`]: String(JSON.stringify((i == 12 ? i : i - 12))) + String(' pm') }));
      }
    }
  }
  return optionsObject
}


  (getObjectProperty(___arguments.context.pageData, 'availabilityModal'))['hrSelectOptions'] = (await genHrSelectOptions());
  (getObjectProperty(___arguments.context.pageData, 'availabilityModal'))['minSelectOptions'] = (await genMinSelectOptions());

  },
  /* handler:onBeforeMount */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var blockData, templateId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Set defaults for pricing to be fixed. Default for packages to be empty
 * list. Default for times is "0". All required or things go wrong...
 */
async function initializeTemplateData() {
  blockData = ({ [`startHr`]: '0',[`startMin`]: '0',[`endHr`]: '0',[`endMin`]: '0' });
  return blockData
}

/**
 * Describe this function...
 */
async function loadTemplateData(templateId) {
  // Get class_template record from database,
  // including location and packages relations.
  blockData = (await Backendless.Data.of('availability_templates').findById(templateId, ));
  // Create properties for the forms using the rrule
  // string and class length info from database.
  Object.assign(blockData, (await BackendlessUI.Functions.Custom['fn_a9caf714cff1d13faba8103aaa293e04']((getObjectProperty(blockData, 'rrule')), (getObjectProperty(blockData, 'availability_length')))));
  return blockData
}


  // If editing, we get existing data for the template
  // and/or instance. If creating, we set some defaults.
  if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'create') {
    // Don't let user create an interval in the past
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('033270d5ed73450b91797033c02ef154'))['minDate'] = (new Date());
    // Initialize minimum end date to be today when component mounts.
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c08f6952c18cc0b9839fa8db9b29fcc4'))['minDate'] = (new Date());
    blockData = await initializeTemplateData();
  } else if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editTemplate') {
    templateId = (getObjectProperty(___arguments.context.pageData, 'availabilityModal.template_objectId'));
    blockData = await loadTemplateData(templateId);
    // The new Dtstart for the template should be the
    // date of the instance selected for editing.
    blockData['startDatetime'] = (new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.newStartDate'))));
    // Initialize minimum end date to be instance date.
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c08f6952c18cc0b9839fa8db9b29fcc4'))['minDate'] = (new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.newStartDate'))));
    Object.assign((getObjectProperty(___arguments.context.pageData, 'availabilityModal')), blockData);
    console.log('block containing form mount on editTemplate, availability modal is:');
    console.log(getObjectProperty(___arguments.context.pageData, 'availabilityModal'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editInstance') {
    // Create various properties for the form using the instance
    // data that's found in availabilityModal in Page Data.
    // Since we're editing single instance, repeat is always "never", which is 1 in the selector.
    Object.assign((getObjectProperty(___arguments.context.pageData, 'availabilityModal')), ({ [`startDatetime`]: (new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.start_datetime')))),[`startHr`]: (JSON.stringify((new Date((new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.start_datetime'))))).getHours()))),[`startMin`]: (JSON.stringify((new Date((new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.start_datetime'))))).getMinutes()))),[`endDatetime`]: (new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.end_datetime')))),[`endHr`]: (JSON.stringify((new Date((new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.end_datetime'))))).getHours()))),[`endMin`]: (JSON.stringify((new Date((new Date((getObjectProperty(___arguments.context.pageData, 'availabilityModal.end_datetime'))))).getMinutes()))),[`repeat`]: 1 }));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Schedule/components/99c1fa15245d95b5a0c8c2bb1d7712cc/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'availabilityModal'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, startDatetime, endDatetime, repeatUntilDatetime, RRuleString, string2, selectValue, inputString, days, j, ALLDays, idx, threshhold, item, TZOffset, classTime, newInstanceObj;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

/**
 * Calculates availability interval length in minutes.
 */
async function calculateAvailabilityLength() {
  return Math.round(((new Date((getObjectProperty(___arguments.context.dataModel, 'endDatetime'))).getTime()) - (new Date((getObjectProperty(___arguments.context.dataModel, 'startDatetime'))).getTime())) / 60000)
}

/**
 * Describe this function...
 */
async function constructRRule() {
  selectValue = (getObjectProperty(___arguments.context.dataModel, 'repeat'));
  if (selectValue == 1) {
    string2 = ';COUNT=1';
  } else if (selectValue == 2) {
    string2 = '';
  } else if (selectValue == 3) {
    string2 = ';BYDAY=MO,TU,WE,TH,FR';
  } else if (selectValue == 4) {
    string2 = ';BYDAY=MO,TU,WE,TH,FR,SA,SU';
  }
  if (typeof (string2.indexOf('BYDAY') + 1) === 'number' && !isNaN((string2.indexOf('BYDAY') + 1))) {
    string2 = await shiftWeekDays(string2, (new Date((getObjectProperty(___arguments.context.dataModel, 'startDatetime'))).getHours()) + (new Date((getObjectProperty(___arguments.context.dataModel, 'startDatetime'))).getMinutes()) / 60);
  }
  RRuleString = ['DTSTART:',(await (async function(timestamp) {
  	// little function to take timestamp and create string matching requirement for RRULE format
  	// input is timestamp, convert to ISO format
  	let date = new Date(timestamp);
  	let str = date.toISOString();
  	// make the string only contain alphanumeric characters
  	var cleanString = str.match(/[a-zA-Z0-9]/g).join("");
  	//then remove the last 4 characters and add back the "Z" + more characters
  	cleanString = cleanString.substring(0, cleanString.length - 4) + "Z";

  	return cleanString
  })(((getObjectProperty(___arguments.context.dataModel, 'startDatetime')).valueOf()))),String(String(' \n') + String('RRULE:FREQ=WEEKLY')) + String(string2),((getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')) === null) || (typeof (getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')) === 'undefined') ? '' : String(';UNTIL=') + String((await (async function(timestamp) {
  	// little function to take timestamp and create string matching requirement for RRULE format
  	// input is timestamp, convert to ISO format
  	let date = new Date(timestamp);
  	let str = date.toISOString();
  	// make the string only contain alphanumeric characters
  	var cleanString = str.match(/[a-zA-Z0-9]/g).join("");
  	//then remove the last 4 characters and add back the "Z" + more characters
  	cleanString = cleanString.substring(0, cleanString.length - 4) + "Z";

  	return cleanString
  })(((getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')).valueOf()))))].join('');
  return RRuleString
}

/**
 * Describe this function...
 */
async function shiftWeekDays(inputString, classTime) {
  // Gets the timezone offset from UTC for the user wherever they are. For
  // example, if user browser is in Argentina, this returns the number: -3
  TZOffset = ((new Date((new Date())).getTimezoneOffset()) / 60) * -1;
  threshhold = classTime - TZOffset;
  // this function only really runs if it needs to.
  if (threshhold < 0 || threshhold >= 24) {
    console.log('inputString in shiftWeekDays on submit of availability form:');
    console.log(inputString);
    days = (inputString.split(';BYDAY=', ))[1].split(',');
    // Al the days of the week starting on Monday
    ALLDays = 'MO,TU,WE,TH,FR,SA,SU'.split(',');
    // Get list of indeces where our chosen days are found in ALLDays.
    idx = (await Promise.all(days.map(async item => {; return (ALLDays.indexOf(item) + 1);})));
    // If the UTC day of the week is different from the local day of the
    // week, we shift days in the string so RRule stores things properly.
    // We take ALLDays and rotate left or right, depending on threshhold.
    if (threshhold < 0) {
      ALLDays.unshift(ALLDays.pop());
    } else if (threshhold >= 24) {
      ALLDays.push(ALLDays.shift());
    }
    days = [];
    // We now pick the days of the rotated ALLDays list at the same indexes
    // where our days were found in the un-rotated list of ALLDays.
    for (var j_index in idx) {
      j = idx[j_index];
      addItemToList(days, (ALLDays[(j - 1)]));
    }
    return (String(';BYDAY=') + String(days.join(',')));
  } else {
    return inputString;
  }
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('1c6650955191fa619a44380b0537851e')), 'button-loading');
  // Don't have to reload all data if we're just editing instance.
  if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) != 'editInstance') {
    // This un-mounts availability scrolly block.
    ___arguments.context.pageData['unmountAvailabilityScrolly'] = true;
  }
  try {
    // Initialize the startDatetime variable with the date
    // user set for start date. It has a messed up time
    // so we set hours and mins to what user wanted.
    startDatetime = (getObjectProperty(___arguments.context.dataModel, 'startDatetime'));
    startDatetime.setHours((getObjectProperty(___arguments.context.dataModel, 'startHr')));
    startDatetime.setMinutes((getObjectProperty(___arguments.context.dataModel, 'startMin')));
    // Add this property to the data model.
    ___arguments.context.dataModel['startDatetime'] = startDatetime;
    endDatetime = (new Date((getObjectProperty(___arguments.context.dataModel, 'startDatetime'))));
    endDatetime.setHours((getObjectProperty(___arguments.context.dataModel, 'endHr')));
    endDatetime.setMinutes((getObjectProperty(___arguments.context.dataModel, 'endMin')));
    // Add this property to the data model.
    ___arguments.context.dataModel['endDatetime'] = endDatetime;
    // If user doesn't set an end repeat Datetime, or they have the class set to never repeat, we just set repeatUntilDatetime to null. Otherwise, do some calculation.
    if ((typeof (getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')) === 'undefined') || ((getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')) === null) || (getObjectProperty(___arguments.context.dataModel, 'repeat')) == 1) {
      repeatUntilDatetime = null;
    } else {
      // Whatever day user chose as the untilDate, we add a day and set time
      // to 0:00. This way we are sure to include the whole day for the RRule.
      repeatUntilDatetime = (new Date((new Date((getObjectProperty(___arguments.context.dataModel, 'repeatUntilDatetime')))).getTime() + (86399 * 1000)));
      repeatUntilDatetime.setHours(0);
      repeatUntilDatetime.setMinutes(0);
    }
    ___arguments.context.dataModel['repeatUntilDatetime'] = repeatUntilDatetime;
    if ((endDatetime.valueOf()) <= (startDatetime.valueOf())) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c139167ba55bc1c82a3f448c43d675b', true);
      throw (new Error('End time has to be bigger than start time!'))
    }
    // To write template to database we need rrule string and
    // availability_length. Just add these properties to dataBlock model.
    ___arguments.context.dataModel['rrule'] = (await constructRRule());
    // availability_length is a required field in database,
    // we have to calculate it and add to data model.
    ___arguments.context.dataModel['availability_length'] = (await calculateAvailabilityLength());
    if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'create') {
      await Backendless.Request.post(`${Backendless.appPath}/services/availability/create_availability`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'availability_length': (getObjectProperty(___arguments.context.dataModel, 'availability_length')),'rrule': (getObjectProperty(___arguments.context.dataModel, 'rrule')),'profile_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) });
    }
    if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editTemplate') {
      console.log('Not updating instances, need help');
      await Backendless.Request.put(`${Backendless.appPath}/services/availability/${(getObjectProperty(___arguments.context.dataModel, 'template_objectId'))}`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'availability_length': (getObjectProperty(___arguments.context.dataModel, 'availability_length')),'rrule': (getObjectProperty(___arguments.context.dataModel, 'rrule')),'from_date': (getObjectProperty(___arguments.context.dataModel, 'startDatetime')) });
    }
    if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editInstance') {
      // simply upsert the availability instance.
      newInstanceObj = (await Backendless.Data.of('availability_instances').save(({ [`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'instance_objectId')),[`start_datetime`]: ((getObjectProperty(___arguments.context.dataModel, 'startDatetime')).valueOf()),[`end_datetime`]: ((getObjectProperty(___arguments.context.dataModel, 'endDatetime')).valueOf()) }), true));
      replaceItemInList((getObjectProperty(___arguments.context.pageData, 'futureAvailabilityData.items')), ({ [`instance_objectId`]: (getObjectProperty(newInstanceObj, 'objectId')),[`start_datetime`]: (getObjectProperty(newInstanceObj, 'start_datetime')),[`end_datetime`]: (getObjectProperty(newInstanceObj, 'end_datetime')),[`teacher_objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`template_objectId`]: (getObjectProperty(___arguments.context.pageData, 'availabilityModal.template_objectId')),[`__class`]: 'availability_instances_view' }), 'instance_objectId');
    }

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Oops — something went wrong! Try again?', 'red', null);

  } finally {
    // Don't have to reload all data if we're just editing instance.
    if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) != 'editInstance') {
      // This mounts the class containers in megaScrolly which
      // gets classes again from the data base (refresh).
      delete ___arguments.context.pageData['unmountAvailabilityScrolly'];
    }
    delete ___arguments.context.pageData['availabilityModal'];

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Schedule/components/d0a2d8bfc147debab4576729eaff1477/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['availabilityModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/c08f6952c18cc0b9839fa8db9b29fcc4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('99c1fa15245d95b5a0c8c2bb1d7712cc'), 'repeat')) > 1);

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('99c1fa15245d95b5a0c8c2bb1d7712cc'), 'repeatUntilDatetime')) === 'undefined')) {
    return 'End repeat:';
  }

  return 'End repeat: never ▾'

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Schedule/components/99cf2c11a7253307960c290e4cee534c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('99cf2c11a7253307960c290e4cee534c')), 'value')) == 1) {
    ___arguments.context.getComponentDataStoreByUid('99c1fa15245d95b5a0c8c2bb1d7712cc')['endRepeat'] = null;
  }

  },
  /* handler:onChange */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If we're editing instance, don't show repeat options

  return (!((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editInstance'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editTemplate')

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/09cf834ebc7c8482513797fbf47c7394/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fa6c5fc695b1b96ac908489b4ed410d9', false);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/89b9fc7c5888833fc78a94f1eba91b77/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c139167ba55bc1c82a3f448c43d675b', false);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/033270d5ed73450b91797033c02ef154/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Convert timestamp from date-picker into date and put into data model.
  ___arguments.context.getComponentDataStoreByUid('99c1fa15245d95b5a0c8c2bb1d7712cc')['startDatetime'] = (new Date(___arguments.value));
  // In date picker for end date, make sure it can't be before start date.
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c08f6952c18cc0b9839fa8db9b29fcc4'))['minDate'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('99c1fa15245d95b5a0c8c2bb1d7712cc'), 'startDatetime'));

  },
  /* handler:onChange */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editInstance') {
    return 'Date';
  }

  return 'Start date'

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'availabilityModal.operationType')) == 'editTemplate')

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/941b3ef8b4384f57b54a0be26bd426a8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Next button is disabled until all required fields have data.
  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'title')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'startDatetime')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'startHr')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'endHr')) || !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('b67e2b4d14c9d8611a67f03813e96e66'), 'repeat')));

  return false

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/b706aff24ce6268a2b1a80c9d93dc5cc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/8f8badcd849c48f4e9d7fd9defc90f81/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/668ebcb59c5a505af1b8314c3739bd9d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/9ec87b111dea9e7ccca8e1b70bea4a15/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/5db1bd18199a4df20d75165c36d8d60c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/56cb298b021e70185b39af75e2e4bc61/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/af99c1962603e0d131d61dc49e492ebf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'scheduleStats')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/1145735df57dc109b95ce0012ed7b3ec/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
      if ('fixed' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8693cfd2d04b9a039bc8808cc5e1a301'))) {
    return 'Cost';
  } else if ('donation' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8693cfd2d04b9a039bc8808cc5e1a301'))) {
    return 'Suggested';
  } else if ('noPayment' == ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8693cfd2d04b9a039bc8808cc5e1a301'))) {
    return 'Display';
  }

  return 'Cost'

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Schedule/components/629a27918adc63754843c4171c36e34f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'string') {
    return ((getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1));
  }

  return '$'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/26ee592dcdb11ba7f3746aa3677a11e6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')) === 'string') {
    return ((getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1));
  }

  return '$'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/cea524e56f7c2cd12241e6ed9a9819c1/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
      return 'Pick a date ▾'

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('6e397fe6e5bb38b7ae0903c5a42c40fa');

  },
  /* handler:onChange */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
      return (new Date())

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/Schedule/components/307cd952012e9eee9fb54d32605cf31a/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var times, i, minutes, hour, am_pm;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function displayTime(am_pm, hour, minutes) {
  if (am_pm) {
    return ([hour > 12 ? hour - 12 : hour,':',String((JSON.stringify(minutes)).length > 1 ? '' : '0') + String(JSON.stringify(minutes)),hour < 12 ? ' am' : ' pm'].join(''));
  }
  return [hour,':',String((JSON.stringify(minutes)).length > 1 ? '' : '0') + String(JSON.stringify(minutes))].join('')
}


  times = [];
  // Number of minutes elapsed during the day at certain times
  for (i = 0; i <= 1440; i += 15) {
    // Decide if user needs am/pm time or not
    addItemToList(times, ({ [`niceTime`]: await displayTime((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 != null, Math.floor(i / 60), i % 60),[`minuteTime`]: i }));
  }
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('307cd952012e9eee9fb54d32605cf31a', times);

  },
  /* handler:onBeforeMount */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Doesn't work without some delay
  await new Promise(r => setTimeout(r, 400 || 0));
  // Show the user 9:00 am at the top... they can scroll back to get earlier times.
  (function (component) { component.el.scrollIntoView() })((getObjectProperty(___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f'), '540')));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ [`scroll-behavior`]: 'smooth' })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Schedule/components/d4713d226b415424de5dcc188aa9c55a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'videoModal.currentTab')) == 'first')

  },
  /* handler:onDisplayAssignment */
  /* handler:onSubmit */
  ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'videoModal'))['currentTab'] = 'second';

  },
  /* handler:onSubmit */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'videoModal'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'videoModal.edit')) {
    return ((typeof (getObjectProperty(___arguments.context.pageData, 'videoModal.title')) === 'undefined') ? ['form-loading'] : []);
  }

  return []

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Schedule/components/430a930cd97d308aeebcc8870eb1920f/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'appointmentModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'appointmentModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'appointmentModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['appointmentModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Schedule/components/e7d22b68efcad4e226e85c234c8cc9b9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e7d22b68efcad4e226e85c234c8cc9b9')), 'button-loading');
  // We get an array of the packages that are valid for this service and put them into createAppointmentModal in Page Data.
  Object.assign((getObjectProperty(___arguments.context.pageData, 'appointmentModal')), ({ [`serviceObj`]: ___arguments.context.getComponentDataStoreByUid('a23798a700b5a8c999b1d911380648b4'),[`relevantPackages`]: (await Backendless.Data.of('services').loadRelations((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a23798a700b5a8c999b1d911380648b4'), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('packages').setPageSize(50).setOffset(0))) }));
  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['tab'] = 'second';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/afdadafb45564c216b1f6aa7353f052b/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['allServices'] = (getObjectProperty(___arguments.context.appData, 'teacherObjects.services'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Schedule/components/612805eba274d85ee835b6d83501145b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['appointmentModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/dd284ee62286d7b374d789e9fd79d2cb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'appointmentModal.appointment_objectId')) === 'undefined') ? 'Create appointment' : 'Reschedule appointment')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/f4cf7e3d9879ec1115fd3609c536a4f3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8e66b74fd661970a73bc12ce17a85821'), 'serviceObj.length')) + String(' minutes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Schedule/components/74c86c8e506fdde3f7f478adb9e5c0f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var start_datetime, end_datetime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setEndDateTime() {
  end_datetime = (new Date((getObjectProperty(___arguments.context.pageData, 'appointmentModal.date'))));
  end_datetime.setHours((Math.floor((getObjectProperty(___arguments.context.pageData, 'appointmentModal.end_minuteTime')) / 60)));
  end_datetime.setMinutes(((getObjectProperty(___arguments.context.pageData, 'appointmentModal.end_minuteTime')) % 60));
  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['end_datetime'] = end_datetime;
}

/**
 * Create timestamp that will be stored in the database.
 */
async function setStartDateTime() {
  // make start_endtime for datetime in database
  start_datetime = (new Date((getObjectProperty(___arguments.context.pageData, 'appointmentModal.date'))));
  start_datetime.setHours((Math.floor((getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime')) / 60)));
  start_datetime.setMinutes(((getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime')) % 60));
  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['start_datetime'] = start_datetime;
}


  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['start_minuteTime'] = (getObjectProperty(___arguments.context.dataModel, 'minuteTime'));
  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['niceTime'] = (getObjectProperty(___arguments.context.dataModel, 'niceTime'));
  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['end_minuteTime'] = ((getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime')) + (getObjectProperty(___arguments.context.pageData, 'appointmentModal.serviceObj.length')));
  await setStartDateTime();
  await setEndDateTime();
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('6e397fe6e5bb38b7ae0903c5a42c40fa');

  },
  /* handler:onClick */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')[(JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'minuteTime'))))] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('74c86c8e506fdde3f7f478adb9e5c0f2'));

  },
  /* handler:onBeforeMount */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime')) == (getObjectProperty(___arguments.context.dataModel, 'minuteTime'))) {
    return ({ [`background-color`]: '#5D89A9',[`border-color`]: '#ffffff' });
  } else {
    return ({ [`background-color`]: '#ffffff',[`border-color`]: 'transparent' });
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Schedule/components/87a9279a4d8f5f3eb5510c51f757ad17/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'appointmentModal.niceTime'))

  },
  /* handler:onValueAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime')) === 'number' && !isNaN((getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime'))))

  },
  /* handler:onDisplayAssignment */
  /* handler:onFocus */
  async ['onFocus'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('6e397fe6e5bb38b7ae0903c5a42c40fa');

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/Schedule/components/afbeb6a64b13e01c954540a48b989b14/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var allStudents;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'studentList'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Schedule/components/e543c8a6ddf5ca44abbc5cdfc7c82e36/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var studentPackageCandidates, textToAdd, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // When user selects a student, we add their
  // profile objectId to appointmentObject data.
  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['student_object_id'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('97f5052f59342738d95b5e4a743e28f8'), 'student_profile_id'));
    // only check student_packages for the student if packages apply to the service
    if (!!(getObjectProperty(___arguments.context.pageData, 'appointmentModal.relevantPackages')).length) {
      // Get all student_packages owned by the student,
      // with package_id (relation to teacher packages)
      studentPackageCandidates = (await Backendless.Data.of('student_packages').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_id.objectId = \'',(getObjectProperty(___arguments.context.pageData, 'appointmentModal.student_object_id')),'\''].join(''))).setRelated(['package_id']).setPageSize(20)));
      // Student has active subscription which doesn't run on credits or which has 1 or more credits.
      // non-exired pass with credits
      // Definition of a pass
      studentPackageCandidates = (await asyncListFilter(studentPackageCandidates, async (item) => {


       return ((getObjectProperty(item, 'sub_status')) == 'active' && ((getObjectProperty(item, 'credits')) == null || (getObjectProperty(item, 'credits')) > 0) || (getObjectProperty(item, 'sub_status')) == null && (getObjectProperty(item, 'credits')) > 0 && ((getObjectProperty(item, 'expiry_date')) > (new Date()) || (getObjectProperty(item, 'expiry_date')) == null));
      }));
      // List of packages student owns which are found in relevantPackages (could be used to book the service).
      studentPackageCandidates = (await asyncListFilter(studentPackageCandidates, async (item) => {


       return (((getObjectProperty(___arguments.context.pageData, 'appointmentModal.relevantPackages')).map(item => item['objectId'])).includes((getObjectProperty(item, 'package_id.objectId'))));
      }));
      // If student doesn't have any packages that
      // works for the service, don't even bother...
      if (!!studentPackageCandidates.length) {
        studentPackageCandidates = (await Promise.all(studentPackageCandidates.map(async item => {  item['label'] = (getObjectProperty(item, 'package_id.title'));
          delete item['package_id'];
          item['value'] = (JSON.parse((JSON.stringify(item))));
        ; return ({ [`label`]: (getObjectProperty(item, 'label')),[`value`]: (getObjectProperty(item, 'value')) });})));
        // null will be the option to not use any student package.
        addItemToList(studentPackageCandidates, ({ [`label`]: 'Don\'t use any package',[`value`]: null }));
        ___arguments.context.pageData['studentPackageCandidates'] = studentPackageCandidates;
        // opens modal where teacher chooses which student package to use, if any
        await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('e9e96b76214d73cd9346fa668611c017');
        while (typeof (getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse')) === 'undefined') {
          await new Promise(r => setTimeout(r, 200 || 0));
        }
        if (!((getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse')) === null)) {
          // Will be used to update view for the user, further below.
          // If they use the package successfully, a credit will have been deducted.
          textToAdd = ({ [`student_package_used`]: (getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse.label')),[`credits`]: ((getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse.credits')) === null) ? null : (getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse.credits')) - 1,[`package_used_objectId`]: (getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse.objectId')),[`paid_online`]: null });
          // We have t remove "label" because it's not a column in
          // the DB, can't have it in registerAppoinmentfunction
          delete (getObjectProperty(___arguments.context.pageData, 'appointmentModal.packageToUse'))['label'];
        }
      } else {
        // If student doesn't have any applicable packages:
        // no student package is used to register student.
        (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['packageToUse'] = null;
      }
    }
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('97f5052f59342738d95b5e4a743e28f8'), 'student_profile_id')) == (getObjectProperty(___arguments.context.pageData, 'appointmentModal.student_object_id')))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/d5a5e77888c30108ef3eb2fe934cb358/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'appointmentModal.student_object_id')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Schedule/components/b0b31c21379fde4b7bad9cb23f28b086/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_minuteTime')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('74c86c8e506fdde3f7f478adb9e5c0f2'), 'minuteTime'))) {
    return ({ [`color`]: '#ffffff' });
  } else {
    return ({  });
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Schedule/components/3ce551612c2a96fea26570b2b02bcb24/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'studentPackageCandidates'))

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'appointmentModal'))['packageToUse'] = ___arguments['value'];
  // make the closing of the modal somewhat smoother
  await new Promise(r => setTimeout(r, 500 || 0));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('e9e96b76214d73cd9346fa668611c017');

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Schedule/components/fa091dd3a8c8d20de8b591c708553502/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// block is not visible if user is moving an appointment to a different datetime

  return (!(getObjectProperty(___arguments.context.pageData, 'appointmentModal.move')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/7d843213ab44333e6e9df38a8a88522c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only shown when teacher is moving appointment (changing datetime)

  return (getObjectProperty(___arguments.context.pageData, 'appointmentModal.move'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Schedule/components/52d1062fd69f4c65514588b5cef232bb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newAppointmentObj, appointmentObj;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('52d1062fd69f4c65514588b5cef232bb')), 'button-loading');
  // need to refresh appointments view in megaScrolly
  ___arguments.context.pageData['unmountAppointmentsScrolly'] = true;
  try {
    newAppointmentObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'appointmentModal.appointment_objectId')),[`start_datetime`]: (getObjectProperty(___arguments.context.pageData, 'appointmentModal.start_datetime')),[`end_datetime`]: (getObjectProperty(___arguments.context.pageData, 'appointmentModal.end_datetime')) });
    await Backendless.Data.of('appointments').save(newAppointmentObj, true);
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('52d1062fd69f4c65514588b5cef232bb')), 'button-loading', '');
    // This mounts the class containers in megaScrolly which
    // gets videos again from the data base (refresh).
    delete ___arguments.context.pageData['unmountAppointmentsScrolly'];
    // Cleanup Page Data
    delete ___arguments.context.pageData['appointmentModal'];
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('430a930cd97d308aeebcc8870eb1920f');
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Appointment has been moved!', 'green', null);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Schedule/components/45a5fb36ec9f74974095797b369b56ea/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'appointmentModal.move'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);
  Object.assign(___arguments.context.pageData, ({ [`settings`]: (getObjectProperty(___arguments.context.appData, 'teacherObjects.settings')) }));

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Settings';
  await BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a']((getObjectProperty(___arguments.context.pageData, 'page')));
  // Populate timezones object with the world's timezones.
  ___arguments.context.pageData['timezones'] = (await Promise.all((await (async function() {
  	return Intl.supportedValuesOf('timeZone');
  })()).map(async item => {; return ({ [`label`]: item,[`value`]: item });})));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Settings/components/3a7953c1a85a350182f967497264b13c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.stripe_account_id')) === 'string'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/3b263f86da965dcc0ab66f36a1696fc4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (!(((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/bf03fd595271b4bf70b2b729f5f4b42a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (!(((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/5889d02922e9e01fd86ba50a3b472852/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/f5d2df84a991b73340b527815cb1c6b8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Upgrade', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/39bb0ea7568b98abd41a9b0177db8043/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('60aae1d9a3639c370f538f7d842c5b8a');

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.pageData, 'settings.policy_doc')) === null))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/9763199780435bc874b255873a122f47/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'settings.policy_doc')) === null)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('60aae1d9a3639c370f538f7d842c5b8a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/5dea1a8e8118225bc7ecfba9fd853e52/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/62fdd47ef15e3c9a059faa3f102cfca3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'currency': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (String('Currency successfully updated to ') + String(getObjectProperty(___arguments.context.pageData, 'settings.currency'))), 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/42a1bacbec3e1cda57fc35dca2c6fabf/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'waiver_policy': ___arguments.value }), true));
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (___arguments.value ? 'Your policy is now active' : 'Your policy is deactivated'), 'green', null);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/25cd600160a804f6983b94eff7cd8d03/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),[`student_referral_active`]: ___arguments.value }), true));
  if (___arguments.value) {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('6a4943f6f3192d8b2c5803a944bbea08');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/bd829bd475b10214a316a66e349db8e2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'show_spots_left': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (___arguments.value ? 'People can now see how many spots remain in your classes' : 'Remaining spots are no longer shown on your public schedule'), 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/ccff25e974c76145a5fa5d549c2cc9d7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'waitlist': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (___arguments.value ? 'Waitlist is now enabled on all your classes' : 'Waitlist is now disabled on all your classes'), 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/194931a5ff4c33476791c855048529ee/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'notification_class_registration': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your preferences have been saved', 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/10cbb0a95e081474a0c66e2cf5bb2192/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'notification_payment_received': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your preferences have been saved', 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/ac4b399d43ec5567dee3965c109f3054/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'notification_new_student': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your preferences have been saved', 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/6decd2b4289b583468df76c924769b53/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'notification_video_accessed': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your preferences have been saved', 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/141a4a8e553c13b993cbaae87a828824/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'notification_private_booking': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your preferences have been saved', 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/6a4b82f6e35a6cf94a9852f77a8ed6cd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'notification_Ubindi_announcements': ___arguments.value }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your preferences have been saved', 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/bccede977c8565d6bbbd634701d4dc34/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('6a4943f6f3192d8b2c5803a944bbea08');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/12baf8b6839f679e132f86f15543453b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.pageData, 'settings.policy_doc')) === null))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/7f48759d80534fa647d7abea5c8e23ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var policyContent, waiverPolicy;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7f48759d80534fa647d7abea5c8e23ca')), 'button-loading');
  // If user has wiped out their policy doc, we set policy_doc to null
  // (otherwise we have empty html code) and turn off their waver.
  if ((await ( async function (componentUid, index, length) { return ___arguments.context.getComponentByUid(componentUid).getText(index, length) })('ed9327331d51318d76698c11fbe93473', undefined, undefined)).length < 2) {
    policyContent = null;
    waiverPolicy = false;
  } else {
    policyContent = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getHTML() })('ed9327331d51318d76698c11fbe93473'));
    waiverPolicy = (getObjectProperty(___arguments.context.pageData, 'settings.waiver_policy'));
  }
  (getObjectProperty(___arguments.context.pageData, 'settings'))['policy_doc'] = policyContent;
  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),[`waiver_policy`]: waiverPolicy,[`policy_doc`]: policyContent }), true));
  await new Promise(r => setTimeout(r, 1000 || 0));
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7f48759d80534fa647d7abea5c8e23ca')), 'button-loading', '');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('60aae1d9a3639c370f538f7d842c5b8a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/da264c57c44708d21fb591facf8ca497/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.pageData, 'settings.policy_doc')) === null))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/27fc19813be39106a4aeb1c6e3f55cf6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var finalMinutes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'number')) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'period')));
  finalMinutes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'number')) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'period'));
  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'cancel_period': Math.abs(Math.round(finalMinutes)) }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your cancel period is updated', 'green', null);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Settings/components/47757db7c4f7f0af2e4f497af78f20b8/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    var finalMinutes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'number')) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'period')));
  finalMinutes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'number')) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5ae9c3e78d7b807bb3587a2d4f1891ac'), 'period'));
  ___arguments.context.pageData['settings'] = (await Backendless.Data.of('profile_settings').save(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'settings.objectId')),'cancel_period': Math.abs(Math.round(finalMinutes)) }), true));
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Your cancel period is updated', 'green', null);

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/Settings/components/5ae9c3e78d7b807bb3587a2d4f1891ac/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var rawNumber;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Have to wait until the settings object is in Page
  // Data to initialize the cancel period components.
  while (typeof (getObjectProperty(___arguments.context.pageData, 'settings.cancel_period')) === 'undefined') {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  rawNumber = (getObjectProperty(___arguments.context.pageData, 'settings.cancel_period'));
  if (rawNumber % 1440 == 0) {
    ___arguments.context.dataModel['number'] = (rawNumber / 1440);
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('27fc19813be39106a4aeb1c6e3f55cf6', 1440);
  } else if (rawNumber % 60 == 0) {
    ___arguments.context.dataModel['number'] = (rawNumber / 60);
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('27fc19813be39106a4aeb1c6e3f55cf6', 60);
  } else {
    ___arguments.context.dataModel['number'] = rawNumber;
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('27fc19813be39106a4aeb1c6e3f55cf6', 1);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Settings/components/9034e49f77d8e434691cf0df896e64a2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "upgraded".
// This returns an object with user profile info, including upgrade status. Ask for property "upgraded".

  return (String('Ubindi ') + String((typeof (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) === 'undefined') ? 'Free' : (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Settings/components/ff0a41c572cc1748ca2553822111b52d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// if they ever paid us, they will have zoho_subs_customer_id and should see link to their billing history on Zoho.

  return (typeof (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_subs_customer_id')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/d88b90786e3f390f3a6c668de4cbfa1b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Upgrade', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/2add607fb218b214d3a7d55577ca14d6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Upgrade', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (!(((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/f9a7a358fb04cda1024a706eb65e4577/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.stripe_account_id')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/37fc6f2ab41dbbc499da363bbaad15ee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://dashboard.stripe.com/dashboard', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/aa667013486dbc698fe2bedb033c72c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.ubindi.com/partners', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/018c622e95bc0487fcc91379a0ce6547/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".
// This returns an object with user profile info, including upgrade status. Ask for property "ugraded".

  return (!(((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null) || (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Settings/components/6e493de231dcbe9522159332585c6aac/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var planId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  planId = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id'));
  if (planId == 101) {
    return '$10';
  } else if (planId == 102) {
    return '$96';
  } else if (planId == 201) {
    return '$99';
  } else if (planId == 202) {
    return '$828';
  } else {
    return '$0';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Settings/components/29ac6b1f760668d3507d4e4882de0435/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var planId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  planId = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id'));
  if (planId == 102 || planId == 202) {
    return 'per year';
  } else {
    return 'per month';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Settings/components/20a95d938d9e6c33f8a5634361ace7bd/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // The rich editor is so shitty that we cannot assign
  // html content on mounted... because it's still
  // mounting (!) at that moment. Hence a 500msec delay.
  await new Promise(r => setTimeout(r, 500 || 0));
  await ( async function (componentUid, content) {  ___arguments.context.getComponentByUid(componentUid).setHTML(content) })('ed9327331d51318d76698c11fbe93473', (getObjectProperty(___arguments.context.pageData, 'settings.policy_doc')));

  },
  /* handler:onMounted */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'settings.policy_doc'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/Settings/components/b2977d572f3d3c4eb9e97b4fbf8f6824/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var content2;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b2977d572f3d3c4eb9e97b4fbf8f6824')), 'button-loading-dark');
  content2 = (await Backendless.Request.get(`${Backendless.appPath}/services/exportData/${(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))}/students/csv`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...({ 'Accept': 'text/csv' }), ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  await BackendlessUI.Functions.Custom['fn_9dd2d48dddefbbe8930ac234476c7fc3']('students.csv', content2, 'text/csv');
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b2977d572f3d3c4eb9e97b4fbf8f6824')), 'button-loading-dark', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/5862cf5c54f8b05d25aa138f17aace0c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('74fa973109e638552b9b81bbe6273342');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/59ea855201ec18565c246e41a64f08e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('74fa973109e638552b9b81bbe6273342');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/3e68bd9efc4ba19de6359506cf115ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var content2;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3e68bd9efc4ba19de6359506cf115ed1')), 'button-loading-dark');
  content2 = (await Backendless.Request.get(`${Backendless.appPath}/services/exportData/${(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))}/classes/csv`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...({ 'Accept': 'text/csv' }), ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  await BackendlessUI.Functions.Custom['fn_9dd2d48dddefbbe8930ac234476c7fc3']('classes.csv', content2, 'text/csv');
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3e68bd9efc4ba19de6359506cf115ed1')), 'button-loading-dark', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/abbb64e368c4e569e359e8a6f3246b78/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var content2;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('abbb64e368c4e569e359e8a6f3246b78')), 'button-loading-dark');
  content2 = (await Backendless.Request.get(`${Backendless.appPath}/services/exportData/${(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))}/videos/csv`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...({ 'Accept': 'text/csv' }), ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  await BackendlessUI.Functions.Custom['fn_9dd2d48dddefbbe8930ac234476c7fc3']('videos.csv', content2, 'text/csv');
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('abbb64e368c4e569e359e8a6f3246b78')), 'button-loading-dark', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/b1821f0c22287d2d1074a863a3ad550c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var content2;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b1821f0c22287d2d1074a863a3ad550c')), 'button-loading-dark');
  content2 = (await Backendless.Request.get(`${Backendless.appPath}/services/exportData/${(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))}/packages/csv`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...({ 'Accept': 'text/csv' }), ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  await BackendlessUI.Functions.Custom['fn_9dd2d48dddefbbe8930ac234476c7fc3']('packages.csv', content2, 'text/csv');
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('b1821f0c22287d2d1074a863a3ad550c')), 'button-loading-dark', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/7c859fb58268b716108dcd4985c3d1cf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('60aae1d9a3639c370f538f7d842c5b8a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/dcdb15f49f9ec54882255dfce6aff4a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var updatedPackageId, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Triggers connectPackageMModal for a particular package id.
 */
async function triggerPackageConnect() {
  ___arguments.context.pageData['connectedContent'] = [];
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['packageObjectId'] = updatedPackageId;
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['open'] = true;
}


  updatedPackageId = (getObjectProperty(((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), async (item) => {


   return ((getObjectProperty(item, 'title')) == 'General Pass');
  }))[0]), 'objectId'));
  if (typeof updatedPackageId === 'string') {
    await triggerPackageConnect();
  } else {
    console.log('Cannot find General Pass for the teacher, some error occurred.');
  }
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('6a4943f6f3192d8b2c5803a944bbea08');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/e41f5bafb25838e28cf63717218e1504/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var onboarding_link;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e41f5bafb25838e28cf63717218e1504')), 'button-loading');
  onboarding_link = (await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_teacher_onboarding_link`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'teacher_profile_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'return_url': (await (async function() {
  	return window.location.href;
  })()),'email': (getObjectProperty(___arguments.context.appData, 'accountProfile.skeleton_email')),'fist_name': (getObjectProperty(___arguments.context.appData, 'accountProfile.first_name')),'last_name': (getObjectProperty(___arguments.context.appData, 'accountProfile.last_name')) }));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(onboarding_link, 'stripe_account_link')), false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Settings/components/5b6d0782509b9f0c13128cc760d6e98d/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'timezones')) == null) {
    return ;
  }

  return (getObjectProperty(___arguments.context.pageData, 'timezones'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'settings.timezone'))

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'settings'))['timezone'] = ___arguments.value;
  (getObjectProperty((getObjectProperty(___arguments.context.appData, 'teacherObjects')), 'settings'))['timezone'] = ___arguments.value;
  try {
    await Backendless.Data.of('profile_settings').save( (getObjectProperty(___arguments.context.pageData, 'settings')) );
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'You changed your timezone!', 'green', null);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/StudentDetail/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);
  // if studentList is not in AppData, load students from the db.
  if (typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') {
    await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
  }
  // Get the TSR object from studentList in App Data
  // (no need to ask the db) and store it in Page Data.
  ___arguments.context.pageData['TSR_object'] = ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'studentList')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'TSR_objectId')));
  }))[0]);
  // Get all packages that the student owns.
  ___arguments.context.pageData['studentPackages'] = (await Backendless.Data.of('student_packages_view').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_objectId = \'',(getObjectProperty(___arguments.context.pageData, 'TSR_object.student_profile_id')),'\' AND teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join(''))).setPageSize(100)));
  ___arguments.context.pageData['studentNumbers'] = (await Backendless.Request.get(`${Backendless.appPath}/services/stats/singleStudentStats`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'student_profile_id': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'TSR_object.student_profile_id'))),'teacher_profile_id': JSON.stringify((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) }).send());

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'StudentDetail';
  await BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a']((getObjectProperty(___arguments.context.pageData, 'page')));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/StudentDetail/components/0f964528b9b8409cd84e85ef5708bd58/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/3dae981d7f92d69b39ed74a900aad941/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('840cf30f8f08f3dfd6a2577a39bf2945')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/5a37ca72172337c9b8fe230e7d58b9fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['assignPassModal'] = ({ 'open': true });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/57632881a798841c83ca54fe0309b6dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // Close modal
  delete ___arguments.context.pageData['assignPassModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/a3588ec2e1dc665be94e7091c78a66ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
      return (new Date())

  },
  /* handler:onMinDateAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d94ec41caaadd7b6c17334c9a98f75c'), 'expiry_date')) === null) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d94ec41caaadd7b6c17334c9a98f75c'), 'expiry_date')) === 'undefined')) {
    return 'Expiry date';
  }

  return 'Never expire ▾'

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/063c5502892637aadb21d3121552bdbb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['studentNotesModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/ad8e1b1e0c1197da19be1b24958da749/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['emailModal'] = ({ 'full_name': (getObjectProperty(___arguments.context.pageData, 'TSR_object.full_name')),'student_profile_id': (getObjectProperty(___arguments.context.pageData, 'TSR_object.student_profile_id')),'type': 'singleStudent' });

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'TSR_object.email_subscribed')) || (getObjectProperty(___arguments.context.pageData, 'TSR_object.skeleton_email')) == '')

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/0ee6a0b9031dea6c17a2ab26ba2176d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0ee6a0b9031dea6c17a2ab26ba2176d5')), 'button-loading');
  (getObjectProperty(___arguments.context.pageData, 'TSR_object'))['notes'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getHTML() })('52979f8eac66a29412294a94256835c4'));
  (getObjectProperty(___arguments.context.pageData, 'TSR_object'))['notes_text'] = (await ( async function (componentUid, index, length) { return ___arguments.context.getComponentByUid(componentUid).getText(index, length) })('52979f8eac66a29412294a94256835c4', undefined, undefined));
  await Backendless.Data.of('teacher_student_rels').save( (getObjectProperty(___arguments.context.pageData, 'TSR_object')) );
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0ee6a0b9031dea6c17a2ab26ba2176d5')), 'button-loading', '');
  delete ___arguments.context.pageData['studentNotesModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/86c31d0ae65549a49925684d463e25dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Students', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/c19127f5026d1da66b3591b4f45357ee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.pageData, 'TSR_object.created')))).toDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/5c0d5a7d47252d956b6ca895fb8bae1e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var studentTagFormatted, selectedTags;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

/**
 * Describe this function...
 */
async function saveStudentTags() {
  // Save the new list of tags to be related to the student in the students TSR object.
  await Backendless.Data.of('teacher_student_rels').setRelation((getObjectProperty(___arguments.context.pageData, 'TSR_object')), 'tags', selectedTags);
  // Make the new tags string (listing all student tags
  // in one string) and add/replace it in the Form Data.
  (getObjectProperty(___arguments.context.pageData, 'TSR_object'))['tagsString'] = ((selectedTags.map(item => item['title'])).join(', '));
  // Replace old student info with new student info in
  // studentList in App Data for updated view for the teacher.
  replaceItemInList((getObjectProperty(___arguments.context.appData, 'studentList')), (getObjectProperty(___arguments.context.pageData, 'TSR_object')), 'objectId');
}


  selectedTags = (getObjectProperty(___arguments.context.pageData, 'TSR_object.tags'));
  studentTagFormatted = ({ 'title': (getObjectProperty(___arguments.context.dataModel, 'title')),'objectId': (getObjectProperty(___arguments.context.dataModel, 'tag_objectId')),'tag_type': 'student' });
  // Active/inactive class for the tag item is gotten from "selectedTags" array.
  if ((selectedTags.map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) {
    removeItemInList(selectedTags, studentTagFormatted, 'objectId');
  } else {
    addItemToList(selectedTags, studentTagFormatted);
  }
  await saveStudentTags();

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var selectedTags;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // We have to wait until TSR_object is in Page Data, otherwise page crashes.
  if (!(typeof (getObjectProperty(___arguments.context.pageData, 'TSR_object.tags')) === 'undefined')) {
    selectedTags = (getObjectProperty(___arguments.context.pageData, 'TSR_object.tags'));
    // If student has a tag assigned, make it appear active.
    if ((selectedTags.map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) {
      return ['active'];
    } else {
      return ['inactive'];
    }
  }

  return ['inactive']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/37d93411fa096389f0fb0168ea463407/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['manageTagsModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/066345fc442738fc7b42f54ca0f24d14/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Need this logic because errors are thrown when allTeacherTags is undefined, i.e. not yet loaded into Page Data.

  return (((typeof (getObjectProperty(___arguments.context.pageData, 'allTeacherTags')) === 'undefined') ? 0 : (getObjectProperty(___arguments.context.pageData, 'allTeacherTags')).length) == 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/9e23d488114d8b8a2b35f6a2a3caaff7/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'allTeacherTags'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/2ddd44a572a15bbb111d859f5a85da51/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'studentNumbers.3moClassesAttended')) == 1 ? 'Class attended' : 'Classes attended')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/88dbf6a5431bb5ffd31157d57d19d564/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'studentNumbers.30dClassesAttended')) == 1 ? 'Class attended' : 'Classes attended')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/e6561af2d5573bbfac032cc5d30f6dfa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'studentNumbers.3moClassesBooked')) == 1 ? 'Class booked' : 'Classes booked')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/86df211dd364c6032b4a34754c7dc211/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'studentNumbers.30dClassesBooked')) == 1 ? 'Class booked' : 'Classes booked')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/eddef2944be4d15c2088a6d5a77a6d73/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'studentNumbers.30dNoShows')) == 1 ? 'No-show' : 'No-shows')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/41b2c5a6a01e97cfdba9a20d8b6f1479/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'studentNumbers.3moNoShows')) == 1 ? 'No-show' : 'No-shows')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/ffde9603f3e2293e40a127e86568d55e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? '$1' : String((getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1)) + String(getObjectProperty(___arguments.context.pageData, 'studentNumbers.3moRevenue')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/6804241370384e83e80558de5fddd79e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? '$1' : String((getObjectProperty(___arguments.context.appData, 'teacherObjects.settings.currency')).slice(-1)) + String(getObjectProperty(___arguments.context.pageData, 'studentNumbers.30dRevenue')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/8677feb2c34c71754ef8cc4807d715f3/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')))) {
    // Only Passes can be assigned, not Subscriptions
    return (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), async (item) => {


     return ((getObjectProperty(item, 'type')) == 'Pass');
    })).map(async item => {; return ({ 'label': (getObjectProperty(item, 'title')),'value': (getObjectProperty(item, 'objectId')) });})));
  }

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredPackages, item, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  // check if student already has the package, If so,
  // set expiry date and credits accordingly by prefilling
  // form with the existing student_package data
  filteredPackages = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'studentPackages')), async (item) => {


   return ((getObjectProperty(item, 'package_object_id')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d94ec41caaadd7b6c17334c9a98f75c'), 'package_object_id')));
  }));
  if (!!filteredPackages.length) {
    Object.assign(___arguments.context.getComponentDataStoreByUid('6d94ec41caaadd7b6c17334c9a98f75c'), (filteredPackages[0]));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/StudentDetail/components/b36e907e0397649983223f651aac5fd2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')))) {
    return (!(getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')).length);
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/9495dcd58933349b3293a4eca8de2654/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')))) {
    return (!!(getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')).length);
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/e8f7a7d5407e75c3b2dc84ec8043f77b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['assignPassModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/6d94ec41caaadd7b6c17334c9a98f75c/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var optionsText, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function genCreditsOpts() {
  optionsText = [];
  for (i = 0; i <= 100; i++) {
    addItemToList(optionsText, ({ [`value`]: i,[`label`]: (JSON.stringify(i)) }));
  }
  return optionsText
}


  ___arguments.context.dataModel['creditsSelectOptions'] = (await genCreditsOpts());
  // the "normal" ways of setting data models don't work in POD containers,
  // it seems. So we brute force assign properties to the form.
  Object.assign(___arguments.context.dataModel, (getObjectProperty(___arguments.context.pageData, 'assignPassModal.packageObj')));
  // to initialize credits selector to the credits the student currently has
  ___arguments.context.dataModel['startCredits'] = (Number((JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'credits'))))));

  },
  /* handler:onBeforeMount */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, item, newCredits, revenue, saveStudentPassTx, studentPackageObj, TxResult, upsertStudentPackageRef;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('338a87a777baaef2997e58274098f69a')), 'button-loading');
  // Object that will be saved in student_packages table. Missing
  // is objectId, to be determined below (or left undefined).
  studentPackageObj = ({ [`credits`]: (getObjectProperty(___arguments.context.dataModel, 'credits')),[`expiry_date`]: (getObjectProperty(___arguments.context.dataModel, 'expiry_date')),[`paid_online`]: false });
  // If the student already has this pass, use it's objectId
  // so we don't create a new pass in the database.
  if (!(typeof (getObjectProperty(___arguments.context.dataModel, 'student_package_object_id')) === 'undefined')) {
    studentPackageObj['objectId'] = (getObjectProperty(___arguments.context.dataModel, 'student_package_object_id'));
  }
  // Credits added to the pass on submit
  newCredits = (getObjectProperty(___arguments.context.dataModel, 'credits')) - (getObjectProperty(___arguments.context.dataModel, 'startCredits'));
  // Prorated revenue to be added to metrics table as a result of teacher
  // assigning a pass or editing credits. If no credits added, revenue is $0
  revenue = Math.round((newCredits > 0 ? newCredits : 0) * ((getObjectProperty(___arguments.context.dataModel, 'cost')) / (getObjectProperty(___arguments.context.dataModel, 'usual_credits'))) * 100) / 100;
  try {
    saveStudentPassTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async () => {
      const _uow = saveStudentPassTx;

      upsertStudentPackageRef = (_uow.upsert('student_packages', studentPackageObj).setOpResultId('upsertStudentPackageObj'));
      // Relate student to student_package object.
      _uow.setRelation('student_packages', (upsertStudentPackageRef.resolveTo('objectId')), 'student_id', ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'TSR_object.student_profile_id')) })).setOpResultId('relateStudentToStudentPackage');
      // Relate package to student_packages object.
      _uow.setRelation('student_packages', (upsertStudentPackageRef.resolveTo('objectId')), 'package_id', ({ [`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'package_object_id')) })).setOpResultId('relatePackageToStudentPackage');
      // If revenue is > 0, we save a record to the revenue_metrics table.
      if (revenue > 0) {
        // Save a revenue object to the revenue_metrics table
        _uow.create('revenue_metrics', ({ [`source`]: 'package',[`student_profile_Id`]: (getObjectProperty(___arguments.context.pageData, 'TSR_object.student_profile_id')),[`teacher_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`value`]: revenue })).setOpResultId('saveToMetrics');
      }

    })();

    TxResult = (await saveStudentPassTx.execute());
    if (TxResult.isSuccess()) {
    } else {
      console.log('Failed to save student_package.');
      console.log(TxResult.getError());
      throw (TxResult.getError())
    }
    // If the student package is a new one (new object
    // created in DB, we reload all student packages to
    // have the page display the current sate of things.
    if (typeof (getObjectProperty(___arguments.context.dataModel, 'student_package_object_id')) === 'undefined') {
      // Get all packages that the student owns.
      ___arguments.context.pageData['studentPackages'] = (await Backendless.Data.of('student_packages_view').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_objectId = \'',(getObjectProperty(___arguments.context.pageData, 'TSR_object.student_profile_id')),'\' AND teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join(''))).setPageSize(100)));
    } else {
      ___arguments.context.pageData['studentPackages'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'studentPackages')).map(async item => {  if ((getObjectProperty(item, 'student_package_object_id')) == (getObjectProperty(___arguments.context.dataModel, 'student_package_object_id'))) {
          Object.assign(item, ({ [`credits`]: (getObjectProperty(___arguments.context.dataModel, 'credits')),[`expiry_date`]: (getObjectProperty(___arguments.context.dataModel, 'expiry_date')) }));
        }
      ; return item;})));
    }
    // If the teacher is giving themselves a pass, they need their user data updated.
    if ((getObjectProperty(___arguments.context.pageData, 'TSR_object.student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // Close modal
    delete ___arguments.context.pageData['assignPassModal'];

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/StudentDetail/components/94655fd59574be36a774f41d8b02bd5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5d034c6a5cfb4c95070b5b98b1b64e66');

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// only show delete button if student package exists already in the database

  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d94ec41caaadd7b6c17334c9a98f75c'), 'student_package_object_id')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/5b51d5053c6b3efe939bef9cbbc52f9d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('94655fd59574be36a774f41d8b02bd5b')), 'button-loading-dark');
  await Backendless.Data.of('student_packages').remove( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d94ec41caaadd7b6c17334c9a98f75c'), 'student_package_object_id')) );
  // Make the page reflect the deletion in the database
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'studentPackages')), ___arguments.context.getComponentDataStoreByUid('6d94ec41caaadd7b6c17334c9a98f75c'), 'student_package_object_id');
  // Close modal
  delete ___arguments.context.pageData['assignPassModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/8aae999dcdd9a348461ee5a50a27ad6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5d034c6a5cfb4c95070b5b98b1b64e66');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/97913db4398f7e69c78e53a628bd4a61/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['studentNotesModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/StudentDetail/components/954e699dd9356e52e7bff6d859e4badc/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['studentNotesModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/StudentDetail/components/52979f8eac66a29412294a94256835c4/bundle.js', [], () => ({
  /* content */
  /* handler:contentLogic */
  ['contentLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'TSR_object.notes'))

  },
  /* handler:contentLogic */
  /* content */
}))

define('./pages/StudentDetail/components/031e70ae3015044e38bfb2bf5f67976a/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['assignPassModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/StudentDetail/components/bdaf18224b6766cacf6afb33b218c2f1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'assignPassModal.open'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/0796cba698ec1f7fd283948a6fae5458/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Supply all the student data as object for property "editStudent"
  // in Page Data. It will be used to trigger a dialog modal.
  ___arguments.context.pageData['editStudent'] = (getObjectProperty(___arguments.context.pageData, 'TSR_object'));
  // Add a boolean to the data model which will be used to trigger editStudentModal.
  ___arguments.context.pageData['editStudent.editStudentModal'] = true;

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'TSR_object')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/707b4b0dd52dafca43632c2c9c48d5fc/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'TSR_object.thumbnail_small')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty(___arguments.context.pageData, 'TSR_object.thumbnail_small')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/23e568b8b7834d89326a2269fa9e6be3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/7c710791d2a9648b462535efe3afbf61/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'TSR_object.full_name')) === 'undefined') ? '        ' : (getObjectProperty(___arguments.context.pageData, 'TSR_object.full_name')))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/20c45bf6519e316da9b97606ba9281f9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'TSR_object.skeleton_email')) === 'undefined') ? '' : ((getObjectProperty(___arguments.context.pageData, 'TSR_object.email_subscribed')) ? (((getObjectProperty(___arguments.context.pageData, 'TSR_object.skeleton_email')) === null) ? 'No email address' : (getObjectProperty(___arguments.context.pageData, 'TSR_object.skeleton_email'))) : 'Unsubscribed'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/f6a82cb6e44444739e04f2dbe28f9e1f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/f5f2b89141b9cc5b831a94255bd89711/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/53a6b80c15758297ae1303a51347c1f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/e1676441759c6e81304fcb0925eba0ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/d55e2e6805aabcaadbd17645936d1b72/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/64b2f33125b4ed98f399debd0f511ca5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/a07aaa361f3847249beea3aae274e886/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/StudentDetail/components/201dcdf5bb25d2755bec0fef4a132f72/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentNumbers')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Students';
  await BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a']((getObjectProperty(___arguments.context.pageData, 'page')));

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var newestStudent, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['loading'] = true;
  // initialize so we have something, instead of undefined.
  ___arguments.context.pageData['selectedFilters'] = [];
  // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);
  // if studentList is not in AppData, load students from the db.
  if (typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') {
    await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
  }
  newestStudent = (getObjectProperty(___arguments.context.appData, 'studentList'))[0];
  // loop to find newestStudent in studentList
  var j_list = (getObjectProperty(___arguments.context.appData, 'studentList'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    if ((getObjectProperty(j, 'created')) > (getObjectProperty(newestStudent, 'created'))) {
      newestStudent = j;
    }
  }
  ___arguments.context.pageData['newestStudent'] = newestStudent;
  ___arguments.context.pageData['studentStats'] = (await Backendless.Request.get(`${Backendless.appPath}/services/stats/student_stats`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'teacher_profile_id': JSON.stringify((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) }).send());

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Students/components/f98ddad625e9c5364fbcd637619c076b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/a17d4988694a25797bbcfb5eacf143f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If view change isn't there, adds it. If it is there, removes it.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')).indexOf('view-change') + 1 == 0) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')), 'view-change');
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')).splice(((((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('25cc55dd8e22fb7df10d80a949dbf38f')).indexOf('view-change') + 1) - 1), 1);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/46ec727239683d84064935d9373880c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filteredList, filterTag, item, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Function which populates "filteredList" property
 * in page data. filteredList is a subset of the
 * entire studentList where selected tags are found.
 */
async function setFilteredList() {
  filteredList = (getObjectProperty(___arguments.context.appData, 'studentList'));
  // We pass the studentList through a loop where in each
  // iteration, we keep only the students with the selected
  // tag. Looping over all selected tags ("AND" logic).
  var j_list = (getObjectProperty(___arguments.context.pageData, 'selectedFilters'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    filterTag = (getObjectProperty(j, 'title'));
    // Go through students list and keep only the records where selected tags exist
    filteredList = (await asyncListFilter(filteredList, async (item) => {


     return (((getObjectProperty(item, 'tags')).map(item => item['title'])).includes(filterTag));
    }));
  }
  ___arguments.context.pageData['filteredList'] = filteredList;
}


  console.log((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).includes(___arguments.context.dataModel));
  // Toggle active/inactive class for the tag item, and add or remove the
  // selected tag to/from PageData (array object is "selectedFilters").
  if (((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).map(item => item['tag_objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'selectedFilters')), ___arguments.context.dataModel, 'tag_objectId');
  } else {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedFilters')), ___arguments.context.dataModel);
  }
  await setFilteredList();

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'title')),' (',(getObjectProperty(___arguments.context.dataModel, 'numberUsed')),')'].join(''))

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).map(item => item['tag_objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) ? ['active'] : ['inactive'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/d07cca35ac24576f4ae21fd37e5c356d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Plural grammar if number is not 1.

  return ((Array.isArray((getObjectProperty(___arguments.context.appData, 'studentList')))) ? String((getObjectProperty(___arguments.context.appData, 'studentList')).length) + String((getObjectProperty(___arguments.context.appData, 'studentList')).length == 1 ? ' STUDENT' : ' STUDENTS') : ' ')

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadSomeOrAllStudents() {
}



  return ((typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') ? ['button-loading'] : ['white'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/8da2f654b30c9611a61dcbad42dfcfda/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['(+',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a88fecbe2bf607ed99c57a0dae121986'), 'hiddenStudentsNumber')),')'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a88fecbe2bf607ed99c57a0dae121986'), 'hiddenStudentsNumber')) === 'number' && !isNaN((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a88fecbe2bf607ed99c57a0dae121986'), 'hiddenStudentsNumber'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/dd2de49a91f5a7f754a339791c4b053e/bundle.js', [], () => ({
  /* content */
  /* handler:onDelete */
  async ['onDelete'](___arguments) {
    var filteredList, filterTag, item, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Function which populates "filteredList" property
 * in page data. filteredList is a subset of the
 * entire studentList where selected tags are found.
 */
async function setFilteredList() {
  filteredList = (getObjectProperty(___arguments.context.appData, 'studentList'));
  // We pass the studentList through a loop where in each
  // iteration, we keep only the students with the selected
  // tag. Looping over all selected tags ("AND" logic).
  var j_list = (getObjectProperty(___arguments.context.pageData, 'selectedFilters'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    filterTag = (getObjectProperty(j, 'title'));
    // Go through students list and keep only the records where selected tags exist
    filteredList = (await asyncListFilter(filteredList, async (item) => {


     return (((getObjectProperty(item, 'tags')).map(item => item['title'])).includes(filterTag));
    }));
  }
  ___arguments.context.pageData['filteredList'] = filteredList;
}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'selectedFilters')), ___arguments.context.dataModel, '');
  await setFilteredList();

  },
  /* handler:onDelete */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'title')),' (',(getObjectProperty(___arguments.context.dataModel, 'numberUsed')),')'].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Students/components/c3e1b8263a010667321cffdb76a6afda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['manageTagsModal'] = true;
  // Crazy hack to hide newTagWidget when manage
  // tags modal is open (which uses the same widget)
  ___arguments.context.pageData['hideWidgetOnPage'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Need this logic because errors are thrown when allTeacherTags is undefined, i.e. not yet loaded into Page Data.

  return (((typeof (getObjectProperty(___arguments.context.appData, 'allTeacherTags')) === 'undefined') ? 0 : (getObjectProperty(___arguments.context.appData, 'allTeacherTags')).length) != 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/ee24e237ab5883c4efc3c83b577dea99/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Show spinner block while studentList is still undefined

  return (typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/a88fecbe2bf607ed99c57a0dae121986/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var studentLimit, totalStudents;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free') {
    studentLimit = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.max_students'));
  } else if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) == null) {
    studentLimit = 50;
  }
  // This runs only if they have a student limit (free plan
  // or no record in upgrades table)... we get max_students
  // from the 'upgraded' object in profileObj... then only if
  // they are maxed out do we ask the db for total students.
  if (typeof studentLimit === 'number' && !isNaN(studentLimit)) {
    while (typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') {
      // Have to wait to make sure studentList has arrived...
      await new Promise(r => setTimeout(r, 300 || 0));
    }
    if ((getObjectProperty(___arguments.context.appData, 'studentList')).length >= studentLimit) {
      // Total number of teacher_student_rels objects where user is the teacher
      totalStudents = (await Backendless.Data.of('teacher_student_rels').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['teacher_profile_id.objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join('')))));
      ___arguments.context.dataModel['hiddenStudentsNumber'] = (totalStudents - studentLimit);
      await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Student limit reached!', 'red', 5000);
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Students/components/f79fa16a760c186bab4ca1df036b0d43/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, studentTagFormatted, selectedTags;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

/**
 * Describe this function...
 */
async function saveStudentTags() {
  // Save the new list of tags to be related to the student in the students TSR object.
  await Backendless.Data.of('teacher_student_rels').setRelation((getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a'), 'objectId')), 'tags', (getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a'), 'tags')));
  // Make the new tags string (listing all student tags
  // in one string) and add/replace it in the Form Data.
  ___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a')['tagsString'] = (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a'), 'tags')).map(item => item['title'])).join(', '));
  // Replace old student info with new student info in
  // studentList in App Data for updated view for the teacher.
  replaceItemInList((getObjectProperty(___arguments.context.appData, 'studentList')), ___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a'), 'objectId');
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'filteredList')))) {
    if ((getObjectProperty(___arguments.context.pageData, 'filteredList')).length > 0) {
      // Replace old student info with new student info in filtered
      // studentList in Page Data for updated view for the teacher.
      replaceItemInList((getObjectProperty(___arguments.context.pageData, 'filteredList')), ___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a'), 'objectId');
    }
  }
  // Update allTeacherTags to have the latest numberUsed for the teachers tags.
  ___arguments.context.appData['allTeacherTags'] = (await BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08'](null, null, null));
}


  selectedTags = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a'), 'tags'));
  studentTagFormatted = ({ 'title': (getObjectProperty(___arguments.context.dataModel, 'title')),'objectId': (getObjectProperty(___arguments.context.dataModel, 'tag_objectId')),'tag_type': 'student' });
  // Active/inactive class for the tag item is gotten from "selectedTags" array.
  if ((selectedTags.map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) {
    removeItemInList(selectedTags, studentTagFormatted, 'objectId');
  } else {
    addItemToList(selectedTags, studentTagFormatted);
  }
  await saveStudentTags();
  // If any filters are selected while user is adding or removing a
  // student tag, we update the number used in the filter chip here.
  ___arguments.context.pageData['selectedFilters'] = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'allTeacherTags')), async (item) => {


   return (((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).map(item => item['tag_objectId'])).includes((getObjectProperty(item, 'tag_objectId'))));
  }));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var selectedTags;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedTags = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('35e1a081c3ce91b296c115348349dc8a'), 'tags'));
  // If student has a tag assigned, make it appear active.
  if ((selectedTags.map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) {
    return ['active'];
  } else {
    return ['inactive'];
  }

  return ['inactive']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/ec43cadffa144f14223a116f04660798/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // Clean up Page Data and in the process close modal, too.
  delete ___arguments.context.pageData['editStudentTags'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/dde4654f01960597ce2dedc551aa4347/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Empty state, when a teacher has no students

  return ((typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') ? false : !(getObjectProperty(___arguments.context.appData, 'studentList')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/5d3da89e7d2119b57f2ca874da3d6963/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Don't show panel until data is fully there.

  return ((getObjectProperty(___arguments.context.pageData, 'editStudentTags')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editStudentTags'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editStudentTags')) === 'object'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/8132872ae40a99364b8948247fa63b3a/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'allTeacherTags'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Students/components/35e1a081c3ce91b296c115348349dc8a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Get the relevant student TSR object and store it in the
  // Form Data Model. This is an object from studentList.
  // Get student info for the particular student. Put into PageData from studentCard component.
  Object.assign(___arguments.context.dataModel, (getObjectProperty(___arguments.context.pageData, 'editStudentTags')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Students/components/286ad6bbc5647c5f66629479d75302d4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Need this logic because errors are thrown when allTeacherTags is undefined, i.e. not yet loaded into Page Data.
  // Crazy hack to hide this widget instance if manageTags modal
  // is open, because the same widget is used in that modal.
  if (getObjectProperty(___arguments.context.pageData, 'hideWidgetOnPage')) {
    return false;
  }

  return (((typeof (getObjectProperty(___arguments.context.appData, 'allTeacherTags')) === 'undefined') ? 0 : (getObjectProperty(___arguments.context.appData, 'allTeacherTags')).length) == 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/98cf63ea8987584c5f6e52f9bd4b19ce/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Needed, selected filters is set to empty list
  // on Page Enter but it's too late already.
  if (typeof (getObjectProperty(___arguments.context.pageData, 'selectedFilters')) === 'undefined') {
    return (getObjectProperty(___arguments.context.appData, 'studentList'));
  }
  // Maybe not needed, selected filters is set to empty list on Page Enter.
  if (typeof (getObjectProperty(___arguments.context.pageData, 'studentSubList')) === 'undefined') {
    return ((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length == 0 ? (getObjectProperty(___arguments.context.appData, 'studentList')) : (getObjectProperty(___arguments.context.pageData, 'filteredList')));
  } else {
    return (getObjectProperty(___arguments.context.pageData, 'studentSubList'));
  }

  return (getObjectProperty(___arguments.context.appData, 'studentList'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Students/components/21780091e64c7abd02ca6c2e141451d8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This block only appears when tag filters are selected, to give the active filter chips room.

  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'selectedFilters')) === 'undefined') && (getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/e162dd1bc7747b19dfa90d06f80a19b8/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'allTeacherTags'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Students/components/8b5413755b7d1d9a41d50c37c021c51e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // This is the id for the TSR object, not the students profile object.
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('StudentDetail', ({ 'TSR_objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e7b3dbec4ee07bb9156c487a50cfd6d'), 'student_profile_id')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/3d1ad478d9439671a541c1557f26ccd3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Came to ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e7b3dbec4ee07bb9156c487a50cfd6d'), 'shows')),(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e7b3dbec4ee07bb9156c487a50cfd6d'), 'shows')) == 1 ? ' class' : ' classes'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Students/components/dda884a44676c5b2e1b91aaf733f5137/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // This is the id for the TSR object, not the students profile object.
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('StudentDetail', ({ [`TSR_objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f39a6f2922337657a399594e34678d3'), 'student_profile_id')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/c3ebf24c6ac27253dabfebc9c59dca3d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Missed ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f39a6f2922337657a399594e34678d3'), 'noShows')),(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f39a6f2922337657a399594e34678d3'), 'noShows')) == 1 ? ' class' : ' classes'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Students/components/3fdf17db14768fd8943c6819bc3f2f71/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'studentsStats.last30dNewStudentsTotal')) == 1 ? 'New student' : 'New students')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Students/components/56070cedbf6fc26b681c0543d1a9aefd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // This is the id for the TSR object, not the students profile object.
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('StudentDetail', ({ 'TSR_objectId': (getObjectProperty(___arguments.context.pageData, 'newestStudent.objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/a05e15fdd99b53255f139994fa06ec2e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['copyLinkModal'] = ({ [`titleText`]: 'Share your schedule',[`referral`]: false });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/cb82b1d821056f8941e3c754d1cf802e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).map(item => item['tag_objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) ? ['active'] : ['inactive'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filteredList, filterTag, item, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Function which populates "filteredList" property
 * in page data. filteredList is a subset of the
 * entire studentList where selected tags are found.
 */
async function setFilteredList() {
  filteredList = (getObjectProperty(___arguments.context.appData, 'studentList'));
  // We pass the studentList through a loop where in each
  // iteration, we keep only the students with the selected
  // tag. Looping over all selected tags ("AND" logic).
  var j_list = (getObjectProperty(___arguments.context.pageData, 'selectedFilters'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    filterTag = (getObjectProperty(j, 'title'));
    // Go through students list and keep only the records where selected tags exist
    filteredList = (await asyncListFilter(filteredList, async (item) => {


     return (((getObjectProperty(item, 'tags')).map(item => item['title'])).includes(filterTag));
    }));
  }
  ___arguments.context.pageData['filteredList'] = filteredList;
}


  // Toggle active/inactive class for the tag item, and add or remove the
  // selected tag to/from PageData (array object is "selectedFilters").
  if (((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).map(item => item['tag_objectId'])).includes((getObjectProperty(___arguments.context.dataModel, 'tag_objectId')))) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'selectedFilters')), ___arguments.context.dataModel, 'tag_objectId');
  } else {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedFilters')), ___arguments.context.dataModel);
  }
  await setFilteredList();

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'title')),' (',(getObjectProperty(___arguments.context.dataModel, 'numberUsed')),')'].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/Students/components/f17f4cca0775b5c2e17a1012fcb6e8fb/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'allTeacherTags'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Students/components/3dbd7e9d4e0d4ced34ff3598184d45f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['selectTagsModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/39ca399894ebbabbf1b96c1730d68f7f/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f39a6f2922337657a399594e34678d3'), 'thumbnail_small')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f39a6f2922337657a399594e34678d3'), 'thumbnail_small')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Students/components/758dfa5ed4da40d6bfc97ce8ad181658/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'studentList')))) {
    return ((getObjectProperty(___arguments.context.appData, 'studentList')).length);
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Students/components/e4060b1d343ae2882549ab09f0acd305/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'studentList')))) {
    return ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'studentList')), async (item) => {


     return ((getObjectProperty(item, 'created')) > (new Date((new Date()).getTime() - (2592000 * 1000))));
    })).length);
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Students/components/56539b4d424fdb6fb797e59e0315b334/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'newestStudent.thumbnail_small')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty(___arguments.context.pageData, 'newestStudent.thumbnail_small')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Students/components/6858b0034a337c51cd645402b60339f2/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e7b3dbec4ee07bb9156c487a50cfd6d'), 'thumbnail_small')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e7b3dbec4ee07bb9156c487a50cfd6d'), 'thumbnail_small')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Students/components/1a34480b47d681617e2f6e1ee4ce1d87/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'studentStats.loyals')))) {
    return ((getObjectProperty(___arguments.context.pageData, 'studentStats.loyals')).length == 0);
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This is the id for the TSR object, not the students profile object.

  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentStats')) === 'undefined') ? ['loading', 'small'] : ['small'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/77017bc4b3cd41c24a9280877b189ffe/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'studentStats.noShows')))) {
    return ((getObjectProperty(___arguments.context.pageData, 'studentStats.noShows')).length == 0);
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This is the id for the TSR object, not the students profile object.

  return ((typeof (getObjectProperty(___arguments.context.pageData, 'studentStats')) === 'undefined') ? ['loading', 'small'] : ['small'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/f796f0a39803cc0ba7d30af542e4c74b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// this block is weird if it shows on search results, so we hide it

  return (typeof (getObjectProperty(___arguments.context.pageData, 'studentSubList')) === 'undefined')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Students/components/a683e8e41d464aec347d5f0dc2e36d54/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['selectTagsModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Students/components/d4b63e8602b688fd848e1d81ce4dec57/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['selectTagsModal'];

  },
  /* handler:onClose */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectTagsModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'selectTagsModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'selectTagsModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* content */
}))

define('./pages/Students/components/aa4458dece87784907bfe0476b3606ae/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editStudentTags')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editStudentTags'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editStudentTags')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['editStudentTags'];

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/Students/components/a1dd3091ee6f53924449c2280dad0638/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This is the id for the TSR object, not the students profile object.

  return ((typeof (getObjectProperty(___arguments.context.pageData, 'newestStudent')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/fa741cc540674cea0c1a4581cfe7848f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Students/components/a93aa0aaae75e949d98cf7f5d86eebbb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.appData, 'studentList')) === 'undefined') ? ['loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Unsubscribe/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['page'] = 'Unsubscribe';

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // if user is logged in
  if (!((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null)) {
    ___arguments.context.pageData['formData'] = ({ 'email': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'email')) });
  }
  ___arguments.context.pageData['teacherData'] = (await Backendless.Data.of('profiles').findById((getObjectProperty(___arguments.context.pageData, 'profile_id')), ));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Unsubscribe/components/39a9bea6ec68ad85812b84cbcd58f728/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()) !== null && !Array.isArray((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']())) && (typeof (await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()) === 'object')) ? 'Email address' : 'Enter email address')

  },
  /* handler:onLabelAssignment */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88dca09cf7c3c431c6090f198517dbf9', false);

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/Unsubscribe/components/4eae3e525684efb64558f1520de1a3a7/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, TSR_obj, TSRs;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    TSRs = (await Backendless.Data.of('teacher_student_rels').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_profile_id.skeleton_email = \'',(getObjectProperty(___arguments.context.dataModel, 'email')),'\' AND teacher_profile_id.objectId = \'',(getObjectProperty(___arguments.context.pageData, 'profile_id')),'\''].join(''))).setPageSize(10)));
    if (!TSRs.length) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88dca09cf7c3c431c6090f198517dbf9', true);
    } else {
      TSR_obj = TSRs[0];
      TSR_obj['email_subscribed'] = false;
      await Backendless.Data.of('teacher_student_rels').save(TSR_obj, true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7e3fc6b94a69f42355840cf398ca8653', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4eae3e525684efb64558f1520de1a3a7', false);
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/Unsubscribe/components/27e58db290e649f53a7a29a618d977ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4eae3e525684efb64558f1520de1a3a7'), 'email')) === 'string') ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4eae3e525684efb64558f1520de1a3a7'), 'email')).length < 3 : true)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Unsubscribe/components/5007d1559262175aac2629d4a7a38aa7/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'teacherData.thumbnail')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('large')) : (getObjectProperty(___arguments.context.pageData, 'teacherData.thumbnail')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Upgrade/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // if App Data wiped, re-fetch data and populate App Data.
  await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, null);
  // Check the database to double-confirm what plan the user is on.
  ___arguments.context.pageData['plan'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id'));
  // If user is on an annual plan, show them they're on it when page loads.
  if ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 102) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cde48daaade9179967bc62862b2f1a5e', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ab8b30c095e7a2b203e3df258fafe0c', false);
  }
  if ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 202) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc27364cf6128709d78e2624e87bd28e', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('10a19f55770cf4fb24a6b7737ad49a67', false);
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['page'] = 'Upgrade';
  await BackendlessUI.Functions.Custom['fn_0ad2b60f2b5d7267fc2a9b1ae396cd0a']((getObjectProperty(___arguments.context.pageData, 'page')));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Upgrade/components/199b3fdcef64852bc2bb5735f9f92ca8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cde48daaade9179967bc62862b2f1a5e', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ab8b30c095e7a2b203e3df258fafe0c', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/cf39d2f69945aa41d2667194eef15c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ab8b30c095e7a2b203e3df258fafe0c', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cde48daaade9179967bc62862b2f1a5e', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/e704cdf23cb699892a128ced2377b815/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
      return true

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ 'pointer': 'none' })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Upgrade/components/8b9d1fe6159b131c3d273bf9cecf0924/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc27364cf6128709d78e2624e87bd28e', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('10a19f55770cf4fb24a6b7737ad49a67', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/be01f503a4c70bc1c4599752febc96c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('10a19f55770cf4fb24a6b7737ad49a67', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc27364cf6128709d78e2624e87bd28e', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/60399b15bcf949bb35ca104deff1e042/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('60399b15bcf949bb35ca104deff1e042')), 'button-loading');
    // if user is on Pro Plan they cannot upgrade to an Essential plan, so first they have to cancel
    if (!((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) === null) && (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) >= 200) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('21bcdd37638c8a585396388b441f791f');
    } else {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/zoho/subscription/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'profileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'planCode': '101' })), false, undefined);
    }

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry! Something went wrong. Please contact support.', 'red', null);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('60399b15bcf949bb35ca104deff1e042')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/9d4e034d4217aebc8d2f60b8357fa317/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.plan_name')) == 'Free' || !(typeof (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.plan_name')) === 'string'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/9e7c1069812b6a347439eec71475ccc2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 101)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/f31695ee851a49a405a9a2bf44620493/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 102)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/5c1980bf5afc054cec39de6be96460ff/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 201)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/b9fc3d61ed26297b1c01db9f6b491dac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 202)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/b57c9f699d3cf615eb15314eb7bac635/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 202))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/7f622dc8aef28a166c91a443a9aef4d4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 201))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/6f8987c7ada7e431e145ab7ea0afdd9f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 102))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/d879a642a7a6902cf61c8ad9224f6dda/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.zoho_plan_id')) == 101))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/a078269d47e22dff0e57f575e6c98755/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.plan_name')) != 'Free')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Upgrade/components/8e175e8b043b10d2a7efc436a48cad17/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('21bcdd37638c8a585396388b441f791f');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/c5ce6f413112f29b3fadf16bfe3cfd11/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('c5ce6f413112f29b3fadf16bfe3cfd11')), 'button-loading');
    // if user is on Pro Plan they cannot upgrade to an Essential plan, so first they have to cancel
    if (!((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) === null) && (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) >= 200) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('21bcdd37638c8a585396388b441f791f');
    } else {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/zoho/subscription/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'profileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'planCode': '102' })), false, undefined);
    }

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry! Something went wrong. Please contact support.', 'red', null);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('c5ce6f413112f29b3fadf16bfe3cfd11')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/18dd0eacdc35c76ac7149b7603b7e673/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('18dd0eacdc35c76ac7149b7603b7e673')), 'button-loading');
    // if user is on Pro Plan they cannot upgrade to an Essential plan, so first they have to cancel
    if (!((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) === null) && (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) < 200) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('21bcdd37638c8a585396388b441f791f');
    } else {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/zoho/subscription/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'profileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'planCode': '201' })), false, undefined);
    }

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry! Something went wrong. Please contact support.', 'red', null);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('18dd0eacdc35c76ac7149b7603b7e673')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/847625e13b4129874135eb063f51723a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('847625e13b4129874135eb063f51723a')), 'button-loading');
    // if user is on Pro Plan they cannot upgrade to an Essential plan, so first they have to cancel
    if (!((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) === null) && (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.zoho_plan_id')) < 200) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('21bcdd37638c8a585396388b441f791f');
    } else {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((await Backendless.Request.post(`${Backendless.appPath}/services/zoho/subscription/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'profileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'planCode': '202' })), false, undefined);
    }

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry! Something went wrong. Please contact support.', 'red', null);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('847625e13b4129874135eb063f51723a')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/87c2813858188558fb1bbb235fdf6eff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newUpgraded;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('87c2813858188558fb1bbb235fdf6eff')), 'button-loading');
    await Backendless.Request.delete(`${Backendless.appPath}/services/zoho/subscription/delete`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))));
    newUpgraded = (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded'));
    // Change status in db.
    Object.assign(newUpgraded, ({ [`fee_rate`]: 0.05,[`plan_name`]: 'Free',[`zoho_plan_id`]: null,[`objectId`]: (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.objectId')) }));
    newUpgraded = (await Backendless.Data.of('upgrades').save(newUpgraded, true));
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'You\'re back on the Free plan!', 'green', null);
    await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry! Something went wrong, we couldn\'t cancel your plan. Please contact support.', 'red', null);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('87c2813858188558fb1bbb235fdf6eff')), 'button-loading', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Upgrade/components/354d30bdffaa9d4a36bfde2963fb4d53/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newUpgraded;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('87c2813858188558fb1bbb235fdf6eff')), 'button-loading');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('354d30bdffaa9d4a36bfde2963fb4d53')), 'button-loading-dark');
    await Backendless.Request.delete(`${Backendless.appPath}/services/zoho/subscription/delete`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))));
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('87c2813858188558fb1bbb235fdf6eff')), 'button-loading', '');
    newUpgraded = (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded'));
    // Change status in db.
    Object.assign(newUpgraded, ({ [`fee_rate`]: 0.05,[`plan_name`]: 'Free',[`zoho_plan_id`]: null,[`objectId`]: (getObjectProperty(___arguments.context.appData, 'accountProfile.upgraded.objectId')) }));
    newUpgraded = (await Backendless.Data.of('upgrades').save(newUpgraded, true));
    await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'You\'re back on the Free plan!', 'green', null);

  } catch (error) {
    console.log(error);
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry! Something went wrong, we couldn\'t cancel your plan. Please contact support.', 'red', null);

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('21bcdd37638c8a585396388b441f791f');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_230bf36c81ec429d75093e1c2478be79/logic/b029aed706a5d66a25018f55940f01b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Supply the objectId of the TSR object in "removeStudent"
  // in Page Data. It will be used to trigger a dialog modal.
  ___arguments.context.pageData['removeStudent'] = ___arguments.context.rootComponentData;
  // Add a boolean to the data model which will trigger removeStudentMModal.
  (getObjectProperty(___arguments.context.pageData, 'removeStudent'))['removeStudentModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_230bf36c81ec429d75093e1c2478be79/logic/7db51e1f880d53fd8c3a82e57b74d535/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // Supply all the student data as object for property "editStudent"
  // in Page Data. It will be used to trigger a dialog modal.
  ___arguments.context.pageData['editStudent'] = ___arguments.context.rootComponentData;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_230bf36c81ec429d75093e1c2478be79/logic/c2be871b947850539e4b6c6425f95247/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // Add a boolean to the data model which will
  // be used to trigger editStudentTagsModal.
  ___arguments.context.rootComponentData['editStudentTagsModal'] = true;
  // Supply all the student data as object for property "editStudentTags"
  // in Page Data. It will be used to trigger a editStudentTags modal.
  ___arguments.context.pageData['editStudentTags'] = ___arguments.context.rootComponentData;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_230bf36c81ec429d75093e1c2478be79/logic/49b6f3fde6b3d7c75077ebfd652aaebd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // This is the id for the TSR object, not the students profile object.
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('StudentDetail', ({ 'TSR_objectId': (getObjectProperty(___arguments.context.rootComponentData, 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_230bf36c81ec429d75093e1c2478be79/logic/925ca04a6ead5873a4c730b1589b316f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['emailModal'] = ({ 'full_name': (getObjectProperty(___arguments.context.rootComponentData, 'full_name')),'student_profile_id': (getObjectProperty(___arguments.context.rootComponentData, 'student_profile_id')),'type': 'singleStudent' });

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'email_subscribed')) || (getObjectProperty(___arguments.context.rootComponentData, 'skeleton_email')) == '' || ((getObjectProperty(___arguments.context.rootComponentData, 'skeleton_email')) === null))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'email_subscribed')) || (getObjectProperty(___arguments.context.rootComponentData, 'skeleton_email')) == '' || ((getObjectProperty(___arguments.context.rootComponentData, 'skeleton_email')) === null) ? ['minimenu', 'lightGrey'] : ['minimenu'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_230bf36c81ec429d75093e1c2478be79/logic/5ab182fbf9739f3fd98bb6d6ecdbd8e9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('5ab182fbf9739f3fd98bb6d6ecdbd8e9', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('5ab182fbf9739f3fd98bb6d6ecdbd8e9', false);

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./components/reusable/r_2ef67e6931f674d1679ae4cf747de34c/logic/f014cb1d414290bb664769fd78d9dc8b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0a2af71b073d0127d6d6e74d1f1ba187')), 'show');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f014cb1d414290bb664769fd78d9dc8b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a2af71b073d0127d6d6e74d1f1ba187', true);
  await new Promise(r => setTimeout(r, 300 || 0));
  (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('753ce23d59079f3b0792cc9c1276a3b5')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2ef67e6931f674d1679ae4cf747de34c/logic/de5f6f4bf8ee8b910f9c77f1495bd2f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // All the logic to create a new tag for the teacher only
  // runs if there are characters in the newTag field.
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187'), 'newTag')) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('de5f6f4bf8ee8b910f9c77f1495bd2f8')), 'button-loading-icon');
    ___arguments.context.appData['allTeacherTags'] = (await BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08']('Create', null, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187'), 'newTag'))));
    // Stop the spinner.
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('de5f6f4bf8ee8b910f9c77f1495bd2f8')), 'button-loading-icon', '');
  }
  // return to the state where "add tag" button shows and special input field doesn't.
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0a2af71b073d0127d6d6e74d1f1ba187')), 'show', '');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f014cb1d414290bb664769fd78d9dc8b', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a2af71b073d0127d6d6e74d1f1ba187', false);
  // Empty the field for next time use
  ___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187')['newTag'] = '';
  // Have to do this because selected tags appearance gets messed up on Students page.
  ___arguments.context.pageData['selectedFilters'] = [];
  // Crazy hack to unmount components in newTagWidget on page instance
  delete ___arguments.context.pageData['hideWidgetOnPage'];

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If input field is empty, icon is "close". If input field has some text, icon is "check".

  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187'), 'newTag')) ? 'close' : 'check')

  },
  /* handler:onIconAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Change background color of button depending on if input field has some text. Unfortunately, we need to hard code hex code values.

  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187'), 'newTag')) ? ({ 'background': '#8CB92B' }) : ({ 'background': '#00000061' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_2ef67e6931f674d1679ae4cf747de34c/logic/35db70e3f381b1a41a77f7f16643b40d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // All the logic to edit a tag for the teacher only runs
  // if there are characters in the editTagTitle field.
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('eb31937e40172743f0070f77c5ed1383'), 'editTagTitle')) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('35db70e3f381b1a41a77f7f16643b40d')), 'button-loading-icon');
    ___arguments.context.appData['allTeacherTags'] = (await BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08']('Edit', (getObjectProperty(___arguments.context.pageData, 'chosenTag.tag_objectId')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('eb31937e40172743f0070f77c5ed1383'), 'editTagTitle'))));
    // Clean up by deleting chosenTag from Page Data.
    delete ___arguments.context.pageData['chosenTag'];
    // Have to do this because selected tags appearance gets messed up on Students pag
    ___arguments.context.pageData['selectedFilters'] = [];
    // Changing tag name means we should reload students with the right tag names.
    await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Change background color of button depending on if input field has some text. Unfortunately, we need to hard code hex code values.

  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('eb31937e40172743f0070f77c5ed1383'), 'editTagTitle')) ? ({ 'background': '#8CB92B' }) : ({ 'background': '#00000061' }))

  },
  /* handler:onStyleAssignment */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If input field is empty, icon is "close". If input field has some text, icon is "check".

  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('eb31937e40172743f0070f77c5ed1383'), 'editTagTitle')) ? 'close' : 'check')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./components/reusable/r_2ef67e6931f674d1679ae4cf747de34c/logic/4da9c394f0c49698c2a7e5ee4f11ed99/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'chosenTag.Edit'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onVisibilityChange */
  ['onVisibilityChange'](___arguments) {
      (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b2242d975056d5bbef6c0b242c429af6')));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./components/reusable/r_2ef67e6931f674d1679ae4cf747de34c/logic/9a45fe4bdf41f1ec8bc5ccdcacbdfefd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'chosenTag.Edit')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_2ef67e6931f674d1679ae4cf747de34c/logic/b2242d975056d5bbef6c0b242c429af6/bundle.js', [], () => ({
  /* content */
  /* handler:onPlaceholderAssignment */
  ['onPlaceholderAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'chosenTag.title'))

  },
  /* handler:onPlaceholderAssignment */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // All the logic to edit a tag for the teacher only runs
  // if there are characters in the editTagTitle field.
  // "13" is the code for the carriage return key. This logic (a duplicate of the "on click" for the icon button) runs when user hits enter on keyboard.
  if (___arguments.keyCode == '13' && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('eb31937e40172743f0070f77c5ed1383'), 'editTagTitle'))) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('35db70e3f381b1a41a77f7f16643b40d')), 'button-loading-icon');
    ___arguments.context.appData['allTeacherTags'] = (await BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08']('Edit', (getObjectProperty(___arguments.context.pageData, 'chosenTag.tag_objectId')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('eb31937e40172743f0070f77c5ed1383'), 'editTagTitle'))));
    // Clean up by deleting chosenTag from Page Data.
    delete ___arguments.context.pageData['chosenTag'];
    // Have to do this because selected tags appearance gets messed up on Students page.
    ___arguments.context.pageData['selectedFilters'] = [];
    // Changing tag name means we should reload students with the right tag names.
    await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./components/reusable/r_2ef67e6931f674d1679ae4cf747de34c/logic/753ce23d59079f3b0792cc9c1276a3b5/bundle.js', [], () => ({
  /* content */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // All the logic to create a new tag for the teacher only
  // runs if there are characters in the newTag field.
  // "13" is carriage return. Also need some text in the input field.
  if (___arguments.keyCode == '13' && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187'), 'newTag'))) {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('de5f6f4bf8ee8b910f9c77f1495bd2f8')), 'button-loading-icon');
    ___arguments.context.appData['allTeacherTags'] = (await BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08']('Create', null, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187'), 'newTag'))));
    // Stop the spinner.
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('de5f6f4bf8ee8b910f9c77f1495bd2f8')), 'button-loading-icon', '');
    // return to the state where "add tag" button shows and special input field doesn't.
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0a2af71b073d0127d6d6e74d1f1ba187')), 'show', '');
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f014cb1d414290bb664769fd78d9dc8b', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a2af71b073d0127d6d6e74d1f1ba187', false);
    // Empty the field for next time use
    ___arguments.context.getComponentDataStoreByUid('0a2af71b073d0127d6d6e74d1f1ba187')['newTag'] = '';
    // Have to do this because selected tags appearance gets messed up on Students page.
    ___arguments.context.pageData['selectedFilters'] = [];
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/c1340c13384a1d144f2ab36fdbcb3b94/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'manageTagsModal'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/56962e375ff99783d9fbbaaa9fc2cc16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    /**
 * Describe this function...
 */
async function cleanUpTagModal() {
  // clear chosenTag from Page Data if it exists, to clear data
  delete ___arguments.context.pageData['chosenTag'];
  // Crazy hack to show newTagWidget again (and
  // mount its components) when modal is closed
  delete ___arguments.context.pageData['hideWidgetOnPage'];
  ___arguments.context.pageData['manageTagsModal'] = false;
}


  await cleanUpTagModal();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/ceacef8d312d5de9e752c7688f94e0ff/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5b9f261c9bcfbb4fa45baa632e863741'), 'title')),' (',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5b9f261c9bcfbb4fa45baa632e863741'), 'numberUsed')),')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/ad4b6131994aea0cb033c9cba20a641d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('5b9f261c9bcfbb4fa45baa632e863741')['Edit'] = true;
  ___arguments.context.pageData['chosenTag'] = ___arguments.context.getComponentDataStoreByUid('5b9f261c9bcfbb4fa45baa632e863741');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/90479d7abec33577e3922d88698c4305/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['chosenTag'] = ___arguments.context.getComponentDataStoreByUid('5b9f261c9bcfbb4fa45baa632e863741');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('31518e96f3def14d632328c89bb34b13');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/933c930146ecf5c77f8cbd25eee592ec/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('933c930146ecf5c77f8cbd25eee592ec', true);

  },
  /* handler:onTargetClick */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('933c930146ecf5c77f8cbd25eee592ec', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/34f6f09705bddabbabc9bd6902f64ab4/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'allTeacherTags'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/028f71d5f29bddba136a3db886e3d053/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'manageTagsModal'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    /**
 * Describe this function...
 */
async function cleanUpTagModal() {
  // clear chosenTag from Page Data if it exists, to clear data
  delete ___arguments.context.pageData['chosenTag'];
  // Crazy hack to show newTagWidget again (and
  // mount its components) when modal is closed
  delete ___arguments.context.pageData['hideWidgetOnPage'];
  ___arguments.context.pageData['manageTagsModal'] = false;
}


  await cleanUpTagModal();

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/07023c89c26a91bf2df3a57731dc142f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Delete the tag "',(getObjectProperty(___arguments.context.pageData, 'chosenTag.title')),'"?'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/d23fbb1ed87f575e9336ccb56c8b73a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      // clear chosenTag from Page Data
  delete ___arguments.context.pageData['chosenTag'];
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('31518e96f3def14d632328c89bb34b13');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/26c665af90bcbb9fdd47da48b94c9c53/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('26c665af90bcbb9fdd47da48b94c9c53')), 'button-loading-dark');
  ___arguments.context.appData['allTeacherTags'] = (await BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08']('Delete', (getObjectProperty(___arguments.context.pageData, 'chosenTag.tag_objectId')), null));
  // clear chosenTag from Page Data
  delete ___arguments.context.pageData['chosenTag'];
  // stop button spinner
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('26c665af90bcbb9fdd47da48b94c9c53')), 'button-loading-dark', '');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('31518e96f3def14d632328c89bb34b13');
  // Have to do this because selected tags appearance gets messed up on Students page.
  ___arguments.context.pageData['selectedFilters'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_4125d2292f897d883df3ca86a0a83d18/logic/36cb721c5f7fdb99269f3a218737f203/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('36cb721c5f7fdb99269f3a218737f203', true);

  },
  /* handler:onTargetClick */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('36cb721c5f7fdb99269f3a218737f203', false);

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('36cb721c5f7fdb99269f3a218737f203', false);

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./components/reusable/r_a3b918674fb7464e8b74fd41addac1f8/logic/330b77cc787140af40ac074ff63279b2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Remove ',(getObjectProperty(___arguments.context.pageData, 'removeStudent.full_name')),'?'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a3b918674fb7464e8b74fd41addac1f8/logic/2a323188722dd845d358c5386808d8c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Keep removeStudent object, just set value of removeStudentModal to false.
  (getObjectProperty(___arguments.context.pageData, 'removeStudent'))['removeStudentModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a3b918674fb7464e8b74fd41addac1f8/logic/a18e7504210aae8aad9720814b263734/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a18e7504210aae8aad9720814b263734')), 'button-loading-dark');
  // Delete the teacher_student_rels relationship in the database.
  await Backendless.Data.of('teacher_student_rels').remove( (getObjectProperty(___arguments.context.pageData, 'removeStudent.objectId')) );
  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'studentList')))) {
    ___arguments.context.appData['studentList'] = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'studentList')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.pageData, 'removeStudent.objectId')));
    }));
  }
  // remove the student from filteredList also (if that array exists)
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'filteredList')))) {
    ___arguments.context.pageData['filteredList'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'filteredList')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.pageData, 'removeStudent.objectId')));
    }));
  }
  // remove the student from studentSubList also (if that array exists)
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'studentSubList')))) {
    ___arguments.context.pageData['studentSubList'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'studentSubList')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.pageData, 'removeStudent.objectId')));
    }));
  }
  // Special case: teacher removes themselves as
  // student, have to reload userData for the teacher.
  if ((getObjectProperty(___arguments.context.pageData, 'removeStudent.student_profile_id')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
    await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
  }
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (String(getObjectProperty(___arguments.context.pageData, 'removeStudent.full_name')) + String('has been removed from your student list!')), 'green', null);
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a18e7504210aae8aad9720814b263734')), 'button-loading-dark', '');
  // If remove student was called while edit student modal is open,
  // we close that modal. Should already be closed, actually.
  if ((getObjectProperty(___arguments.context.pageData, 'editStudent')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editStudent'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editStudent')) === 'object')) {
    // Clean up Page Data and in the process close modal, too.
    delete ___arguments.context.pageData['editStudent'];
  }
  // Clean up Page Data and in the process close modal, too.
  delete ___arguments.context.pageData['removeStudent'];
  // If user is on StudentDetail and removes student, take them back to Students page.
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'StudentDetail') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Students', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a3b918674fb7464e8b74fd41addac1f8/logic/5188faca997c43dba5bc7d44a2cbe4e2/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// removeStudentModal inside of removeStudent has to be True for this modal to appear.

  return (getObjectProperty(___arguments.context.pageData, 'removeStudent.removeStudentModal'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Keep removeStudent object, just set value of removeStudentModal to false.
  (getObjectProperty(___arguments.context.pageData, 'removeStudent'))['removeStudentModal'] = false;

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/e3dad5b3dcf461aa909d7be7b321eefc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Don't show panel until data is fully there.

  return ((getObjectProperty(___arguments.context.pageData, 'editStudent')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editStudent'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editStudent')) === 'object'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/5884b449dcb96b29d2f538cc2c3cf052/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // Clean up Page Data and in the process close modal, too.
  delete ___arguments.context.pageData['editStudent'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/be2aa36efb2ef7bf7eeeda541a3548ab/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	// code snippet from crop.guide, detects file upload process on page and inserts crop/resize overlay
  	await BackendlessUI.requireModule("https://cdn.crop.guide/loader/l.js?c=MDWNWW");

  })();
  // Get the relevant student's profile object and store it in
  // the Form Data Model. This is NOT the object in studentList.
  Object.assign(___arguments.context.dataModel, (await Backendless.Data.of('profiles').findById((getObjectProperty(___arguments.context.pageData, 'editStudent.student_profile_id')), Backendless.DataQueryBuilder.create().setRelated(['user_email']))));
  // If profile table has no link to a thumbnail,
  // we set imageURL to the "defaultThumbnail" image.
  if ((getObjectProperty(___arguments.context.dataModel, 'thumbnail')) === null) {
    ___arguments.context.dataModel['thumbnail'] = (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('large'));
  }
  // Create "removeStudent" property in Page Data in case the
  // user decides to remove the student, instead of editing them.
  ___arguments.context.pageData['removeStudent'] = ({ [`full_name`]: (getObjectProperty(___arguments.context.pageData, 'editStudent.full_name')),[`objectId`]: (getObjectProperty(___arguments.context.pageData, 'editStudent.objectId')),[`student_profile_id`]: (getObjectProperty(___arguments.context.pageData, 'editStudent.student_profile_id')) });

  },
  /* handler:onBeforeMount */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, newProfile, existingProfiles, dummyObj, item, preExistingProfile;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * Describe this function...
 */
async function associateExistingProfile() {
  // There can only be one existing profile because of
  // unique constraint on skeleton_email in profiles table.
  // So we get the 1st item in list of existingProfiles..
  preExistingProfile = existingProfiles[0];
  // Global function to create teacher_student relationship,
  // returns the relationship object. Here we create a new
  // relationship with any pre-existing profile we find.
  await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8']((await Backendless.Data.of('profiles').findById((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')), )), preExistingProfile, (getObjectProperty(___arguments.context.pageData, 'editStudent.tags')));
  // Delete the relation where the profile was supposed to be edited.
  // We get the object ID for the TSR from "removeStudent" in Page Data.
  await Backendless.Data.of('teacher_student_rels').remove( (getObjectProperty(___arguments.context.pageData, 'removeStudent.objectId')) );
  // If the preExistingProfile is a skeleton, we update it with new data from
  // the form (if it's a live profile with user_email, we leave it alone).
  if ((getObjectProperty(preExistingProfile, 'user_email')) == null) {
    preExistingProfile['first_name'] = (getObjectProperty(___arguments.context.dataModel, 'first_name'));
    // Only update last name if a new last name is supplied in the form.
    if ((getObjectProperty(___arguments.context.dataModel, 'last_name')) != null) {
      preExistingProfile['last_name'] = (getObjectProperty(___arguments.context.dataModel, 'last_name'));
    }
    console.log('the thumbnail replace should work but sadly it doesn\'t. Ignore for now.');
    preExistingProfile['thumbnail'] = (getObjectProperty(___arguments.context.dataModel, 'thumbnail'));
    // Update preExisting skeleton student profile with new name, image.
    await Backendless.Data.of('profiles').save(preExistingProfile, true);
  }
  return preExistingProfile
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

/**
 * Replaces student in studentList with updated info from the form,
 * without having to reload all studentList objects from the database.
 */
async function fakeUpdateView() {
  // Have to construct full_name if I don't want to talk to the db
  (getObjectProperty(___arguments.context.pageData, 'editStudent'))['full_name'] = ([(getObjectProperty(___arguments.context.dataModel, 'first_name')),' ',(getObjectProperty(___arguments.context.dataModel, 'last_name'))].join(''));
  (getObjectProperty(___arguments.context.pageData, 'editStudent'))['skeleton_email'] = (getObjectProperty(___arguments.context.dataModel, 'skeleton_email'));
  (getObjectProperty(___arguments.context.pageData, 'editStudent'))['thumbnail_small'] = (getObjectProperty(___arguments.context.dataModel, 'thumbnail'));
  // Whatever happens above, we just replace old student info with new
  // student in studentList in Page Data for updated view for the teacher.
  replaceItemInList((getObjectProperty(___arguments.context.appData, 'studentList')), (getObjectProperty(___arguments.context.pageData, 'editStudent')), 'objectId');
}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6ce94e894a37f43910ecc85a96ee4277')), 'button-loading');
    // no email — there cannot be an existing profile and we
    // just upsert with data from the form. OR: email not changed
    // so we don't need to check the db for existing profiles.
    if (((getObjectProperty(___arguments.context.dataModel, 'skeleton_email')) === null) || (getObjectProperty(___arguments.context.dataModel, 'skeleton_email')) == (getObjectProperty(___arguments.context.pageData, 'editStudent.skeleton_email'))) {
      // Update student profile with new name, image.
      await Backendless.Data.of('profiles').save(___arguments.context.dataModel, true);
      await fakeUpdateView();
    } else {
      // Don't allow user to add an email that matches an email already associated with a student of theirs.
      if (((getObjectProperty(___arguments.context.appData, 'studentList')).map(item => item['skeleton_email'])).includes((getObjectProperty(___arguments.context.dataModel, 'skeleton_email')))) {
        dummyObj = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'studentList')), async (item) => {


         return ((getObjectProperty(item, 'skeleton_email')) == (getObjectProperty(___arguments.context.dataModel, 'skeleton_email')));
        }))[0];
        ___arguments.context.dataModel['emailErrorText'] = (['Email is already associated with ',(dummyObj !== null && !Array.isArray(dummyObj) && (typeof dummyObj === 'object')) ? (getObjectProperty(dummyObj, 'full_name')) : 'someone','.'].join(''));
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bca69eecc626e19bedc7c4c6ccc707e7', true);
        removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6ce94e894a37f43910ecc85a96ee4277')), 'button-loading', '');
        throw (new Error('duplicateEmailAttemptEditStudent'))
      }
      // Check email format — if it's not correct, database can't accept it.
      if (!(await BackendlessUI.Functions.Custom['fn_d47cae3b23b596aa0043c941a238585f']((getObjectProperty(___arguments.context.dataModel, 'skeleton_email')))) && (getObjectProperty(___arguments.context.dataModel, 'skeleton_email')) != null) {
        ___arguments.context.dataModel['emailErrorText'] = 'This is not a valid email';
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bca69eecc626e19bedc7c4c6ccc707e7', true);
        removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6ce94e894a37f43910ecc85a96ee4277')), 'button-loading', '');
        throw (new Error('invalidEmailFormat'))
      }
      // Look for existing profiles with the email address supplied, get a list of them.
      existingProfiles = (await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['skeleton_email=\'',(getObjectProperty(___arguments.context.dataModel, 'skeleton_email')),'\''].join(''))).setRelated(['user_email']).setPageSize(10)));
      // if there are no pre-existing skeletons, we just update the
      // profile. But if there are, it gets more complicated...
      if (!existingProfiles.length) {
        // Update student profile with new name, image.
        await Backendless.Data.of('profiles').save(___arguments.context.dataModel, true);
        await fakeUpdateView();
      } else {
        newProfile = await associateExistingProfile();
        await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
      }
    }
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6ce94e894a37f43910ecc85a96ee4277')), 'button-loading', '');
    // Clean up removeStudent object in Page Data
    delete ___arguments.context.pageData['removeStudent'];
    // now close modal by killing editStudent in Page
    // Data. This unmounts components within the modal.
    delete ___arguments.context.pageData['editStudent'];

  } catch (error) {
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','type': 'upsert','value': (String('error in editStudent flow:') + String(error)) });

  }

  },
  /* handler:onSubmit */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.dataModel, 'first_name')) === 'undefined') ? ['form-loading'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/3cde6f8929b47f7660a5e2bf73f4ca99/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If there is no user_email (have null), we can edit. Otherwise not.

  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === null) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/1f360376a127bc4e8c7317db7e97e0e1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If there is no user_email (have null), we can edit. Otherwise not.

  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === null) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === 'undefined'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/51c9aaa43aa9e401f4949917a76626dc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If there is no user_email (have null), we can edit. Otherwise not.

  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === null) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === 'undefined'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/48b3a81874481a0a288487883cd42d44/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Disable when profile is a live one: if there is no user_email (have null), we can edit. Otherwise not.

  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === null) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === 'undefined'))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      // When user clicks into field, get rid of error message. give them another chance.
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bca69eecc626e19bedc7c4c6ccc707e7', false);

  },
  /* handler:onFocus */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/369bf4a93b6332dae24fde61341c1013/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var backendlessProfileFile;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // replace whatever was in Form Data with new thumbnail image link
  ___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab')['thumbnail'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': ___arguments.uploadedFiles,'path': 'profiles/thumbnails/large' }));
  // get link to small avatar/thumbnail of the user
  // Makes a small thumbnail if not used before... otherwise returns cached image or link to small image. See here: https://backendless.com/features/marketplace/image-processor-api
  backendlessProfileFile = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/ImageProcessor/resize`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'image_path': JSON.stringify(___arguments.uploadedFiles),'width': 64,'height': 64 }).send()), 'url'));
  ___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab')['thumbnail_small'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': backendlessProfileFile,'path': 'profiles/thumbnails/small' }));
  // make the uploader button reappear to show
  // image and in case user wants to change image
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('369bf4a93b6332dae24fde61341c1013');

  },
  /* handler:onUploadSuccess */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If object in page data has a thumbnail link, get it...
  // otherwise get link to default thumbnail. Then make this the
  // url for the background image of the file upload button.
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('369bf4a93b6332dae24fde61341c1013'))['background-image'] = (['url(',((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'thumbnail')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('large')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'thumbnail')),')'].join(''));

  },
  /* handler:onStyleAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If there is no user_email (have null), we can edit. Otherwise not.

  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === null) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === 'undefined'))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onButtonLabelAssignment */
  ['onButtonLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If there is no user_email (have null), we can edit. Otherwise not.

  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === null) && !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === 'undefined') ? '' : '⎋')

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}



  return (generateUUID().short())

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/7cf5e7e585fc74bd4b5f06cd8e0b7fb3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Add a boolean to the removeStudent property in
  // Page Data to trigger removeStudent dialog modal.
  (getObjectProperty(___arguments.context.pageData, 'removeStudent'))['removeStudentModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/6ce94e894a37f43910ecc85a96ee4277/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === null) || (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('be2aa36efb2ef7bf7eeeda541a3548ab'), 'user_email')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_349fb8b4d16223dc56a154e629debba5/logic/5a929bf7ef28723e087462a9b3cea577/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editStudent')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'editStudent'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'editStudent')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['editStudent'];
  // clean up Page Data, removeStudent was created when form mounted.
  delete ___arguments.context.pageData['removeStudent'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/0a8f1876cceb9d8803068d23fc4eaaad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['addStudentModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/0a5e9442d45b012b87cf20c7fc8700ee/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, studentProfileObject, teacherStudentRelObject, newStudent, teacherProfileObject, tagsForRels, j, item, teachStudRel;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  // add class to show spinner
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a50edfc2764ed2b190dd556f2749e38f')), 'button-loading');
  // If student they want to add is already in their list, show alert
  if (((getObjectProperty(___arguments.context.appData, 'studentList')).map(item => item['skeleton_email'])).includes((getObjectProperty(___arguments.context.dataModel, 'skeleton_email')))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'A student with this email is already on your list!', 'red', null);
  } else {
    try {
      // Get the users teacher profile object from App Data.
      teacherProfileObject = (getObjectProperty(___arguments.context.appData, 'accountProfile'));
      studentProfileObject = (await BackendlessUI.Functions.Custom['fn_3d883efd8c416a7df85afca371c23a98']((getObjectProperty(___arguments.context.dataModel, 'skeleton_email')), (getObjectProperty(___arguments.context.dataModel, 'first_name')), (getObjectProperty(___arguments.context.dataModel, 'last_name'))));
      // We need those tag child objects from allTeacherTags where objectId
      // matches the tag_objectId from selectedTags from the form.
      tagsForRels = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'allTeacherTags')), async (item) => {


       return (((getObjectProperty(___arguments.context.dataModel, 'selectedTags')).map(item => item['tag_objectId'])).includes((getObjectProperty(item, 'tag_objectId'))));
      }));
      // need tag properties to be called "objectId" for the
      // transaction to create tags in follow relationship
      for (var j_index in tagsForRels) {
        j = tagsForRels[j_index];
        j['objectId'] = (getObjectProperty(j, 'tag_objectId'));
        delete j['tag_objectId'];
      }
      // Global function to create teacher_student relationship, returns the relationship object.
      teacherStudentRelObject = (await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8'](teacherProfileObject, studentProfileObject, tagsForRels));
      // Special case: teacher adds themselves as student,
      // have to reload userData for the teacher.
      if ((getObjectProperty(teacherProfileObject, 'objectId')) == (getObjectProperty(studentProfileObject, 'objectId'))) {
        await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
      }
      // Reloading students of the teacher into App Data
      await BackendlessUI.Functions.Custom['fn_8c13aae25af53def398c5c3595a47349'](___arguments.context.appData);
      // Only runs if add student is happening in the
      // context of registrations/attendance management.
      if (((getObjectProperty(___arguments.context.pageData, 'attendanceView')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'attendanceView'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'attendanceView')) === 'object')) && (Array.isArray((getObjectProperty(___arguments.context.pageData, 'allStudents'))))) {
        // Since we just loaded all students into AppData, go there to
        // retrieve our new student. Put them at the top of allStudents list.
        (getObjectProperty(___arguments.context.pageData, 'allStudents')).unshift((getObjectProperty(___arguments.context.appData, 'studentList'))[((((getObjectProperty(___arguments.context.appData, 'studentList')).map(item => item['objectId'])).indexOf(getObjectProperty(teacherStudentRelObject, 'objectId')) + 1) - 1)]);
      }
      // Closes the modal
      ___arguments.context.pageData['addStudentModal'] = false;
      await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, ([(getObjectProperty(studentProfileObject, 'first_name')),' ',(getObjectProperty(studentProfileObject, 'last_name')),' was added to your student list.'].join('')), 'green', null);

    } catch (error) {
      await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','tags': ({ [`operation`]: 'add student' }),'extra': (JSON.parse((String('teacher id is:') + String(getObjectProperty(___arguments.context.appData, 'accountProfile.objectId'))))),'type': 'error','value': (JSON.stringify(error)) });
      await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (JSON.stringify(error)), 'red', null);

    }
  }

  },
  /* handler:onSubmit */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      // Whatever is in here when form submits will get saved to database.
  ___arguments.context.dataModel['selectedTags'] = [];

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/ec0ac631dc6bd8d6bc899c346ebf57b9/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      // Clear field when user clicks into it
  ___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee')['email'] = '';

  },
  /* handler:onFocus */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/a50edfc2764ed2b190dd556f2749e38f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee'), 'first_name')));

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('0a5e9442d45b012b87cf20c7fc8700ee');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/50559a3fb8e4f174cb77dfb56191c79a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // Active/inactive class for the tag item is gotten from "selectedTags" array.
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee'), 'selectedTags')).includes(___arguments.context.dataModel)) {
    removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee'), 'selectedTags')), ___arguments.context.dataModel, '');
  } else {
    addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee'), 'selectedTags')), ___arguments.context.dataModel);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var selTags;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selTags = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee'), 'selectedTags'));
  // Need this "if not undefined" because we wipe all properties of Form Data on submit.
  if (!(typeof selTags === 'undefined') && !(selTags === null)) {
    if (selTags.includes(___arguments.context.dataModel)) {
      return ['active'];
    } else {
      return ['inactive'];
    }
  }

  return ['inactive']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/67b8f5e4459bdba9aae198fdc0c4a119/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If Page Data has the allTeacherTags array already, do nothing.
  // Otherwise, we fetch all of a teachers tags and keep them in Page Data.
  if (typeof (getObjectProperty(___arguments.context.appData, 'allTeacherTags')) === 'undefined') {
    ___arguments.context.appData['allTeacherTags'] = (await BackendlessUI.Functions.Custom['fn_9fd1d14c3001203b50de810ab2dbec08'](null, null, null));
  }

  },
  /* handler:onMounted */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'allTeacherTags'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/0bbc3199be162e67d152094d187cebf9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'addStudentModal'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_32b987a2deb85b31c8b7d8fe9a9778be/logic/a9deaea368ae6cb1d497b2e355b53b0d/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'addStudentModal'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['addStudentModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/09c9d24df38d7ee7ecf6a0ca87068a85/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'connectedContent.open'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['connectedContent'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/b4d2baff8aea9f8292c72b710e82091f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('09c9d24df38d7ee7ecf6a0ca87068a85');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/5a59207a75ec0e8420e40dd7656f800c/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, txResult, getConnectedContent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  getConnectedContent = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = getConnectedContent;

    // Don't fetch templates that involve only past classes.
    _uow.find('class_templates', Backendless.DataQueryBuilder.create().setWhereClause((['profiles[class_templates].objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND (until_date > NOW() OR until_date is NULL)'].join(''))).setProperties(['title']).setRelated(['packages']).setSortBy(['title']).setPageSize(100)).setOpResultId('getAllClassTemplates');
    _uow.find('videos', Backendless.DataQueryBuilder.create().setWhereClause((['profiles[videos].objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join(''))).setProperties(['title']).setRelated(['packages']).setSortBy(['title']).setPageSize(100)).setOpResultId('getAllVideos');
    _uow.find('services', Backendless.DataQueryBuilder.create().setWhereClause((['profiles[services].objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join(''))).setProperties(['title']).setRelated(['packages']).setSortBy(['title']).setPageSize(100)).setOpResultId('getAllServices');

  })();

  txResult = (await getConnectedContent.execute());
  if (txResult.isSuccess()) {
    (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['connectedClasses'] = (await Promise.all((getObjectProperty((txResult.getResults()), 'getAllClassTemplates.result')).map(async item => {  item['packages'] = ((getObjectProperty(item, 'packages')).map(item => item['objectId']));
      item['checked'] = ((getObjectProperty(item, 'packages')).includes((getObjectProperty(___arguments.context.pageData, 'connectedContent.packageObjectId'))));
      item['changed'] = false;
    ; return item;})));
    (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['connectedVideos'] = (await Promise.all((getObjectProperty((txResult.getResults()), 'getAllVideos.result')).map(async item => {  item['packages'] = ((getObjectProperty(item, 'packages')).map(item => item['objectId']));
      item['checked'] = ((getObjectProperty(item, 'packages')).includes((getObjectProperty(___arguments.context.pageData, 'connectedContent.packageObjectId'))));
      item['changed'] = false;
    ; return item;})));
    (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['connectedServices'] = (await Promise.all((getObjectProperty((txResult.getResults()), 'getAllServices.result')).map(async item => {  item['packages'] = ((getObjectProperty(item, 'packages')).map(item => item['objectId']));
      item['checked'] = ((getObjectProperty(item, 'packages')).includes((getObjectProperty(___arguments.context.pageData, 'connectedContent.packageObjectId'))));
      item['changed'] = false;
    ; return item;})));
  } else {
    console.log('Transaction to get teacher data failed for some reason.');
    throw (new Error('Transaction to get teacher data failed for some reason.'))
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/c98d16ca4e9fb57d29ec051c591f9e5f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d1cf3355a42f94e42f58076122db1329');
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['titleText'] = 'Select classes';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/6b878e34a15ba8838d4e2312332a72f3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



  return ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedClasses')))) ? String((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedClasses')), async (item) => {


 return (getObjectProperty(item, 'checked'));
})).length) + String(' connected') : 'loading...')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/3136cc2c6752c7e293c84ecf1244e064/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d1cf3355a42f94e42f58076122db1329');
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['titleText'] = 'Select videos';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/9c084eb6d29addbcfefa7f8ad658d31f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



  return ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedVideos')))) ? String((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedVideos')), async (item) => {


 return (getObjectProperty(item, 'checked'));
})).length) + String(' connected') : 'loading...')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/9a4c02b65f9716f9a2e8e1c8e04c6a6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d1cf3355a42f94e42f58076122db1329');
  (getObjectProperty(___arguments.context.pageData, 'connectedContent'))['titleText'] = 'Select services';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/8d032fdf367d44517cd471f630e68adf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



  return ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedServices')))) ? String((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedServices')), async (item) => {


 return (getObjectProperty(item, 'checked'));
})).length) + String(' connected') : 'loading...')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/a35b6745e98e571e0ca6b9a5b80ada58/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var videosToBeConnected, item, classesToBeConnected;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a35b6745e98e571e0ca6b9a5b80ada58')), 'button-loading');
  // We only care about classes whose connection to our package
  // has changed (either to be added or to be removed).
  classesToBeConnected = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedClasses')), async (item) => {


   return (getObjectProperty(item, 'changed'));
  })).map(async item => {; return ({ [`objectId`]: (getObjectProperty(item, 'objectId')),[`add`]: (getObjectProperty(item, 'checked')) });})));
  // Only run database updates for classes if there is something to be updated.
  if (!!classesToBeConnected.length) {
    // The API returns how many msecs it too to complete. For now, we print it.
    console.log((await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/bulkRelations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'child_object_id': (getObjectProperty(___arguments.context.pageData, 'connectedContent.packageObjectId')),'parents_obj_id_array': classesToBeConnected,'table_name': 'class_templates' })));
  }
  console.log('need API connection for videos, services too.');
  // We only care about classes whose connection to our package
  // has changed (either to be added or to be removed).
  videosToBeConnected = (await Promise.all((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedVideos')), async (item) => {


   return (getObjectProperty(item, 'changed'));
  })).map(async item => {; return ({ [`objectId`]: (getObjectProperty(item, 'objectId')),[`add`]: (getObjectProperty(item, 'checked')) });})));
  // Only run database updates for classes if there is something to be updated.
  if (!!videosToBeConnected.length) {
    // The API returns how many msecs it too to complete. For now, we print it.
    console.log((await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/bulkRelations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'child_object_id': (getObjectProperty(___arguments.context.pageData, 'connectedContent.packageObjectId')),'parents_obj_id_array': videosToBeConnected,'table_name': 'videos' })));
  }
  // Clean up Page Data by deleting connectedContent data. Should also close the modal
  delete ___arguments.context.pageData['connectedContent'];
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('09c9d24df38d7ee7ecf6a0ca87068a85');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/4f16c57776598dc44c0a39445644f7c1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'connectedContent')), 'titleText'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/114655e7e8b9e26794f44685cc63571d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d1cf3355a42f94e42f58076122db1329');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/b28c467fb418a1054c288bd2ba8b27fe/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'connectedContent')), 'titleText')) == 'Select classes')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'connectedContent'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/700fb8460704cb7ebc19543e961cf6d1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('b28c467fb418a1054c288bd2ba8b27fe')['connectedClasses'] = (await Promise.all((getObjectProperty(___arguments.context.getComponentDataStoreByUid('b28c467fb418a1054c288bd2ba8b27fe'), 'connectedClasses')).map(async item => {  item['changed'] = ((getObjectProperty(item, 'checked')) == ___arguments.value ? (getObjectProperty(item, 'changed')) : !(getObjectProperty(item, 'changed')));
    item['checked'] = ___arguments.value;
  ; return item;})));

  },
  /* handler:onChange */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!!(getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedClasses')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/12d318e6594328ae7a75e34e32ccd321/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedClasses')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/bdc65a7468b741dcd4f74939b3af3ac7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['changed'] = (!(getObjectProperty(___arguments.context.dataModel, 'changed')));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/61888d4d92c5031de7dbef5cfa48a8ea/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'connectedContent')), 'titleText')) == 'Select videos')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'connectedContent'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/224d61ad4787c88a9afe7094020bef3e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('61888d4d92c5031de7dbef5cfa48a8ea')['connectedVideos'] = (await Promise.all((getObjectProperty(___arguments.context.getComponentDataStoreByUid('61888d4d92c5031de7dbef5cfa48a8ea'), 'connectedVideos')).map(async item => {  item['changed'] = ((getObjectProperty(item, 'checked')) == ___arguments.value ? (getObjectProperty(item, 'changed')) : !(getObjectProperty(item, 'changed')));
    item['checked'] = ___arguments.value;
  ; return item;})));

  },
  /* handler:onChange */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!!(getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedVideos')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/60b2663a7dca22ab8ce27e2b450e4506/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedVideos')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/86948a510bc443128b1bc4ef5e18a5e1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['changed'] = (!(getObjectProperty(___arguments.context.dataModel, 'changed')));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/071b15461759ffb6a6390baa4088a401/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'connectedContent')), 'titleText')) == 'Select services')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'connectedContent'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/633b4a927b0831dd9a944904df77a5b1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('071b15461759ffb6a6390baa4088a401')['connectedServices'] = (await Promise.all((getObjectProperty(___arguments.context.getComponentDataStoreByUid('071b15461759ffb6a6390baa4088a401'), 'connectedServices')).map(async item => {  item['changed'] = ((getObjectProperty(item, 'checked')) == ___arguments.value ? (getObjectProperty(item, 'changed')) : !(getObjectProperty(item, 'changed')));
    item['checked'] = ___arguments.value;
  ; return item;})));

  },
  /* handler:onChange */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!!(getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedServices')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/eeaa2189fcb3123cc57dc5f29be13fef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'connectedContent.connectedServices')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d57d872b10ae71b98a1f58be4084f7f6/logic/89248811c1a55bbb878d3cdb901ed375/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d1cf3355a42f94e42f58076122db1329');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9a696a156cdf4796c755f85e2ea47015/logic/4b9c82a28f6d9c8282ddfdd2e5a299de/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.dataModel['icon'] = 'search';

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_9a696a156cdf4796c755f85e2ea47015/logic/663382a7eb0579bc8c7b387a32eba625/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['addStudentModal'] = true;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// if this property exists as a number in Page Data, user has maxed out their student limit and we disable the Add New button

  return (typeof (getObjectProperty(___arguments.context.pageData, 'hiddenStudentsNumber')) === 'number' && !isNaN((getObjectProperty(___arguments.context.pageData, 'hiddenStudentsNumber'))))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_9a696a156cdf4796c755f85e2ea47015/logic/dd47476bcd7cfc874c5ab54b1881b2b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Students') {
    ___arguments.context.pageData['emailModal'] = ({ [`type`]: 'taggedStudents' });
  } else {
    if (typeof (getObjectProperty(___arguments.context.pageData, 'attendanceView.instance_id')) === 'string') {
      ___arguments.context.pageData['emailModal'] = ({ [`type`]: 'registeredStudents',[`class_instance_id`]: (getObjectProperty(___arguments.context.pageData, 'attendanceView.instance_id')) });
    } else if (typeof (getObjectProperty(___arguments.context.pageData, 'videoAccessView.video_objectId')) === 'string') {
      ___arguments.context.pageData['emailModal'] = ({ [`type`]: 'registeredStudents',[`video_id`]: (getObjectProperty(___arguments.context.pageData, 'videoAccessView.video_objectId')) });
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9a696a156cdf4796c755f85e2ea47015/logic/50b7ecbac5dc32ce4fef96fdf72d4fc8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectTagsModal'] = ({ [`open`]: true });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9a696a156cdf4796c755f85e2ea47015/logic/7439d2be05436fedecef58a8038873b5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList, needle, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (___arguments.value.length > 2) {
    // make search work for capitals or lower case by setting everything to lower case.
    needle = ___arguments.value.toLowerCase();
    // Logic on main Students page is a bit different because
    // we have tagged students or all students. In other places
    // like Attendance Modals, we're dealing with different list.
    if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Students') {
      // Go through students list and keep only the records where there's a match for name or email
      // code breaks (error) if student has no email so we need this.
      filteredList = (await asyncListFilter(((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length == 0 ? (getObjectProperty(___arguments.context.appData, 'studentList')) : (getObjectProperty(___arguments.context.pageData, 'filteredList'))), async (item) => {


       return ((((getObjectProperty(item, 'full_name')).toLowerCase()).match(needle)) || (((getObjectProperty(item, 'skeleton_email')) === null) ? false : (((getObjectProperty(item, 'skeleton_email')).toLowerCase()).match(needle))));
      }));
    } else {
      // Go through students list and keep only the records where there's a match for name or email
      // code breaks (error) if student has no email so we need this.
      filteredList = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'allStudents')), async (item) => {


       return ((((getObjectProperty(item, 'full_name')).toLowerCase()).match(needle)) || (((getObjectProperty(item, 'skeleton_email')) === null) ? false : (((getObjectProperty(item, 'skeleton_email')).toLowerCase()).match(needle))));
      }));
    }
    ___arguments.context.pageData['studentSubList'] = filteredList;
    ___arguments.context.pageData['searchMessage'] = (filteredList.length == 0 ? 'Nothing found' : 'Here\'s who we found:');
  } else {
    // Hide the searchMessage block.
    delete ___arguments.context.pageData['searchMessage'];
    // Clear out studentSubList so repeater shows all students.
    delete ___arguments.context.pageData['studentSubList'];
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_9a696a156cdf4796c755f85e2ea47015/logic/644e6b7f112b20b833f4afdc10b1fd6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // Toggle classes of various sub-components in the searchBlock
  // component and switch where the studentsBlock gets its repeater data.
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7439d2be05436fedecef58a8038873b5')).includes('show')) {
    // Clear studentSubList so repeater shows all students.
    delete ___arguments.context.pageData['studentSubList'];
    // Hide the searchMessage block.
    delete ___arguments.context.pageData['searchMessage'];
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7439d2be05436fedecef58a8038873b5')), 'show', '');
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('c814245cd765c29a589a1405a997b0c3')), 'show', '');
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('644e6b7f112b20b833f4afdc10b1fd6d')), 'show', '');
    ___arguments.context.getComponentDataStoreByUid('4b9c82a28f6d9c8282ddfdd2e5a299de')['icon'] = 'search';
    // Clear out the search field using data binding.
    ___arguments.context.getComponentDataStoreByUid('4b9c82a28f6d9c8282ddfdd2e5a299de')['searchTerm'] = '';
    // Logic on main Students page is a bit different because
    // we have tagged students or all students. In other places
    // like Attendance Modals, we're dealing with different list.
    if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Students') {
    }
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7439d2be05436fedecef58a8038873b5')), 'show');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('c814245cd765c29a589a1405a997b0c3')), 'show');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('644e6b7f112b20b833f4afdc10b1fd6d')), 'show');
    ___arguments.context.getComponentDataStoreByUid('4b9c82a28f6d9c8282ddfdd2e5a299de')['icon'] = 'close';
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7439d2be05436fedecef58a8038873b5')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9a696a156cdf4796c755f85e2ea47015/logic/fbebf8b8e599ae83eabd4dfd6ffe7365/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.pageData, 'searchMessage')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'searchMessage'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4a56d2d45bf1dc153548de6d91cae3fb/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      // Default tab is Classes
  ___arguments.context.dataModel['scrollyTab'] = 'Classes';

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/ffd9475a707f152b48fdffdc877d9854/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb')['scrollyTab'] = 'Classes';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Classes') {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4765d2a45f07927b09d1eda44dbc06cd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb')['scrollyTab'] = 'Videos';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Videos') {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/75dfb67b800f36dc61afef52beb89afa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb')['scrollyTab'] = 'Privates';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Privates') {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4edd9b2fd1ec0b03aa59b6215833af96/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb')['scrollyTab'] = 'Packs';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Packs') {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/0b4fde785d644366840d1ef3450d1045/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If this property exists and is true (happens on Schedule
  // page when teacher creates or edits a new template on
  // "submit"), we turn off visibility which unmounts all the
  // containers that have classes. When the property is deleted,
  // visibility is re-established and class containers re-mount.
  if (getObjectProperty(___arguments.context.pageData, 'unmountClassScrolly')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Classes')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/b6ec0a94099ced324ea8eea5b583d328/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var query, teacherIds, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * If component is on student Home page, we get all
 * instances of all teachers followed by the student. If
 * we're on a teachers public page, we get the classes for
 * that teacher. Otherwise, we're on Schedule and we get
 * the classes of the user (who is the controlling teacher)
 */
async function buildClassesQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    counter = 0;
    // Can't build query until we know teachers being followed in
    // App Data. After 4 seconds, give up and break out of loop.
    while (!(getObjectProperty(___arguments.context.appData, 'teachersFollowed'))) {
      counter = (typeof counter == 'number' ? counter : 0) + 1;
      await new Promise(r => setTimeout(r, 100 || 0));
      if (counter > 40) {
        console.log('failed to get teachersFollowed');
        break;
      }
    }
    teacherIds = ((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id']));
    // Query to get all class instances from all the teachers the user follows...
    query = ['teacher_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(teacherIds)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),') AND end_datetime <',(new Date((new Date())).getTime())].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime <',(new Date((new Date())).getTime())].join('');
  } else {
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\' AND end_datetime <',(new Date((new Date())).getTime())].join('');
  }
  return query
}


  await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('up', 'classesScrolly', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0b4fde785d644366840d1ef3450d1045')), (getObjectProperty(___arguments.context.pageData, 'pastClassesData')), ({ [`tableName`]: 'class_instances_view',[`whereClause`]: await buildClassesQuery(),[`properties`]: undefined,[`sortBy`]: 'start_datetime desc',[`pageSize`]: 10 }), null, null);

  },
  /* handler:onMounted */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pastClassesData')) == null ? [] : (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastClassesData')), 'items')))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b6ec0a94099ced324ea8eea5b583d328'))['display'] = '';

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b6ec0a94099ced324ea8eea5b583d328'))

  },
  /* handler:onStyleAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If we're not on Schedule page (i.e. student facing), don't show past classes block if student has not attended any classes. Got it?
  if ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule' && ((Array.isArray((getObjectProperty(___arguments.context.appData, 'classRegList')))) ? !(getObjectProperty(___arguments.context.appData, 'classRegList')).length : true)) {
    return false;
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['pastClassesData'] = ({ [`items`]: [] });

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4e72caafb93ae3acebb98a76c275ac37/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUnmount */
  async ['onBeforeUnmount'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('destroy', 'classesScrolly', null, null, null, null, null);

  },
  /* handler:onBeforeUnmount */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var query, teacherIds, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * If component is on student Home page, we get all
 * instances of all teachers followed by the student. If
 * we're on a teachers public page, we get the classes for
 * that teacher. Otherwise, we're on Schedule and we get
 * the classes of the user (who is the controlling teacher)
 */
async function buildClassesQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    counter = 0;
    // Can't build query until we know teachers being followed in
    // Page Data. After 4 seconds, give up and break out of loop.
    while (!(getObjectProperty(___arguments.context.appData, 'teachersFollowed'))) {
      counter = (typeof counter == 'number' ? counter : 0) + 1;
      await new Promise(r => setTimeout(r, 100 || 0));
      if (counter > 40) {
        console.log('failed to get teachersFollowed');
        break;
      }
    }
    teacherIds = ((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id']));
    // Query to get all class instances from all the teachers the user follows...
    query = ['teacher_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(teacherIds)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),') AND end_datetime >',(new Date((new Date())).getTime())].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime >',(new Date((new Date())).getTime())].join('');
  } else {
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\' AND end_datetime >',(new Date((new Date())).getTime())].join('');
  }
  return query
}


  await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('down', 'classesScrolly', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0b4fde785d644366840d1ef3450d1045')), (getObjectProperty(___arguments.context.pageData, 'futureClassesData')), ({ [`tableName`]: 'class_instances_view',[`whereClause`]: await buildClassesQuery(),[`properties`]: undefined,[`sortBy`]: 'start_datetime asc',[`pageSize`]: 25 }), null, null);
  // Some delay to try and ensure we have results from the database
  await new Promise(r => setTimeout(r, 2000 || 0));
  // If there are no future classes, we set an item
  // in the classes array: emtpy: true, this sets
  // visibility of class card and empty state paragraph.
  if (!(getObjectProperty(___arguments.context.pageData, 'futureClassesData.items')).length) {
    (getObjectProperty(___arguments.context.pageData, 'futureClassesData.items'))[0] = ({ [`empty`]: true });
  }

  },
  /* handler:onMounted */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'futureClassesData')) == null ? [] : (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'futureClassesData')), 'items')))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['futureClassesData'] = ({ [`items`]: [] });

  },
  /* handler:onBeforeMount */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ [`min-height`]: 'auto !important;' })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/941c0b5a1039379d9f9a6b5e9e870dd8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If this property exists and is true (happens on Schedule
  // page when teacher creates or edits a new template on
  // "submit"), we turn off visibility which unmounts all the
  // containers that have classes. When the property is deleted,
  // visibility is re-established and class containers re-mount.
  if (getObjectProperty(___arguments.context.pageData, 'unmountVideoScrolly')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Videos')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/73a7210a81d885669f673a37ae688ed6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var query, teacherIds, counter, watchedVideos, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * Describe this function...
 */
async function buildVideosQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    counter = 0;
    // Can't build query until we know teachers being followed in
    // Page Data. After 4 seconds, give up and break out of loop.
    while (!(getObjectProperty(___arguments.context.appData, 'teachersFollowed'))) {
      counter = (typeof counter == 'number' ? counter : 0) + 1;
      await new Promise(r => setTimeout(r, 100 || 0));
      if (counter > 40) {
        console.log('failed to get teachersFollowed');
        break;
      }
    }
    teacherIds = ((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id']));
    // Query to get all videos from all the teachers the user follows...
    query = ['teacher_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(teacherIds)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),')',' AND ',await makeWatchedQuery()].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join('');
  } else {
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\'',' AND ',await makeWatchedQuery()].join('');
  }
  return query
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Generates part of a where clause where video objectId is in a list.
 */
async function makeWatchedQuery() {
  // List of objectId's for previously watched videos.
  // Only include videos the user has already watched
  watchedVideos = ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'videoRegList')), async (item) => {


   return (getObjectProperty(item, 'attended'));
  })).map(item => item['instance_id']));
  return !watchedVideos.length ? 'video_objectId = \'abcdefgh\'' : ['video_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(watchedVideos)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),')'].join('')
}


  // Previously watched videos don't make sense on Schedule dashboard.
  if ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule') {
    await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('up', 'videosScrolly', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('941c0b5a1039379d9f9a6b5e9e870dd8')), ___arguments.context.dataModel, ({ [`tableName`]: 'videos_view',[`whereClause`]: String(await buildVideosQuery()) + String(' AND deleted!= TRUE'),[`properties`]: undefined,[`sortBy`]: 'video_date asc',[`pageSize`]: 10 }), null, null);
  }

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('73a7210a81d885669f673a37ae688ed6'))['display'] = '';

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('73a7210a81d885669f673a37ae688ed6'))

  },
  /* handler:onStyleAssignment */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'items'))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/f91290958f6bf22ba624a762ce6fdf19/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'availability')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/bc66579295921e3592f9f4a4119a2d91/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If this property exists and is true (happens on Schedule
  // page when teacher creates or edits a package on "submit" or
  // when student buys a package), we turn off visibility which
  // unmounts the containers that have packages. When the property is
  // deleted, visibility is re-established and package containers re-mount.
  if (getObjectProperty(___arguments.context.pageData, 'unmountPackageScrolly')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Packs')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/5221cc57943fa11cfffd8a5c011bcaef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['createTemplate'] = ({ 'startDate': (new Date()) });

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/8c07f7030ef2a79b482149c9d949c77b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ 'day': 'numeric','month': 'short','weekday': 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3a3203e21dcb88d3784963a697e99086'), 'start_datetime'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/933c512f8771859faa86d39787548a76/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var prevItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3a3203e21dcb88d3784963a697e99086'), 'idx')) > 1) {
    prevItem = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'futureClassesData')), 'items'))[(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3a3203e21dcb88d3784963a697e99086'), 'idx')) - 1) - 1)];
    if (prevItem !== null && !Array.isArray(prevItem) && (typeof prevItem === 'object')) {
      if ((new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3a3203e21dcb88d3784963a697e99086'), 'start_datetime')))) == (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(prevItem, 'start_datetime'))))) {
        return false;
      }
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/b21e56b3d9e1ec2f8527f79d3b615699/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ 'day': 'numeric','month': 'short','weekday': 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bdaa3dba3ea292a94338552ccc4bf985'), 'start_datetime'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/2f8ec0b321f4c83c4cd366fae41a9aa4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var prevItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bdaa3dba3ea292a94338552ccc4bf985'), 'idx')) < (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastClassesData')), 'items')).length) {
    // Logic is quite different from future classes because indexes
    // run in reverse order (highest idx is 1st item in the array).
    prevItem = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastClassesData')), 'items'))[(((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastClassesData')), 'items')).length - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bdaa3dba3ea292a94338552ccc4bf985'), 'idx'))) - 1)];
    // just to prevent a crash when prev item is not quite available yet.
    if (prevItem !== null && !Array.isArray(prevItem) && (typeof prevItem === 'object')) {
      // if the days are the same, hide the date block.
      if ((new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bdaa3dba3ea292a94338552ccc4bf985'), 'start_datetime')))) == (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(prevItem, 'start_datetime'))))) {
        return false;
      }
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/74effd83bb332b74c42ebe5f64e534c7/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, query, teacherIds, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * If component is on student Home page, we get all
 * packages of all teachers followed by the student.
 * If we're on a teachers public page, we get the packages
 * for that teacher. Otherwise, we're on Schedule and we get
 * the packages of the user (who is the controlling teacher)
 */
async function buildPackagesQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    counter = 0;
    // Can't build query until we know teachers being followed in
    // Page Data. After 4 seconds, give up and break out of loop.
    while (!(getObjectProperty(___arguments.context.appData, 'teachersFollowed'))) {
      counter = (typeof counter == 'number' ? counter : 0) + 1;
      await new Promise(r => setTimeout(r, 100 || 0));
      if (counter > 40) {
        console.log('failed to get teachersFollowed');
        break;
      }
    }
    teacherIds = ((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id']));
    // Query to get all class instances from all the teachers the user follows...
    // We don't show packages to consumers if teacher is not connected to Stripe.
    query = ['teacher_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(teacherIds)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),' ) AND active = TRUE AND deleted != TRUE',' AND stripe_account_id != NULL'].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\'  AND deleted != TRUE'].join('');
  } else {
    // We don't show packages to consumers if teacher is not connected to Stripe.
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\' AND active = TRUE AND deleted != TRUE',' AND stripe_account_id != NULL'].join('');
  }
  return query
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('74effd83bb332b74c42ebe5f64e534c7', (await BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'](___arguments.context.pageData, 'packages_view', (await buildPackagesQuery()), null, null, null, null)));
  console.log('packs repeater data is:');
  console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('74effd83bb332b74c42ebe5f64e534c7'));
  // If there are no packages, we set an item in the repeater array:
  // emtpy: true, this sets visibility of empty state paragraph.
  // filtering out "General Pass" which is always there
  if (!(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('74effd83bb332b74c42ebe5f64e534c7')), async (item) => {


   return (getObjectProperty(item, 'editable'));
  })).length) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('74effd83bb332b74c42ebe5f64e534c7')).unshift(({ [`empty`]: true }));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/6c69d94992f27c2731c6d48d4341046a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['packageModal'] = ({ 'open': true });

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/9e790ad371d631b32977649b47853004/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['videoModal'] = ({ 'open': true,'currentTab': 'first' });

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/557805fbd2230c6f04329e2678219f19/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var dummy, query, teacherIds, counter, unwatchedVideos, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * Describe this function...
 */
async function buildVideosQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    counter = 0;
    // Can't build query until we know teachers being followed in
    // Page Data. After 4 seconds, give up and break out of loop.
    while (!(getObjectProperty(___arguments.context.appData, 'teachersFollowed'))) {
      counter = (typeof counter == 'number' ? counter : 0) + 1;
      await new Promise(r => setTimeout(r, 100 || 0));
      if (counter > 40) {
        console.log('failed to get teachersFollowed');
        break;
      }
    }
    teacherIds = ((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id']));
    // Query to get all videos from all the teachers the user follows...
    query = ['teacher_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(teacherIds)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),')',' AND ',await makeUnwatchedQuery()].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\''].join('');
  } else {
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\'',' AND ',await makeUnwatchedQuery()].join('');
  }
  return query
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Generates part of a where clause where video objectId is in a list.
 */
async function makeUnwatchedQuery() {
  // List of objectId's for previously watched videos.
  // Only include videos the user has NOT already watched
  unwatchedVideos = ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'videoRegList')), async (item) => {


   return (getObjectProperty(item, 'attended'));
  })).map(item => item['instance_id']));
  return !unwatchedVideos.length ? 'video_objectId != \'abcdefgh\'' : ['video_objectId NOT IN (',((subsequenceFromStartFromEnd((JSON.stringify(unwatchedVideos)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),')'].join('')
}


  dummy = (await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('down', 'videosScrolly', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('941c0b5a1039379d9f9a6b5e9e870dd8')), ___arguments.context.dataModel, ({ [`tableName`]: 'videos_view',[`whereClause`]: String(await buildVideosQuery()) + String(' AND deleted!= TRUE'),[`properties`]: undefined,[`sortBy`]: 'video_date desc',[`pageSize`]: 25 }), null, null));
  await new Promise(r => setTimeout(r, 2000 || 0));
  // If there are no future classes, we set an item
  // in the classes array: emtpy: true, this sets
  // visibility of class card and empty state paragraph.
  if (!(getObjectProperty(___arguments.context.dataModel, 'items')).length) {
    (getObjectProperty(___arguments.context.dataModel, 'items'))[0] = ({ [`empty`]: true });
  }

  },
  /* handler:onMounted */
  /* handler:onBeforeUnmount */
  async ['onBeforeUnmount'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('destroy', 'videosScrolly', null, null, null, null, null);

  },
  /* handler:onBeforeUnmount */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'items'))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.dataModel['items'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/2eda981408c91e0c03fafbd6101c1e3f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If user doesn't have stripe_account_id in upgraded object in local storage, show this element.

  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule' && !(typeof (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.stripe_account_id')) === 'string'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/3d34ff983922efe5d0c13193aaa896e4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var onboarding_link;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  onboarding_link = (await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_teacher_onboarding_link`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'teacher_profile_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'return_url': (await (async function() {
  	return window.location.href;
  })()) }));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(onboarding_link, 'stripe_account_link')), false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/f3b8d7455021b4ac307e4704eecea94b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3')['currentTab'] = 'services';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'services') {
    return ['tab', 'current'];
  }

  return ['tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/052e77a87ac80b340ed9916fe67c9a2c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3')['currentTab'] = 'availability';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'availability') {
    return ['tab', 'current'];
  }

  return ['tab']

  },
  /* handler:onClassListAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/2a2ec773daeb59f56293e51e64924a29/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3')['currentTab'] = 'appointments';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'appointments') {
    return ['tab', 'current'];
  }

  return ['tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/9393e7a7da82b899649ab9bf2afaa2e4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'services')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var query, teacherIds, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * If component is on student Home page, we get all services of
 * all teachers followed by the student. If we're on a teachers
 * public page, we get the services for that teacher. Otherwise,
 * we're on Schedule and we get the services of the user (who is the
 * controlling teacher). Essentially the same logic as for packages.
 */
async function buildServicesQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    counter = 0;
    // Can't build query until we know teachers being followed in
    // Page Data. After 4 seconds, give up and break out of loop.
    while (!(getObjectProperty(___arguments.context.appData, 'teachersFollowed'))) {
      counter = (typeof counter == 'number' ? counter : 0) + 1;
      await new Promise(r => setTimeout(r, 100 || 0));
      if (counter > 40) {
        console.log('failed to get teachersFollowed');
        break;
      }
    }
    teacherIds = ((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id']));
    // Query to get all class instances from all the teachers the user follows...
    // We don't show packages to consumers if teacher is not connected to Stripe.
    query = ['teacher_objectId IN (',((subsequenceFromStartFromEnd((JSON.stringify(teacherIds)), 1, 1)).replace((new RegExp('"', 'g')), '\'')),' ) AND active = TRUE AND deleted != TRUE',' AND stripe_account_id != NULL'].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\'  AND deleted != TRUE'].join('');
  } else {
    // We don't show packages to consumers if teacher is not connected to Stripe.
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\' AND active = TRUE AND deleted != TRUE',' AND stripe_account_id != NULL'].join('');
  }
  return query
}


  ___arguments.context.pageData['servicesView'] = [];
  ___arguments.context.pageData['servicesView'] = (await BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'](___arguments.context.pageData, 'services_view', (await buildServicesQuery()), null, null, null, null));
  // If there are no services, we set an item in the array:
  // emtpy: true, this sets visibility of empty state paragraph.
  if (!(getObjectProperty(___arguments.context.pageData, 'servicesView')).length) {
    (getObjectProperty(___arguments.context.pageData, 'servicesView'))[0] = ({ [`empty`]: true });
  }

  },
  /* handler:onMounted */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'servicesView'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/83fa2f2c18cd3c9acbdfefbb252bdb1e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If empty: true exists (set on "on mount" of repeater), we show the empty state content.

  return (getObjectProperty(___arguments.context.dataModel, 'empty'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return 'Click the green \'+\' button to add a group class to your schedule!';
  }

  return 'There are no upcoming classes'

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = ['fadeIn', null];
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    addItemToList(classList, 'green');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/3a3203e21dcb88d3784963a697e99086/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'futureClassesData.items'))[0]), 'empty')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/6866950264bdb0b32d126e046644b6b1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If empty: true exists (set on "on mount" of repeater), we show the empty state content.

  return (getObjectProperty(___arguments.context.dataModel, 'empty'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = ['fadeIn'];
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    addItemToList(classList, 'green');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return 'Create an on-demand library with class recordings or other videos. No upload required, just a link! Click the "+" button to add your first video.';
  }

  return 'There are no videos available'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/0fea53e8fab4d4082ccad40fb48b6526/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('557805fbd2230c6f04329e2678219f19'), 'items'))[0]), 'empty')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/9f96397d5261a2397b59386a71b3c2a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Privates')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4751f38d08aaf9797c1124a492da559d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3')['currentTab'] = 'services';

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/0373f421120ad70ae2cd076cb96d660c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c2618a64089fdade563d0668353db16f'), 'datetime'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/75b119928f73d06f0e73d6ccc5ff9dd8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'futureClassesData.loading'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/b03469dbae8f1983a6681e7c5c3f45ee/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'pastClassesData.loading'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/44ad58c8cc57cdffec0d1514fcbcce9b/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['hrOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'hrOptions'));
  ___arguments.context.dataModel['minOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'minOptions'));

  },
  /* handler:onBeforeMount */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'availabilityTab')) == 'weekly')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/af5f33827b7c4a73804370bedffcc433/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_templates.items'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/21af59fdf5343d31858905663efa222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var lastHour, firstHour;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1745e58eb9701cfa7067e8ba964b2c2'), 'intervals')).length) {
    firstHour = 9;
    lastHour = 17;
  } else {
    firstHour = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1745e58eb9701cfa7067e8ba964b2c2'), 'intervals')).slice(-1)[0][2] + 1;
    lastHour = firstHour + 1;
  }
  if (firstHour >= 22) {
    firstHour = 0;
    lastHour = 0;
  }
  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1745e58eb9701cfa7067e8ba964b2c2'), 'intervals')), [[firstHour, 0, lastHour, 0]]);
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/3ab7316b5c9eb2a5b46b9f8b6fc32aac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('fb85236434edbd144b1906ce34f2449d'), 'intervals')).length)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var lastHour, firstHour;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  firstHour = 9;
  lastHour = 17;
  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fb85236434edbd144b1906ce34f2449d'), 'intervals')), [[firstHour, 0, lastHour, 0]]);
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/0cf3607884e7ff1c7523795a570d6a84/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('44ad58c8cc57cdffec0d1514fcbcce9b'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[0] = ___arguments.value;
  ___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[2] = ___arguments.value + 1;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[0])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/372d551b838260b77f979e5866f3ae1e/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('44ad58c8cc57cdffec0d1514fcbcce9b'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[1] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[1])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/f696f936db429438fbe17937dc69a188/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('44ad58c8cc57cdffec0d1514fcbcce9b'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[2] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[2])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4d14c113dd792bd5c5a160e7692101e8/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('44ad58c8cc57cdffec0d1514fcbcce9b'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[3] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a')[3])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4c285498ac12d8952f21fe6648d43123/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // We remove the interval from the list of intervals.
  removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fb85236434edbd144b1906ce34f2449d'), 'intervals')), ___arguments.context.getComponentDataStoreByUid('762b3408d43470fe4b2fc2571b9f5c7a'), '');
  if (!(await BackendlessUI.Functions.Custom['fn_d89644da099a68b1ba14d478acbdd215'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Changes saved', 'green', 2000);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/3b1f7e5cdb71518224f4bc16b9df1dcd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    // Show error if end time is not larger than start time.

  return (___arguments.context.dataModel[0] * 100 + ___arguments.context.dataModel[1] >= ___arguments.context.dataModel[2] * 100 + ___arguments.context.dataModel[3])

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/5665a96c9c0e5397173de7151eede6e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3')['availabilityTab'] = 'weekly';

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/05f5591d764eca440c73ac8ca555b259/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3')['availabilityTab'] = 'weekly';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'availabilityTab')) == 'weekly') {
    return ['tab', 'current'];
  }

  return ['tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/d99c1965f5970a63459b8c491aee00e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3')['availabilityTab'] = 'daily';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'availabilityTab')) == 'daily') {
    return ['tab', 'current'];
  }

  return ['tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/fa9c5811f0d892e32b04061679de29e8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'availabilityTab')) == 'daily')

  },
  /* handler:onDisplayAssignment */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['hrOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'hrOptions'));
  ___arguments.context.dataModel['minOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'minOptions'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/5672a7525c2a6ecf6708939dc089d3e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // FAB creates a new service is user is on "services" tab
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'services') {
    ___arguments.context.pageData['serviceModal'] = ({ [`open`]: true,[`tab`]: 'first',[`active`]: true });
  }
  // FAB creates a new appointment is user is on "appointments" tab
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'appointments') {
    // FAB is disabled if teacher doesn't have any services — because making an appointment requires a service.
    if (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'servicesView'))[0]), 'empty')) {
      await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'You need to have at least 1 service set up in order to book an appointment!', 'red', null);
    } else {
      ___arguments.context.pageData['appointmentModal'] = ({ [`open`]: true,[`tab`]: 'first' });
    }
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// only shows to teachers of course
// can create new service or new appointment

  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule' && ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'services' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'appointments'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/e18f8ad58bcb1395e22bf94d28732175/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['dayOverride'] = ({ [`override_objectId`]: null,[`datetime`]: null,[`intervals`]: [[9, 0, 17, 0]] });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/bd082d07a5b737381001126a3183e0b6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('bd082d07a5b737381001126a3183e0b6')), 'button-loading-dark');
    await Backendless.Data.of('availability_overrides').remove( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c2618a64089fdade563d0668353db16f'), 'override_objectId')) );
    removeItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), ___arguments.context.getComponentDataStoreByUid('c2618a64089fdade563d0668353db16f'), 'override_objectId');

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Something went wrong: couldn\'t delete this day from overrides', 'red', null);
    console.log(error);

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('bd082d07a5b737381001126a3183e0b6')), 'button-loading-dark', '');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/2a062a985827ac063f3d33d7be16b1ad/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/6a57f1efb04e7786ec58b528e9c96192/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3f0acb2772810a5a4187351598432553'), 'intervals')))) ? !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('3f0acb2772810a5a4187351598432553'), 'intervals')).length : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/fd121232cae73335cc77dedd82d1343a/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      // Decide if user needs am/pm time or not
  if ((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 == null) {
    return ([___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[0],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[1]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[1])))].join(''));
  } else {
    return ([___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[0] > 12 ? ___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[0] - 12 : ___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[0],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[1]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[1]))),___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[0] < 12 ? ' am' : ' pm'].join(''));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/efcae95b729aeb3990574141bfdb95a1/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      // Decide if user needs am/pm time or not
  if ((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 == null) {
    return ([___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[2],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[3]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[3])))].join(''));
  } else {
    return ([___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[2] > 12 ? ___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[2] - 12 : ___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[2],':',String((JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[3]))).length > 1 ? '' : '0') + String(JSON.stringify((___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[3]))),___arguments.context.getComponentDataStoreByUid('013fe007e0a8436e503abd72f4d52d92')[2] < 12 ? ' am' : ' pm'].join(''));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/27098526533d91df9e4ac4ad74e1be45/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9598396658669b09245a60a46c1ec21b', false);
  // If the override already exists (same date as the
  // one chosen by date picker), we load that item from
  // Page Data, our create will turn into an edit.
  if ((await Promise.all((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')).map(async item => {; return ((new Date((getObjectProperty(item, 'datetime')))).toDateString());}))).includes(((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'datetime')))).toDateString()))) {
    Object.assign(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), async (item) => {


     return (((new Date((getObjectProperty(item, 'datetime')))).toDateString()) == ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'datetime')))).toDateString()));
    }))[0]));
  }

  },
  /* handler:onChange */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'datetime')) === null) ? 'Pick a date' : 'Date')

  },
  /* handler:onLabelAssignment */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
      return (new Date())

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/519fd517504dea06efd68a8a93398666/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('930791cdcbd77c6a9ea84fc5a2e4accf');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4062a5f8488c196967b1e2d4ac11ca88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var lastHour, firstHour;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'intervals')).length) {
    firstHour = 9;
    lastHour = 17;
  } else {
    firstHour = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'intervals')).slice(-1)[0][2] + 1;
    lastHour = firstHour + 1;
  }
  if (firstHour >= 22) {
    firstHour = 0;
    lastHour = 0;
  }
  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'intervals')), [[firstHour, 0, lastHour, 0]]);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/45e2c7d138a4f072304282e518a64a1e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'intervals')))) ? !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'intervals')).length : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/8d61c5140e314f117daf791a9a1daa74/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // We remove the interval from the list of intervals.
  removeItemInList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5f67196c60f94ae40026f4cfc98249a3'), 'intervals')), ___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4'), '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/7606526bb91c148502124384a4784b3d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    // Show error if end time is not larger than start time.

  return (___arguments.context.dataModel[0] * 100 + ___arguments.context.dataModel[1] >= ___arguments.context.dataModel[2] * 100 + ___arguments.context.dataModel[3])

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/b1e6df56811d2069297a8eb24f0fccf2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[0] = ___arguments.value;
  ___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[2] = ___arguments.value + 1;

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fa9c5811f0d892e32b04061679de29e8'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[0])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/9896414327a19c4d77d8f27136ea8b52/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[1] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fa9c5811f0d892e32b04061679de29e8'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[1])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/aa9832557fcfb05a2ca91fe2f81df36d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[2] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fa9c5811f0d892e32b04061679de29e8'), 'hrOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[2])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/41f5114f21699f81e775f56619445067/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[3] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fa9c5811f0d892e32b04061679de29e8'), 'minOptions'))

  },
  /* handler:onOptionsAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
      return (___arguments.context.getComponentDataStoreByUid('f3f9dfaffc0a475d798aab37d53951c4')[3])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/50bef547f01634143bd73bf9c115acc9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('930791cdcbd77c6a9ea84fc5a2e4accf');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/5f67196c60f94ae40026f4cfc98249a3/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['hrOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'hrOptions'));
  ___arguments.context.getPodDataModelByUid('6b6d2b798c040e21fdcbde3fe11b859f')['minOptions'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_a5a3eec897d959b6c72285df27d8907f'](5)), 'minOptions'));

  },
  /* handler:onBeforeMount */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'dayOverride'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, item, cleanOverrideObj, overrideObj, newOverrideObj, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  error = false;
  // User has not chosen a date... show error text.
  if ((getObjectProperty(___arguments.context.dataModel, 'datetime')) === null) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9598396658669b09245a60a46c1ec21b', true);
    error = true;
  }
  if (!!(getObjectProperty(___arguments.context.dataModel, 'intervals')).length) {
    var j_list = (getObjectProperty(___arguments.context.dataModel, 'intervals'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      // If error is already true, we keep it. Otherwise, set it to our test result.
      // Show error if end time is not larger than start time.
      error = error ? true : j[0] * 100 + j[1] >= j[2] * 100 + j[3];
    }
  }
  if (!error) {
    try {
      addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('d464fdcdbd778b279c947d347a8c5648')), 'button-loading');
      // basically just rename override_objectId to objectId for subsequent DeepSave.
      overrideObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'override_objectId')),[`datetime`]: (getObjectProperty(___arguments.context.dataModel, 'datetime')),[`intervals`]: (getObjectProperty(___arguments.context.dataModel, 'intervals')) });
      // Deep save lets us create and relate an availability_overridesobject if there was none before. If there is one already, this just upserts the existing availability_overrides object with new interval.
      newOverrideObj = (getObjectProperty((await Backendless.Data.of('profiles').deepSave( ({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`availability_overrides`]: [overrideObj] }) )), 'availability_overrides'))[0];
      newOverrideObj['override_objectId'] = (getObjectProperty(newOverrideObj, 'objectId'));
      cleanOverrideObj = ({ [`override_objectId`]: (getObjectProperty(newOverrideObj, 'override_objectId')),[`datetime`]: (getObjectProperty(newOverrideObj, 'datetime')),[`intervals`]: (getObjectProperty(newOverrideObj, 'intervals')) });
      // If user is just editing a day, we replace the item
      // in App Data with the newly updated override day.
      if (typeof (getObjectProperty(overrideObj, 'objectId')) === 'string') {
        replaceItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), cleanOverrideObj, 'override_objectId');
      } else {
        // else the override day is new. Have to add to
        // our array in App Data and re-sort by date.
        (getObjectProperty(___arguments.context.appData, 'teacherObjects'))['availability_overrides'] = (await asyncListSort((addItemToList((getObjectProperty(___arguments.context.appData, 'teacherObjects.availability_overrides')), cleanOverrideObj)), 1, async (item) => {


         return (getObjectProperty(item, 'datetime'));
        }));
      }

    } catch (error) {
      await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Something went wrong: couldn\'t save the override data!', 'red', null);
      console.log(error);

    } finally {
      removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('d464fdcdbd778b279c947d347a8c5648')), 'button-loading', '');
      delete ___arguments.context.pageData['dayOverride'];

    }
  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/930791cdcbd77c6a9ea84fc5a2e4accf/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'dayOverride')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'dayOverride'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'dayOverride')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['dayOverride'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/3f0acb2772810a5a4187351598432553/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['dayOverride'] = ___arguments.context.dataModel;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/b08749d53a0230ce0c96748bf75f7e42/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If empty: true exists (set on "on mount" of repeater), we show the empty state content.

  return (getObjectProperty(___arguments.context.dataModel, 'empty'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = ['fadeIn', null];
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    addItemToList(classList, 'green');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return 'You can offer various services for private bookings. Click the \'+\' to create a service!';
  }

  return 'There are no services available'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/80f20dfcf253e5a43f97ca57b11126f4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'unmountPrivateScrolly')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4a56d2d45bf1dc153548de6d91cae3fb'), 'scrollyTab')) == 'Privates')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/e8813c1a700faf24fa5932ac12e7eb7a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'empty'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = ['fadeIn', null];
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    addItemToList(classList, 'green');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return 'Boost your business by offering passes and memberships. Click the \'+\' to get started!';
  }

  return 'There are no packs available'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/13d519e4994321982c95e0d17df51c81/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return 'You don\'t have any upcoming appointments.';
  }

  return 'You don\'t have any upcoming appointments'

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classList = ['fadeIn', null];
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    addItemToList(classList, 'green');
  }

  return classList

  },
  /* handler:onClassListAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If empty: true exists (set on "on mount" of repeater), we show the empty state content.

  return (getObjectProperty(___arguments.context.dataModel, 'empty'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/7faf54d771565703394b660e20a3e8b7/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['futureAppointmentsData'] = ({ [`items`]: [] });

  },
  /* handler:onBeforeMount */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var query;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * If component is on student Home page, we get all
 * instances of all teachers followed by the student. If
 * we're on a teachers public page, we get the classes for
 * that teacher. Otherwise, we're on Schedule and we get
 * the classes of the user (who is the controlling teacher)
 */
async function buildAppointmentsQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    // Query to get all appointments where the user is the client
    query = ['student_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime >',(new Date((new Date())).getTime())].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    // Query to get all future appointments where I am the teacher
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime >',(new Date((new Date())).getTime())].join('');
  } else {
    // Query to get all future appointments where user is the client and teacher is indicated by the profile_url of Page Data.
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\' AND student_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime >',(new Date((new Date())).getTime())].join('');
  }
  return query
}


  await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('down', 'appointmentsScrolly', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('9cfcdb9ffc0e3ff7b024ff865e7125c3')), (getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData')), ({ [`tableName`]: 'appointments_view',[`whereClause`]: await buildAppointmentsQuery(),[`properties`]: undefined,[`sortBy`]: 'start_datetime asc',[`pageSize`]: 25 }), null, null);
  // Some delay to try and ensure we have results from the database. 1 sec not enough.
  await new Promise(r => setTimeout(r, 2000 || 0));
  // If there are no future classes, we set an item
  // in the classes array: emtpy: true, this sets
  // visibility of class card and empty state paragraph.
  if (!(getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData.items')).length) {
    (getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData.items'))[0] = ({ [`empty`]: true });
  }

  },
  /* handler:onMounted */
  /* handler:onBeforeUnmount */
  async ['onBeforeUnmount'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('destroy', 'appointmentsScrolly', null, null, null, null, null);

  },
  /* handler:onBeforeUnmount */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData')) == null ? [] : (getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData.items')))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/4358327ea88a9f0ba08669797bf3e669/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData.items'))[0]), 'empty')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/b1f94ec2cbc5eaf46f7a43ffe3a9a312/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['pastAppointmentsData'] = ({ [`items`]: [] });

  },
  /* handler:onBeforeMount */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var query;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * If component is on student Home page, show past
 * appointments where client is user. If we're on a teachers
 * public page, we get the appointments for that teacher
 * where client was user. Otherwise, we're on Schedule
 * and we get the appointments where user is the teacher.
 */
async function buildAppointmentsQuery() {
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    // Query to get all class instances from all the teachers the user follows...
    query = ['student_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime <',(new Date((new Date())).getTime())].join('');
  } else if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    query = ['teacher_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime <',(new Date((new Date())).getTime())].join('');
  } else {
    query = ['teacher_url = \'',(getObjectProperty(___arguments.context.pageData, 'profile_url')),'\' AND student_objectId = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'\' AND end_datetime <',(new Date((new Date())).getTime())].join('');
  }
  return query
}


  await BackendlessUI.Functions.Custom['fn_6cf4ed1f3196bfda722ecad5828f16b9']('up', 'appointmentsScrolly', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('9cfcdb9ffc0e3ff7b024ff865e7125c3')), (getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData')), ({ [`tableName`]: 'appointments_view',[`whereClause`]: await buildAppointmentsQuery(),[`properties`]: undefined,[`sortBy`]: 'start_datetime desc',[`pageSize`]: 10 }), null, null);

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1f94ec2cbc5eaf46f7a43ffe3a9a312'))['display'] = '';

  return ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1f94ec2cbc5eaf46f7a43ffe3a9a312'))

  },
  /* handler:onStyleAssignment */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData')) == null ? [] : (getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData.items')))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/262eaa948b4110934fad5edca136b902/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var prevItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4358327ea88a9f0ba08669797bf3e669'), 'idx')) > 1) {
    prevItem = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData')), 'items'))[(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4358327ea88a9f0ba08669797bf3e669'), 'idx')) - 1) - 1)];
    if (prevItem !== null && !Array.isArray(prevItem) && (typeof prevItem === 'object')) {
      if ((new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4358327ea88a9f0ba08669797bf3e669'), 'start_datetime')))) == (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(prevItem, 'start_datetime'))))) {
        return false;
      }
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/9cfcdb9ffc0e3ff7b024ff865e7125c3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If this property exists and is true (happens on Schedule
  // page when teacher creates or edits a new template on
  // "submit"), we turn off visibility which unmounts all the
  // containers that have classes. When the property is deleted,
  // visibility is re-established and class containers re-mount.
  if (getObjectProperty(___arguments.context.pageData, 'unmountAppointmentsScrolly')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9f96397d5261a2397b59386a71b3c2a3'), 'currentTab')) == 'appointments')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/701f9c4875d7e448e9dd9337b0adee28/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4358327ea88a9f0ba08669797bf3e669'), 'start_datetime'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/e30f843e311d44596a4916e38bb6e001/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var prevItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('b50426174647cfe76c551ba37be3442b'), 'idx')) < (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData')), 'items')).length) {
    // Logic is quite different from future classes because indexes
    // run in reverse order (highest idx is 1st item in the array).
    prevItem = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData')), 'items'))[(((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData')), 'items')).length - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('b50426174647cfe76c551ba37be3442b'), 'idx'))) - 1)];
    // just to prevent a crash when prev item is not quite available yet.
    if (prevItem !== null && !Array.isArray(prevItem) && (typeof prevItem === 'object')) {
      // if the days are the same, hide the date block.
      if ((new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('b50426174647cfe76c551ba37be3442b'), 'start_datetime')))) == (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(prevItem, 'start_datetime'))))) {
        return false;
      }
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_008c1bca1f4bd49f065b76b32d3aefb6/logic/cad7865f621691150e82756298b47529/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('b50426174647cfe76c551ba37be3442b'), 'start_datetime'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/c15ae8e8b8859e471fd4d2b3f275239d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/47440504fbccdf8d8cb35454312cf8f3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/0b93a01f5122a6187f673d7d7977ac5d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.rootComponentData, 'pass_valid_period')) === null) ? 'No expiry date' : ['Valid for ',(getObjectProperty(___arguments.context.rootComponentData, 'pass_valid_period')),' days'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/724776116ec3af05a6fba4cc4fc313b7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/822cc603dd3b4cf94b417afda77442cc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Never show the "buy" block on Schedule page, and don't show it if it's a subscription and the user is subscribed.

  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule' && !(((getObjectProperty(___arguments.context.rootComponentData, 'owned')) !== null && !Array.isArray((getObjectProperty(___arguments.context.rootComponentData, 'owned'))) && (typeof (getObjectProperty(___arguments.context.rootComponentData, 'owned')) === 'object')) && (getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/d95d3c019eca91551523e04f8422e6fa/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('d95d3c019eca91551523e04f8422e6fa', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('d95d3c019eca91551523e04f8422e6fa', false);

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/334d3c416c592e9bac29ecd75c2d3d5a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Unfortunately, on Public page we don't get packages from packages_view but from packages_table, so currency is missing. Should fix, on classCard when "register".

  return ([((typeof (getObjectProperty(___arguments.context.rootComponentData, 'currency')) === 'undefined') ? (getObjectProperty(___arguments.context.pageData, 'teacherData.settings.currency')) : (getObjectProperty(___arguments.context.rootComponentData, 'currency'))).slice(-1),(getObjectProperty(___arguments.context.rootComponentData, 'cost')),(getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass' ? '' : String('/') + String(((getObjectProperty(___arguments.context.rootComponentData, 'billing_cycle')).split('ly', ))[0])].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/176bc96a6d7aaf5f60e2d0044a1bf082/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.rootComponentData, 'credits')) + String(' credits'))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'credits')) > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/a1b18d0111342caa4e25aef1aceba62b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/ab26ca491208c458600f7c3f39ab791b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.rootComponentData, 'billing_cycle')) + String(' subscription'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/904699ed346bba92d3b179e96f0edc04/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var packageOwned, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // packagesOwned must be in App Data or else errors.
  if (getObjectProperty(___arguments.context.appData, 'packagesOwned')) {
    // If we're not on 'Schedule', we must be on a
    // consumer facing page (Home, Public, Embed)
    if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
      if (!(getObjectProperty(___arguments.context.rootComponentData, 'active'))) {
        return ['card', 'cancelled'];
      }
    } else {
      // If user has the package and either a) it's a pass and user
      // has one or more credits, or b) user has active subscription,
      // we show the card as light green ("active" subclass of cards)
      if ((getObjectProperty(___arguments.context.rootComponentData, 'owned')) !== null && !Array.isArray((getObjectProperty(___arguments.context.rootComponentData, 'owned'))) && (typeof (getObjectProperty(___arguments.context.rootComponentData, 'owned')) === 'object')) {
        return ['card', 'active'];
      }
    }
  }

  return ['card']

  },
  /* handler:onClassListAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var packageOwned, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  // See if the user owns this package.
  packageOwned = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


   return ((getObjectProperty(item, 'package_object_id')) == (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')));
  }))[0];
  // Add a special object to the packageCard data
  // with the users student_package information.
  if (packageOwned !== null && !Array.isArray(packageOwned) && (typeof packageOwned === 'object')) {
    // don't include cancelled subscriptions and passes with no credits.
    if ((getObjectProperty(packageOwned, 'sub_status')) == 'active' || (getObjectProperty(packageOwned, 'type')) == 'Pass' && (getObjectProperty(packageOwned, 'credits')) >= 1) {
      ___arguments.context.rootComponentData['owned'] = packageOwned;
    }
  }

  },
  /* handler:onMounted */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.rootComponentData, 'currency')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/6c94c485b1132e7a081b3dfb17a3a781/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ['time ', (getObjectProperty(___arguments.context.rootComponentData, 'active')) ? 'white' : 'red ']

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'active')) ? 'Active' : 'Hidden')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/918dba683e0e0a7f755124565633b68d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'active'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('70e17a98155ef5aa6da2929faef4b995', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc7d8b3110088df9f26f89617bb3db36', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3840a5e3aba41d765631e74858d6d34', true);
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/144a45325382454e5d112a81ae1251c2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'active')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('70e17a98155ef5aa6da2929faef4b995', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3840a5e3aba41d765631e74858d6d34', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc7d8b3110088df9f26f89617bb3db36', true);
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/35bca24ee7d3f2e6cfc7d08696bbed8d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Only show this block if the user owns the package
  // and it's relevant (credits or active subscription)
  if (((getObjectProperty(___arguments.context.rootComponentData, 'owned')) !== null && !Array.isArray((getObjectProperty(___arguments.context.rootComponentData, 'owned'))) && (typeof (getObjectProperty(___arguments.context.rootComponentData, 'owned')) === 'object')) && (getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule') {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/5eb4242d39add607004c14597424cb89/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.rootComponentData, 'owned')) !== null && !Array.isArray((getObjectProperty(___arguments.context.rootComponentData, 'owned'))) && (typeof (getObjectProperty(___arguments.context.rootComponentData, 'owned')) === 'object')) {
    return ((getObjectProperty(___arguments.context.rootComponentData, 'owned.type')) == 'Pass' ? (((getObjectProperty(___arguments.context.rootComponentData, 'owned.expiry_date')) === null) ? 'Never expires.' : String('Expires on ') + String(new Intl.DateTimeFormat(undefined, undefined).format((new Date((getObjectProperty(___arguments.context.rootComponentData, 'owned.expiry_date'))))))) : String('Next billing: ') + String(new Intl.DateTimeFormat(undefined, undefined).format((new Date((getObjectProperty(___arguments.context.rootComponentData, 'owned.current_period_end')))))));
  }

  return ''

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/79e2fb8d95e6bd3d94cfe54d0646a6ca/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(___arguments.context.rootComponentData, 'owned.credits')) === null)) {
    return (String(getObjectProperty(___arguments.context.rootComponentData, 'owned.credits')) + String(' credits left'));
  }

  return ''

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/0f713d2229a8527d4bdc5c12bf1acff3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'owned.type')) == 'Subscription')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['cancelSubscription'] = ({ [`packageItem`]: ___arguments.context.rootComponentData });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/a93347da1445b9bd970935db178902e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newStudentPackage, item, is_production, stripe_customer_id, params, cosmetics, tsr_view_obj, error, txResult, packageOwned, createStudentPackageTx, upsertStudentPackageRef, studentPackageObj, package_id, student_id, saveStudentPassTx, student_package_obj, alreadyOwnedPass;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * If user is buying a subscription we do this.
 */
async function buySubscriptionLogic() {
  // To create a subscription, we need stripe_price_id.
  params['stripe_price_id'] = (getObjectProperty(___arguments.context.rootComponentData, 'stripe_price_id'));
  // Forcing the form not to have all sorts of payment options. This is where we might open up things in the future.
  // https://stripe.com/docs/api/subscriptions/create#create_subscription-payment_settings-payment_method_types
  params['payment_method_types'] = ['card'];
  // If user has to pay something, we create stripeObj in Page Data.
  ___arguments.context.pageData['stripeObj'] = ({ [`open`]: true,[`type`]: 'subscription',[`cosmetics`]: cosmetics,[`params`]: params,[`is_production`]: is_production });
  student_id = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'));
  package_id = (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId'));
  // We have to wait for Stripe success. Keep waiting
  // until success is there or stripeObj is destroyed.
  while (((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object')) && !(getObjectProperty(___arguments.context.pageData, 'stripeObj.confirmed'))) {
    await new Promise(r => setTimeout(r, 300 || 0));
  }
  // if user closes payment modal, stop the spinner on the package card.
  if (!((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object'))) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a93347da1445b9bd970935db178902e2')), 'button-loading', '');
  }
  if (getObjectProperty(___arguments.context.pageData, 'stripeObj.confirmed')) {
    // if payment succeeds, we get back things to
    // use to save this object to student_packages
    studentPackageObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'stripeObj.student_package_id')),[`sub_status`]: 'active',[`paid_online`]: true,[`stripe_subscription_id`]: (getObjectProperty(___arguments.context.pageData, 'stripeObj.stripe_subscription_id')),[`current_period_start`]: (getObjectProperty(___arguments.context.pageData, 'stripeObj.current_period_start')),[`current_period_end`]: (getObjectProperty(___arguments.context.pageData, 'stripeObj.current_period_end')) });
    try {
      createStudentPackageTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
      await ( async () => {
        const _uow = createStudentPackageTx;

        // Upsert object in student_packages table with custom objectId.
        upsertStudentPackageRef = (_uow.upsert('student_packages', studentPackageObj).setOpResultId('upsertStudentPackage'));
        // Relate student to student_packages object.
        _uow.setRelation('student_packages', (upsertStudentPackageRef.resolveTo('objectId')), 'student_id', ({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) })).setOpResultId('relateStudentToStudentPackage');
        // Relate the package to student_packages object.
        _uow.setRelation('student_packages', (upsertStudentPackageRef.resolveTo('objectId')), 'package_id', ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')) })).setOpResultId('relatePackageToStudentPackage');

      })();

      txResult = (await createStudentPackageTx.execute());
      if (txResult.isSuccess()) {
        // Update packagesOwned for the user.
        await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
        newStudentPackage = (getObjectProperty((txResult.getResults()), 'upsertStudentPackage.result'));
        // See if the user owns this package.
        packageOwned = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


         return ((getObjectProperty(item, 'package_object_id')) == (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')));
        }))[0];
        ___arguments.context.rootComponentData['owned'] = packageOwned;
        await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'You\'re subscribed!', 'green', null);
      } else {
        console.log('failed to save subscription in student_packages');
        console.log(txResult.getError());
        throw (txResult.getError())
      }

    } catch (error) {
      console.log(error);

    } finally {
      // remount packages in megascrolly
      delete ___arguments.context.pageData['unmountPackageScrolly'];
      // Destroy the stripeObj that might be used in payment form.
      delete ___arguments.context.pageData['stripeObj'];

    }
  }
}

/**
 * If user is buying a pass we do this.
 */
async function buyPassLogic() {
  // For pass, we don't pass object ID. we create stripeObj in Page Data.
  ___arguments.context.pageData['stripeObj'] = ({ [`open`]: true,[`type`]: 'single',[`cosmetics`]: cosmetics,[`params`]: params,[`is_production`]: is_production });
  // We have to wait for Stripe success. Keep waiting
  // until success is there or stripeObj is destroyed.
  while (((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object')) && !(getObjectProperty(___arguments.context.pageData, 'stripeObj.confirmed'))) {
    await new Promise(r => setTimeout(r, 300 || 0));
  }
  // if user closes payment modal, stop the spinner on the package card.
  if (!((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object'))) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a93347da1445b9bd970935db178902e2')), 'button-loading', '');
  }
  // Once payment is confirmed, submit the registration form
  // which registers the student and closes the register modal.
  if (getObjectProperty(___arguments.context.pageData, 'stripeObj.confirmed')) {
    // Prepare student_packages object for saving.
    // set expiry date, unless valid period is null
    student_package_obj = ({ [`paid_online`]: true,[`expiry_date`]: ((getObjectProperty(___arguments.context.rootComponentData, 'pass_valid_period')) === null) ? null : (new Date((new Date()).getTime() + (((getObjectProperty(___arguments.context.rootComponentData, 'pass_valid_period')) * 86400) * 1000))),[`credits`]: (getObjectProperty(___arguments.context.rootComponentData, 'credits')) });
    // See if the user already owns the package. If so,
    // get their existing student_packages object.
    alreadyOwnedPass = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


     return ((getObjectProperty(item, 'package_object_id')) == (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')));
    }))[0];
    // If student already has this pass, we set objectId of existing
    // student_package, and we add credits of the new pass purchase.
    if (alreadyOwnedPass !== null && !Array.isArray(alreadyOwnedPass) && (typeof alreadyOwnedPass === 'object')) {
      // Passes ALWAYS have credits so no "if" logic required.
      // if student already owns the package, we include the objectId in the upsert deepsave to follow.
      // If student already has package, credits they should have is sum of old and new credits.
      Object.assign(student_package_obj, ({ [`objectId`]: (getObjectProperty(alreadyOwnedPass, 'student_package_object_id')),[`credits`]: (getObjectProperty(alreadyOwnedPass, 'credits')) + (getObjectProperty(___arguments.context.rootComponentData, 'credits')) }));
    }
    // If this property exists and is true (happens on Schedule
    // page when teacher creates or edits a package on "submit" or
    // when student buys a package), we turn off visibility which
    // unmounts the containers that have packages. When the property is
    // deleted, visibility is re-established and package containers re-mount.
    ___arguments.context.pageData['unmountPackageScrolly'] = true;
    try {
      saveStudentPassTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
      await ( async () => {
        const _uow = saveStudentPassTx;

        upsertStudentPackageRef = (_uow.upsert('student_packages', student_package_obj).setOpResultId('upsertStudentPackageObj'));
        // Relate student to student_package object.
        _uow.setRelation('student_packages', (upsertStudentPackageRef.resolveTo('objectId')), 'student_id', ({ [`objectId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) })).setOpResultId('relateStudentToStudentPackage');
        // Relate package to student_packages object.
        _uow.setRelation('student_packages', (upsertStudentPackageRef.resolveTo('objectId')), 'package_id', ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')) })).setOpResultId('relatePackageToStudentPackage');
        // Save a revenue object to the revenue_metrics table with cost of the package.
        _uow.create('revenue_metrics', ({ [`source`]: 'package',[`student_profile_Id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`teacher_profile_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`value`]: (getObjectProperty(___arguments.context.rootComponentData, 'cost')) })).setOpResultId('saveToMetrics');

      })();

      txResult = (await saveStudentPassTx.execute());
      if (txResult.isSuccess()) {
        newStudentPackage = (getObjectProperty((txResult.getResults()), 'upsertStudentPackageObj.result'));
        // Update packagesOwned for the user. Have to ask db because
        // it gets data from a view, not the table we saved to.
        await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
        await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'You\'ve got the pass!', 'green', null);
      } else {
        console.log('Failed to save student_package.');
        console.log(txResult.getError());
        throw (txResult.getError())
      }

    } catch (error) {
      console.log(error);

    } finally {
      // remount packages in megascrolly
      delete ___arguments.context.pageData['unmountPackageScrolly'];
      // Destroy the stripeObj that might be used in payment form.
      delete ___arguments.context.pageData['stripeObj'];

    }
  }
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a93347da1445b9bd970935db178902e2')), 'button-loading');
  // If user is not logged in, we ask them to log in (or sign up) first.
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null) {
    ___arguments.context.pageData['currentTab'] = 'second';
    ___arguments.context.pageData['openLoginModal'] = true;
  } else {
    // If user is not yet following teacher, make them follow
    if (!(((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId'))))) {
      await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8'](({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')) }), (getObjectProperty(___arguments.context.appData, 'accountProfile')), null);
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // Extract the TSR object for this teacher & student from App Data.
    tsr_view_obj = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teachersFollowed')), async (item) => {


     return ((getObjectProperty(item, 'teacher_profile_id')) == (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')));
    }))[0];
    // Get stripe_customer_id. Might be null.
    stripe_customer_id = (getObjectProperty(tsr_view_obj, 'stripe_customer_id'));
    // If there is no stripe_customer_id, we create a Stripe customer first.
    if (stripe_customer_id === null) {
      // Get stripe customer id back from Stripe after creating
      stripe_customer_id = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_stripe_customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'teacher_student_rel_id': (getObjectProperty(tsr_view_obj, 'tsr_objectId')),'stripe_account_id': (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')),'teacher_profile_id': (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),'student_profile_id': (getObjectProperty(___arguments.context.appData, 'accountProfile.objectId')),'student_email': (getObjectProperty(___arguments.context.appData, 'accountProfile.skeleton_email')),'student_name': (getObjectProperty(___arguments.context.appData, 'accountProfile.full_name')) })), 'stripe_customer_id'));
    }
    // Will be property in stripeObj, styling the payment form
    cosmetics = ({ [`teacher_thumbnail`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_thumbnail')),[`teacher_name`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_full_name')),[`item_title`]: (getObjectProperty(___arguments.context.rootComponentData, 'title')),[`item_subtitle`]: (getObjectProperty(___arguments.context.rootComponentData, 'description')),[`costString`]: String((getObjectProperty(___arguments.context.rootComponentData, 'currency')).slice(-1)) + String(getObjectProperty(___arguments.context.rootComponentData, 'cost')) });
    // Will be property in stripeObj. Only small difference
    // between signle payment and subscription payment.
    params = ({ [`teacher_profile_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`student_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`stripe_customer_id`]: stripe_customer_id,[`cost`]: (getObjectProperty(___arguments.context.rootComponentData, 'cost')),[`description`]: (getObjectProperty(___arguments.context.rootComponentData, 'title')),[`stripe_account_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')),[`stripe_currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_currency')),[`fee_rate`]: (getObjectProperty(___arguments.context.rootComponentData, 'fee_rate')),[`metadata`]: ({ [`teacher_profile_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`student_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`package_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')) }) });
    // Determines of we pay to Stripe Test environment
    // or real money Stripe Production environment.
    is_production = false;
    if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass') {
      await buyPassLogic();
    } else if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription') {
      await buySubscriptionLogic();
    }
    // If user is buying a package in the context of booking something,
    // we set "userPackageToUse" to immediately update the interface.
    // If user has a package that applies to the class, we show this element.
    if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal')) === 'object')) {
      // If user was trying to book content and bought package
      // in the process, we now make it the packageToUse.
      // newStudentPackage is object from student_packes table... find the equivalent object from student_packages_view
      (getObjectProperty(___arguments.context.pageData, 'bookContentModal'))['userPackageToUse'] = ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


       return ((getObjectProperty(item, 'student_package_object_id')) == (getObjectProperty(newStudentPackage, 'objectId')));
      }))[0]);
    }
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.rootComponentData, 'owned')) !== null && !Array.isArray((getObjectProperty(___arguments.context.rootComponentData, 'owned'))) && (typeof (getObjectProperty(___arguments.context.rootComponentData, 'owned')) === 'object')) && (getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass' ? 'Buy more credits' : 'Buy package')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/5ab419dcbaba20a61af4c94cbfee28a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['packageModal'] = ({ 'open': true,'type': (getObjectProperty(___arguments.context.rootComponentData, 'type')) });
  // We will need "objectId" as property for the packageModal on Schedule page.
  ___arguments.context.rootComponentData['objectId'] = (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId'));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'packageModal')), ___arguments.context.rootComponentData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/fe8c9e16013737edbfdea03db3547911/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/ae59beefb5b5d0a4338d3c37880f9372/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ae59beefb5b5d0a4338d3c37880f9372')), 'button-loading-dark');
  // Have to add title and cost as they are NN constrained, Backendless has a bug with upsert...
  await Backendless.Data.of('packages').save( ({ 'objectId': (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')),'active': false }) );
  ___arguments.context.rootComponentData['active'] = false;
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/9d5f837e1c33a9c38dafceba258a46e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9d5f837e1c33a9c38dafceba258a46e8')), 'button-loading-dark');
  // Have to add title and cost as they are NN constrained, Backendless has a bug with upsert...
  await Backendless.Data.of('packages').save( ({ 'objectId': (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')),'active': true }) );
  ___arguments.context.rootComponentData['active'] = true;
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/93baecfd96bdf1ed32b8f58dfd640bef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/5dd24372907f755e9f9fa5f9447f331e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/7e1248bc48c99c9fb16fe92400f245a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass') {
    console.log(___arguments.context.rootComponentData);
    ___arguments.context.rootComponentData['toDeleteNumber'] = (await Backendless.Data.of('student_packages').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['package_id.type = \'Pass\' AND credits > 0 AND expiry_date > NOW() AND package_id = \'',(getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')),'\''].join('')))));
    console.log(getObjectProperty(___arguments.context.rootComponentData, 'toDeleteNumber'));
  }
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3840a5e3aba41d765631e74858d6d34', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc7d8b3110088df9f26f89617bb3db36', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('70e17a98155ef5aa6da2929faef4b995', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/f40f56bb4af84a0f6cd9c68105e13f97/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var deleteNum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deleteNum = (getObjectProperty(___arguments.context.rootComponentData, 'toDeleteNumber'));
  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass') {
    return (deleteNum == 0 ? 'None of your students have credits on this pass, so it\'s safe to delete.' : [deleteNum,deleteNum == 1 ? ' person has ' : ' people have ','this pass with some credits remaining. If you completely delete the package, their credits will be deleted.'].join(''));
  } else if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription') {
    return (deleteNum == 0 ? 'None of your students are subscribed to this membership, so it\'s safe to delete.' : [deleteNum,deleteNum == 1 ? 'person is ' : 'people are ','subscribed to this package. If you delete it, they will all be cancelled.'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/24c5284d71fd95cc12db4f769f07655d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var deleteNum;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('24c5284d71fd95cc12db4f769f07655d')), 'button-loading-dark');
  deleteNum = (getObjectProperty(___arguments.context.rootComponentData, 'toDeleteNumber'));
  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass') {
    // Only bother to update student_packages if there are students with credits and future expiry date.
    if (deleteNum != 0) {
      // Setting credits to 0 for any students who
      // have this pass and still have some credits.
      await Backendless.Data.of('student_packages').bulkUpdate( (['package_id.type = \'Pass\' AND credits > 0 AND package_id = \'',(getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')),'\''].join('')), ({ [`credits`]: 0 }) );
    }
  } else if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription') {
    // Only bother to update student_packages if there are students with credits and future expiry date.
    if (deleteNum != 0) {
      console.log('have to cancel student subscriptions');
    }
  }
  // Take care of teacherObjects App Data to remove deleted package.
  // Upsert the package to be marked as deleted.
  removeItemInList((getObjectProperty(___arguments.context.appData, 'teacherObjects.packages')), (await Backendless.Data.of('packages').save(({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')),[`deleted`]: true }), true)), 'objectId');
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('24c5284d71fd95cc12db4f769f07655d')), 'button-loading-dark', '');
  // Closes modal
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd84a86589cf21be5dbdaaaa90283b86')), 'open', '');
  // Unmount container with packages
  ___arguments.context.pageData['unmountPackageScrolly'] = true;
  await new Promise(r => setTimeout(r, 300 || 0));
  // Remount container with packages
  delete ___arguments.context.pageData['unmountPackageScrolly'];
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Package deleted!', 'green', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/a534340140f56e6d4e7239a150997d59/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var cancelledSubscription;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a534340140f56e6d4e7239a150997d59')), 'button-loading-dark');
  cancelledSubscription = (await Backendless.Request.delete(`${Backendless.appPath}/services/stripe/cancel_student_subscription`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'student_package_obj': ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'packageItem.owned.student_package_object_id')),[`stripe_subscription_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'packageItem.owned.stripe_subscription_id')) }),'stripe_account_id': (getObjectProperty(___arguments.context.rootComponentData, 'packageItem.stripe_account_id')) }));
  delete (getObjectProperty(___arguments.context.rootComponentData, 'packageItem'))['owned'];
  delete ___arguments.context.pageData['cancelSubscription'];
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (['Your subscription "',(getObjectProperty(___arguments.context.rootComponentData, 'packageItem.title')),'" is cancelled — you won\'t be billed anymore.'].join('')), 'green', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/9e1a08c1cb33c779680fa4a18a7f4a5d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['cancelSubscription'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_de63261b1c27dfa2c945c4d3d58a190e/logic/1127d2316f7f6d2c630608663a633dfb/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'cancelSubscription')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'cancelSubscription'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'cancelSubscription')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['cancelSubscription'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/099103482d397396f73244845aa9392a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // deleting instance deletes in the database but only toggles visibility on the page.
  if (getObjectProperty(___arguments.context.rootComponentData, 'deleted')) {
    return false;
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/d779b955113b70e3934fda8131d79323/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'is_online')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/d1d6d4e1a0ef9a4cbe4465c60718d5d1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only show this block when appointment end time is not in the past.

  return ((new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) >= (new Date()))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/f0494dbf2f5b123e3b68136129353992/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// show checkin block after t = 15 mins before class start time. When class is finished, the entire class card goes into the past where the query should take care of "did its". But we might as well also set logic to hide block when class is over.
// and only if class has not yet finished....
// Only show for online classes.
  // Don't show register or checkin on teachers Schedule page
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return false;
  }

  return ((new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (900 * 1000))) <= (new Date()) && (new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) > (new Date()) && (getObjectProperty(___arguments.context.rootComponentData, 'is_online')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Send user to link for the online class.
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.rootComponentData, 'link')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/8b6ca2182aedddba3b02ffa83419f03e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Send user to link for the online class.
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.rootComponentData, 'link')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/0132e07afae4675eaaa57034bf8f8522/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('0132e07afae4675eaaa57034bf8f8522', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('0132e07afae4675eaaa57034bf8f8522', false);

  },
  /* handler:onMouseOver */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only show ellipses to teacher on Schedule page.

  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/4c48a26db442e12dbbdc8e42285e23dd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')) === 'undefined') ? '' : [(await (async function(datetime) {

	//removes seconds so we only show hours and minutes
	let result = datetime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit"});
	return (result)
})((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))))),' - ',(await (async function(datetime) {

	//removes seconds so we only show hours and minutes
	let result = datetime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit"});
	return (result)
})((new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime'))))))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/fbe9ebd3cd7811a89e57009967c2a0f2/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'TV' : 'pin drop')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/8714bd5469a949fc3c79c87a00795fb2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This block only shows on Schedule page.
  if (getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/74fe277eaac1c2dabc4f56be9e2abc52/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This block shows for any page student sees (not Schedule page)page.
  if (getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/11018d1d1ca5fde645c2fc3d5fee44c3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ('donation' == (getObjectProperty(___arguments.context.rootComponentData, 'pricing'))) {
    return 'DONATION';
  } else if (0 == (getObjectProperty(___arguments.context.rootComponentData, 'cost'))) {
    return 'FREE';
  } else {
    return (String((typeof (getObjectProperty(___arguments.context.rootComponentData, 'currency')) === 'string') ? (getObjectProperty(___arguments.context.rootComponentData, 'currency')).slice(-1) : '$') + String((await (async function(value) {
    	// format number to have 2 decimal places
    	if (typeof value !== 'number') {
    	  value = 0
    	}

    	return value.toFixed(2)
    })((getObjectProperty(___arguments.context.rootComponentData, 'cost'))))));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/93a22737c05aace6e4e25ce25541bad9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Don't show to teachers on manage Schedule page, and never show for future classes. The class card itself for past classes is only shown to users who attended, that's set in MegaScrolly logic.

  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule' && (new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) < (new Date()))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/9737a8bc3a5b7184be93124bd0796608/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.rootComponentData['operation'] = 'editAppointment';
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('422592c941ffe6547d07ed781de47af5')), 'open');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/134d4c05de4151bf26fed798bab2bb9a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.rootComponentData['operation'] = 'deleteAppointment';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/f688e2f7a6bdb19d3bcc9d5e9912b631/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('551a8d0c9916bc025943210319d1caf6')), 'open');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/3d1a2ad5a411501cb54b33585e02d1d2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Do you really want to cancel the appointment with ',(getObjectProperty(___arguments.context.rootComponentData, 'student_full_name')),' for ',(getObjectProperty(___arguments.context.rootComponentData, 'title')),' on ',((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).toLocaleDateString()),'? They won\'t be notified — it’s up to you to make sure they know!'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/aa3aa4fbf4439b59e1356dcb2cb10a44/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('422592c941ffe6547d07ed781de47af5')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/7cae2b27a085bb818b3331c7a8997852/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/8b3778f9f0d4c08e684d84bfa8a53371/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'deleteAppointment')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/8035ed035d89f6c1128eb34cd7711d0a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'editAppointment')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/345cbceb3c67cb1b9df5695b7d841b96/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var am_pm, appointmentModalObj, hour, minutes, start_minuteTime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function displayTime(am_pm, hour, minutes) {
  if (am_pm) {
    return ([hour > 12 ? hour - 12 : hour,':',String((JSON.stringify(minutes)).length > 1 ? '' : '0') + String(JSON.stringify(minutes)),hour < 12 ? ' am' : ' pm'].join(''));
  }
  return [hour,':',String((JSON.stringify(minutes)).length > 1 ? '' : '0') + String(JSON.stringify(minutes))].join('')
}


  // clean up and remove operation property of appointmentCard data.
  delete ___arguments.context.rootComponentData['operation'];
  console.log('appointmentCard data is:');
  console.log(___arguments.context.rootComponentData);
  start_minuteTime = (new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime'))))).getHours()) * 60 + (new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime'))))).getMinutes());
  // Affects visibility of "next" vs "Confirm" button on Modal.
  // Decide if user needs am/pm time or not
  appointmentModalObj = ({ [`move`]: true,[`appointment_objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'appointment_objectId')),[`tab`]: 'second',[`serviceObj`]: ({ [`title`]: (getObjectProperty(___arguments.context.rootComponentData, 'title')),[`length`]: Math.round(((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')) - (getObjectProperty(___arguments.context.rootComponentData, 'start_datetime'))) / 60000) }),[`date`]: (new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))),[`start_minuteTime`]: start_minuteTime,[`end_minuteTime`]: (new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime'))))).getMinutes()),[`niceTime`]: await displayTime((JSON.stringify(((new Date()).toLocaleTimeString()))).indexOf('M') + 1 != null, Math.floor(start_minuteTime / 60), start_minuteTime % 60) });
  console.log('sending to appointmentModal in Page Data:');
  console.log(appointmentModalObj);
  // The presence of appojntmentModal as object in Page Data
  // triggers "createAppointmentMModal" on the Schedule page.
  ___arguments.context.pageData['appointmentModal'] = appointmentModalObj;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/422592c941ffe6547d07ed781de47af5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.rootComponentData, 'operation')) === 'undefined')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/9006bab95be193ccc85aa28c9ffe34c5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
      return false

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var classRegListItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If we're within the teacher's cancel period, we don't let user cancel. Instead we show them a toastr message.
  // If cancel_period is null or not a number in any way, we use 15 minutes to be the default cancel period
  if ((new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (((typeof (getObjectProperty(___arguments.context.rootComponentData, 'cancel_period')) === 'number' && !isNaN((getObjectProperty(___arguments.context.rootComponentData, 'cancel_period')))) ? (getObjectProperty(___arguments.context.rootComponentData, 'cancel_period')) * 60 : 900) * 1000))) <= (new Date())) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Sorry, it\'s too late to cancel your booking.', 'red', null);
  } else {
    // Open cancel modal and populate property
    // "appointmentCancelModal" in the appointmentCardData.
    ___arguments.context.pageData['bookContentModal'] = ({ [`type`]: 'cancelAppointment',[`going`]: true,[`student_package_used`]: (getObjectProperty(___arguments.context.rootComponentData, 'student_package_used')),[`paid_online`]: (getObjectProperty(___arguments.context.rootComponentData, 'paid_online')),[`content`]: ___arguments.context.rootComponentData });
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/c5d7bf2beb2acbf369f27f434c0da001/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'TV' : 'pin drop')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/a6e7698b357354082ded7d672645d8e6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'ONLINE' : (getObjectProperty(___arguments.context.rootComponentData, 'location_title')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/e88b7c99926214c2455da912e0786b87/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'address'))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'is_online')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/e3d454ef45b58b7dc9fb7bddcfa43a83/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.rootComponentData, 'description')) === null) || (typeof (getObjectProperty(___arguments.context.rootComponentData, 'description')) === 'undefined') ? 'This class has no description.' : (getObjectProperty(___arguments.context.rootComponentData, 'description')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/6f8f14bff6a82027eeeedf079ebc5773/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/5857712216e2066da2c6b2e19ad3af9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('551a8d0c9916bc025943210319d1caf6')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/cacd36e4789a8afa03defc7ceeecb181/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var deleteAppointmentTx, error, item, itemArray, j, n, studentPackageObj, toastrText, txResult, upsertStudentPackageRef;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('cacd36e4789a8afa03defc7ceeecb181')), 'button-loading-dark');
  if ((getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData.items')).includes(___arguments.context.rootComponentData)) {
    itemArray = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastAppointmentsData')), 'items'));
  } else {
    itemArray = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'futureAppointmentsData')), 'items'));
  }
  toastrText = 'Appointment deleted.';
  try {
    console.log('still have to give credit back to student if appropriate');
    studentPackageObj = null;
    // if student_package_used exists and "credits" is a number,
    // we will have to add a credit to the student package object.
    if (typeof (getObjectProperty(___arguments.context.rootComponentData, 'student_package_used')) === 'string') {
      // Only bother to give a credit back if credits is a thing in the student_package.
      if (typeof (getObjectProperty(___arguments.context.rootComponentData, 'student_package_used_credits')) === 'number' && !isNaN((getObjectProperty(___arguments.context.rootComponentData, 'student_package_used_credits')))) {
        studentPackageObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'student_package_used')),[`credits`]: (getObjectProperty(___arguments.context.rootComponentData, 'student_package_used_credits')) + 1 });
      }
    }
    // transaction now deletes appointment and, if
    // applicable, adds credit back to student package.
    deleteAppointmentTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async () => {
      const _uow = deleteAppointmentTx;

      // delete the appointment in the data base
      _uow.delete('appointments', (getObjectProperty(___arguments.context.rootComponentData, 'appointment_objectId'))).setOpResultId('deleteAppointmentObj');
      if (!(studentPackageObj === null)) {
        console.log('should warn teacher if paid online is true that they owe the student money.');
        // If student is supposed to get a credit back, upsert object
        // in student_packages table with a credit added back.
        upsertStudentPackageRef = (_uow.upsert('student_packages', studentPackageObj).setOpResultId('upsertStudentPackage'));
        toastrText = String(toastrText) + String(' A credit was added back to the students\' package.');
      } else if (getObjectProperty(___arguments.context.rootComponentData, 'paid_online')) {
        toastrText = String(toastrText) + String(' The client was not refunded their payment, it\'s up to you to re-schedule or refund them on Stripe.');
      }

    })();

    txResult = (await deleteAppointmentTx.execute());
    if (txResult.isSuccess()) {
    } else {
      console.log('Failed to delete appointment. Error is:');
      console.log(txResult.getError());
      throw (txResult.getError())
    }
    // delete the class instances from the list in our Data Model.
    // So we don't have to reload appointments in MegaScrolly.
    removeItemInList(itemArray, ___arguments.context.rootComponentData, 'appointment_objectId');
    n = 1;
    // Have to re-index the list or else the date display doesn't work anymore.
    for (var j_index in itemArray) {
      j = itemArray[j_index];
      j['idx'] = n;
      n = (typeof n == 'number' ? n : 0) + 1;
    }
    // clean up and remove operation property of classCard data.
    delete ___arguments.context.rootComponentData['operation'];
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, toastrText, 'green', null);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/233849bdd22365dc09047e6a8eec8d1c/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return (getObjectProperty(___arguments.context.rootComponentData, 'student_full_name'));
  }
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    return (getObjectProperty(___arguments.context.rootComponentData, 'teacher_full_name'));
  }

  },
  /* handler:onTextAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Public')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var resultTSR, TSR_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ [`profile_url`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_url')) }));
  }
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    resultTSR = (await Backendless.Data.of('teacher_student_rels').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_profile_id = \'',(getObjectProperty(___arguments.context.rootComponentData, 'student_objectId')),'\' AND teacher_profile_id = \'',(getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),'\''].join(''))).setPageSize(1)))[0];
    // To navigate to the student detail page we need the TSR
    // objectId. Cannot be put into view (because there can be
    // more than one TSR objects for a particular student), so
    // To get TSR object ID we have to ask database with a query
    TSR_objectId = (resultTSR === null) || (typeof resultTSR === 'undefined') ? null : (getObjectProperty(resultTSR, 'objectId'));
    // Edge case: if student is no longer following teacher we have to
    // make them follow again so teacher can access StudentDetail page
    if (!(typeof TSR_objectId === 'string')) {
      TSR_objectId = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8'](({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')) }), ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'student_objectId')) }), null)), 'objectId'));
    }
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('StudentDetail', ({ [`TSR_objectId`]: TSR_objectId }));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/37ba51ec1cdba2f3b818bb7447d6c4ba/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/baf35152226f7c820a841d434b940158/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Public')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_bebd677b420361a8511971190c854eb1/logic/7895c6080f7e35bdd77b6cb6302e7168/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/47440504fbccdf8d8cb35454312cf8f3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/822cc603dd3b4cf94b417afda77442cc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/d95d3c019eca91551523e04f8422e6fa/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('d95d3c019eca91551523e04f8422e6fa', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('d95d3c019eca91551523e04f8422e6fa', false);

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/334d3c416c592e9bac29ecd75c2d3d5a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.rootComponentData, 'currency')).slice(-1)) + String(getObjectProperty(___arguments.context.rootComponentData, 'cost')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/904699ed346bba92d3b179e96f0edc04/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Inactive services get "cancelled" class (transparent background)
  if (!(getObjectProperty(___arguments.context.rootComponentData, 'active'))) {
    return ['card', 'cancelled'];
  }

  return ['card']

  },
  /* handler:onClassListAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.rootComponentData, 'currency')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/6c94c485b1132e7a081b3dfb17a3a781/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ['time ', (getObjectProperty(___arguments.context.rootComponentData, 'active')) ? 'white' : 'red ']

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'active')) ? 'Active' : 'Hidden')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/918dba683e0e0a7f755124565633b68d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'active'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('23074914b5227dc1290fa51fbda6bd7a');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3840a5e3aba41d765631e74858d6d34', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/144a45325382454e5d112a81ae1251c2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'active')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('23074914b5227dc1290fa51fbda6bd7a');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc7d8b3110088df9f26f89617bb3db36', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/a93347da1445b9bd970935db178902e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newStudentPackage, item, is_production, stripe_customer_id, params, cosmetics, tsr_view_obj, error, txResult, packageOwned, createStudentPackageTx, upsertStudentPackageRef, studentPackageObj, package_id, student_id, saveStudentPassTx, student_package_obj, alreadyOwnedPass;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a93347da1445b9bd970935db178902e2')), 'button-loading');
  // If user is not logged in, we ask them to log in (or sign up) first.
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null) {
    ___arguments.context.pageData['currentTab'] = 'second';
    ___arguments.context.pageData['openLoginModal'] = true;
  } else {
    // If user is not yet following teacher, make them follow
    if (!(((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId'))))) {
      await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8'](({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')) }), (getObjectProperty(___arguments.context.appData, 'accountProfile')), null);
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // Extract the TSR object for this teacher & student from App Data.
    tsr_view_obj = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teachersFollowed')), async (item) => {


     return ((getObjectProperty(item, 'teacher_profile_id')) == (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')));
    }))[0];
    // Get stripe_customer_id. Might be null.
    stripe_customer_id = (getObjectProperty(tsr_view_obj, 'stripe_customer_id'));
    // If there is no stripe_customer_id, we create a Stripe customer first.
    if (stripe_customer_id === null) {
      // Get stripe customer id back from Stripe after creating
      stripe_customer_id = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_stripe_customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'teacher_student_rel_id': (getObjectProperty(tsr_view_obj, 'tsr_objectId')),'stripe_account_id': (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')),'teacher_profile_id': (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),'student_profile_id': (getObjectProperty(___arguments.context.appData, 'accountProfile.objectId')),'student_email': (getObjectProperty(___arguments.context.appData, 'accountProfile.skeleton_email')),'student_name': (getObjectProperty(___arguments.context.appData, 'accountProfile.full_name')) })), 'stripe_customer_id'));
    }
    // Will be property in stripeObj, styling the payment form
    cosmetics = ({ [`teacher_thumbnail`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_thumbnail')),[`teacher_name`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_full_name')),[`item_title`]: (getObjectProperty(___arguments.context.rootComponentData, 'title')),[`item_subtitle`]: (getObjectProperty(___arguments.context.rootComponentData, 'description')),[`costString`]: String((getObjectProperty(___arguments.context.rootComponentData, 'currency')).slice(-1)) + String(getObjectProperty(___arguments.context.rootComponentData, 'cost')) });
    // Will be property in stripeObj. Only small difference
    // between signle payment and subscription payment.
    params = ({ [`teacher_profile_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`student_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`stripe_customer_id`]: stripe_customer_id,[`cost`]: (getObjectProperty(___arguments.context.rootComponentData, 'cost')),[`description`]: (getObjectProperty(___arguments.context.rootComponentData, 'title')),[`stripe_account_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')),[`stripe_currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_currency')),[`fee_rate`]: (getObjectProperty(___arguments.context.rootComponentData, 'fee_rate')),[`metadata`]: ({ [`teacher_profile_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`student_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`package_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'package_objectId')) }) });
    // Determines of we pay to Stripe Test environment
    // or real money Stripe Production environment.
    is_production = false;
    // If user is buying a package in the context of booking something,
    // we set "userPackageToUse" to immediately update the interface.
    // If user has a package that applies to the class, we show this element.
    if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal')) === 'object')) {
      // If user was trying to book content and bought package
      // in the process, we now make it the packageToUse.
      // newStudentPackage is object from student_packes table... find the equivalent object from student_packages_view
      (getObjectProperty(___arguments.context.pageData, 'bookContentModal'))['userPackageToUse'] = ((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


       return ((getObjectProperty(item, 'student_package_object_id')) == (getObjectProperty(newStudentPackage, 'objectId')));
      }))[0]);
    }
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.rootComponentData, 'owned')) !== null && !Array.isArray((getObjectProperty(___arguments.context.rootComponentData, 'owned'))) && (typeof (getObjectProperty(___arguments.context.rootComponentData, 'owned')) === 'object')) && (getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass' ? 'Buy more credits' : 'Buy package')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/5ab419dcbaba20a61af4c94cbfee28a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['serviceModal'] = ({ [`open`]: true,[`tab`]: 'first' });
  // We will need "objectId" as property for the serviceModal on Schedule page.
  ___arguments.context.rootComponentData['objectId'] = (getObjectProperty(___arguments.context.rootComponentData, 'service_objectId'));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'serviceModal')), ___arguments.context.rootComponentData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/fe8c9e16013737edbfdea03db3547911/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('23074914b5227dc1290fa51fbda6bd7a');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/ae59beefb5b5d0a4338d3c37880f9372/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ae59beefb5b5d0a4338d3c37880f9372')), 'button-loading-dark');
  ___arguments.context.rootComponentData['active'] = (getObjectProperty((await Backendless.Data.of('services').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'service_objectId')),[`active`]: false }) )), 'active'));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('23074914b5227dc1290fa51fbda6bd7a');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/9d5f837e1c33a9c38dafceba258a46e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9d5f837e1c33a9c38dafceba258a46e8')), 'button-loading-dark');
  ___arguments.context.rootComponentData['active'] = (getObjectProperty((await Backendless.Data.of('services').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'service_objectId')),[`active`]: true }) )), 'active'));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('23074914b5227dc1290fa51fbda6bd7a');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/93baecfd96bdf1ed32b8f58dfd640bef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('23074914b5227dc1290fa51fbda6bd7a');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/5dd24372907f755e9f9fa5f9447f331e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('23074914b5227dc1290fa51fbda6bd7a');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/7e1248bc48c99c9fb16fe92400f245a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('23074914b5227dc1290fa51fbda6bd7a');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('70e17a98155ef5aa6da2929faef4b995', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/24c5284d71fd95cc12db4f769f07655d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('24c5284d71fd95cc12db4f769f07655d')), 'button-loading-dark');
  // Upsert the package to be marked as deleted.
  await Backendless.Data.of('services').save(({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'service_objectId')),[`deleted`]: true }), true);
  // Unmount container with packages
  ___arguments.context.pageData['unmountPrivateScrolly'] = true;
  await new Promise(r => setTimeout(r, 300 || 0));
  // Remount container with packages
  delete ___arguments.context.pageData['unmountPrivateScrolly'];
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Service deleted!', 'green', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/ab26ca491208c458600f7c3f39ab791b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.rootComponentData, 'length')) + String(' minutes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/a99c87a9c17cd4bf05d43f353f74ee9d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_c891e0cd50c6e657abe3fc0df4eaa20b/logic/a633e8173035b0b8a05b113f193dac64/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'personal video' : 'pin drop')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/855b8bee62f33e8a334adf9bd52a5562/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  // videoRegList is list of videos that the user has access to, in App Data. If user has access to the video AND attended = not TRUE, we show the big green bar and make card class include "active".
  // Only include videos the user has not yet watched
  if ((((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'videoRegList')), async (item) => {


   return (!(getObjectProperty(item, 'attended')));
  })).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')))) && (getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule') {
    return ['card', 'active'];
  }

  return ['card']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/83fc43b9dd31af6fe1abe4c71dfdf8f7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Ellipses only shown to teacher on their Schedule page.

  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/5c4fb4b1ec43dbac6d9d112647c870b6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'Page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/d884e30bd0f9fcd219f5a55ce5580e27/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'videoRegList')))) {
    // videoRegList is list of videos that the user has access to, in App Data. If user has access to the video AND attended = not TRUE, we show the big green bar.
    // Only include videos the user has already watched
    if (((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'videoRegList')), async (item) => {


     return (getObjectProperty(item, 'attended'));
    })).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')))) {
      return true;
    }
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


// videoRegList is list of videos that the user has access to, in App Data. If user has access to the video AND attended = TRUE, we show the "watch again" button.
// Only include videos the user has already watched

  return ((((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'videoRegList')), async (item) => {


 return (getObjectProperty(item, 'attended'));
})).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')))) ? 'Play again' : 'Play now')

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var playURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    playURL = (await BackendlessUI.Functions.Custom['fn_e13bc3056d54967e1cba0de250054c9c']((getObjectProperty(___arguments.context.rootComponentData, 'videoURL'))));
    if (!playURL.length) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.rootComponentData, 'videoURL')), true, undefined);
    } else {
      ___arguments.context.rootComponentData['playURL'] = playURL;
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('f0a96026c5e95675a00826fff21bb0d9');
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/e0dae57dcddc0e89228327ea9f0cbb32/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'videoRegList')))) {
    // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user registered for a the class, don't show the "register" button.
    if (((getObjectProperty(___arguments.context.appData, 'videoRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')))) {
      return false;
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userPackageToUse, relevantPackages, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e0dae57dcddc0e89228327ea9f0cbb32')), 'button-loading-dark');
  // If user is not logged in, we ask them to log in (or sign up).
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null) {
    ___arguments.context.pageData['currentTab'] = 'second';
    ___arguments.context.pageData['openLoginModal'] = true;
  } else {
    // If user is not yet following teacher, make them follow
    if (!(((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).includes((getObjectProperty(___arguments.context.pageData, 'teacherData.objectId'))))) {
      await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8']((getObjectProperty(___arguments.context.pageData, 'teacherData')), (getObjectProperty(___arguments.context.appData, 'accountProfile')), null);
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // Fetching packages that are attached to this class and filtering out in-actives.
    relevantPackages = (await asyncListFilter((await Backendless.Data.of('videos').loadRelations((getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('packages').setPageSize(50).setOffset(0))), async (item) => {


     return (getObjectProperty(item, 'active'));
    }));
    // Can only use a package if a) it's a package that is attached to class, b) it has credits > 0, or credits are not a thing for the package (as in subscriptions).
    userPackageToUse = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


     return (((relevantPackages.map(item => item['objectId'])).includes((getObjectProperty(item, 'package_object_id')))) && (!(typeof (getObjectProperty(item, 'credits')) === 'number' && !isNaN((getObjectProperty(item, 'credits')))) || (getObjectProperty(item, 'credits')) > 0));
    }))[0];
    // have to massage relevant packages to contain the
    // same properties as we get from packages_view
    relevantPackages = (await Promise.all(relevantPackages.map(async item => {; return (Object.assign(item, ({ [`package_objectId`]: (getObjectProperty(item, 'objectId')),[`stripe_account_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')),[`stripe_currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_currency')),[`currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'currency')),[`teacher_full_name`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_full_name')),[`teacher_objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`teacher_thumbnail`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_thumbnail')) })));})));
    // Open register modal, put some parameters into the object.
    ___arguments.context.pageData['bookContentModal'] = ({ [`type`]: 'Video',[`content`]: ___arguments.context.rootComponentData,[`going`]: false,[`relevantPackages`]: relevantPackages,[`userPackageToUse`]: userPackageToUse });
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('e0dae57dcddc0e89228327ea9f0cbb32')), 'button-loading-dark', '');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/0ac848c71c33d97ecffead442fedd66a/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('0ac848c71c33d97ecffead442fedd66a', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('0ac848c71c33d97ecffead442fedd66a', false);

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/c1858beedf9f1c9223b8006df63f8658/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/7d97a066183bb8d3f2c2e3c2f136d3b3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.rootComponentData, 'length')) + String(' mins'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/f0bbe08fa4f3952150173ed3dabbec3f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This block shows for any page student sees (not Schedule page).

  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/854ab59b7f7b5a25eaf831d66d3a714d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ('donation' == (getObjectProperty(___arguments.context.rootComponentData, 'pricing'))) {
    return 'DONATION';
  } else if (0 == (getObjectProperty(___arguments.context.rootComponentData, 'cost'))) {
    return 'FREE';
  } else {
    return (String((typeof (getObjectProperty(___arguments.context.rootComponentData, 'currency')) === 'string') ? (getObjectProperty(___arguments.context.rootComponentData, 'currency')).slice(-1) : '$') + String((await (async function(value) {
    	// format number to have 2 decimal places
    	if (typeof value !== 'number') {
    	  value = 0
    	}

    	return value.toFixed(2)
    })((getObjectProperty(___arguments.context.rootComponentData, 'cost'))))));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/afd16d736c1f27b4b331634cc15e7939/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'videoRegList')))) {
    // videoRegList is list of videos that the user has access to, in App Data. If user has access to the video AND attended = not TRUE, we show the big green bar.
    // Only include videos the user has not yet watched
    if (((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'videoRegList')), async (item) => {


     return (!(getObjectProperty(item, 'attended')));
    })).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')))) {
      return true;
    }
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/6b432e66fb433c7cb18c8a1def7c641f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.rootComponentData, 'video_date')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/796d3f8cc779a7dd42fbc7a1399b3e2e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/70536ccc15b15824b76848df1e41525d/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (JSON.stringify((getObjectProperty(___arguments.context.rootComponentData, 'accessed'))))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['videoAccessView'] = ({ [`video_objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')) });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/4e9cb99c54c6ea1f8a38716c3683ea03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['videoModal'] = ({ [`open`]: true,[`edit`]: true,[`currentTab`]: 'first',[`video_objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')) });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/146a0e37d555a638d61dcbe92ddbbb01/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.rootComponentData['operation'] = 'deleteVideo';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/2f03affeb1740d3a005b0b14a19601e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.rootComponentData, 'operation')) === 'undefined')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/4c9364a4ed315464e2b25335b781727d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/f31e202ad15d75aab7f6ad1c150b010d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/4fdbe9212d84f386d6663c2bc0d35d19/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('videos').save(({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'video_objectId')),[`deleted`]: true }), true);
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Video has been deleted!', 'green', null);
  // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/147ebbb9f338e69f8654c58fc02c1bbc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ [`aspect-ratio`]: '16/9' })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/36d3ead2f3ba9e05a8b3c8e33bb33dc2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.rootComponentData, 'video_thumbnail')) === null))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/b36ccf977d78b3951895c213c7452335/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var playURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    playURL = (await BackendlessUI.Functions.Custom['fn_e13bc3056d54967e1cba0de250054c9c']((getObjectProperty(___arguments.context.rootComponentData, 'videoURL'))));
    if (!playURL.length) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.rootComponentData, 'videoURL')), true, undefined);
    } else {
      ___arguments.context.rootComponentData['playURL'] = playURL;
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('f0a96026c5e95675a00826fff21bb0d9');
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/96cfea8411273cb89028268295a1ee7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('f0a96026c5e95675a00826fff21bb0d9');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/fd18f7ac53940bc5b9a70529bb278f37/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ [`aspect-ratio`]: '18 / 9' })

  },
  /* handler:onStyleAssignment */
  /* handler:onSourceAssignment */
  ['onSourceAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'playURL'))

  },
  /* handler:onSourceAssignment */
  /* content */
}))

define('./components/reusable/r_73ad49d1d18d39d4e8ded3d9070f87d9/logic/c515154298065eac560294db00644ef2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var playURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    playURL = (await BackendlessUI.Functions.Custom['fn_e13bc3056d54967e1cba0de250054c9c']((getObjectProperty(___arguments.context.rootComponentData, 'videoURL'))));
    if (!playURL.length) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.rootComponentData, 'videoURL')), true, undefined);
    } else {
      ___arguments.context.rootComponentData['playURL'] = playURL;
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('f0a96026c5e95675a00826fff21bb0d9');
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/099103482d397396f73244845aa9392a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Cancelled classes get cancelled class state for all pages
  if (getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled')) {
    // active is the light shade of green.
    return ['card', 'cancelled', 'clickableCard'];
  }
  if ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule') {
    // class is NOT the past.
    // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user didn't register for a past class, don't show the class card at all.
    if ((new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) >= (new Date()) && (!(typeof (getObjectProperty(___arguments.context.appData, 'classRegList')) === 'undefined') && !(typeof (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')) === 'undefined') ? (((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')))) : false)) {
      // active is the light shade of green.
      return ['card', 'active', 'clickableCard'];
    }
  }

  return ['card', 'clickableCard']

  },
  /* handler:onClassListAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // deleting instance deletes in the database but only toggles visibility on the page.
  if (getObjectProperty(___arguments.context.rootComponentData, 'deleted')) {
    return false;
  }
  // If we're not on Schedule page, we are "student facting"
  // class is in the past. If user did not attend, hide the class.
  if ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule' && (new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) <= (new Date())) {
    if (!(typeof (getObjectProperty(___arguments.context.appData, 'classRegList')) === 'undefined')) {
      // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user didn't register for a past class, don't show the class card at all.
      return (((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId'))));
    } else {
      return false;
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/d779b955113b70e3934fda8131d79323/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'is_online')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/d1d6d4e1a0ef9a4cbe4465c60718d5d1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only show this block when class end time is not in the past.

  return ((new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) >= (new Date()))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/fae25305062c80a3afb4b4ee1f31368e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// from profiles_settings table, boolean in class_instances_view

  return (getObjectProperty(___arguments.context.rootComponentData, 'show_spots_left'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var taken_spots;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.rootComponentData, 'spots')) - (getObjectProperty(___arguments.context.rootComponentData, 'taken_spots'))) + String(' spots left'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/868c71c19f7ca6f6802cf1e71cd60f89/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'taken_spots')) >= (getObjectProperty(___arguments.context.rootComponentData, 'spots')) ? 'Full' : 'Register')

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If class is full, register button is disabled.

  return ((getObjectProperty(___arguments.context.rootComponentData, 'taken_spots')) >= (getObjectProperty(___arguments.context.rootComponentData, 'spots')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userPackageToUse, relevantPackages, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('868c71c19f7ca6f6802cf1e71cd60f89')), 'button-loading-dark');
  // If user is not logged in, we ask them to log in (or sign up).
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('868c71c19f7ca6f6802cf1e71cd60f89')), 'button-loading-dark', '');
    ___arguments.context.pageData['currentTab'] = 'second';
    ___arguments.context.pageData['openLoginModal'] = true;
  } else {
    // If user is not yet following teacher, make them follow
    if (!(((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId'))))) {
      await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8'](({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')) }), (getObjectProperty(___arguments.context.appData, 'accountProfile')), null);
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // Fetching packages that are attached to this class and filtering out in-actives.
    relevantPackages = (await asyncListFilter((await Backendless.Data.of('class_templates').loadRelations((getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('packages').setPageSize(50).setOffset(0))), async (item) => {


     return (getObjectProperty(item, 'active'));
    }));
    // Can only use a package if a) it's a package that is attached to class, b) it has credits > 0, or credits are not a thing for the package (as in subscriptions).
    userPackageToUse = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


     return (((relevantPackages.map(item => item['objectId'])).includes((getObjectProperty(item, 'package_object_id')))) && (!(typeof (getObjectProperty(item, 'credits')) === 'number' && !isNaN((getObjectProperty(item, 'credits')))) || (getObjectProperty(item, 'credits')) > 0));
    }))[0];
    // have to massage relevant packages to contain the
    // same properties as we get from packages_view
    relevantPackages = (await Promise.all(relevantPackages.map(async item => {; return (Object.assign(item, ({ [`package_objectId`]: (getObjectProperty(item, 'objectId')),[`stripe_account_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')),[`stripe_currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_currency')),[`currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'currency')),[`teacher_full_name`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_full_name')),[`teacher_objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`teacher_thumbnail`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_thumbnail')) })));})));
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('868c71c19f7ca6f6802cf1e71cd60f89')), 'button-loading-dark', '');
    // Open register modal, put some parameters into the object.
    ___arguments.context.pageData['bookContentModal'] = ({ [`type`]: 'Class',[`content`]: ___arguments.context.rootComponentData,[`going`]: false,[`relevantPackages`]: relevantPackages,[`userPackageToUse`]: userPackageToUse });
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule') {
    if (Array.isArray((getObjectProperty(___arguments.context.appData, 'classRegList')))) {
      // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user registered for a the class, don't show the "register" button.
      if (((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')))) {
        return false;
      }
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/a9f6b011a77ed3ee58e452c98f2c5b6b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// show this component only if:
// class is not online
// time now is later than 15 mins before class start time
// attendance has not been taken
// only if class is about to start or is in the past.
// only if class is in-person and attendance was not yet taken.

  return ((new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (900 * 1000))) <= (new Date()) && (getObjectProperty(___arguments.context.rootComponentData, 'attendance_done')) == false && (getObjectProperty(___arguments.context.rootComponentData, 'is_online')) == false)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('class_instances').save(({ 'objectId': (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')),'attendance_done': true }), true);
  ___arguments.context.rootComponentData['attendance_done'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/f0494dbf2f5b123e3b68136129353992/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// show checkin block after t = 15 mins before class start time. When class is finished, the entire class card goes into the past where the query should take care of "did its". But we might as well also set logic to hide block when class is over.
// and only if class has not yet finished....
  // Don't show register or checkin on teachers Schedule page
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return false;
  }

  return ((new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (900 * 1000))) <= (new Date()) && (new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) > (new Date()) && (getObjectProperty(___arguments.context.rootComponentData, 'is_online')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    
  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/6791884fe4d527aafe8dfda8d7ff8b38/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'classRegList')))) {
    // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user registered for a the class, don't show the "register" button.
    if (((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')))) {
      return false;
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'Join class' : 'Register')

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userPackageToUse, relevantPackages, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6791884fe4d527aafe8dfda8d7ff8b38')), 'button-loading');
  // If user is not logged in, we ask them to log in (or sign up).
  if ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) === null) {
    ___arguments.context.pageData['currentTab'] = 'second';
    ___arguments.context.pageData['openLoginModal'] = true;
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6791884fe4d527aafe8dfda8d7ff8b38')), 'button-loading', '');
  } else {
    // If user is not yet following teacher, make them follow
    if (!(((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).includes((getObjectProperty(___arguments.context.pageData, 'teacherData.objectId'))))) {
      await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8']((getObjectProperty(___arguments.context.pageData, 'teacherData')), (getObjectProperty(___arguments.context.appData, 'accountProfile')), null);
      await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
    }
    // Fetching packages that are attached to this class and filtering out in-actives.
    relevantPackages = (await asyncListFilter((await Backendless.Data.of('class_templates').loadRelations((getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('packages').setPageSize(50).setOffset(0))), async (item) => {


     return (getObjectProperty(item, 'active'));
    }));
    // Can only use a package if a) it's a package that is attached to class, b) it has credits > 0, or credits are not a thing for the package (as in subscriptions).
    userPackageToUse = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


     return (((relevantPackages.map(item => item['objectId'])).includes((getObjectProperty(item, 'package_object_id')))) && (!(typeof (getObjectProperty(item, 'credits')) === 'number' && !isNaN((getObjectProperty(item, 'credits')))) || (getObjectProperty(item, 'credits')) > 0));
    }))[0];
    // have to massage relevant packages to contain the
    // same properties as we get from packages_view
    relevantPackages = (await Promise.all(relevantPackages.map(async item => {; return (Object.assign(item, ({ [`package_objectId`]: (getObjectProperty(item, 'objectId')),[`stripe_account_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')),[`stripe_currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_currency')),[`currency`]: (getObjectProperty(___arguments.context.rootComponentData, 'currency')),[`teacher_full_name`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_full_name')),[`teacher_objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_objectId')),[`teacher_thumbnail`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_thumbnail')) })));})));
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6791884fe4d527aafe8dfda8d7ff8b38')), 'button-loading', '');
    // Open register modal, put some parameters into the object.
    ___arguments.context.pageData['bookContentModal'] = ({ [`type`]: 'Class',[`content`]: ___arguments.context.rootComponentData,[`going`]: false,[`relevantPackages`]: relevantPackages,[`userPackageToUse`]: userPackageToUse });
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/8b6ca2182aedddba3b02ffa83419f03e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'classRegList')))) {
    // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user registered for a the class, don't show the "register" button.
    if (((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')))) {
      return true;
    }
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'Join class now' : 'Check in')

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, attendanceObj, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('8b6ca2182aedddba3b02ffa83419f03e')), 'button-loading');
  try {
    // get the attendance object that matches class instance id from App Data
    attendanceObj = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'classRegList')), async (item) => {


     return ((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')) == (getObjectProperty(item, 'instance_id')));
    }))[0];
    if (attendanceObj !== null && !Array.isArray(attendanceObj) && (typeof attendanceObj === 'object')) {
      attendanceObj['attended'] = true;
    } else {
      throw (new Error('attendanceObj not found'))
    }
    // mark user as attended in db.
    await Backendless.Data.of('class_attendance_entries').save(attendanceObj, true);
    // mark user as attended in App Data to update front end.
    replaceItemInList((getObjectProperty(___arguments.context.appData, 'classRegList')), attendanceObj, 'instance_id');

  } catch (error) {
    console.log(error);
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','tags': ({ [`api`]: 'none' }),'extra': ({ [`user_profileObjId`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`classInstanceId`]: (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')) }),'type': 'Upsert error','value': 'Check in failed' });

  } finally {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('8b6ca2182aedddba3b02ffa83419f03e')), 'button-loading', '');
    if (getObjectProperty(___arguments.context.rootComponentData, 'is_online')) {
      // Send user to link for the online class.
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.rootComponentData, 'link')), true, undefined);
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/0132e07afae4675eaaa57034bf8f8522/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('0132e07afae4675eaaa57034bf8f8522', (!___arguments['isOpen']));

  },
  /* handler:onTargetClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('0132e07afae4675eaaa57034bf8f8522', false);

  },
  /* handler:onMouseOver */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only show ellipses to teacher on Schedule page.

  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/4c48a26db442e12dbbdc8e42285e23dd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')) === 'undefined') ? '' : [(await (async function(datetime) {

	//removes seconds so we only show hours and minutes
	let result = datetime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit"});
	return (result)
})((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))))),' - ',(await (async function(datetime) {

	//removes seconds so we only show hours and minutes
	let result = datetime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit"});
	return (result)
})((new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime'))))))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/fbe9ebd3cd7811a89e57009967c2a0f2/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'TV' : 'pin drop')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/8714bd5469a949fc3c79c87a00795fb2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This block only shows on Schedule page.
  if (getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/cecc7c93c4d74c6fb8f898bb4b1effff/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Always show if class is in the future (more than 15 mins)
// always show this block for online classes, also show if attendance_done is true.

  return ((getObjectProperty(___arguments.context.rootComponentData, 'attendance_done')) == true || (getObjectProperty(___arguments.context.rootComponentData, 'is_online')) == true || (new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (900 * 1000))) > (new Date()))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/4bc4f7649e679cc658de1238c61f48e7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// show attendance button after t = 15 mins before class start time.

  return ((new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (900 * 1000))) <= (new Date()))

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.rootComponentData, 'attendedNum')) === null) || (typeof (getObjectProperty(___arguments.context.rootComponentData, 'attendedNum')) === 'undefined') ? '0' : String(getObjectProperty(___arguments.context.rootComponentData, 'attendedNum')))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['attendanceView'] = ({ 'instance_id': (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')),'template_objectId': (getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')),'type': 'attendances' });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/3f24212f999f69ad190b19261959012a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// show registrations icon up to t = 15 mins before class start time.

  return ((new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (900 * 1000))) > (new Date()))

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.rootComponentData, 'num_registered')),'/',(getObjectProperty(___arguments.context.rootComponentData, 'spots'))].join(''))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['attendanceView'] = ({ 'instance_id': (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')),'template_objectId': (getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')),'type': 'registrations' });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/74fe277eaac1c2dabc4f56be9e2abc52/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This block shows for any page student sees (not Schedule page)page.
  if (getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/11018d1d1ca5fde645c2fc3d5fee44c3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ('donation' == (getObjectProperty(___arguments.context.rootComponentData, 'pricing'))) {
    return 'DONATION';
  } else if (0 == (getObjectProperty(___arguments.context.rootComponentData, 'cost'))) {
    return 'FREE';
  } else {
    return (String((getObjectProperty(___arguments.context.rootComponentData, 'currency')).slice(-1)) + String((await (async function(value) {
    	// format number to have 2 decimal places
    	if (typeof value !== 'number') {
    	  value = 0
    	}

    	return value.toFixed(2)
    })((getObjectProperty(___arguments.context.rootComponentData, 'cost'))))));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/93a22737c05aace6e4e25ce25541bad9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Don't show to teachers on manage Schedule page, and never show for future classes. The class card itself for past classes is only shown to users who attended, that's set in MegaScrolly logic.

  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule' && (new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) < (new Date()))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/d22c332f702598fc4b2b54521ae3b025/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  // If class is cancelled, we un-cancel. Otherwise we cancel class and
  // un-register all students, giving back credits to package used.
  if (getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled')) {
    ___arguments.context.rootComponentData['operation'] = 'unCancelClass';
  } else {
    ___arguments.context.rootComponentData['operation'] = 'cancelClass';
  }
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('422592c941ffe6547d07ed781de47af5')), 'open');

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled')) ? 'Un-cancel' : 'Cancel')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/9737a8bc3a5b7184be93124bd0796608/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.rootComponentData['operation'] = 'editClass';
  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('422592c941ffe6547d07ed781de47af5')), 'open');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/134d4c05de4151bf26fed798bab2bb9a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.rootComponentData['operation'] = 'deleteClass';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/fb94cc149542f8880b9c834b126a771f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
      return ({ 'color': '#E44868' })

  },
  /* handler:onStyleAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'is_cancelled'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/f688e2f7a6bdb19d3bcc9d5e9912b631/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('551a8d0c9916bc025943210319d1caf6')), 'open');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/3d1a2ad5a411501cb54b33585e02d1d2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Cancel ',(getObjectProperty(___arguments.context.rootComponentData, 'title')),' on ',((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).toLocaleDateString()),'? Any students registered will be unregistered and given a credit. '].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/aa3aa4fbf4439b59e1356dcb2cb10a44/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('422592c941ffe6547d07ed781de47af5')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/7cae2b27a085bb818b3331c7a8997852/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/38e759c62dde575e22bf432374a90f24/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('38e759c62dde575e22bf432374a90f24')), 'button-loading-dark');
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/classes/${(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))}/classes`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'classInstanceId': (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')) });
    // Change classCard state on page without talking to database
    ___arguments.context.rootComponentData['is_cancelled'] = true;
    // clean up and remove operation property of classCard data.
    delete ___arguments.context.rootComponentData['operation'];

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/8b3778f9f0d4c08e684d84bfa8a53371/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'cancelClass')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/b2db15abf771fa5ff0211023f03fa72e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Un-cancel (restore) ',(getObjectProperty(___arguments.context.rootComponentData, 'title')),' on ',((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).toLocaleDateString()),'?'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/c4b8f046c841632d7eb8a53c5fc151a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/31e2c0683211fb648f78e96f3d85bb2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('31e2c0683211fb648f78e96f3d85bb2b')), 'button-loading-dark');
  try {
    // Set class instance to be cancelled.
    await Backendless.Data.of('class_instances').save(({ 'objectId': (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')),'is_cancelled': false }), true);
    // Change classCard state on page without talking to database
    ___arguments.context.rootComponentData['is_cancelled'] = false;
    // clean up and remove operation property of classCard data.
    delete ___arguments.context.rootComponentData['operation'];

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/a4ca84714318aafa23adbbfcfd6f5942/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'unCancelClass')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/8035ed035d89f6c1128eb34cd7711d0a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'editClass')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/b7e2c0fc3aaca84bf7aef5f78b55929b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];
  ___arguments.context.pageData['editTemplate'] = ({ 'template_objectId': (getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')),'newStartDate': (getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')) });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/345cbceb3c67cb1b9df5695b7d841b96/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];
  // Put template and instance objectId's into "editInstance"
  // property in Page Data for use outside of the reusable component.
  ___arguments.context.pageData['editInstance'] = ({ 'template_objectId': (getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')),'instance_objectId': (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')) });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/ff6e31f5a68b3d92f85671c1aa0cd6c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'deleteClass')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/422592c941ffe6547d07ed781de47af5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.rootComponentData, 'operation')) === 'undefined')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/9a7e68c91ecdcacc5e7e51ba556a54ce/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'deleteInstanceNotAllowed')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/f35b8b34e19a5d21151b0e36f6c53255/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/0064543402de7e04c8b4dad3c1d2b535/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/49c967dac1a43aa2a589318d5deb438a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'operation')) == 'deleteAllFutureNotAllowed')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/25cd15f41be26e82191e3607bcfe5b1f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If we're not on Schedule page, we are "student facting"
  // class is in the past. If user did not attend, hide the class.
  if ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule' && (new Date((getObjectProperty(___arguments.context.rootComponentData, 'end_datetime')))) <= (new Date())) {
    if (!(typeof (getObjectProperty(___arguments.context.appData, 'classRegList')) === 'undefined')) {
      // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user didn't register for a past class, don't show the class card at all.
      return (((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId'))));
    } else {
      return false;
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/6d9fb4ceb855a0ce2cba279701b847e7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['attendanceView'] = ({ 'instance_id': (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')),'template_objectId': (getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')),'type': 'attendances' });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/9006bab95be193ccc85aa28c9ffe34c5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Schedule') {
    if (Array.isArray((getObjectProperty(___arguments.context.appData, 'classRegList')))) {
      // classRegList is list of instances that the user registered for, in App Data or loaded on Page Enter. If user registered for a the class, don't show the "register" button.
      if (((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).includes((getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')))) {
        return true;
      }
    }
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
      return false

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var classRegListItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If we're within the teacher's cancel period, we don't let user cancel. Instead we show them a toastr message.
  // If cancel_period is null or not a number in any way, we use 15 minutes to be the default cancel period
  if ((new Date((new Date((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')))).getTime() - (((typeof (getObjectProperty(___arguments.context.rootComponentData, 'cancel_period')) === 'number' && !isNaN((getObjectProperty(___arguments.context.rootComponentData, 'cancel_period')))) ? (getObjectProperty(___arguments.context.rootComponentData, 'cancel_period')) * 60 : 900) * 1000))) <= (new Date())) {
    await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'You\'re registered for this class. Sorry, it\'s too late to cancel your booking.', 'red', null);
  } else {
    // Find the item in classRegList which matches the class instance id.
    classRegListItem = (getObjectProperty(___arguments.context.appData, 'classRegList'))[((((getObjectProperty(___arguments.context.appData, 'classRegList')).map(item => item['instance_id'])).indexOf(getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')) + 1) - 1)];
    // Open cancel modal and populate property "classRegisterModal" in the classCardData.
    ___arguments.context.pageData['bookContentModal'] = ({ [`type`]: 'cancelClass',[`going`]: true,[`student_package_used`]: (getObjectProperty(classRegListItem, 'student_package_used')),[`paid_online`]: (getObjectProperty(classRegListItem, 'paid_online')),[`class_attendance_object_id`]: (getObjectProperty(classRegListItem, 'objectId')),[`content`]: ___arguments.context.rootComponentData });
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/9109c3d6307c9bb71298de87fd7e0ae6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // clean up and remove operation property of classCard data.
  delete ___arguments.context.rootComponentData['operation'];
  ___arguments.context.pageData['setHolidayModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/c5d7bf2beb2acbf369f27f434c0da001/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'TV' : 'pin drop')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/a6e7698b357354082ded7d672645d8e6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'is_online')) ? 'ONLINE' : (getObjectProperty(___arguments.context.rootComponentData, 'location_title')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/e88b7c99926214c2455da912e0786b87/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'address'))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'is_online')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/e3d454ef45b58b7dc9fb7bddcfa43a83/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.rootComponentData, 'description')) === null) || (typeof (getObjectProperty(___arguments.context.rootComponentData, 'description')) === 'undefined') ? 'This class has no description.' : (getObjectProperty(___arguments.context.rootComponentData, 'description')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/6f8f14bff6a82027eeeedf079ebc5773/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/5857712216e2066da2c6b2e19ad3af9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('551a8d0c9916bc025943210319d1caf6')), 'open', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/9bd42105540cf89a972ccf3945313b3b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/cacd36e4789a8afa03defc7ceeecb181/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var allowed, error, existingRegistrations, itemArray, j, n;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * Checks that there are no class registrations for any
 * one the instances that would be deleted if the user
 * succeeds with "delete all future". If there are any,
 * we stop the user and show them a dialog message.
 */
async function deleteInstanceAllowed() {
  // Get all instances that refer to the chosen class template.
  existingRegistrations = (await BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'](___arguments.context.pageData, 'class_attendance_entries', (['class_instance_id = \'',(getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')),'\''].join('')), null, null, null, null));
  if (!existingRegistrations.length) {
    return true;
  }
  return false
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('cacd36e4789a8afa03defc7ceeecb181')), 'button-loading-dark');
  if ((getObjectProperty(___arguments.context.pageData, 'pastClassesData.items')).includes(___arguments.context.rootComponentData)) {
    console.log('found class in the past');
    itemArray = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'pastClassesData')), 'items'));
    // We always allow people to delete past classes, evemn when there are registrations.
    allowed = true;
  } else {
    console.log('class is in the future');
    itemArray = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'futureClassesData')), 'items'));
    // If class is in the future, we check for regostrations.
    // Don't allow user to delete upcoming class with registrations.
    allowed = await deleteInstanceAllowed();
  }
  if (allowed) {
    try {
      // delete the instance in the data base
      await Backendless.Data.of('class_instances').remove( (getObjectProperty(___arguments.context.rootComponentData, 'instance_objectId')) );
      // delete the class instances from the list in our
      // Data Model. So we don't have to reload classes.
      removeItemInList(itemArray, ___arguments.context.rootComponentData, 'instance_objectId');
      n = 1;
      // Have to re-index the list or else the date display doesn't work anymore.
      for (var j_index in itemArray) {
        j = itemArray[j_index];
        j['idx'] = n;
        n = (typeof n == 'number' ? n : 0) + 1;
      }
      // clean up and remove operation property of classCard data.
      delete ___arguments.context.rootComponentData['operation'];

    } catch (error) {
      console.log(error);

    }
  } else {
    // Show the user alert that they have to unregister
    // students first before they can delete class instance.
    ___arguments.context.rootComponentData['operation'] = 'deleteInstanceNotAllowed';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_d5427738553184465e3b20d2bb2ca8c2/logic/3aea8e3d57b6e7dd041a9d8606f5d3af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var doomedRegistrations, doomedInstances, item, allInstances, error, newRRule, templateObject, newUntilDate;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * Checks that there are no class registrations for any
 * one the instances that would be deleted if the user
 * succeeds with "delete all future". If there are any,
 * we stop the user and show them a dialog message.
 */
async function deleteInstancesAllowed() {
  // Get all instances that refer to the chosen class template.
  allInstances = (await BackendlessUI.Functions.Custom['fn_2b649a7c977d9e7a695f1b21106afc93'](___arguments.context.pageData, 'class_instances', (['class_templates[class_instances].objectId = \'',(getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')),'\''].join('')), null, null, null, null));
  // Get list of objectId's for all instances that are scheduled for deletion
  doomedInstances = ((await asyncListFilter(allInstances, async (item) => {


   return ((getObjectProperty(item, 'start_datetime')) >= (getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')));
  })).map(item => item['objectId']));
  // Get any attendance records that apply to doomedInstances.
  // Massage list of doomedInstances into text string that is accepted in a Where Clause.
  doomedRegistrations = (await Backendless.Data.of('class_attendance_entries').find(Backendless.DataQueryBuilder.create().setWhereClause((['class_instance_id IN (',subsequenceFromStartFromEnd(((JSON.stringify(doomedInstances)).replace((new RegExp('"', 'g')), '\'')), 1, 1),')'].join(''))).setPageSize(10)));
  if (!doomedRegistrations.length) {
    return true;
  }
  return false
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3aea8e3d57b6e7dd041a9d8606f5d3af')), 'button-loading-dark');
  // If instances to be deleted have any registration records, we
  // simply don't allow the teacher to perform the "delete all future".
  if (await deleteInstancesAllowed()) {
    try {
      templateObject = (await Backendless.Data.of('class_templates').findById((getObjectProperty(___arguments.context.rootComponentData, 'template_objectId')), ));
      // New "until date" for the template is set to be just 5 mins less than
      // 24 hours before the start_datetime of the instance being modified.
      // We set the new until date to be nearly 24 hours before the current instance date to ensure that all instances are deleted, including the one from the current nstance date.
      newUntilDate = (await (async function(timestamp) {
      	// little function to take timestamp and create string matching requirement for RRULE format
      	// input is timestamp, convert to ISO format
      	let date = new Date(timestamp);
      	let str = date.toISOString();
      	// make the string only contain alphanumeric characters
      	var cleanString = str.match(/[a-zA-Z0-9]/g).join("");
      	//then remove the last 4 characters and add back the "Z" + more characters
      	cleanString = cleanString.substring(0, cleanString.length - 4) + "Z";

      	return cleanString
      })((((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')).valueOf()) - 86100000)));
      newRRule = [((getObjectProperty(templateObject, 'rrule')).split(';UNTIL', ))[0],';UNTIL=',newUntilDate].join('');
      // from_date NEEDS to be wired for the API logic to work.
      await Backendless.Request.put(`${Backendless.appPath}/services/class_templates/${(getObjectProperty(templateObject, 'objectId'))}`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'rrule': newRRule,'from_date': (((getObjectProperty(___arguments.context.rootComponentData, 'start_datetime')).valueOf()) - 86100000) });
      // clean up and remove operation property of classCard data.
      delete ___arguments.context.rootComponentData['operation'];
      // This unmounts all children of classesScrolly (i.e. past and future classes).
      ___arguments.context.pageData['unmountClassScrolly'] = true;

    } catch (error) {
      console.log(error);

    } finally {
      // This makes classesScrolly container visible again and thus re-mounts
      // containers which display classes... so data is fetched from the data base.
      delete ___arguments.context.pageData['unmountClassScrolly'];

    }
  } else {
    ___arguments.context.rootComponentData['operation'] = 'deleteAllFutureNotAllowed';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_64fbfb9f6fe4ed893bcc7452efc77509/logic/5884b449dcb96b29d2f538cc2c3cf052/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['copyLinkModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_64fbfb9f6fe4ed893bcc7452efc77509/logic/5ca6a2073388b73e188be3270ecc4b3b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  await (async function(inputText) {
  	//input text will be copied to clipboard
  	return navigator.clipboard.writeText(inputText);
  })(((getObjectProperty(___arguments.context.pageData, 'copyLinkModal.referral')) ? [(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'customDomain')),(getObjectProperty(___arguments.context.pageData, 'profile_url')),'?ref=',(getObjectProperty(((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teachersFollowed')), async (item) => {


   return ((getObjectProperty(item, 'teacher_profile_id')) == (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')));
  }))[0]), 'ref_code'))].join('') : String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'customDomain')) + String(getObjectProperty(___arguments.context.appData, 'accountProfile.url'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc15e72feb5d613bbd9858d6dbc4cae6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('023a602484452e2b8b1a461633f9d638', true);
  await new Promise(r => setTimeout(r, 2000 || 0));
  ___arguments.context.rootComponentData['open'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_64fbfb9f6fe4ed893bcc7452efc77509/logic/e92cd96d5b1ca70deb1aa20e609d0318/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



  return ((getObjectProperty(___arguments.context.pageData, 'copyLinkModal.referral')) ? [(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'customDomain')),(getObjectProperty(___arguments.context.pageData, 'profile_url')),'?ref=',(getObjectProperty(((await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teachersFollowed')), async (item) => {


 return ((getObjectProperty(item, 'teacher_profile_id')) == (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')));
}))[0]), 'ref_code'))].join('') : String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'customDomain')) + String(getObjectProperty(___arguments.context.appData, 'accountProfile.url')))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_64fbfb9f6fe4ed893bcc7452efc77509/logic/cc15e72feb5d613bbd9858d6dbc4cae6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'copyLinkModal.referral')) ? 'Copy & share your unique link below to invite friends to sign up with me. When they do, you both get credit for a free class!' : 'Send students to your class schedule by sharing the following link on social media, in an email, or anywhere...')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_64fbfb9f6fe4ed893bcc7452efc77509/logic/17c442711ef221ebedb5424b100bc1a3/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'copyLinkModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'copyLinkModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'copyLinkModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['copyLinkModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_64fbfb9f6fe4ed893bcc7452efc77509/logic/b4140af9a7136d4c14297d6f76aa53d3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'copyLinkModal.titleText'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/e8b37feefe2c2a56ce2d98e6861f4ec5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['stripeObj'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/aba3ce56dce16f318d75ff987b9aecf8/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var stripeObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  stripeObj = (getObjectProperty(___arguments.context.pageData, 'stripeObj'));
  (getObjectProperty(stripeObj, 'params'))['form_component'] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2e9d5fdf34cafd777b99bbe2d1486d8d'));
  await BackendlessUI.Functions.Custom['fn_0da4120383addec95876bbd33fbcb589']((getObjectProperty(stripeObj, 'type')), (getObjectProperty(stripeObj, 'params')), ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('2e9d5fdf34cafd777b99bbe2d1486d8d')), (getObjectProperty(stripeObj, 'is_production')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/55e7fc59641598bfbc25ee3c7669f1b4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d'), 'error'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (['Sorry, but ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d'), 'message')).charAt(0).toLowerCase(),subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d'), 'message')), 1)].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/60ef680cde42bac48dddce6311d67dd9/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('PAY ') + String(getObjectProperty(___arguments.context.pageData, 'stripeObj.cosmetics.costString')))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var n;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('60ef680cde42bac48dddce6311d67dd9')), 'button-loading');
  // In case this is a retry, we want to wipe properties
  // that we need to test for in the loop below.
  delete ___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d')['confirmed'];
  // In case this is a retry, we want to wipe properties
  // that we need to test for in the loop below.
  delete ___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d')['error'];
  n = 0;
  // Wait until we get a success or an error from Stripe.
  // Wait up to 10 seconds for Stripe to succeed or fail
  while (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d'), 'confirmed')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d'), 'error')) && n < 50) {
    n = n + 1;
    await new Promise(r => setTimeout(r, 200 || 0));
  }
  // Add "completed" (success) and "error" to the stripeObj in Page Data.
  Object.assign((getObjectProperty(___arguments.context.pageData, 'stripeObj')), ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('2e9d5fdf34cafd777b99bbe2d1486d8d')));
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('60ef680cde42bac48dddce6311d67dd9')), 'button-loading', '');
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('2e9d5fdf34cafd777b99bbe2d1486d8d'), 'confirmed')) {
    // If success, close the payment modal. But keep the stripeObj in Page Data.
    (getObjectProperty(___arguments.context.pageData, 'stripeObj'))['open'] = false;
  } else {
    console.log('Stripe payment failed for some reason.');
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','tags': ({ 'api': 'stripe','operation': 'payment' }),'type': 'api_error','value': 'Stripe payment failed for some reason.' });
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/366d7b2bf32830b3568693e9b654ed16/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'stripeObj.cosmetics.teacher_thumbnail')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty(___arguments.context.pageData, 'stripeObj.cosmetics.teacher_thumbnail')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/1fb774784b72d9f661a937c3ee3b4876/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['stripeObj'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/422846e2ac22419f9b16e49a845eda41/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/533e5d6fd0af656f9621ea13be310ed4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'stripeObj.cosmetics.item_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/94a2c302a2e9055431aaec15ca3b3cc1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'stripeObj.cosmetics.teacher_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aed7b444aa6489f7924e9334051ff22f/logic/0fadb9c37b8a7e434bf4b22c7802eb4a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'stripeObj.cosmetics.item_subtitle'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/a228196611dddedc237277b113d17a8e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If pack is required, teacher has Stripe account, and user does not have a package to use, we hide the top panel.
  // Making this visible mounts the children.
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')) === 'string') && (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pack_required')) && !((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) && !(getObjectProperty(___arguments.context.pageData, 'bookContentModal.going'))) {
    return false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal')) === 'object')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/cf3ef09ca7033b4b5481730519438ecf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['bookContentModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/a974fb7acfd4095d2a41755c8746acc1/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var toastrMessage, item, videoAccessObj, studentPackageObj, newSpotsTaken, j, classAttendanceObj, classInstanceObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) {
    // userPackageToUse is object from a view, but we will need
    // object for student_packages table. So we create the object.
    studentPackageObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse.student_package_object_id')),[`credits`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse.credits')) });
  } else {
    studentPackageObj = null;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    // Construct class_instances object from classCard Data
    // (which is from a view, not the instances table)
    // To record what was actually paid in case it's donation.
    classInstanceObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.instance_objectId')),[`taken_spots`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.taken_spots')),[`cost`]: (getObjectProperty(___arguments.context.dataModel, 'trueCost')) });
    // Here's where we write many things to the database
    classAttendanceObj = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/registerStudentForClass`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'student_object_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'teacher_object_id': (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_objectId')),'classInstanceObj': classInstanceObj,'studentPackageObj': studentPackageObj,'paid_online': (getObjectProperty(___arguments.context.dataModel, 'paid_online')),'attended': null }));
    // We update classCard repeater data by incrementing spots taken in the class.
    newSpotsTaken = (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.taken_spots')) + 1;
    // Update the array in Page Data so class card
    // "available s[pots" reflects the new booking.
    var j_list = (getObjectProperty(___arguments.context.pageData, 'futureClassesData.items'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if ((getObjectProperty(j, 'instance_objectId')) == (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.instance_objectId'))) {
        j['taken_spots'] = newSpotsTaken;
        j['num_registered'] = newSpotsTaken;
      }
    }
    toastrMessage = ['You\'re signed up for ',(getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.title')),' — enjoy your class!'].join('');
  }
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    videoAccessObj = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/giveStudentVideoAccess`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'videoObj': ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.video_objectId')) }),'student_object_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'studentPackageObj': studentPackageObj,'paid_online': (getObjectProperty(___arguments.context.dataModel, 'paid_online')),'teacher_object_id': (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_objectId')) }));
    toastrMessage = ['You\'ve got access to "',(getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.title')),'"  — enjoy !'].join('');
  }
  // Ask the database for all the most recent user data, including
  // packagesOwned, etc. — this updates the state for all class or video
  // or appointment cards and ensures we have reality from the database.
  await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
  // close class register modal
  delete ___arguments.context.pageData['bookContentModal'];
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, toastrMessage, 'green', null);

  },
  /* handler:onSubmit */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Never show form if user is registered
  if (getObjectProperty(___arguments.context.pageData, 'bookContentModal.going')) {
    return false;
  }
  // If user has a package that applies to the class, we always show the form.
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) {
    return true;
  } else {
    // User has no package, package is required,
    // teacher has no Stripe account: we hide the form.
    // if teacher is not connected to Stripe we don't show packages available.
    if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pack_required')) && !(typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')) === 'string')) {
      return false;
    }
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If teacher is not connected to Stripe, we flip pricing to
  // "noPayment", even if the teacher has set "fixed" or "by donation".
  // This way student simply clicks "confirm" and buys/books.
  if (!(typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')) === 'string')) {
    (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content'))['pricing'] = 'noPayment';
  }
  ___arguments.context.dataModel['trueCost'] = (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/a9775f0c48cda81c259466218b9577b3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var string2, string1;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    string1 = '" is good for this class.';
    string2 = 'Just confirm to reserve your spot.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    string1 = '" works for this video.';
    string2 = 'Just confirm to get video access.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
    string1 = '" can be used to book.';
    string2 = 'Just confirm to book this appointment.';
  }

  return (['Your package "',(getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse.title')),string1,(getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse.credits')) > 0 ? [' You have ',(getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse.credits')),(getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse.credits')) == 1 ? ' credit left. ' : ' credits left. '].join('') : '','\n \n',string2].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If user has a package that applies to the class, we show this element.

  return ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/b622d81f1af3961fd1f4074c8be5eda3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var string2, string1;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user has a package that applies to the class, we never show this element.
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pricing')) == 'noPayment' || (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost')) == 0)

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var string1;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    string1 = 'Just confirm to reserve your spot.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    string1 = 'Just confirm to get video access.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
    string1 = 'Just confirm to book this appointment.';
  }

  return string1

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/99fa352f2e1e90018f2c4f2a8cbc4763/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user has a package that applies to the class, we never show this element.
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pricing')) == 'fixed' && (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost')) > 0)

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var string1;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object')) {
    string1 = 'Payment processing...';
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
      string1 = 'Please confirm & pay to reserve your spot';
    } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
      string1 = 'Click confirm & pay to get video access';
    } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
      string1 = 'Please confirm & pay to book this appointment';
    }
  }

  return string1

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/643990e2852faf35247541ad5acc8d29/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If user has a package that applies to the class, we never show this element.
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pricing')) == 'donation')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/e5c1a915b1767f5c63f13866d8cf07d6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('025c5a07f7a5b33210f6f963f46ce3e5', (___arguments.checkedValue == 4));
  if (___arguments.checkedValue != 4) {
    ___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1')['trueCost'] = ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost')) * (___arguments.checkedValue == 3 ? 2 : ___arguments.checkedValue / 2));
  }
  console.log('bookContentModal.content.cost is now:');
  console.log(getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost'));
  console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1'), 'trueCost'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/27358b041fc5a87a2b5c00bd40f629d6/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.currency')).slice(-1)) + String((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost')) * 0.5))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/04a94bf10d092b7f4e9ae74b1da279ca/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.currency')).slice(-1)) + String((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost')) * 1))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/020fc092a954d90b7fdebdfb38ce983d/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.currency')).slice(-1)) + String((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost')) * 2))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/eb51a225c4df8a27d32ac0933d7801a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1'), 'trueCost')) === null)) {
    // Simplified Block
    ___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1')['trueCost'] = (await (async function(num) {

    	return Math.abs(num.toFixed(2));
    })(___arguments.value));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/cee8fc971ebe042e4138543f1a968144/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Use "pay" word in button unless it's free class or user is using a package.
// If teacher is not connected to Stripe, people can't pay. So we just show "confirm"
// If teacher is not connected to Stripe, people can't pay. So we just show "confirm"
// If user has a package that applies to the class

  return ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pricing')) == 'noPayment' || (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.cost')) == 0 || ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')) === null) || (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')) === 'undefined') || ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) ? 'Confirm' : 'Confirm & pay')

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var stripe_customer_id, tsr_view_obj, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function subtitleGenerate() {
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    return ([(new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.start_datetime')))),' - ',(await (async function(datetime) {

    	//removes seconds so we only show hours and minutes
    	let result = datetime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit"});
    	return (result)
    })((new Date((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.start_datetime'))))))].join(''));
  }
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    return ' ';
  }
  return 'subtitle'
}

/**
 * Describe this function...
 */
async function descriptionForStripe() {
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    return ([(getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.title')),' - ',(new Intl.DateTimeFormat(undefined, ({ [`day`]: 'numeric',[`month`]: 'short',[`weekday`]: 'long' })).format((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.start_datetime')))),' - ',(await (async function(datetime) {

    	//removes seconds so we only show hours and minutes
    	let result = datetime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit"});
    	return (result)
    })((new Date((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.start_datetime'))))))].join(''));
  }
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    return (String(getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.title')) + String(' - video'));
  }
  return 'description'
}

/**
 * Describe this function...
 */
async function buyPassLogic() {
}

/**
 * Describe this function...
 */
async function buySubscriptionLogic() {
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('cee8fc971ebe042e4138543f1a968144')), 'button-loading');
  // If class is free or user has a valid package, we register the user for class.
  // Can't let user pay less than $1 (or pounds or whatever), so it's just free then.
  // If user has a package that applies to the class
  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pricing')) == 'noPayment' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1'), 'trueCost')) < 1 || ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object'))) {
    // Student didn't pay online
    ___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1')['paid_online'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('a974fb7acfd4095d2a41755c8746acc1');
  } else {
    // Extract the TSR object for this teacher & student from App Data.
    tsr_view_obj = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'teachersFollowed')), async (item) => {


     return ((getObjectProperty(item, 'teacher_profile_id')) == (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_objectId')));
    }))[0];
    // Get stripe_customer_id. Might be null.
    stripe_customer_id = (getObjectProperty(tsr_view_obj, 'stripe_customer_id'));
    // If there is no stripe_customer_id, we create a Stripe customer first.
    if (stripe_customer_id === null) {
      // Get stripe customer id back from Stripe after creating
      stripe_customer_id = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/stripe/create_stripe_customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'teacher_student_rel_id': (getObjectProperty(tsr_view_obj, 'tsr_objectId')),'stripe_account_id': (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')),'teacher_profile_id': (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_objectId')),'student_profile_id': (getObjectProperty(___arguments.context.appData, 'accountProfile.objectId')),'student_email': (getObjectProperty(___arguments.context.appData, 'accountProfile.skeleton_email')),'student_name': (getObjectProperty(___arguments.context.appData, 'accountProfile.full_name')) })), 'stripe_customer_id'));
    }
    // If user has to pay something, we create stripeObj in Page Data.
    ___arguments.context.pageData['stripeObj'] = ({ [`open`]: true,[`type`]: 'single',[`cosmetics`]: ({ [`teacher_thumbnail`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_thumbnail')),[`teacher_name`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_full_name')),[`item_title`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.title')),[`item_subtitle`]: await subtitleGenerate(),[`costString`]: String((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.currency')).slice(-1)) + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1'), 'trueCost')) }),[`params`]: ({ [`teacher_profile_id`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_objectId')),[`student_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),[`stripe_customer_id`]: stripe_customer_id,[`cost`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1'), 'trueCost')),[`description`]: await descriptionForStripe(),[`stripe_account_id`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')),[`stripe_currency`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_currency')),[`fee_rate`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.fee_rate')),[`metadata`]: ({ [`teacher_profile_id`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_objectId')),[`student_profile_id`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')) }) }),[`is_production`]: false });
    // We have to wait for Stripe success. Keep waiting
    // until success is there or stripeObj is destroyed.
    while (((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object')) && !(getObjectProperty(___arguments.context.pageData, 'stripeObj.confirmed'))) {
      await new Promise(r => setTimeout(r, 300 || 0));
    }
    // Once payment is confirmed, submit the registration form
    // which registers the student and closes the register modal.
    if (getObjectProperty(___arguments.context.pageData, 'stripeObj.confirmed')) {
      // Add this property to the class_registration_entries record
      ___arguments.context.getComponentDataStoreByUid('a974fb7acfd4095d2a41755c8746acc1')['paid_online'] = true;
      (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('a974fb7acfd4095d2a41755c8746acc1');
      // Destroy the stripeObj that might be used in payment form.
      delete ___arguments.context.pageData['stripeObj'];
    }
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object')) && !(getObjectProperty(___arguments.context.pageData, 'stripeObj.confirmed')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var stripe_customer_id;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function subtitleGenerate() {
}

/**
 * Describe this function...
 */
async function descriptionForStripe() {
}


  if ((getObjectProperty(___arguments.context.pageData, 'stripeObj')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'stripeObj'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'stripeObj')) === 'object')) {
    return ['button-loading'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/54f9070789e119e4fbefb42126f56d56/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'bookContentModal.going'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/2b2165ea41dc8b6815ccd77b6c5fbf2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var successText, packageUsed, classInstanceObj, j, tempPackageUsed, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('2b2165ea41dc8b6815ccd77b6c5fbf2b')), 'button-loading-dark');
  // Construct class_instances object from classCard Data
  // (which is from a view, not the instances table)
  classInstanceObj = ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.instance_objectId')),[`taken_spots`]: (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.taken_spots')) });
  // if registration was paid_online, there is no packageUsed...
  // but we can save ourselves an API call IF we find that
  // the student has a General Pass with the teacher already.
  // If paid_online = F, we get packageUsed from the classCard
  // Data (it can be null or undefined, no problem).
  if (getObjectProperty(___arguments.context.pageData, 'bookContentModal.paid_online')) {
    tempPackageUsed = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'packagesOwned')), async (item) => {


     return ((getObjectProperty(item, 'title')) == 'General Pass' && (getObjectProperty(item, 'teacher_full_name')) == (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_full_name')));
    }))[0];
    if (tempPackageUsed !== null && !Array.isArray(tempPackageUsed) && (typeof tempPackageUsed === 'object')) {
      packageUsed = ({ [`objectId`]: (getObjectProperty(tempPackageUsed, 'student_package_object_id')),[`credits`]: (getObjectProperty(tempPackageUsed, 'credits')) });
    } else {
      packageUsed = null;
    }
  } else {
    // This could be null or undefined.
    packageUsed = (getObjectProperty(___arguments.context.pageData, 'bookContentModal.student_package_used'));
  }
  classInstanceObj = (await Backendless.Request.post(`${Backendless.appPath}/services/functionsUbindi/unregisterStudentFromClass`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'student_object_id': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'teacher_object_id': (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.teacher_objectId')),'attendance_object_id': (getObjectProperty(___arguments.context.pageData, 'bookContentModal.class_attendance_object_id')),'classInstanceObj': classInstanceObj,'studentPackageObj': packageUsed,'paid_online': (getObjectProperty(___arguments.context.pageData, 'bookContentModal.paid_online')) }));
  successText = String('You\'ve been un-registered from ') + String(getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.title'));
  // Update the array in Page Data so class card
  // "available spots" reflects the new booking.
  var j_list = (getObjectProperty(___arguments.context.pageData, 'futureClassesData.items'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    if ((getObjectProperty(j, 'instance_objectId')) == (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.instance_objectId'))) {
      j['taken_spots'] = (getObjectProperty(classInstanceObj, 'taken_spots'));
      j['num_registered'] = (getObjectProperty(classInstanceObj, 'taken_spots'));
    }
  }
  // Get the latest classReg list, packagesOwned
  // with updated credits, etc. for this user.
  await BackendlessUI.Functions.Custom['fn_f82bb73eefabfdd6cf85d43337b1ba28'](___arguments.context.appData);
  if (getObjectProperty(___arguments.context.pageData, 'bookContentModal.paid_online')) {
    successText = String(successText) + String('. You got a credit added to a General Pass.');
  } else if ((packageUsed !== null && !Array.isArray(packageUsed) && (typeof packageUsed === 'object')) && (typeof (getObjectProperty(packageUsed, 'credits')) === 'number' && !isNaN((getObjectProperty(packageUsed, 'credits'))))) {
    successText = String(successText) + String('. A credit has been added back to your pass.');
  }
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('2b2165ea41dc8b6815ccd77b6c5fbf2b')), 'button-loading-dark', '');
  // close class register modal
  delete ___arguments.context.pageData['bookContentModal'];
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, successText, 'green', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/6556e16439b13134bae8a36706899c44/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // Just close modal when user changes mind about cancelling booking.
  delete ___arguments.context.pageData['bookContentModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/073ae4ad91b47b7ae98b665bd1bd2a02/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // if teacher is not connected to Stripe we don't show packages available.
  // If user has a package that applies to the class, we never show this element.
  if (((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) || !(typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')) === 'string')) {
    return false;
  }

  return ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.relevantPackages')))) && !!(getObjectProperty(___arguments.context.pageData, 'bookContentModal.relevantPackages')).length)

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var string1;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pack_required')) {
    if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
      string1 = 'To book this class, a package is required:';
    } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
      string1 = 'To access this video, a package is required';
    } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
      string1 = 'You need a package to book this service:';
    }
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
      string1 = 'There are packages available for this class:';
    } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
      string1 = 'There are packages available for this video:';
    } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
      string1 = 'Consider a package for this service:';
    }
  }

  return string1

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/62bed3a102231c9d5c65b65e56069e7e/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'bookContentModal.relevantPackages'))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only show relevant packages if they exist.
  // If user has a package that applies to the class, we never show this element.
  // if teacher is not connected to Stripe we don't show packages available.
  if (((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')) || !(typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.stripe_account_id')) === 'string')) {
    return false;
  }

  return (!!((Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.relevantPackages')))) ? (getObjectProperty(___arguments.context.pageData, 'bookContentModal.relevantPackages')) : []).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/9d7c21483d1d4de171e8cd8c416cbdcb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    return 'Class registration';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'cancelClass') {
    return 'Cancel booking';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    return 'Video access';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
    return 'Book appointment';
  }

  return 'An error happened...'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/566ea5a880594b596f38fe1fe26beee4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var string1;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    string1 = 'This class is by donation. Please choose what you\'d like to pay to reserve your spot:';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    string1 = 'This video is by donation. Please choose what you\'d like to pay to access it:';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
    string1 = 'This service is by donation. Please choose what you\'d like to pay to book the appointment:';
  }

  return string1

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/8f0f561a8a1c6ed1bc720e7d3aed9f05/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If user doesn't have a package that applies to the content, we show this element.
  if (getObjectProperty(___arguments.context.pageData, 'bookContentModal.going')) {
    return false;
  }

  return ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.content.pack_required')) && !((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal.userPackageToUse')) === 'object')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/88de71d751f5c0d4abfadcbc85a8d7a6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var string1;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Class') {
    string1 = 'Sorry, this class';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Video') {
    string1 = 'Sorry, this video';
  } else if ((getObjectProperty(___arguments.context.pageData, 'bookContentModal.type')) == 'Appointment') {
    string1 = 'Sorry, this service';
  }

  return (String(string1) + String(' requires a package. Please contact me about getting one!'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/463c8f41299868259bcaaff425e31a24/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['bookContentModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_257107abaa5ba597ec32e7f3df68594c/logic/02466c01b3b843271af335eb439596db/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'bookContentModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'bookContentModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'bookContentModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['bookContentModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/b195721dbf2ee73196c5b8ed2b47ee21/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'singleStudent') {
    return (String('Email ') + String(getObjectProperty(___arguments.context.pageData, 'emailModal.full_name')));
  }
  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'registeredStudents') {
    return 'Email registered students';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'taggedStudents') {
    return 'Email students';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/2d6d90dd03cdc855311b6e748e4f5bde/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['emailModal'];

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/30cf981bd923c47429de80c996db6625/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// This block is only shown when email type is "taggedStudents". It can only work on Students page. Default visibility is off so we don't mount components that needs things from Students Page Data.

  return ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'taggedStudents')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/852af81ddce5396cdd6b58c4f8b97115/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'selectedFilters')))) {
    return ((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length == 0);
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/3d2133223b9bbf774b3d20c36e34b31e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('43fbd8fe29944d9e4b5a394588a73552'))['titleText'] = 'Filter students';
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('43fbd8fe29944d9e4b5a394588a73552'))['open'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/ef5d6469e13f314faf783578829343bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'selectedFilters')))) {
    return ((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length > 0);
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/c9f70c6d6d33505b9346e2f7f2bc175c/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedFilters'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/1d0c18ae000e03aacaaad315c443e084/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'selectedFilters')))) {
    return (((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).includes(___arguments.context.dataModel)) ? ['active'] : ['inactive']);
  }

  return ['inactive']

  },
  /* handler:onClassListAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'title')),' (',(getObjectProperty(___arguments.context.dataModel, 'numberUsed')),')'].join(''))

  },
  /* handler:onLabelAssignment */
  /* handler:onDelete */
  async ['onDelete'](___arguments) {
    var filteredList, filterTag, item, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Function which populates "filteredList" property
 * in page data. filteredList is a subset of the
 * entire studentList where selected tags are found.
 */
async function setFilteredList() {
  filteredList = (getObjectProperty(___arguments.context.appData, 'studentList'));
  // We pass the studentList through a loop where in each
  // iteration, we keep only the students with the selected
  // tag. Looping over all selected tags ("AND" logic).
  var j_list = (getObjectProperty(___arguments.context.pageData, 'selectedFilters'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    filterTag = (getObjectProperty(j, 'title'));
    // Go through students list and keep only the records where selected tags exist
    filteredList = (await asyncListFilter(filteredList, async (item) => {


     return (((getObjectProperty(item, 'tags')).map(item => item['title'])).includes(filterTag));
    }));
  }
  ___arguments.context.pageData['filteredList'] = filteredList;
}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'selectedFilters')), ___arguments.context.dataModel, '');
  await setFilteredList();

  },
  /* handler:onDelete */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/968fa80ee35e1db51f161191a84a3428/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var class_instance_id, tags, video_id;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a63a8c0b62bb4c0e318b74d54af7ba15')), 'button-loading');
  class_instance_id = null;
  video_id = null;
  tags = null;
  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) != 'singleStudent') {
    // If we're on Schedule page and user has attendance view open,
    // classInstanceObj will exist and contain objectId. See docs.
    if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'registeredStudents') {
      // Depending on if we're looking at registered students for a class,
      // or student list for video access, we set inputs for the massEmail API.
      if (typeof (getObjectProperty(___arguments.context.pageData, 'emailModal.class_instance_id')) === 'string') {
        class_instance_id = (getObjectProperty(___arguments.context.pageData, 'emailModal.class_instance_id'));
      } else if (typeof (getObjectProperty(___arguments.context.pageData, 'emailModal.video_id')) === 'string') {
        video_id = (getObjectProperty(___arguments.context.pageData, 'emailModal.video_id'));
      }
      // If we're on Students page, selectedFilters will be an array
      // of tags the user has selected. If the array is empty, all the
      // teacher's students will be targeted for an email. See docs.
      if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'taggedStudents') {
        // if selectedFilters is empty, tags input is not wired and all students are targeted by the email API.
        tags = !(getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length ? null : ((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).map(item => item['title']));
      }
      await Backendless.Request.post(`${Backendless.appPath}/services/email/ses/massemail`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'fromProfileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'classInstanceId': class_instance_id,'tags': tags,'subject': (getObjectProperty(___arguments.context.dataModel, 'emailSubject')),'message': (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getHTML() })('091dba840642324ef2d16f9fd407842c')),'videoId': video_id });
    }
  } else {
    await Backendless.Request.post(`${Backendless.appPath}/services/email/ses/sendemail`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'fromProfileId': (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id')),'toProfileIds': [(getObjectProperty(___arguments.context.pageData, 'emailModal.student_profile_id'))],'subject': (getObjectProperty(___arguments.context.dataModel, 'emailSubject')),'message': (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getHTML() })('091dba840642324ef2d16f9fd407842c')) });
  }
  delete ___arguments.context.pageData['emailModal'];
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, 'Message sent!', 'green', null);

  },
  /* handler:onSubmit */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/bd5f2756de3352c1dbbe3f2aaa726738/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var lengthFilteredList, item, listToFilter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  // If we're on Schedule page and user has attendance view
  // open, allStudents is an array that will exist. See docs.
  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'registeredStudents' && (typeof (getObjectProperty(___arguments.context.pageData, 'emailModal.class_instance_id')) === 'string')) {
    listToFilter = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'allStudents')), async (item) => {


     return (!(typeof (getObjectProperty(item, 'class_attendance_objectId')) === 'undefined'));
    }));
  }
  // If we're on Schedule page and user has attendance view
  // open, allStudents is an array that will exist. See docs.
  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'registeredStudents' && (typeof (getObjectProperty(___arguments.context.pageData, 'emailModal.video_id')) === 'string')) {
    listToFilter = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'allStudents')), async (item) => {


     return (!(typeof (getObjectProperty(item, 'video_access_objectId')) === 'undefined'));
    }));
  }
  // If we're on Students page and hs triggered email modal,
  // studentList and filteredList should both exist.
  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'taggedStudents') {
    // we check if tags are selected (and filteredList has arrived), if yes we use filteredList, if no we use studentList.
    listToFilter = (getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length > 0 && (Array.isArray((getObjectProperty(___arguments.context.pageData, 'filteredList')))) ? (getObjectProperty(___arguments.context.pageData, 'filteredList')) : (getObjectProperty(___arguments.context.appData, 'studentList'));
  }
  if (Array.isArray(listToFilter)) {
    // this variable is to make it easy to create the return
    // this is to remove people who are not subscribed or don't have an email.
    lengthFilteredList = (await asyncListFilter(listToFilter, async (item) => {


     return ((getObjectProperty(item, 'email_subscribed')) && !((getObjectProperty(item, 'skeleton_email')) == ''));
    })).length;
    return (String(lengthFilteredList) + String(lengthFilteredList == 1 ? ' student' : ' students'));
  }

  return 'Some students'

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Don't show number text if single student is being emailed.

  return (!((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'singleStudent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/a63a8c0b62bb4c0e318b74d54af7ba15/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('968fa80ee35e1db51f161191a84a3428'), 'emailSubject'))) {
    return true;
  }
  if ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'selectedFilters')))) && (Array.isArray((getObjectProperty(___arguments.context.appData, 'studentList'))))) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedFilters')).length > 0) {
      if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'filteredList')))) {
        return ((getObjectProperty(___arguments.context.pageData, 'filteredList')).length == 0);
      }
    } else {
      return ((getObjectProperty(___arguments.context.appData, 'studentList')).length == 0);
    }
  }
  // This will be 0 if no students are registered for the class. Disable send button.
  if ((getObjectProperty(___arguments.context.pageData, 'emailModal.type')) == 'registeredStudents') {
    if (typeof (getObjectProperty(___arguments.context.pageData, 'emailModal.class_instance_id')) === 'string') {
      return ((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'allStudents')), async (item) => {


       return (!(typeof (getObjectProperty(item, 'class_attendance_objectId')) === 'undefined'));
      })).length == 0);
    } else if (typeof (getObjectProperty(___arguments.context.pageData, 'emailModal.video_id')) === 'string') {
      return ((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'allStudents')), async (item) => {


       return (!(typeof (getObjectProperty(item, 'video_access_objectId')) === 'undefined'));
      })).length == 0);
    }
  }

  return false

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_e04d1e9e4be6f0cc227fa04892b73d3c/logic/100d05015718acc2f4c3ed15a5143da1/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'emailModal')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'emailModal'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'emailModal')) === 'object'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      delete ___arguments.context.pageData['emailModal'];

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/517d46ee30611d0bf26f94ac51eb99b3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If the page has "snippetText" string, then the snippet will show above the login/signup block

  return (typeof (getObjectProperty(___arguments.context.pageData, 'snippetText')) === 'string')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/c8796cc9497b573912c30d5b6ed6f6a4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'snippetTitle'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/1f789413596441adf1e430bd7d34f2b3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'snippetText'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/df2b093a4c680d91cbefbe765768b9bc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'first') {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      console.log((function () { return window.location.href })());

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/0a8f1876cceb9d8803068d23fc4eaaad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['openLoginModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/0a5e9442d45b012b87cf20c7fc8700ee/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, profileObj, TSRobject, txResult, createUserAndProfileTx, upsertProfileRef, createUserRef, createGenericPassRef, createSelfFollowRef, email, createSettingsRef, skeletonList, urlVariable, existingURLs;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * Big transaction with a number of operations: searches db for
 * profile that matches email but which has no user relation.
 * If found, take the 1st one....if not found, create new profile
 * object. Also creates "settings" object in profiles_settings,
 * and relates to the profile object. Also creates a new user
 * (triggering email confirmation) and relates user to profile.
 */
async function claimSkeletonOrCreateProfile(email) {
  skeletonList = (await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['skeleton_email=\'',email,'\'  AND user_email IS NULL'].join(''))).setPageSize(10)));
  if (skeletonList.length > 1) {
    // Send a warning to Sentry about there being 2 skeletons with the same email
    await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'warning','tags': ({ [`operation`]: 'Signup' }),'type': 'DB messy','value': (['Found more than one skeleton profile with email: ',email,'Admin may want to have a look and merge profiles'].join('')) });
  }
  // Get 1st profileObject that matches skeleton email. Will include objectId.
  profileObj = skeletonList[0];
  // if the query fails (no profile found with skeleton_email
  // matching user email), profileObject is not defined
  // and we have to create a profileObject and set an
  // email. This object will not yet have an objectId.
  if (typeof profileObj === 'undefined') {
    profileObj = ({ [`skeleton_email`]: email });
  }
  // Either way, we overwrite whatever the skeleton profile name and teacher status was
  // Here we set if the profile should be teacher or student. Depending on which page user has landed on. Generally, if they come in on Landing they will be a teacher, otherwise a student.
  // function below
  Object.assign(profileObj, ({ [`first_name`]: (getObjectProperty(___arguments.context.dataModel, 'firstName')),[`last_name`]: (getObjectProperty(___arguments.context.dataModel, 'lastName')),[`is_teacher`]: (getObjectProperty(___arguments.context.pageData, 'make_teacher')),[`url`]: await generateUniqueURL2() }));
  // Massive transaction now creates user, various table objects + relations...
  createUserAndProfileTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = createUserAndProfileTx;

    // Upsert profile object in profiles table.
    upsertProfileRef = (_uow.upsert('profiles', profileObj).setOpResultId('upsertProfile'));
    // Create object in profile_settings table. Default USD as currency.
    createSettingsRef = (_uow.create('profile_settings', ({ [`currency`]: 'US Dollar $',[`notification_class_registration`]: true,[`notification_new_student`]: true,[`notification_payment_received`]: true,[`notification_private_booking`]: true,[`notification_Ubindi_announcements`]: true,[`notification_video_accessed`]: true,[`timezone`]: (await (async function() {
    	console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
    	return Intl.DateTimeFormat().resolvedOptions().timeZone
    })()) })).setOpResultId('createSettingsObj'));
    // Relate profile_settings object to profile.
    _uow.setRelation('profiles', (upsertProfileRef.resolveTo('objectId')), 'settings', (createSettingsRef.resolveTo('objectId'))).setOpResultId('relateSettings');
    // Create user!
    createUserRef = (_uow.create('Users', ({ [`email`]: email,[`password`]: (getObjectProperty(___arguments.context.dataModel, 'password')) })).setOpResultId('createUserObj'));
    // Relate user object to profile.
    _uow.setRelation('profiles', (upsertProfileRef.resolveTo('objectId')), 'user_email', (createUserRef.resolveTo('objectId'))).setOpResultId('relateProfileToUser');
    console.log('Make user follow themselves');
    // Create teacher_student_rel object
    createSelfFollowRef = (_uow.create('teacher_student_rels', ({ [`notes`]: 'You are your own student just so you can play with the student experience.',[`email_subscribed`]: true })).setOpResultId('createSelfFollow'));
    // Set relation for student
    _uow.setRelation('teacher_student_rels', (createSelfFollowRef.resolveTo('objectId')), 'student_profile_id', (upsertProfileRef.resolveTo('objectId'))).setOpResultId('relateProfileTSRStudent');
    // Set relation for teacher
    _uow.setRelation('teacher_student_rels', (createSelfFollowRef.resolveTo('objectId')), 'teacher_profile_id', (upsertProfileRef.resolveTo('objectId'))).setOpResultId('relateProfileTSRTeacher');
    if (getObjectProperty(profileObj, 'is_teacher')) {
      // Create General Pass for new teachers only
      createGenericPassRef = (_uow.create('packages', ({ [`title`]: 'General Pass',[`description`]: 'This pass is active when your referral program is active.',[`active`]: false,[`editable`]: false,[`type`]: 'Pass',[`credits`]: 0,[`cost`]: 0 })).setOpResultId('createGenericPass'));
      // Set relation for teacher to their General Pass
      _uow.addToRelation('profiles', (upsertProfileRef.resolveTo('objectId')), 'packages', (createGenericPassRef.resolveTo('objectId'))).setOpResultId('relateProfileToGeneralPass');
    }

  })();

  txResult = (await createUserAndProfileTx.execute());
  if (txResult.isSuccess()) {
    profileObj = (getObjectProperty((txResult.getResults()), 'upsertProfile.result'));
    console.log('User registered. Profile is:');
    console.log(profileObj);
  } else {
    console.log('Transaction to register user failed. Want the error!');
    console.log(txResult.getError());
    throw (txResult.getError())
  }
  return profileObj
}

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

/**
 * New teacher registration requires URL for their public
 * profile, run this function to piece together first &
 * last name. If such a URL already exists, append number
 */
async function generateUniqueURL2() {
  // default url is first_name.last_name —  unless no last name supplied, then just first_name.
  urlVariable = String(getObjectProperty(___arguments.context.dataModel, 'firstName')) + String((getObjectProperty(___arguments.context.dataModel, 'lastName')) == null ? '' : String('.') + String(getObjectProperty(___arguments.context.dataModel, 'lastName')));
  // Get rid of any characters other than letters, numbers, underscores and hyphens.
  urlVariable = (urlVariable.match((new RegExp('[a-zA-Z0-9_\\.\\-]', 'g')))).join('');
  // Check if URL is already taken. If not, just return the initial url.
  if (0 == (await Backendless.Data.of('profiles').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['url = \'',urlVariable,'\''].join('')))))) {
    return urlVariable;
  } else {
    existingURLs = ((await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['url LIKE \'',urlVariable,'%\''].join(''))).setPageSize(10))).map(item => item['url']));
    while (existingURLs.includes(urlVariable)) {
      // Add random number to url variable until it's not in list of already taken URLs
      urlVariable = String(urlVariable) + String(mathRandomInt(1, 100));
    }
  }
  return urlVariable
}


  try {
    // prevent double clicking Go button
    ___arguments.context.dataModel['disableRegisterButton'] = true;
    // add class to show spinner
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a50edfc2764ed2b190dd556f2749e38f')), 'button-loading');
    // Upsert profile, create various relations, user, etc.
    profileObj = await claimSkeletonOrCreateProfile((getObjectProperty(___arguments.context.dataModel, 'email')));
    if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Public') {
      // See if the new user is already following the teacher whose public profile it is
      TSRobject = (await Backendless.Data.of('teacher_student_rels').find(Backendless.DataQueryBuilder.create().setWhereClause((['student_profile_id = \'',(getObjectProperty(profileObj, 'objectId')),'\'  AND teacher_profile_id = \'',(getObjectProperty(___arguments.context.pageData, 'profile_id')),'\''].join(''))).setPageSize(10)))[0];
      // if the query fails (no TSR found with student following this teacher),
      // TSR is not defined and we have to create a following relation.
      if (typeof TSRobject === 'undefined') {
        TSRobject = (await BackendlessUI.Functions.Custom['fn_54acde4f4ff93dd22b3943e777da5ac8']((getObjectProperty(___arguments.context.pageData, 'profile_id')), (getObjectProperty(profileObj, 'objectId')), null));
      }
    }
    console.log('trigger T_NewStudentSignUp template email');
    console.log('1 find all tsrs where this profile id is the student ');
    console.log('2 load info for student and array of their teachers');
    console.log('3 create a loop to send emails');
    // Need profile objectId for the image upload in next form, can't
    // use "getProfileObj" because there is no current user yet.
    ___arguments.context.pageData['profile_id'] = (getObjectProperty(profileObj, 'objectId'));
    // Need email for the confirm email prompt
    ___arguments.context.pageData['email'] = (getObjectProperty(___arguments.context.dataModel, 'email'));
    // Need profile objectId for the image upload in next form, can't
    // use "getProfileObj" because ther eis no current user yet.
    ___arguments.context.pageData['firstName'] = (getObjectProperty(___arguments.context.dataModel, 'firstName'));
    // Need profile objectId for the image upload in next form, can't
    // use "getProfileObj" because ther eis no current user yet.
    ___arguments.context.pageData['lastName'] = (getObjectProperty(___arguments.context.dataModel, 'lastName'));
    // Stop the spinner in the button
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a50edfc2764ed2b190dd556f2749e38f')), 'button-loading', '');
    // After user is successfully registered, hide the
    // register form and reveal the picture upload form.
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a5e9442d45b012b87cf20c7fc8700ee', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4c6da191e8a7839a0b9a7adc1cf4a3ef', true);

  } catch (error) {
    console.log(error);
    // Stop the spinner in the button
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('a50edfc2764ed2b190dd556f2749e38f')), 'button-loading', '');
    // If user email already exists in data base... show red help
    // text under form. If that's not the error, show general error.
    if (((JSON.stringify(error)).search('User already exists.')) > 0) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ce2b2484bb5d2507a38211cd0a3b8cd3', true);
    } else {
      ___arguments.context.dataModel['statusMessageRegister'] = 'Hm... an error occurred... please try again. If you have further trouble, please contact support.';
      // Send an error to Sentry
      await Backendless.Request.post(`${Backendless.appPath}/services/sentry/exception/create`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'level': 'error','tags': ({ [`operation`]: 'Signup' }),'type': 'Signup failed','value': (JSON.stringify(error)) });
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7aeefdd4202a6486704ed73b715b1d02', true);
    }

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/ec0ac631dc6bd8d6bc899c346ebf57b9/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee')['email'] = '';
  ___arguments.context.getComponentDataStoreByUid('0a5e9442d45b012b87cf20c7fc8700ee')['disableRegisterButton'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ce2b2484bb5d2507a38211cd0a3b8cd3', false);

  },
  /* handler:onFocus */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/f8d728870afa7bcea767d2d3f8d87009/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'second';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/e1d2eaa18e889a6137bb5c6e2adc60c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'second';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/4c6da191e8a7839a0b9a7adc1cf4a3ef/bundle.js', [], () => ({
  /* content */
  /* handler:onVisibilityChange */
  ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // Get profile data from App Data and configure/pre-fill form with the right text
  if ((getObjectProperty(___arguments.context.pageData, 'make_teacher')) == true) {
    ___arguments.context.dataModel['picPromptText'] = 'Profile saved! Add a pic to help your students recognize you:';
  } else {
    ___arguments.context.dataModel['picPromptText'] = 'Profile saved! Add a pic to help your teacher recognize you:';
  }
  ___arguments.context.dataModel['firstName'] = (getObjectProperty(___arguments.context.pageData, 'firstName'));
  ___arguments.context.dataModel['lastName'] = (getObjectProperty(___arguments.context.pageData, 'lastName'));

  },
  /* handler:onVisibilityChange */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
      await (async function() {
  	// code snippet from crop.guide, detects file upload process on page and inserts crop/resize overlay
  	await BackendlessUI.requireModule("https://cdn.crop.guide/loader/l.js?c=MDWNWW");

  })();
  ___arguments.context.pageData['imageURL'] = 'https://worthypickle.backendless.app/api/files/media/Thumbnails_large/defaultThumbnail.png';

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/a6eba7098159a1e5aee53dd16079737b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4c6da191e8a7839a0b9a7adc1cf4a3ef', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a5ff96cee68e90bd13dc3c30665f166', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/1a5ff96cee68e90bd13dc3c30665f166/bundle.js', [], () => ({
  /* content */
  /* handler:onVisibilityChange */
  ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['displayEmail'] = (String('Please click the link in the email that was just sent to ') + String(getObjectProperty(___arguments.context.pageData, 'email')));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/15c17cd587b31a77a8e638f8da3667ce/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'second') {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/a12d3f33b3e4b74f8a181eb2593ba22c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['openLoginModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/87d8c4464108bcd5b4795f55d02f3ecc/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, noRedirects;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

/**
 * Logic to redirect user to "Home" (student) or
 * "Schedule" (teacher) if they log in on certain pages.
 * Can expand the list of "redirect" pages as needed.
 */
async function decideLoginRedirect() {
  noRedirects = ['Home', 'Schedule', 'Students', 'Public'];
  if (!(noRedirects.includes((getObjectProperty(___arguments.context.pageData, 'page'))))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) ? 'Schedule' : 'Home'), undefined);
  }
}


  try {
    // prevent double clicking Login button
    ___arguments.context.dataModel['disableLoginButton'] = true;
    // add class to show spinner
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4fe175060916d01d01212fe43321f418')), 'button-loading');
    await Backendless.UserService.login( (getObjectProperty(___arguments.context.dataModel, 'email')), (getObjectProperty(___arguments.context.dataModel, 'password')), true  );
    await BackendlessUI.Functions.Custom['fn_6bbab8956421f262b3881eeaa1a2ab6b'](___arguments.context.appData, (getObjectProperty(___arguments.context.dataModel, 'email')));
    // Close modal after log in is successful
    ___arguments.context.pageData['openLoginModal'] = false;
    // stop the spinner by removing class
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4fe175060916d01d01212fe43321f418')), 'button-loading', '');
    // see the function
    await decideLoginRedirect();

  } catch (error) {
    // stop the spinner by removing class
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4fe175060916d01d01212fe43321f418')), 'button-loading', '');
    // If password doesn't match email...
    if (3003 == (getObjectProperty(error, 'code'))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9501641ef78fdb336afcec8f9813a1c1', true);
    } else {
      ___arguments.context.dataModel['statusMessageLogin'] = 'Sorry, there was an error logging you in. Please contact support!';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19454c07a6499f793448181c1b22f368', true);
      console.log(error);
    }

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/a9be600512b6059e71a569fe76d932de/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('87d8c4464108bcd5b4795f55d02f3ecc')['disableLoginButton'] = false;
  ___arguments.context.getComponentDataStoreByUid('87d8c4464108bcd5b4795f55d02f3ecc')['email'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9501641ef78fdb336afcec8f9813a1c1', false);

  },
  /* handler:onFocus */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/fea7296f95e64b5e3ebaa1c1e56b00d1/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('87d8c4464108bcd5b4795f55d02f3ecc')['disableLoginButton'] = false;
  ___arguments.context.getComponentDataStoreByUid('87d8c4464108bcd5b4795f55d02f3ecc')['password'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9501641ef78fdb336afcec8f9813a1c1', false);

  },
  /* handler:onFocus */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/da2ee60e1f419378d62ec5361cb3ce36/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'first';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/c16ec857110d481796eb468d7fce0651/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d66b16f0b2949195146fd7ffb1f3c3a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('64f3525a8904a53a86bfde6212825339', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/8e1f7981538ef39d880595eec49a47c9/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['recoveryTitleText'] = 'Password recovery';

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/c29a235a2bd65cf694538c49ce4150c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['openLoginModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/697a19c7eb39ce25be839bf43b888e83/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.UserService.restorePassword((getObjectProperty(___arguments.context.dataModel, 'recoveryEmail')));
    ___arguments.context.pageData['recoveryTitleText'] = 'Check your email';
    ___arguments.context.dataModel['recoveryText'] = (['We just sent an email to \'',(getObjectProperty(___arguments.context.dataModel, 'recoveryEmail')),'\'. \n \n Please click the password recovery link in that email to re-set your password.'].join(''));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90b8f3bbc705df61bbf22cf83f24f9a5', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('15a15aaeb731e13c4e81a75b134bc6df', false);

  } catch (error) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e6f62a05029b42788c3229ee64183d52', true);
    console.log(error);

  }

  },
  /* handler:onSubmit */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.dataModel['recoveryText'] = 'Enter your email & click "Reset password":';

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/90b8f3bbc705df61bbf22cf83f24f9a5/bundle.js', [], () => ({
  /* content */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('697a19c7eb39ce25be839bf43b888e83')['recoveryEmail'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e6f62a05029b42788c3229ee64183d52', false);

  },
  /* handler:onFocus */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/3c3d79249f9a32064cf283882b7b9cf8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('64f3525a8904a53a86bfde6212825339', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d66b16f0b2949195146fd7ffb1f3c3a', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/b284748973ac87342054fcc16c9477c5/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'is_teacher'))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/b3ed97f2b28afad24275f992092f3c6f/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var profileObject, backendlessProfileFile, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Get profile object, add reference to the thumbnail
 * file, save updated object to profiles table.
 */
async function saveImageFileReference() {
  // Get record for the user... add thumbnail file reference, save to database.
  profileObject = (await Backendless.Data.of('profiles').findById((getObjectProperty(___arguments.context.pageData, 'profile_id')), ));
  // replace whatever was in Form Data with new thumbnail image link
  profileObject['thumbnail'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': ___arguments.uploadedFiles,'path': 'profiles/thumbnails/large' }));
  // get link to small avatar/thumbnail of the user
  // Makes a small thumbnail if not used before... otherwise returns cached image or link to small image. See here: https://backendless.com/features/marketplace/image-processor-api
  backendlessProfileFile = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/ImageProcessor/resize`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'image_path': JSON.stringify(___arguments.uploadedFiles),'width': 64,'height': 64 }).send()), 'url'));
  profileObject['thumbnail_small'] = (await Backendless.Request.post(`${Backendless.appPath}/services/utils/s3/putobject`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'url': backendlessProfileFile,'path': 'profiles/thumbnails/small' }));
  await Backendless.Data.of('profiles').save( profileObject );
}


  ___arguments.context.pageData['imageURL'] = ___arguments.uploadedFiles;
  try {
    await saveImageFileReference();

  } catch (error) {
    console.log(error);

  } finally {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4c6da191e8a7839a0b9a7adc1cf4a3ef', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a5ff96cee68e90bd13dc3c30665f166', true);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// We name the image file according to profile_id. If there is none (anon testing), set to "test_1").

  return (String(getObjectProperty(___arguments.context.pageData, 'profile_id')) + String('_large'))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/eb350df2a01812ab72efd4b7548a14f7/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('green'))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_abb5b96998a68189fb9ddfdf3a66487d/logic/99029e1b351cbfbaa228d4d14d453a79/bundle.js', [], () => ({
  /* content */
  /* handler:modalVisibilityLogic */
  ['modalVisibilityLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'openLoginModal'))

  },
  /* handler:modalVisibilityLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
      ___arguments.context.pageData['openLoginModal'] = false;

  },
  /* handler:onClose */
  /* content */
}))

define('./components/reusable/r_bdcf5676a642bdcdf387f00c223aec45/logic/e92ff7f82fe8e1b5770ede13e3114182/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) != 'Account' && (getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      // opens assignPassModal... of course, this must exist on the page.
  ___arguments.context.pageData['assignPassModal'] = ({ 'open': true,'packageObj': ___arguments.context.rootComponentData });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bdcf5676a642bdcdf387f00c223aec45/logic/0934f5aa846d8ffa1dee6dbeb81cd093/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// the bin only shows for subscriptions (not passes) on the users account page or on the Student Detail page

  return (((getObjectProperty(___arguments.context.pageData, 'page')) == 'Account' || (getObjectProperty(___arguments.context.pageData, 'page')) == 'StudentDetail') && (getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription' && (getObjectProperty(___arguments.context.rootComponentData, 'sub_status')) == 'active')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('e25ba27b799950daf937a0d2fb92cfbc');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bdcf5676a642bdcdf387f00c223aec45/logic/5b6907da0b11ffd1bd79ee132fef0ee2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var color;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  color = 'red';
  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass') {
    if ((getObjectProperty(___arguments.context.rootComponentData, 'credits')) > 0) {
      color = 'green';
    }
  }
  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription') {
    if ((getObjectProperty(___arguments.context.rootComponentData, 'sub_status')) == 'active') {
      color = 'green';
    }
  }

  return [color, 'timeSmall']

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var color;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription') {
    return (getObjectProperty(___arguments.context.rootComponentData, 'sub_status'));
  }
  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass') {
    return (String(getObjectProperty(___arguments.context.rootComponentData, 'credits')) + String((getObjectProperty(___arguments.context.rootComponentData, 'credits')) == 1 ? ' credit' : ' credits'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bdcf5676a642bdcdf387f00c223aec45/logic/a83defc82a063d8d0969dc0bb2a69a42/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Account')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ [`profile_url`]: (getObjectProperty(___arguments.context.rootComponentData, 'teacher_url')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bdcf5676a642bdcdf387f00c223aec45/logic/4c2a1b839b0efed19b9e78f27c13f931/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Subscription') {
    return (String('Last billing ') + String(new Intl.DateTimeFormat(undefined, undefined).format((new Date((getObjectProperty(___arguments.context.rootComponentData, 'current_period_start')))))));
  }
  if ((getObjectProperty(___arguments.context.rootComponentData, 'type')) == 'Pass') {
    return (((getObjectProperty(___arguments.context.rootComponentData, 'expiry_date')) === null) ? 'Never expires' : String('Expires on ') + String(new Intl.DateTimeFormat(undefined, undefined).format((new Date((getObjectProperty(___arguments.context.rootComponentData, 'expiry_date')))))));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_bdcf5676a642bdcdf387f00c223aec45/logic/5577b2ab1c015bdeda8b751cf57cf132/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('e25ba27b799950daf937a0d2fb92cfbc');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bdcf5676a642bdcdf387f00c223aec45/logic/057a5d94d55fcd0f483d57541342260d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var cancelledSubscription;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('057a5d94d55fcd0f483d57541342260d')), 'button-loading-dark');
  cancelledSubscription = (await Backendless.Request.delete(`${Backendless.appPath}/services/stripe/cancel_student_subscription`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'student_package_obj': ({ [`objectId`]: (getObjectProperty(___arguments.context.rootComponentData, 'student_package_object_id')),[`stripe_subscription_id`]: (getObjectProperty(___arguments.context.rootComponentData, 'stripe_subscription_id')) }),'stripe_account_id': (getObjectProperty(___arguments.context.rootComponentData, 'stripe_account_id')) }));
  ___arguments.context.rootComponentData['sub_status'] = 'cancelled';
  await BackendlessUI.Functions.Custom['fn_7e5839b30aa99041f10d110f888e624f'](___arguments.context.pageData, (['Your subscription "',(getObjectProperty(___arguments.context.rootComponentData, 'title')),'" is cancelled — you won\'t be billed anymore.'].join('')), 'green', null);
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('e25ba27b799950daf937a0d2fb92cfbc');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/4c81b0768b23ea196dc605d646314761/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Home') {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/feb33f72600ab3c4863660fa1bffa9a9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Schedule', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Schedule') {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/7232818ed00e5e24120315a63d76e05d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Students', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Students' || (getObjectProperty(___arguments.context.pageData, 'page')) == 'StudentDetail') {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/c3c290d9d8ca8d4c436ae223a769289a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function loadSelfTeacherData() {
  ___arguments.context.pageData['profile_url'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'url'));
  // get profile object and some related data for the teacher who's public page it is.
  ___arguments.context.pageData['teacherData'] = ((await Backendless.Data.of('profiles').find(Backendless.DataQueryBuilder.create().setWhereClause((['url = \'',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'url')),'\''].join(''))).setRelated(['locations', 'settings']).setPageSize(1)))[0]);
  (getObjectProperty(___arguments.context.pageData, 'teacherData'))['locations'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'teacherData.locations')), async (item) => {


   return (getObjectProperty(item, 'active'));
  }));
  // if user is following the teacher on this page or
  // not, we set "following" property in Page Data
  if (Array.isArray((getObjectProperty(___arguments.context.appData, 'teachersFollowed')))) {
    (getObjectProperty(___arguments.context.pageData, 'teacherData'))['following'] = (((getObjectProperty(___arguments.context.appData, 'teachersFollowed')).map(item => item['teacher_profile_id'])).includes((getObjectProperty(___arguments.context.pageData, 'teacherData.objectId'))));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'teacherData'))['following'] = false;
  }
  console.log('have to completely re-mount scrolly container');
}

/**
 * Describe this function...
 */
async function remountScrollyContent() {
  // This trigger unmounting of classes content in megaScrolly.
  // Remounting later will trigger a fetch (refresh) from the database.
  ___arguments.context.pageData['unmountClassScrolly'] = true;
  // This triggers unmounting of videos content in megaScrolly.
  // Remounting later will trigger a fetch (refresh) from the database.
  ___arguments.context.pageData['unmountVideoScrolly'] = true;
  // This triggers unmounting of videos content in megaScrolly.
  // Remounting later will trigger a fetch (refresh) from the database.
  ___arguments.context.pageData['unmountPrivateScrolly'] = true;
  // This triggers unmounting of videos content in megaScrolly.
  // Remounting later will trigger a fetch (refresh) from the database.
  ___arguments.context.pageData['unmountPackageScrolly'] = true;
  await new Promise(r => setTimeout(r, 500 || 0));
  delete ___arguments.context.pageData['unmountClassScrolly'];
  delete ___arguments.context.pageData['unmountVideoScrolly'];
  delete ___arguments.context.pageData['unmountPrivateScrolly'];
  delete ___arguments.context.pageData['unmountPackageScrolly'];
}


  // If user is a teacher on a different teachers page, we
  // have to re-load teacherData without going to a new page.
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Public') {
    await loadSelfTeacherData();
    await remountScrollyContent();
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Public', ({ [`profile_url`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'url')) }));
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  // If the profile_id of current user matches profile_id of Page Data then user is looking at their own public page, so navItem is active.
  if ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Public' && (getObjectProperty(___arguments.context.pageData, 'teacherData.objectId')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'profile_id'))) {
    return ['navButton', 'active'];
  }

  return ['navButton']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/fca3455a1922a7d685d14c297dfdd116/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fca3455a1922a7d685d14c297dfdd116')).includes('active')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fca3455a1922a7d685d14c297dfdd116')), 'active', '');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fca3455a1922a7d685d14c297dfdd116')), 'active');
  }
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('aa2728a76d8fc01cefd4e66dfee8790b')).includes('show')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('aa2728a76d8fc01cefd4e66dfee8790b')), 'show', '');
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('debf54f7f457bcf74a568cf8dedb6b84')), 'show', '');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('aa2728a76d8fc01cefd4e66dfee8790b')), 'show');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('debf54f7f457bcf74a568cf8dedb6b84')), 'show');
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'page')) == 'Account' || (getObjectProperty(___arguments.context.pageData, 'page')) == 'Settings' || (getObjectProperty(___arguments.context.pageData, 'page')) == 'Upgrade' ? ['active', 'navButton'] : ['navButton'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/0f9d2bcf8f3cf878ce07ab5627167d85/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (!(null == (await Backendless.UserService.getCurrentUser(false))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/debf54f7f457bcf74a568cf8dedb6b84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('aa2728a76d8fc01cefd4e66dfee8790b')).includes('show')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('aa2728a76d8fc01cefd4e66dfee8790b')), 'show', '');
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('debf54f7f457bcf74a568cf8dedb6b84')), 'show', '');
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fca3455a1922a7d685d14c297dfdd116')), 'active', '');
  }
  console.log('click happened');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/40bc98a2226c62dd0202ebed4b69572e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// Only teachers get access to a 'Settings' pag

  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Settings', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/61837ee9217a0d5d11d7669c29e9599b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


// If profile is teacher and on a free plan (or there's no upgrade record), show them option to upgrade.
// if profile is not upgraded (null in "upgraded" in profileObj), show upgrade option in menu
// if profile is not upgraded (null in "upgraded" in profileObj), show upgrade option in menu

  return (((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded.plan_name')) == 'Free' || ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'upgraded')) === null)) && (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher')) == true)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Upgrade', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/0e4f85abc0886a960b326db5bf031991/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'avatar')) === null) ? (await BackendlessUI.Functions.Custom['fn_5b0f4231d30bdc56dbdfa912013ea529']('small')) : (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'avatar')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/f0edba02ee73e31bd3d690bf4b4bb1ee/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'avatar')) == null ? 'https://worthypickle.backendless.app/api/files/media/Thumbnails_small/default_small.png' : (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'avatar')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/82fe95136957b01c9257c128da8d225e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'full_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/f8119cb739c1b40abd89248ba32d5c6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var make_teacher;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.logout();
  make_teacher = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_caae0044ca52123d14818f26157c82b8']()), 'is_teacher'));
  // Wipe out profileObj!
  localStorage.removeItem('profileObj');
  await BackendlessUI.Functions.Custom['fn_764b1cb20d01ea0112cf5815a4efac0e'](___arguments.context.appData);
  // In case user registers a new user on Landing, we don't make them a teacher profile if when they logged out they were not a teacher. "make_teacher" in Page Data of Landing page sets this.
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Landing', ({ [`make_teacher`]: make_teacher }));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/d243f79f026fb2ba0f155c89ac3e35cd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Account', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/e99812970bfd83145df74fbbd21d122e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://help.ubindi.com', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7a56dbdecdd25ccf755a5cda044f50e4/logic/c13be07ee33732a6157ff1cc0b214bba/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((await Backendless.UserService.getCurrentUser(false)) !== null && !Array.isArray((await Backendless.UserService.getCurrentUser(false))) && (typeof (await Backendless.UserService.getCurrentUser(false)) === 'object')) ? (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'admin')) : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Admin', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_fe3b5e29de5719cf656b31466277c55b/logic/dc448e85251ae2a87536223c36123d52/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'toastrClasses')) === 'undefined') ? ['toastr'] : (getObjectProperty(___arguments.context.pageData, 'toastrClasses')))

  },
  /* handler:onClassListAssignment */
  /* content */
}))

